import React, { Component } from "react";

import "./OptionButton.scss";

class OptionButton extends Component {
    imageUrl(e, option) {
        const { getImage, imageFormat } = this.props;

        if (!getImage)
            return `${
                process.env.PUBLIC_URL
            }/assets/icons/${option.value.toLowerCase().replace("/", "_")}.${
                imageFormat || "png"
            }`;

        return getImage(option.value);
    }

    click(value) {
        const { selectAction, deselectAction, selected } = this.props;

        if (selected) deselectAction(value);
        else selectAction(value);
    }

    render() {
        const { option, selected, text, noIcon, hexCode } = this.props;

        let className = "option-button";
        if (selected) className += " selected";
        if (hexCode) className += " with-colour";

        return (
            <div
                className={className}
                onClick={this.click.bind(this, option.id)}
            >
                {hexCode && (
                    <div style={{ backgroundColor: hexCode }}>
                        <img
                            src={this.imageUrl(this, option)}
                            onError={(event) =>
                                event.target.parentNode.removeChild(
                                    event.target
                                )
                            }
                        />
                    </div>
                )}
                {!hexCode && !noIcon && (
                    <img
                        src={this.imageUrl(this, option)}
                        onError={(event) =>
                            event.target.parentNode.removeChild(event.target)
                        }
                    />
                )}
                <p
                    className={
                        noIcon || !this.imageUrl(this, option) ? "no-icon" : ""
                    }
                >
                    {option.value}
                </p>
                {text && <small>{text}</small>}
            </div>
        );
    }
}

export default OptionButton;

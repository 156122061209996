export const employeeRanges = [
    // TODO LOW entity?
    "0-10",
    "10-50",
    "50-100",
    "100-500",
    "500-1000",
    "1000+"
];

export const timescaleOptions = [
    {
        // TODO LOW entity?
        id: 1,
        value: "1-6 months"
    },
    {
        id: 2,
        value: "6-12 months"
    },
    {
        id: 3,
        value: "12-18 months"
    },
    {
        id: 4,
        value: "18 months+"
    }
];

export const financeRoundOptions = [
    {
        // TODO LOW entity?
        id: 1,
        value: "1"
    },
    {
        id: 2,
        value: "2"
    },
    {
        id: 3,
        value: "3"
    },
    {
        id: 4,
        value: "4"
    },
    {
        id: 5,
        value: "5"
    }
];

export const turnoverOptions = [
    { id: "Pre Revenue", value: "Pre Revenue" },
    { id: "< £100000", value: "< £100000" },
    { id: "£100,000 - £500,000", value: "£100,000 - £500,000" },
    { id: "£500,00 - £750,000", value: "£500,00 - £750,000" },
    { id: "£750,000- £1m", value: "£750,000- £1m" },
    { id: "£1m - £2m", value: "£1m - £2m" },
    { id: "£2m - £5m", value: "£2m - £5m" },
    { id: "£5m - £10m", value: "£5m - £10m" },
    { id: "£15m - £20m", value: "£15m - £20m" },
    { id: "£20m - £50m", value: "£20m - £50m" },
    { id: "£50m +", value: "£50m +" }
];
// TODO MED add these codes etc to the regions to do a proper lookup
export const regionCodes = {
    europe: "DE",
    asia: "IN",
    africa: "CF",
    mena: "AE",
    northAmerica: "US",
    southAmerica: "BR"
};

export const regionNames = {
    africa: "Africa",
    asia: "Asia and Pacific",
    europe: "Europe",
    northAmerica: "North America",
    southAmerica: "Latin America and the Caribbean",
    mena: "Middle East and North Africa",
    global: "Worldwide",
    GB: "United Kingdom",
    Multi: "Multiple locations"
};

export const regionIcons = {
    GB: `${process.env.PUBLIC_URL}/assets/icons/regions/gb.png`,
    northAmerica: `${process.env.PUBLIC_URL}/assets/icons/regions/na.jpg`,
    europe: `${process.env.PUBLIC_URL}/assets/icons/regions/Africa, Europe, Middle East.png`,
    africa: `${process.env.PUBLIC_URL}/assets/icons/regions/Africa, Europe, Middle East.png`,
    asia: `${process.env.PUBLIC_URL}/assets/icons/regions/Asia Pacific.png`,
    mena: `${process.env.PUBLIC_URL}/assets/icons/regions/Africa, Europe, Middle East.png`,
    southAmerica: `${process.env.PUBLIC_URL}/assets/icons/regions/N and S Americas.png`,
    Africa: `${process.env.PUBLIC_URL}/assets/icons/regions/Africa, Europe, Middle East.png`,
    "Asia and Pacific": `${process.env.PUBLIC_URL}/assets/icons/regions/Asia Pacific.png`,
    Europe: `${process.env.PUBLIC_URL}/assets/icons/regions/Africa, Europe, Middle East.png`,
    "North America": `${process.env.PUBLIC_URL}/assets/icons/regions/na.jpg`,
    "Latin America and the Caribbean": `${process.env.PUBLIC_URL}/assets/icons/regions/N and S Americas.png`,
    "Middle East and North Africa": `${process.env.PUBLIC_URL}/assets/icons/regions/Africa, Europe, Middle East.png`,
    "United Kingdom": `${process.env.PUBLIC_URL}/assets/icons/regions/gb.png`,
    Multi: `${process.env.PUBLIC_URL}/assets/icons/regions/Asia Pacific.png`,
    global: `${process.env.PUBLIC_URL}/assets/icons/regions/Asia Pacific.png`
};

export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

// TODO add to db
export const abbreviations = {
    sectorInterests: {
        1: "Energy...",
        2: "Aggregates...",
        3: "Industrials...",
        4: "B Services...",
        5: "Impact",
        6: "Auto...",
        7: "Apparel...",
        8: "Education...",
        9: "Ent. & Media",
        10: "Retail",
        11: "Food...",
        12: "Health...",
        13: "Pharma...",
        14: "Fin. Services...",
        15: "Comm. Prop...",
        16: "Resi. Prop..."
    },
    expertise: {
        5: "Commercial",
        6: "Bank...",
        7: "Clinical...",
        8: "Comp.",
        9: "Construction",
        10: "Corp...",
        11: "Employment",
        14: "Environ...",
        12: "Intel...",
        13: "International...",
        15: "Maritime",
        16: "Media",
        17: "Prop...",
        18: "Sports",
        19: "Audit",
        20: "Financial...",
        21: "Management...",
        22: "Tax...",
        23: "Forensic...",
        24: "Project...",
        25: "Insolvency",
        26: "Internal...",
        27: "Govt...",
        28: "Audit...",
        29: "Busines...",
        30: "Corp. Fin...",
        31: "Corp. Simp...",
        32: "Deals",
        33: "Strategy",
        34: "Global Op...",
        35: "Int. Services",
        36: "Investment",
        37: "Legal",
        38: "Bus. Ops",
        39: "Public...",
        40: "Employers...",
        41: "Product...",
        42: "Prof. Indemnity",
        43: "Bus. Insurance",
        44: "Bus. Prop...",
        45: "Key...",
        46: "Credit...",
        47: "Cyber..."
    }
};

export const roles = {
    investor: 1,
    business: 2,
    professional: 3
};

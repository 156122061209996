import React, { Component } from "react";

import "./Carousel.scss";

class Carousel extends Component {
    state = {
        currentSlide: 0,
        carouselWidth: 0,
        slideWidth: 0,
        maxSlides: 0,
        numSlides: 0,
        endSlide: 0,
        hiddenSlides: []
    };

    slideForward() {
        const { currentSlide, endSlide } = this.state;

        let newSlide = currentSlide + 1;
        if (newSlide > endSlide) newSlide = 0;

        this.setState({
            currentSlide: newSlide
        });
    }

    slideBackward() {
        const { currentSlide, endSlide } = this.state;

        let newSlide = currentSlide - 1;
        if (newSlide < 0) newSlide = endSlide;

        this.setState({
            currentSlide: newSlide
        });
    }

    componentDidMount() {
        const resize = this.resize.bind(this);
        resize();
        window.onresize = function () {
            resize();
        };
        setInterval(() => {
            resize();
        }, 400);
    }

    resize() {
        const { idKey, children } = this.props;

        const carousel = document.querySelector(`#${idKey}`);
        if (!carousel) return;

        const carouselWidth = carousel.clientWidth;
        const slide = carousel.querySelector(".slide");
        if (!slide) return;

        const slideWidth = slide.clientWidth;
        const maxSlides = Math.floor(carouselWidth / slideWidth);
        const numSlides = children.length;
        let endSlide = numSlides - maxSlides;
        if (endSlide < 0) endSlide = 0;

        this.setState({
            carouselWidth,
            slideWidth,
            maxSlides,
            numSlides,
            endSlide
        });
    }

    hideSlide(slide) {
        const { hiddenSlides } = this.state;

        this.setState({
            hiddenSlides: [...hiddenSlides, slide]
        });

        setTimeout(() => {
            this.resize();
        }, 400);
    }

    render() {
        const { children, idKey, small } = this.props;
        const {
            slideWidth,
            currentSlide,
            endSlide,
            maxSlides,
            hiddenSlides
        } = this.state;

        return (
            <section
                className={small ? "small carousel" : "carousel"}
                id={idKey}
            >
                {endSlide > 0 && (
                    <div
                        className="previous"
                        onClick={this.slideBackward.bind(this)}
                    ></div>
                )}
                <div
                    className="wrapper"
                    style={{ left: -(slideWidth * currentSlide) }}
                >
                    {children.map((child, index) => {
                        let className = "slide";

                        if (index < currentSlide) className += " unfocused";
                        if (index >= currentSlide + maxSlides)
                            className += " unfocused";

                        if (
                            hiddenSlides.indexOf(child.props.opportunityId) > -1
                        )
                            className += " hidden";

                        return (
                            <div key={`slide${index}`} className={className}>
                                {React.cloneElement(child, {
                                    hideSlide: this.hideSlide.bind(
                                        this,
                                        child.props.opportunityId
                                    )
                                })}
                            </div>
                        );
                    })}
                </div>
                {endSlide > 0 && (
                    <div
                        className="next"
                        onClick={this.slideForward.bind(this)}
                    ></div>
                )}
            </section>
        );
    }
}

export default Carousel;

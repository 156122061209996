import React, { Component } from "react";

import "./ProfileIcon.scss";

const PATH_TO_DEFAULT_ICON = `${process.env.PUBLIC_URL}/assets/default-user.svg`;

class ProfileIcon extends Component {
    render() {
        const { person, large, extraLarge } = this.props;

        let className = "profile-icon";
        if (large) className += " large";
        if (extraLarge) className += " extra-large";

        return (
            <div className={className}>
                {person.profilePhoto && (
                    <img
                        src={`${process.env.PUBLIC_URL}${person.profilePhoto}`}
                    />
                )}
                {!person.profilePhoto && <img src={PATH_TO_DEFAULT_ICON} />}
            </div>
        );
    }
}

export default ProfileIcon;

import React, { Component } from "react";

import "./Modal.scss";

class Modal extends Component {
    close() {
        const { closeModal } = this.props;

        closeModal();
    }

    render() {
        const { fullScreen, closable, visible, children } = this.props;

        let modalWrapperClassName = "modal-wrapper";
        if (fullScreen) modalWrapperClassName += " full-screen";
        if (visible) modalWrapperClassName += " visible";

        return (
            <div className={modalWrapperClassName}>
                <div className="modal">
                    {closable && (
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                            className="close"
                            alt="Close"
                            onClick={this.close.bind(this)}
                        />
                    )}
                    {children}
                </div>
            </div>
        );
    }
}

export default Modal;

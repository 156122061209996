import React, { Component } from "react";
import { connect } from "react-redux";

import ContentToggler from "../../components/content-toggler/ContentToggler";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import Connection from "../../components/connection/Connection";
import Button from "../../components/button/Button";
import ProfileIcon from "../../components/profile-icon/ProfileIcon";

import {
    setFocusedUserAction,
    connectToUserAction,
    ignoreUserAction,
    getConnectionRequestsAction,
    getConnectionSuggestionsAction
} from "../../actions/network/networkActions";

import { mapPerson } from "../../data/people";

import "./NetworkOverview.scss";

class NetworkOverview extends Component {
    componentDidMount() {
        const {
            getConnectionRequestsAction,
            getConnectionSuggestionsAction
        } = this.props;

        getConnectionRequestsAction();
        getConnectionSuggestionsAction();
    }

    setFocusedUser(user) {
        const { setFocusedUserAction } = this.props;

        setFocusedUserAction(user);
    }

    acceptConnection(user) {
        const { auth, connectToUserAction } = this.props;

        connectToUserAction(auth.user.id, user.id);
        getConnectionRequestsAction();
        getConnectionSuggestionsAction();
    }

    ignoreConnection(user) {
        const { auth, ignoreUserAction } = this.props;

        ignoreUserAction(auth.user.id, user.id);
        getConnectionRequestsAction();
        getConnectionSuggestionsAction();
    }

    render() {
        const { network, setFocusedUserAction } = this.props;

        return (
            <>
                <HighlightedContent
                    small
                    headline="My network"
                ></HighlightedContent>
                <MainContent>
                    <div className="network-overview">
                        <ContentToggler
                            tabs={[
                                {
                                    id: "invites",
                                    title: (
                                        <>Invites ({network.requests.length})</>
                                    ),
                                    content: network.requests.map((request) => (
                                        <div className="request">
                                            <ProfileIcon
                                                person={request}
                                                large
                                            />
                                            <div className="details">
                                                <strong>{request.name}</strong>
                                                <small>
                                                    {request.occupationName}
                                                </small>
                                                <p>{request.biography}</p>
                                                <Button
                                                    onClick={this.setFocusedUser.bind(
                                                        this,
                                                        request
                                                    )}
                                                >
                                                    View details
                                                </Button>
                                                <div className="actions">
                                                    <Button
                                                        onClick={this.acceptConnection.bind(
                                                            this,
                                                            request
                                                        )}
                                                    >
                                                        Connect
                                                    </Button>
                                                    <Button
                                                        onClick={this.ignoreConnection.bind(
                                                            this,
                                                            request
                                                        )}
                                                    >
                                                        Ignore
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                },
                                {
                                    id: "suggestions",
                                    title: "Suggestions",
                                    content: network.suggestions.map(
                                        (newConnection) => (
                                            <div className="request">
                                                <ProfileIcon
                                                    person={newConnection}
                                                    large
                                                />
                                                <div className="details">
                                                    <strong>
                                                        {newConnection.name}
                                                    </strong>
                                                    <small>
                                                        {
                                                            newConnection.occupationName
                                                        }
                                                    </small>
                                                    <p>
                                                        {
                                                            newConnection.biography
                                                        }
                                                    </p>
                                                    <Button
                                                        onClick={this.setFocusedUser.bind(
                                                            this,
                                                            newConnection
                                                        )}
                                                    >
                                                        View details
                                                    </Button>
                                                    <div className="actions">
                                                        <Button
                                                            onClick={this.acceptConnection.bind(
                                                                this,
                                                                newConnection
                                                            )}
                                                        >
                                                            Connect
                                                        </Button>
                                                        <Button
                                                            onClick={this.ignoreConnection.bind(
                                                                this,
                                                                newConnection
                                                            )}
                                                        >
                                                            Ignore
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                },
                                {
                                    id: "my-network",
                                    title: "My Network",
                                    content: network.connections.map(
                                        (newConnection) => (
                                            <Connection
                                                connection={newConnection}
                                                setFocusedUserAction={
                                                    setFocusedUserAction
                                                }
                                            />
                                        )
                                    )
                                }
                            ]}
                        />
                    </div>
                </MainContent>
            </>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        network: state.network
    }),
    {
        setFocusedUserAction,
        connectToUserAction,
        ignoreUserAction,
        getConnectionRequestsAction,
        getConnectionSuggestionsAction
    }
)(NetworkOverview);

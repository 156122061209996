import React, { Component } from "react";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import ProfileIcon from "../profile-icon/ProfileIcon";

import "./DocumentUploader.scss";

class DocumentUploader extends Component {
    upload(e) {
        const { uploadDocumentAction, keyName } = this.props;

        const file = e.target.files[0];
        uploadDocumentAction(file, keyName);
    }

    openFileUpload() {
        const { keyName } = this.props;
        document.getElementById(`document-upload-${keyName}`).click();
    }

    removeDocument(id) {
        const { removeDocumentAction, keyName } = this.props;

        removeDocumentAction(id, keyName);
    }

    render() {
        const {
            documentUploading,
            documents,
            keyName,
            multiUpload
        } = this.props;

        return (
            <div
                className={
                    documentUploading
                        ? "document-uploader loading"
                        : "document-uploader"
                }
            >
                {documentUploading && <Loader small />}
                {(multiUpload || (!multiUpload && documents.length === 0)) && (
                    <>
                        <input
                            type="file"
                            onChange={this.upload.bind(this)}
                            accept="*"
                            id={`document-upload-${keyName}`}
                        />
                        <Button onClick={this.openFileUpload.bind(this)}>
                            Upload document
                        </Button>
                    </>
                )}

                <div className="documents">
                    {documents.map((document) => (
                        <div className="document">
                            <span>{document.name}</span>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/bin-grey.png`}
                                onClick={this.removeDocument.bind(
                                    this,
                                    document.id
                                )}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default DocumentUploader;

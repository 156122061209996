import React, { Component } from "react";

import "./RangeGroup.scss";

import Range from "../range/Range";
import PlainCard from "../plain-card/PlainCard";

class RangeGroup extends Component {
    state = {
        lookupValue: "",
        baseValue: "",
        initialValue: "",
        rawValue: 0
    };

    componentDidMount() {
        const { lookup, initialValue } = this.props;

        if (lookup) {
            let index = initialValue;
            if (initialValue === null) {
                index = 0;
            }

            this.setState({
                lookupValue: lookup[index],
                initialValue,
                baseValue: lookup[0],
                rawValue: index
            });
        }
    }

    componentDidUpdate() {
        const { lookup, initialValue } = this.props;
        const { lookupValue, rawValue } = this.state;

        if (!lookupValue) return;

        if (lookup[rawValue] !== lookupValue) {
            this.onChange(rawValue);
            this.setState({
                baseValue: lookup[0]
            });
        }

        if (lookup && this.state.initialValue !== initialValue) {
            let index = initialValue;
            if (initialValue === null) {
                index = 0;
            }

            this.onChange(index);
            this.setState({
                lookupValue: lookup[index],
                initialValue
            });
        }
    }

    onChange(value) {
        const { lookup, lookupId, onChange } = this.props;

        if (lookup)
            this.setState({
                lookupValue: lookup[value],
                rawValue: value
            });

        onChange(value, lookup[value], lookupId ? lookupId[value] : null);
    }

    render() {
        const {
            initialValue,
            min,
            max,
            idKey,
            step,
            small,
            lookup,
            notches,
            smallIcon,
            largeIcon
        } = this.props;

        return (
            <PlainCard className={small ? "range-group small" : "range-group"}>
                {lookup && (
                    <p className="range-lookup-label">
                        {this.state.lookupValue}
                    </p>
                )}

                {smallIcon && <img src={smallIcon} className="small-icon" />}
                {largeIcon && <img src={largeIcon} className="large-icon" />}

                <Range
                    initialValue={initialValue}
                    min={lookup ? 0 : min}
                    max={lookup ? lookup.length - 1 : max}
                    step={lookup ? 1 : step}
                    onChange={this.onChange.bind(this)}
                    idKey={idKey}
                    lookup={lookup}
                    notches={notches}
                />
            </PlainCard>
        );
    }
}

export default RangeGroup;

import React, { Component } from "react";

import "./HighlightedContent.scss";

class HighlightedContent extends Component {
    render() {
        const {
            children,
            headline,
            large,
            small,
            hideTitleOnMobile
        } = this.props;

        let className = "highlighted-content";
        if (large) className += " large";
        if (small) className += " small";
        if (hideTitleOnMobile) className += " hide-title-on-mobile";

        return (
            <aside className={className}>
                <h2>{headline}</h2>
                {children}
            </aside>
        );
    }
}

export default HighlightedContent;

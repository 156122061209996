import React, { Component } from "react";

import { months } from "../../data/data";

import "./IndustryNews.scss";

class IndustryNews extends Component {
    componentDidMount() {
        const { getIndustryNewsAction } = this.props;
        getIndustryNewsAction();
    }

    render() {
        const { news } = this.props;

        return (
            <div className="industry-news">
                <h3>Industry news</h3>
                {news.map((newsItem) => {
                    const date = new Date(newsItem.published_at);
                    const dateString = `${date.getDate()} ${
                        months[date.getMonth()]
                    } ${date.getFullYear()}`;
                    return (
                        <article key={newsItem.title}>
                            <time>{dateString}</time>
                            <a href={newsItem.url} target="_blank">
                                <h6>{newsItem.title}</h6>
                            </a>
                            <p>{`${newsItem.description.slice(0, 100)}...`}</p>
                        </article>
                    );
                })}
            </div>
        );
    }
}

export default IndustryNews;

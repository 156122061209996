import React from "react";
import { connect } from "react-redux";
import sdk from "matrix-js-sdk";
import axios from "axios";

import Routes from "../Routes";
import {
    logoutAction,
    checkIfUserLoggedInAction,
    setWelcomeModalAction
} from "../actions/auth/authActions";
import {
    listMessagesAction,
    openMessageCenterAction,
    closeMessageCenterAction,
    toggleMessageCenterAction,
    setMessageCenterViewAction,
    setCurrentMessageParticipantsAction,
    setCurrentMessageOpportunityAction,
    getCurrentChatAction,
    sendMessageAction,
    setMessageTutorialAction,
    getOpportunityDetailsForChatAction,
    leaveRoomAction
} from "../actions/messaging/messagingActions";
import {
    clearFocusedUserAction,
    getUserConnectionsAction,
    connectToUserAction,
    ignoreUserAction,
    searchAction,
    resetSearchAction,
    setFocusedUserAction
} from "../actions/network/networkActions";
import {
    getProfiledOpportunitiesAction,
    getAllOpportunitiesAction,
    getInterestedOpportunitiesAction,
    getMyOpportunitiesAction
} from "../actions/opportunities/opportunityActions";
import {
    getMyBusinessDetailsAction,
    getAllBusinessesAction
} from "../actions/business/businessActions";
import {
    getSectorsAction,
    getLocationsAction,
    getFinanceStagesAction,
    getLevelOfInvestmentAction,
    getBusinessTypesAction,
    getOccupationsAction,
    getExpertisesAction,
    getFinanceTypesAction
} from "../actions/auxillary/auxillaryActions";

import Header from "../components/header/Header";
import Navigation from "../components/navigation/Navigation";
import TopBar from "../components/top-bar/TopBar";
import UserDetails from "../components/user-details/UserDetails";
import Login from "./login/Login";
import Register from "./register/Register";
import PublicPage from "./public-page/PublicPage";
import Loader from "../components/loader/Loader";
import UserGenerator from "./generators/user-generator/UserGenerator";
import ForgottenPassword from "./forgotten-password/ForgottenPassword";
import ResetPassword from "./reset-password/ResetPassword";
import { Link, Route, Switch, useParams } from "react-router-dom";
import UserAgreement from "./static-pages/UserAgreement";

class Root extends React.Component {
    state = {
        matrixInitialised: false,
        authInitialised: false
    };

    componentDidMount() {
        const {
            auth,
            getProfiledOpportunitiesAction,
            getInterestedOpportunitiesAction,
            getUserConnectionsAction,
            getMyBusinessDetailsAction,
            checkIfUserLoggedInAction,
            getSectorsAction,
            getLocationsAction,
            getExpertisesAction,
            getFinanceStagesAction,
            getFinanceTypesAction,
            getLevelOfInvestmentAction,
            getBusinessTypesAction,
            getOccupationsAction,
            getMyOpportunitiesAction,
            leaveRoomAction
        } = this.props;

        axios.defaults.timeout = 0;
        if (process.env.REACT_APP_MOCK_API === "true")
            axios.defaults.timeout = 1;

        getSectorsAction();
        getLocationsAction();
        getExpertisesAction();
        getFinanceTypesAction();
        getFinanceStagesAction();
        getLevelOfInvestmentAction();
        getBusinessTypesAction();
        getOccupationsAction();
        checkIfUserLoggedInAction();

        if (!auth.user) {
            window.matrixClient = sdk.createClient({
                baseUrl: "https://home.ymdrech.com",
                useAuthorizationHeader: true
            });
            return;
        }

        if (auth.user.role === "Business") getMyBusinessDetailsAction();
        else if (window.location.href.indexOf("opportunities") === -1)
            getProfiledOpportunitiesAction();

        this.initMatrixClient();
        getInterestedOpportunitiesAction(true);
        getAllBusinessesAction();
        getUserConnectionsAction();
        getMyOpportunitiesAction();

        this.setState({
            matrixInitialised: true,
            authInitialised: true
        });
    }

    componentDidUpdate() {
        const {
            auth,
            getProfiledOpportunitiesAction,
            getInterestedOpportunitiesAction,
            getUserConnectionsAction,
            getMyOpportunitiesAction
        } = this.props;

        const { matrixInitialised, authInitialised } = this.state;

        if (auth.matrixAccessToken && !matrixInitialised) {
            this.initMatrixClient();
        }

        if (auth.user && auth.user.id && !authInitialised) {
            if (auth.user.role === "Business") getMyBusinessDetailsAction();
            else if (window.location.href.indexOf("opportunities") === -1)
                getProfiledOpportunitiesAction();

            getInterestedOpportunitiesAction(true);
            getAllBusinessesAction();
            getUserConnectionsAction();
            getMyOpportunitiesAction();
            this.setState({
                authInitialised: true
            });
        }
    }

    initMatrixClient() {
        const { auth, listMessagesAction, getCurrentChatAction } = this.props;

        window.matrixClient = sdk.createClient({
            baseUrl: "https://home.ymdrech.com",
            accessToken: auth.matrixAccessToken,
            userId: auth.user.matrixUserID,
            useAuthorizationHeader: true
        });

        window.matrixClient.startClient();

        setInterval(() => {
            listMessagesAction();
        }, 2500);

        window.matrixClient.on(
            "Room.timeline",
            (event, room, toStartOfTimeline) => {
                setTimeout(() => {
                    const { messaging } = this.props;

                    if (
                        messaging.currentChat &&
                        event.event.room_id === messaging.currentChat.chatId
                    ) {
                        const chat = messaging.messages.find(
                            (room) => room.chatId === event.event.room_id
                        );
                        if (chat) getCurrentChatAction(chat, chat.people);
                    }
                }, 1000);
            }
        );

        this.setState({
            matrixInitialised: true
        });
    }

    logout() {
        const { logoutAction } = this.props;

        this.setState({
            matrixInitialised: false,
            authInitialised: false
        });
        logoutAction();
    }

    render() {
        const {
            auth,
            auxillary,
            network,
            messaging,
            opportunities,
            business,
            openMessageCenterAction,
            closeMessageCenterAction,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            sendMessageAction,
            clearFocusedUserAction,
            connectToUserAction,
            ignoreUserAction,
            setMessageTutorialAction,
            setWelcomeModalAction,
            searchAction,
            resetSearchAction,
            setFocusedUserAction,
            getOpportunityDetailsForChatAction,
            getUserConnectionsAction
        } = this.props;

        if (
            !auth.user &&
            window.location.pathname.indexOf("/forgot-password") > -1
        )
            return (
                <PublicPage>
                    {auth.authLoading && <Loader />}
                    <ForgottenPassword />
                </PublicPage>
            );

        /*if (
            !auth.user &&
            window.location.pathname.indexOf("/register") > -1
        )
            return (
                <PublicPage>
                    {auth.authLoading && <Loader />}
                    <Register />
                </PublicPage>
            );*/

        if (
            !auth.user &&
            window.location.pathname.indexOf("/password-reset") > -1
        )
            return (
                <PublicPage>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL}/password-reset/:key`}
                            exact
                            component={() => <PasswordResetKey />}
                            key="password-reset-route"
                        />
                    </Switch>
                </PublicPage>
            );

        if (window.location.pathname.indexOf("/user-generator") > -1)
            return (
                <PublicPage>
                    <UserGenerator />
                </PublicPage>
            );

        if (window.location.pathname.indexOf("/terms") > -1)
            return (
                <PublicPage>
                    <UserAgreement />
                </PublicPage>
            );

        if (
            !auth.user &&
            window.location.pathname.indexOf("/invite/") === -1 &&
            window.location.pathname.indexOf("/reset-password/") === -1
        )
            return (
                <PublicPage>
                    {auth.authLoading && <Loader />}
                    <Login />
                </PublicPage>
            );

        return (
            <>
                <TopBar
                    user={auth.user}
                    myBusiness={business.myBusiness}
                    premiumInvitesCount={auth.premiumInvitesCount}
                />
                <Header
                    user={auth.user}
                    matrixAccessToken={auth.matrixAccessToken}
                    network={network}
                    messaging={messaging}
                    opportunities={opportunities}
                    logoutAction={this.logout.bind(this)}
                    openMessageCenterAction={openMessageCenterAction}
                    closeMessageCenterAction={closeMessageCenterAction}
                    toggleMessageCenterAction={toggleMessageCenterAction}
                    setMessageCenterViewAction={setMessageCenterViewAction}
                    setCurrentMessageParticipantsAction={
                        setCurrentMessageParticipantsAction
                    }
                    setCurrentMessageOpportunityAction={
                        setCurrentMessageOpportunityAction
                    }
                    getCurrentChatAction={getCurrentChatAction}
                    sendMessageAction={sendMessageAction}
                    setMessageTutorialAction={setMessageTutorialAction}
                    setWelcomeModalAction={setWelcomeModalAction}
                    searchAction={searchAction}
                    resetSearchAction={resetSearchAction}
                    setFocusedUserAction={setFocusedUserAction}
                    getOpportunityDetailsForChatAction={
                        getOpportunityDetailsForChatAction
                    }
                    leaveRoomAction={leaveRoomAction}
                />
                <Navigation user={auth.user} />
                {network.focusedUser && (
                    <UserDetails
                        currentUser={auth.user}
                        auxillary={auxillary}
                        user={network.focusedUser}
                        connections={network.connections}
                        messaging={messaging}
                        toggleMessageCenterAction={toggleMessageCenterAction}
                        setMessageCenterViewAction={setMessageCenterViewAction}
                        setCurrentMessageParticipantsAction={
                            setCurrentMessageParticipantsAction
                        }
                        setCurrentMessageOpportunityAction={
                            setCurrentMessageOpportunityAction
                        }
                        getUserConnectionsAction={getUserConnectionsAction}
                        getCurrentChatAction={getCurrentChatAction}
                        clearFocusedUserAction={clearFocusedUserAction}
                        connectToUserAction={connectToUserAction}
                        ignoreUserAction={ignoreUserAction}
                    />
                )}
                <main>
                    <Routes />
                    <footer>
                        <p>&copy; 2021 Fiinect</p>
                        <p>
                            <Link to={`${process.env.PUBLIC_URL}/about-us`}>
                                About Us
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/faqs`}>
                                FAQs
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/privacy`}>
                                Privacy Policy
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/cookies`}>
                                Cookie Policy
                            </Link>
                            <Link
                                to={`${process.env.PUBLIC_URL}/user-agreement`}
                            >
                                Terms and Conditions
                            </Link>
                            <a href="mailto:info@fiinect.com">Contact us</a>
                        </p>
                    </footer>
                </main>
            </>
        );
    }
}

const PasswordResetKey = () => {
    const { key } = useParams();

    return <ResetPassword passwordResetKey={key} />;
};

export default connect(
    (state) => ({
        auth: state.auth,
        network: state.network,
        auxillary: state.auxillary,
        messaging: state.messaging,
        opportunities: state.opportunities,
        business: state.business
    }),
    {
        logoutAction,
        listMessagesAction,
        openMessageCenterAction,
        closeMessageCenterAction,
        toggleMessageCenterAction,
        setMessageCenterViewAction,
        setCurrentMessageParticipantsAction,
        setCurrentMessageOpportunityAction,
        getCurrentChatAction,
        sendMessageAction,
        clearFocusedUserAction,
        getProfiledOpportunitiesAction,
        getAllOpportunitiesAction,
        getInterestedOpportunitiesAction,
        getAllBusinessesAction,
        getUserConnectionsAction,
        checkIfUserLoggedInAction,
        connectToUserAction,
        ignoreUserAction,
        setMessageTutorialAction,
        setWelcomeModalAction,
        getMyBusinessDetailsAction,
        searchAction,
        resetSearchAction,
        setFocusedUserAction,
        getSectorsAction,
        getLocationsAction,
        getExpertisesAction,
        getFinanceStagesAction,
        getFinanceTypesAction,
        getLevelOfInvestmentAction,
        getBusinessTypesAction,
        getOpportunityDetailsForChatAction,
        getOccupationsAction,
        getMyOpportunitiesAction,
        leaveRoomAction
    }
)(Root);

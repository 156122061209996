import React, { Component } from "react";

import { relevantPeople } from "../../data/people";
import { months } from "../../data/data";
import MultiProfileIcon from "../multi-profile-icon/MultiProfileIcon";
import ProfileIcon from "../profile-icon/ProfileIcon";

import "./MessagePreview.scss";
import Button from "../button/Button";

class MessagePreview extends Component {
    state = {
        deleteChat: false
    };
    componentDidMount() {
        const {
            chat,
            opportunity,
            opportunityId,
            getOpportunityDetailsForChatAction
        } = this.props;

        if (opportunityId && !opportunity) {
            getOpportunityDetailsForChatAction(chat.chatId, opportunityId);
        }
    }

    delete() {
        const { chat, leaveRoomAction } = this.props;

        leaveRoomAction(chat.chatId);
        this.setState({
            deleteChat: false
        });
    }

    deleteCancel() {
        this.setState({
            deleteChat: false
        });
    }

    onDelete() {
        this.setState({
            deleteChat: true
        });
    }

    onClick() {
        const {
            chat,
            people,
            opportunity,
            setMessageCenterViewAction,
            openMessageCenterAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction
        } = this.props;

        openMessageCenterAction();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction(people);
        setCurrentMessageOpportunityAction(opportunity);
        getCurrentChatAction(chat, people);
    }

    render() {
        const {
            people,
            date,
            snippet,
            opportunity,
            unread,
            sender
        } = this.props;
        const { deleteChat } = this.state;

        const d = new Date(date);
        let hour = d.getHours();
        let minute = d.getMinutes();
        if (hour < 10) hour = `0${hour}`;
        if (minute < 10) minute = `0${minute}`;
        const dateString = `${d.getDate()} ${
            months[d.getMonth()]
        } ${hour}:${minute}`;

        let className = "message-preview";
        if (unread) className += " unread";
        if (opportunity) className += " opportunity-message";

        let senderPreview = "";
        if (sender === "user") senderPreview = "You";
        if (people.find((u) => u.matrixUserID === sender)) {
            senderPreview = people.find((u) => u.matrixUserID === sender)
                .firstName;
        }

        return (
            <div className={className}>
                <img
                    className="delete"
                    onClick={this.onDelete.bind(this)}
                    src={`${process.env.PUBLIC_URL}/assets/icons/bin-grey.png`}
                />
                {deleteChat && (
                    <div className="delete-confirm">
                        <p>
                            Are you sure you want to delete this conversation?
                        </p>
                        <div className="actions">
                            <Button onClick={this.delete.bind(this)} isPrimary>
                                Yes
                            </Button>
                            <Button onClick={this.deleteCancel.bind(this)}>
                                No
                            </Button>
                        </div>
                    </div>
                )}
                <div className="preview" onClick={this.onClick.bind(this)}>
                    {people.length === 1 && <ProfileIcon person={people[0]} />}
                    {people.length > 1 && <MultiProfileIcon people={people} />}
                    <section>
                        <time>{dateString}</time>
                        <h5>{people.length > 0 && relevantPeople(people)}</h5>
                        {opportunity && (
                            <p className="opportunity">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/opportunity_chat.svg`}
                                    alt="Opportunity chat"
                                />
                                {opportunity.headline}
                            </p>
                        )}
                        <p>
                            <strong>{`${senderPreview}: `}</strong> {snippet}
                        </p>
                    </section>
                </div>
            </div>
        );
    }
}

export default MessagePreview;

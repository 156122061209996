import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";

import CurrentUser from "../current-user/CurrentUser";
import MessageCenter from "../message-center/MessageCenter";
import Navigation from "../navigation/Navigation";
import Search from "../search/Search";

import "./Header.scss";

class Header extends Component {
    state = {
        smallHeader: false,
        mobileNavOpen: false,
        createOppOpen: false
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    handleScroll(e) {
        const header = document.querySelector(".site-header");
        if (!header) return;

        const offset = window.scrollY;
        this.setState({
            smallHeader: offset > 46
        });

        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
            const contentOffset = mainContent.offsetTop;
            if (window.pageYOffset + header.clientHeight + 10 > contentOffset) {
                mainContent.classList.add("sticky");
            } else {
                mainContent.classList.remove("sticky");
            }
        }
    }

    toggleNav() {
        this.setState({
            mobileNavOpen: !this.state.mobileNavOpen,
            createOppOpen: false
        });
    }

    toggleCreateOpp() {
        this.setState({
            createOppOpen: !this.state.createOppOpen
        });
    }

    render() {
        const {
            user,
            matrixAccessToken,
            network,
            messaging,
            opportunities,
            logoutAction,
            openMessageCenterAction,
            closeMessageCenterAction,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getOpportunityDetailsForChatAction,
            getCurrentChatAction,
            sendMessageAction,
            setMessageTutorialAction,
            setWelcomeModalAction,
            searchAction,
            resetSearchAction,
            setFocusedUserAction,
            leaveRoomAction
        } = this.props;

        const { createOppOpen } = this.state;

        return (
            <header
                className={
                    this.state.smallHeader ? "site-header small" : "site-header"
                }
            >
                <div
                    className={
                        this.state.mobileNavOpen
                            ? "mobile-navigation open"
                            : "mobile-navigation"
                    }
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/close_white.svg`}
                        className="close"
                        alt="Close"
                        onClick={this.toggleNav.bind(this)}
                    />
                    <div
                        className={
                            createOppOpen ? "container closed" : "container"
                        }
                    >
                        <Search
                            searchAction={searchAction}
                            resetSearchAction={resetSearchAction}
                            setFocusedUserAction={setFocusedUserAction}
                            searchResults={network.searchResults}
                            openFullResultsAction={this.toggleNav.bind(this)}
                        />
                        <Navigation
                            user={user}
                            closeParent={this.toggleNav.bind(this)}
                        />

                        <Button onClick={this.toggleCreateOpp.bind(this)}>
                            Create an opportunity
                        </Button>

                        <div className="footer">
                            <p>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/my-details`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    About Me
                                </Link>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/my-preferences`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    I am looking for
                                </Link>
                            </p>
                            <p>&copy; 2021 Fiinect</p>
                            <p>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/about-us`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    About Us
                                </Link>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/faqs`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    FAQs
                                </Link>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/privacy`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    Privacy Policy
                                </Link>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/cookies`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    Cookie Policy
                                </Link>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/user-agreement`}
                                    onClick={this.toggleNav.bind(this)}
                                >
                                    Terms &amp; Conditions
                                </Link>
                                <a href="mailto:info@fiinect.com">Contact us</a>
                            </p>
                        </div>
                    </div>

                    <div
                        className={
                            createOppOpen
                                ? "create-opp-container open"
                                : "create-opp-container"
                        }
                    >
                        <Button onClick={this.toggleCreateOpp.bind(this)}>
                            Back
                        </Button>

                        <h3>Create an opportunity</h3>
                        <p>
                            Creating a new opportunity on Fiinect is simple, but
                            it is best done from your desktop PC. You will need
                            to upload PDFs and documents, and for that reason we
                            have disabled this function on mobile devices.
                        </p>
                    </div>
                </div>
                <div
                    className="mobile-navigation-toggler"
                    onClick={this.toggleNav.bind(this)}
                >
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className="logo-container">
                    <Link to={process.env.PUBLIC_URL}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/fiinect.svg`}
                            className="logo"
                            alt="Fiinect"
                        />
                    </Link>
                </div>
                {user && user.profileComplete && (
                    <Search
                        searchAction={searchAction}
                        resetSearchAction={resetSearchAction}
                        setFocusedUserAction={setFocusedUserAction}
                        searchResults={network.searchResults}
                    />
                )}
                <section className="user">
                    <CurrentUser user={user} logoutAction={logoutAction} />
                    {matrixAccessToken && (
                        <MessageCenter
                            user={user}
                            messaging={messaging}
                            network={network}
                            opportunities={opportunities}
                            openMessageCenterAction={openMessageCenterAction}
                            closeMessageCenterAction={closeMessageCenterAction}
                            toggleMessageCenterAction={
                                toggleMessageCenterAction
                            }
                            setMessageCenterViewAction={
                                setMessageCenterViewAction
                            }
                            setCurrentMessageParticipantsAction={
                                setCurrentMessageParticipantsAction
                            }
                            setCurrentMessageOpportunityAction={
                                setCurrentMessageOpportunityAction
                            }
                            getCurrentChatAction={getCurrentChatAction}
                            sendMessageAction={sendMessageAction}
                            setMessageTutorialAction={setMessageTutorialAction}
                            setWelcomeModalAction={setWelcomeModalAction}
                            getOpportunityDetailsForChatAction={
                                getOpportunityDetailsForChatAction
                            }
                            leaveRoomAction={leaveRoomAction}
                        />
                    )}
                </section>
            </header>
        );
    }
}

export default Header;

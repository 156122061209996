import React, { Component } from "react";

import Button from "../button/Button";

import "./TopBar.scss";

class TopBar extends Component {
    render() {
        const { user, myBusiness, premiumInvitesCount } = this.props;

        return (
            <header className="top-bar">
                {myBusiness &&
                    myBusiness.status === "draft" &&
                    user.role === "Business" && (
                        <>
                            <Button
                                href={`${process.env.PUBLIC_URL}/send-invite`}
                            >
                                Invite someone to join Fiinect
                            </Button>
                            <Button
                                href={`${process.env.PUBLIC_URL}/create-business`}
                                isPrimary
                            >
                                Create a business page
                            </Button>
                        </>
                    )}
                {user && !user.profileComplete && user.role !== "Business" && (
                    <Button
                        href={`${process.env.PUBLIC_URL}/complete-profile`}
                        isPrimary
                    >
                        Complete your profile
                    </Button>
                )}
                {user && (
                    <>
                        {user.role !== "Business" && (
                            <Button
                                href={`${process.env.PUBLIC_URL}/opportunities/create`}
                            >
                                Create an opportunity
                            </Button>
                        )}
                        {user.role === "Business" && (
                            <Button
                                href={`${process.env.PUBLIC_URL}/my-business`}
                            >
                                Manage my business
                            </Button>
                        )}
                        <Button href={`${process.env.PUBLIC_URL}/send-invite`}>
                            Invite someone to join Fiinect
                        </Button>
                        {premiumInvitesCount < 10 && (
                            <Button
                                href={`${process.env.PUBLIC_URL}/send-invite`}
                                isPrimary
                            >
                                {10 - premiumInvitesCount} FREE PREMIUM invites
                                for you to send to friends
                            </Button>
                        )}
                    </>
                )}
            </header>
        );
    }
}

export default TopBar;

import axios from "axios";

import { API_BASE } from "../../api";
import { uuidv4 } from "../../util";
import { mapPerson } from "../../data/people";
import { roles } from "../../data/data";
import { connectToUserAction } from "../network/networkActions";
import {
    setFinanceStageExpertiseAction,
    setSectorExpertiseAction,
    setSectorPreferencesAction,
    setUserExpertiseAction
} from "../preferences/preferencesActions";
import { registerBusinessAction } from "../business/businessActions";
import * as actionTypes from "../actionTypes";
import {
    createOpportunityAction,
    getLatestOpportunitiesAction
} from "../opportunities/opportunityActions";

export const loginAction = (username, password) => {
    return (dispatch) => {
        const body = {
            UserName: username,
            Password: password
        };

        dispatch({
            type: actionTypes.LOGIN
        });

        return axios
            .post(`${API_BASE}/Auth/login`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*"
                }
            })
            .then(({ data }) => {
                dispatch(getLoggedInUserDetailsAction(data));
            })
            .catch((error) => {
                dispatch(loginErrorAction(error));
            });
    };
};

export const getLoggedInUserDetailsAction = (user) => {
    if (user.accessToken) localStorage.setItem("accessToken", user.accessToken);
    const accessToken = localStorage.getItem("accessToken");
    return (dispatch) =>
        axios
            .get(`${API_BASE}/Users/${user.userId}`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(loginSuccessAction(mapPerson(data)));
                dispatch(getLoggedInUserFullDetailsAction(user));
                dispatch(checkPremiumInvitesAction());
            })
            .catch((error) => {
                dispatch(loginErrorAction(error));
            });
};

export const getLoggedInUserFullDetailsAction = (user) => {
    const accessToken = localStorage.getItem("accessToken");
    return (dispatch) =>
        axios
            .get(`${API_BASE}/Users/${user.userId}/full`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(loginSuccessAction(mapPerson(data)));
                dispatch(getLatestOpportunitiesAction());
            })
            .catch((error) => {
                dispatch(loginErrorAction(error));
            });
};

export const loginSuccessAction = (user) => ({
    type: actionTypes.LOGIN_SUCCESS,
    user
});

export const loginErrorAction = (error) => ({
    type: actionTypes.LOGIN_ERROR,
    error
});

export const checkIfUserLoggedInAction = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
        return (dispatch) => {
            dispatch(checkIfUserLoggedInErrorAction("User not logged in"));
        };
    }
    return (dispatch) =>
        axios
            .get(`${API_BASE}/Auth/user`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(checkIfUserLoggedInSuccessAction(data));
                dispatch(getLoggedInUserDetailsAction(data));
            })
            .catch((error) => {
                dispatch(checkIfUserLoggedInErrorAction(error));
            });
};

export const checkIfUserLoggedInSuccessAction = (user) => ({
    type: actionTypes.CHECK_IF_USER_LOGGED_IN_SUCCESS,
    user
});

export const checkIfUserLoggedInErrorAction = (error) => ({
    type: actionTypes.CHECK_IF_USER_LOGGED_IN_ERROR,
    error
});

export const logoutAction = () => {
    localStorage.removeItem("accessToken");

    return {
        type: actionTypes.LOGOUT
    };
};

export const getInvitedUserAction = (inviteKey) => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Invites/${inviteKey}`)
            .then(({ data }) => {
                dispatch(getInvitedUserSuccessAction(data));
            })
            .catch((error) => {
                dispatch(getInvitedUserErrorAction(error));
            });
    };
};

export const getInvitedUserSuccessAction = (invitedUser) => ({
    type: actionTypes.GET_INVITE_DETAILS_SUCCESS,
    invitedUser
});

export const getInvitedUserErrorAction = (error) => ({
    type: actionTypes.GET_INVITE_DETAILS_ERROR,
    error
});

export const beginRegistrationAction = (data, role = "investor") => {
    return (dispatch) => {
        dispatch(registerMatrixUserAction(data, role));
        dispatch({
            type: actionTypes.BEGIN_REGISTRATION
        });
    };
};

export const registerMatrixUserAction = (user, role) => {
    return (dispatch) => {
        const body = {
            username: `fiinect__${user.firstName}${
                user.surname
            }__${uuidv4()}`.replace(/ /g, ""),
            initial_device_display_name: "Fiinect",
            password: user.password,
            inhibit_login: false
        };

        return axios
            .post(
                `${window.matrixClient.baseUrl}/_matrix/client/r0/register`,
                body,
                {}
            )
            .then(({ data }) => {
                // this won't work this time, if it does then something's wrong lol
                dispatch(
                    registerMatrixUserSuccessAction(
                        data.access_token,
                        data.user_id
                    )
                );
                dispatch(
                    registerUserAction(
                        user,
                        data.access_token,
                        data.user_id,
                        role
                    )
                );
            })
            .catch((error) => {
                body.sessionId = error.response.data.session;
                body.auth = {
                    session: error.response.data.session,
                    type: "m.login.dummy"
                };
                return axios
                    .post(
                        `${window.matrixClient.baseUrl}/_matrix/client/r0/register`,
                        body,
                        {}
                    )
                    .then(({ data }) => {
                        dispatch(
                            registerMatrixUserSuccessAction(
                                data.access_token,
                                data.user_id
                            )
                        );
                        dispatch(
                            registerUserAction(
                                user,
                                data.access_token,
                                data.user_id,
                                role
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(registerMatrixUserErrorAction(error));
                    });
            });
    };
};

export const registerMatrixUserSuccessAction = (
    matrixAccessToken,
    matrixUserID
) => ({
    type: actionTypes.REGISTER_MATRIX_USER_SUCCESS,
    matrixAccessToken,
    matrixUserID
});

export const registerMatrixUserErrorAction = (error) => ({
    type: actionTypes.REGISTER_MATRIX_USER_ERROR,
    error
});

export const registerUserAction = (
    user,
    matrixAccessToken,
    matrixUserID,
    role
) => {
    return (dispatch, getState) => {
        const body = {
            RoleIds: [roles[role]],
            FirstName: user.firstName,
            LastName: user.surname,
            Password: user.password,
            Email: user.email,
            matrixUserID: matrixUserID,
            LocationID:
                user.registrationLocation &&
                user.registrationLocation.length > 0
                    ? user.registrationLocation[0].id
                    : null,
            OccupationID:
                user.occupations && user.occupations.length > 0
                    ? user.occupations[0].id
                    : null,
            MatrixAccessToken: matrixAccessToken,
            invitingUserId: getState().auth.user.invitingUserId,
            ProfileComplete: false
        };

        return axios
            .post(`${API_BASE}/Users`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*"
                }
            })
            .then(({ data }) => {
                dispatch(registerUserSuccessAction(data));
                dispatch(
                    loginAfterRegistrationAction(
                        user.email,
                        user.password,
                        user,
                        role
                    )
                );
            })
            .catch((error) => {
                dispatch(registerUserErrorAction(error));
            });
    };
};

export const registerUserSuccessAction = (user) => ({
    type: actionTypes.REGISTER_USER_SUCCESS,
    user
});

export const registerUserErrorAction = (error) => ({
    type: actionTypes.REGISTER_USER_ERROR,
    error
});

export const setWelcomeModalAction = (value) => ({
    type: actionTypes.SET_WELCOME_MODAL,
    value
});

export const loginAfterRegistrationAction = (
    username,
    password,
    userData = null,
    role = null
) => {
    return (dispatch, getState) => {
        const invitingUserId = getState().auth.user.invitingUserId;
        const opportunity = getState().opportunities.opportunityDetails;

        const body = {
            UserName: username,
            Password: password
        };
        return axios
            .post(`${API_BASE}/Auth/login`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*"
                }
            })
            .then(({ data }) => {
                if (data.accessToken)
                    localStorage.setItem("accessToken", data.accessToken);
                if (role === "business") {
                    dispatch(createOpportunityAction(userData, data.userId));
                }
                if (role === "professional") {
                    if (userData.sectors) {
                        userData.sectors.forEach((sector) => {
                            dispatch(
                                setSectorExpertiseAction(
                                    sector.id,
                                    "post",
                                    data.userId
                                )
                            );
                        });
                    }
                    if (userData.financeStage) {
                        userData.financeStage.forEach((financeStage) => {
                            dispatch(
                                setFinanceStageExpertiseAction(
                                    financeStage.id,
                                    "post",
                                    data.userId
                                )
                            );
                        });
                    }
                    if (userData.expertises) {
                        userData.expertises.forEach((expertise) => {
                            dispatch(
                                setUserExpertiseAction(
                                    expertise.id,
                                    "post",
                                    data.userId
                                )
                            );
                        });
                    }
                }
                dispatch(getLoggedInUserDetailsAction(data));
                dispatch(getLatestOpportunitiesAction());
                if (invitingUserId) {
                    dispatch(connectToUserAction(data.userId, invitingUserId));
                    dispatch(
                        setSectorPreferencesAction(
                            opportunity.sectorId,
                            "post",
                            data.userId
                        )
                    );
                }
            });
        /*.catch((error) => {
                dispatch(loginErrorAction(error));
            });*/
    };
};

export const inviteToFiinectAction = (userDetails, opportunityId = null) => {
    // TODO LOW is this better suited to the network reducer?
    const accessToken = localStorage.getItem("accessToken");

    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            OpportunityId: opportunityId,
            InvitingUserId: user.userId,
            FirstName: userDetails.firstName,
            Surname: userDetails.surname,
            Email: userDetails.email
        };

        return axios
            .post(`${API_BASE}/Invites`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                // TODO MED make smarter; don't invite existing users
                dispatch(inviteToFiinectSuccessAction());
            })
            .catch((error) => {
                dispatch(inviteToFiinectErrorAction(error));
            });
    };
};

export const inviteToFiinectSuccessAction = () => ({
    type: actionTypes.INVITE_TO_FIINECT_SUCCESS
});

export const inviteToFiinectErrorAction = (error) => ({
    type: actionTypes.INVITE_TO_FIINECT_ERROR,
    error
});

export const checkPremiumInvitesAction = () => {
    // TODO LOW is this better suited to the network reducer?
    const accessToken = localStorage.getItem("accessToken");

    return (dispatch, getState) => {
        const user = getState().auth.user;

        return axios
            .get(`${API_BASE}/Invites/user/${user.userId}`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(
                    checkPremiumInvitesSuccessAction(
                        data.filter((invite) => invite.isPremium).length
                    )
                );
            })
            .catch((error) => {
                dispatch(checkPremiumInvitesErrorAction(error));
            });
    };
};

export const checkPremiumInvitesSuccessAction = (premiumInvitesCount) => ({
    type: actionTypes.CHECK_PREMIUM_INVITES_SUCCESS,
    premiumInvitesCount
});

export const checkPremiumInvitesErrorAction = (error) => ({
    type: actionTypes.CHECK_PREMIUM_INVITES_ERROR,
    error
});

export const forgottenPasswordAction = (email) => {
    return (dispatch) => {
        return axios
            .post(
                `${API_BASE}/Users/forgotpassword/${email}`,
                {},
                {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*"
                    }
                }
            )
            .then(({ data }) => {
                dispatch(forgottenPasswordSuccessAction(data));
            })
            .catch((error) => {
                dispatch(forgottenPasswordErrorAction(error));
            });
    };
};

export const forgottenPasswordSuccessAction = () => ({
    type: actionTypes.FORGOT_PASSWORD_SUCCESS
});

export const forgottenPasswordErrorAction = () => ({
    type: actionTypes.FORGOT_PASSWORD_ERROR
});

export const resetPasswordAction = (email, key, password) => {
    const body = {
        Email: email,
        PasswordResetKey: key,
        Password: password
    };
    return (dispatch) => {
        return axios
            .post(`${API_BASE}/Users/resetpassword`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*"
                }
            })
            .then(({ data }) => {
                dispatch(resetPasswordSuccessAction());
            })
            .catch((error) => {
                dispatch(resetPasswordErrorAction(error));
            });
    };
};

export const resetPasswordSuccessAction = () => ({
    type: actionTypes.RESET_PASSWORD_SUCCESS
});

export const resetPasswordErrorAction = () => ({
    type: actionTypes.RESET_PASSWORD_ERROR
});

export const verifyRecaptchaTokenAction = (token) => {
    return (dispatch) => {
        return axios
            .post(process.env.REACT_APP_VERIFY_RECAPTCHA_TOKEN_ENDPOINT, {
                secret: process.env.REACT_APP_RECAPTCHA_KEY,
                response: token
            })
            .then(({ data }) => {
                dispatch(verifyRecaptchaTokenSuccess(data.success));
            })
            .catch((error) => {
                dispatch(verifyRecaptchaTokenError(error));
            });
    };
};

export const verifyRecaptchaTokenSuccess = (verificationSuccess) => ({
    type: actionTypes.VERIFY_RECAPTCHA_TOKEN_SUCCESS,
    verificationSuccess
});
export const verifyRecaptchaTokenError = (error) => ({
    type: actionTypes.VERIFY_RECAPTCHA_TOKEN_ERROR,
    error
});

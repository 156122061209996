import * as actionTypes from "../../actions/actionTypes";

import { NewsFeed } from "../../data/feed";

const INITIAL_STATE = {
    feed: [],
    focusedUser: null,
    suggestions: [],
    suggestionsLoading: false,
    connections: [],
    unconnectedButMessaged: [],
    unconnectedButMessagedFailures: [],
    unconnectedLoading: false,
    filters: {
        country: {},
        sectorInterests: {},
        expertise: {},
        levelOfInvestment: {}
    },
    news: NewsFeed,
    filteredConnections: [],
    networkView: "",
    marketplaceResults: null,
    requests: [],
    searchResults: {
        users: [],
        opportunities: [],
        businesses: []
    },
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.POST_COMMENT:
            return {
                ...state,
                feed: [action.feedItem, ...state.feed]
            };
        case actionTypes.REFRESH_FEED_SUCCESS:
            return {
                ...state,
                feed: action.feed
            };
        case actionTypes.LOAD_MORE_FEED:
            return {
                ...state,
                feed: [...state.feed]
            };
        case actionTypes.RESET_NETWORK_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                filteredConnections: INITIAL_STATE.filteredConnections
            };
        case actionTypes.SET_NETWORK_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case actionTypes.SET_NETWORK_COUNTRY_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    country: action.country
                }
            };
        case actionTypes.SET_FILTERED_CONNECTIONS:
            return {
                ...state,
                filteredConnections: action.connections
            };
        case actionTypes.SET_NETWORK_VIEW:
            return {
                ...state,
                networkView: action.view
            };
        case actionTypes.SET_FOCUSED_USER:
        case actionTypes.SET_FOCUSED_USER_SUCCESS:
            return {
                ...state,
                focusedUser: action.focusedUser
            };
        case actionTypes.CLEAR_FOCUSED_USER:
            return {
                ...state,
                focusedUser: null
            };
        case actionTypes.GET_MARKETPLACE_RESULTS:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_MARKETPLACE_RESULTS_SUCCESS:
            return {
                ...state,
                marketplaceResults: action.results,
                loading: false
            };
        case actionTypes.RESET_MARKETPLACE:
            return {
                ...state,
                marketplaceResults: INITIAL_STATE.marketplaceResults
            };
        case actionTypes.GET_USER_CONNECTIONS_SUCCESS:
            return {
                ...state,
                connections: action.connections
            };
        case actionTypes.GET_CONNECTION_REQUESTS_SUCCESS:
            return {
                ...state,
                requests: INITIAL_STATE.requests
            };
        case actionTypes.GET_CONNECTION_REQUEST_USER_DETAILS_SUCCESS:
            return {
                ...state,
                requests: [...state.requests, action.user]
            };
        case actionTypes.GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS:
            return {
                ...state,
                unconnectedLoading: true
            };
        case actionTypes.GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_SUCCESS:
            return {
                ...state,
                unconnectedButMessaged: [
                    ...state.unconnectedButMessaged,
                    action.user
                ]
            };
        case actionTypes.GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_ERROR:
            return {
                ...state,
                unconnectedButMessagedFailures: [
                    ...state.unconnectedButMessagedFailures,
                    action.matrixId
                ]
            };
        case actionTypes.SEARCH_SUCCESS: {
            return {
                ...state,
                searchResults: {
                    users: action.users,
                    opportunities: action.opportunities,
                    businesses: action.businesses
                }
            };
        }
        case actionTypes.RESET_SEARCH: {
            return {
                ...state,
                searchResults: INITIAL_STATE.searchResults
            };
        }
        case actionTypes.GET_LOCATION_SUGGESTIONS:
        case actionTypes.GET_FILTER_SUGGESTIONS:
            return {
                ...state,
                suggestions: INITIAL_STATE.suggestions,
                suggestionsLoading: true
            };
        case actionTypes.GET_CONNECTION_SUGGESTIONS_SUCCESS:
        case actionTypes.GET_FILTER_SUGGESTIONS_SUCCESS:
        case actionTypes.GET_LOCATION_SUGGESTIONS_SUCCESS: {
            return {
                ...state,
                suggestions: action.suggestions,
                suggestionsLoading: false
            };
        }
        case actionTypes.LOGOUT:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};

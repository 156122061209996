import React, { Component } from "react";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";

class Privacy extends Component {
    render() {
        return (
            <>
                <HighlightedContent
                    small
                    headline="Fiinect Privacy Policy"
                ></HighlightedContent>
                <MainContent>
                    <div className="container">
                        <p>
                            Fiinect's mission is to quicken the introduction of
                            business to finance. Central to this mission is our
                            commitment to be transparent about the data we
                            collect about you, how it is used and with whom it
                            is shared. This Privacy Policy applies when you use
                            our Services (described below).
                        </p>
                        <h2>1. Introduction </h2>
                        <p>
                            We are a Market Network for the Alternative Finance
                            and business sectors. People use our Services to
                            find and be found for business opportunities, to
                            connect with others and find information. Our
                            Privacy Policy applies to any Member or Visitor to
                            our Services.
                        </p>
                        <p>
                            Our registered users ("Members") share their
                            professional identities, engage with their network,
                            exchange knowledge and professional insights, post
                            and view relevant content, learn and develop skills,
                            and find business opportunities. Content and data on
                            some of our Services is viewable to non-members
                            ("Visitors").
                        </p>
                        <p>Services</p>
                        <p>
                            This Privacy Policy, including our Cookie
                            Policy applies to your use of our Services.
                        </p>
                        <p>
                            This Privacy Policy applies to Fiinect.com &amp;
                            Fiinect-branded apps, communications and services
                            ("Services"), including off-site Services, such as
                            our ad services and the "Apply with Fiinect" and
                            "Share with Fiinect" plugins, but excluding services
                            that state that they are offered under a different
                            privacy policy.{" "}
                        </p>
                        <p>Data Controllers and Contracting Parties</p>
                        <p>
                            Fiinect Ltd will be the controller of your personal
                            data provided to, or collected by or for, or
                            processed in connection with, our Services.
                        </p>
                        <p>
                            As a Visitor or Member of our Services, the
                            collection, use and sharing of your personal data is
                            subject to this Privacy Policy and other documents
                            referenced in this Privacy Policy, as well as
                            updates.
                        </p>
                        <p>Change</p>
                        <p>
                            Changes to the Privacy Policy apply to your use of
                            our Services after the "effective date."
                        </p>
                        <p>
                            Fiinect ("we" or "us") can modify this Privacy
                            Policy, and if we make material changes to it, we
                            will provide notice through our Services, or by
                            other means, to provide you the opportunity to
                            review the changes before they become effective. If
                            you object to any changes, you may close your
                            account.{" "}
                        </p>
                        <p>
                            You acknowledge that your continued use of our
                            Services after we publish or send a notice about our
                            changes to this Privacy Policy means that the
                            collection, use and sharing of your personal data is
                            subject to the updated Privacy Policy, as of its
                            effective date.
                        </p>
                        <h2>2. Data We Collect </h2>
                        <p>2.1 Data You Provide To Us</p>
                        <p>You provide data to create an account with us.</p>
                        <p>Registration</p>
                        <p>
                            To create an account you need to provide data
                            including your name, email address and a password.
                            If you register for a premium Service, you will need
                            to provide payment (e.g., credit card) and billing
                            information.
                        </p>
                        <p>
                            You create your Fiinect profile (a complete profile
                            helps you get the most from our Services).
                        </p>
                        <p>Profile</p>
                        <p>
                            You have choices about the information on your
                            profile. You don't have to provide additional
                            information on your profile; however, profile
                            information helps you to get more from our Services,
                            including helping match business opportunities or
                            finance partners. It's your choice whether to
                            include sensitive information on your profile and to
                            make that sensitive information public. Please do
                            not post or add personal data to your profile that
                            you would not want to be publicly available.
                        </p>
                        <p>
                            You give other data to us, such as by syncing your
                            address book or calendar.
                        </p>
                        <p>Posting and Uploading</p>
                        <p>
                            We collect personal data from you when you provide,
                            post or upload it to our Services, such as when you
                            fill out a form or make selection choices. If you
                            opt to import your address book, we receive your
                            contacts (including contact information your service
                            provider(s) or app automatically added to your
                            address book when you communicated with addresses or
                            numbers not already in your list).
                        </p>
                        <p>
                            If you sync your contacts or calendars with our
                            Services, we will collect your address book and
                            calendar meeting information to keep growing your
                            network by suggesting connections for you and
                            others, and by providing information about events,
                            e.g. times, places, attendees and contacts.
                        </p>
                        <p>
                            You don't have to post or upload personal data;
                            though if you don't, it may limit your ability to
                            grow and engage with your network over our Services.
                        </p>
                        <p>2.2 Data From Others</p>
                        <p>Content and News</p>
                        <p>
                            You and others may post content that includes
                            information about you (as part of articles, posts,
                            comments, videos) on our Services. We also may
                            collect public information about you, such as
                            professional-related news and accomplishments, and
                            make it available as part of our Services,
                            including, as permitted by your settings, in
                            notifications to others.
                        </p>
                        <p>Contact and Calendar Information</p>
                        <p>
                            We receive personal data (including contact
                            information) about you when others send messages
                            using our Services (including invites or connection
                            requests). If you or others opt-in to sync email
                            accounts with our Services, we will also collect
                            "email header" information that we can associate
                            with Member profiles.
                        </p>
                        <p>2.3 Service Use</p>
                        <p>
                            We log your visits and use of our Services,
                            including mobile apps.
                        </p>
                        <p>
                            We log usage data when you visit or otherwise use
                            our Services, including our sites, app and platform
                            technology, such as when you view or click on
                            content or ads (on or off our sites and apps),
                            perform a search, install or update one of our
                            mobile apps, share articles or business
                            opportunities. We use log-ins, cookies, device
                            information and internet protocol ("IP") addresses
                            to identify you and log your use.
                        </p>
                        <p>2.4 Cookies and Similar Technologies</p>
                        <p>
                            We collect data through cookies and similar
                            technologies.
                        </p>
                        <p>
                            As further described in our Cookie Policy, we use
                            cookies and similar technologies to collect data
                            (e.g., device IDs) to recognize you and your
                            device(s) on, off and across different services and
                            devices where you have engaged with our Services. We
                            also allow some others to use cookies as described
                            in our Cookie Policy. We also collect (or rely on
                            others who collect) information about your device
                            where you have not engaged with our Services (e.g.,
                            ad ID, IP address, operating system and browser
                            information) so we can provide our Members with
                            relevant ads and better understand their
                            effectiveness.  You can opt out from our use of data
                            from cookies and similar technologies that track
                            your behaviour on the sites of others for ad
                            targeting and other ad-related purposes.{" "}
                        </p>
                        <p>2.5 Your Device and Location</p>
                        <p>
                            When you visit or leave our Services (including some
                            plugins and our cookies or similar technology on the
                            sites of others), we receive the URL of both the
                            site you came from and the one you go to and the
                            time of your visit. We also get information about
                            your network and device (e.g., IP address, proxy
                            server, operating system, web browser and add-ons,
                            device identifier and features, cookie IDs and/or
                            ISP, or your mobile carrier). If you use our
                            Services from a mobile device, that device will send
                            us data about your location based on your phone
                            settings. We will ask you to opt-in before we use
                            GPS or other tools to identify your precise
                            location.
                        </p>
                        <p>2.6 Messages</p>
                        <p>
                            We collect information about you when you send,
                            receive, or engage with messages in connection with
                            our Services. For example, if you get a Fiinect
                            connection request, we track whether you have acted
                            on it and will send you reminders. We also
                            use automatic scanning technology on messages to
                            support and protect our site. For example, we use
                            this technology to suggest possible responses to
                            messages and to manage or block content that
                            violates our User Agreement from our Services.
                        </p>
                        <p>2.7 Other</p>
                        <p>
                            Our Services are dynamic, and we often introduce new
                            features, which may require the collection of new
                            information. If we collect materially different
                            personal data or materially change how we collect,
                            use or share your data, we will notify you and may
                            also modify this Privacy Policy.
                        </p>
                        <p>Key Terms </p>
                        <h2>3.0. How We Use Your Data </h2>
                        <p>
                            We use your data to provide, support, personalize
                            and develop our Services.
                        </p>
                        <p>
                            How we use your personal data will depend on which
                            Services you use, how you use those Services and the
                            choices you make in your settings. We use the data
                            that we have about you to provide and personalize
                            our Services, including with the help of automated
                            systems and inferences we make, so that our Services
                            (including ads) can be more relevant and useful to
                            you and others.
                        </p>
                        <p>3.1 Services</p>
                        <p>
                            Our Services help you connect with others, find
                            business opportunities or finance partners and stay
                            informed.
                        </p>
                        <p>
                            We use your data to authorize access to our Services
                            and honour your settings.
                        </p>
                        <p>Stay Connected</p>
                        <p>
                            Our Services allow you to stay in touch and up to
                            date with colleagues, partners, clients, and other
                            professional contacts. To do so, you can "connect"
                            with the professionals who you choose, and who also
                            wish to "connect" with you. Subject to your and
                            their settings, when you connect with other Members,
                            you will be able to search each others' connections,
                            in an anonymized format, in order to expedite the
                            exchange of ideas, opportunities and introductions.
                        </p>
                        <p>
                            We use data about you (such as your profile,
                            profiles you have viewed or data provided through
                            contact uploads or partner integrations) to help
                            others find your profile, suggest connections for
                            you and others (e.g. Members who share your
                            objectives) and enable you to invite others to
                            become a Member and connect with you. You can also
                            opt-in to allow us to use your precise location or
                            proximity to others for certain tasks (e.g. to
                            suggest other nearby Members for you to connect with
                            or notify your connections that you are at an event.
                        </p>
                        <p>
                            It is your choice whether to invite someone to our
                            Services, send a connection request, or allow
                            another Member to become your connection. When you
                            invite someone to connect with you, your invitation
                            will include your network and basic profile
                            information (e.g., name, profile photo, job title,
                            region). We will send invitation reminders to the
                            person you invited.{" "}
                        </p>
                        <p>Stay Informed</p>
                        <p>
                            Our Services allow you to stay informed about news,
                            events and business opportunities &amp; communicate
                            with your network. We use the data we have about you
                            (e.g., data you provide, data we collect from your
                            engagement with our Services and inferences we make
                            from the data we have about you), to personalize our
                            Services for you, such as by recommending or ranking
                            relevant business opportunities or finance partners
                            on our Services. We use your content, activity and
                            other data, to provide notices to your network that
                            you have updated your profile, or posted content
                            etc.
                        </p>
                        <p>3.2 Premium Services</p>
                        <p>
                            We sell Premium Services that provide our premium
                            subscribers with priority access to some
                            functionalities as well as unlimited use of other
                            services.{" "}
                        </p>
                        <p>3.3 Communications</p>
                        <p>
                            We contact you and enable communications between
                            Members and your network.{" "}
                        </p>
                        <p>
                            We will contact you through email, mobile phone,
                            notices posted on our websites or apps, messages to
                            your Fiinect message box, and other ways through our
                            Services, including text messages and push
                            notifications. We will send you messages about the
                            availability of our Services, security, or other
                            service-related issues. We also send messages about
                            how to use our Services, network updates, reminders
                            &amp; promotional messages from us and our partners.
                            You may change your communication preferences at any
                            time. Please be aware that you cannot opt out of
                            receiving service messages from us, including
                            security and legal notices or form your connections
                            that you have accepted to be part of your network.
                            If you wish to stop messages from one of your
                            network then disconnect from them.
                        </p>
                        <p>3.4 Advertising</p>
                        <p>
                            We serve you tailored ads both on and off our
                            Services &amp; you cannot opt-out of these ads.
                        </p>
                        <p>
                            We target (and measure the performance of) ads to
                            Members, Visitors and others both on and off our
                            Services directly or through a variety of partners,
                            using the following data, whether separately or
                            combined:
                        </p>
                        <p>
                            Data from advertising technologies on and off our
                            Services, pixels, ad tags, cookies, and device
                            identifiers;
                        </p>
                        <p>
                            Member-provided information (e.g., profile, contact
                            information, title and industry);
                        </p>
                        <p>
                            All Data from your use of our Services (e.g., search
                            history, feed, content you read, who you connect
                            with or who connects with you, page visits, videos
                            you watch, clicking on an ad, etc.) etc
                        </p>
                        <p>
                            Information from advertising partners, vendors
                            and publishers; and
                        </p>
                        <p>
                            We will show you ads called sponsored content which
                            look similar to non-sponsored content, except that
                            they are labeled as "sponsored".{" "}
                        </p>
                        <p>Info to Ad Providers</p>
                        <p>
                            We do not share your personal data with any
                            third-party advertisers or ad networks except for:
                            (i) hashed IDs or device identifiers (to the extent
                            they are personal data in some countries); (ii) with
                            your separate permission (e.g., in a lead generation
                            form) or (iii) data already visible to any users of
                            the Services (e.g., profile). However, if you view
                            or click on an ad on or off our Services, the ad
                            provider will get a signal that someone visited the
                            page that displayed the ad, and they may, through
                            the use of mechanisms such as cookies, determine it
                            is you. Advertising partners can associate personal
                            data collected by the advertiser directly from you
                            with hashed IDs or device identifiers received from
                            us. In such instances, we seek to contractually
                            require such advertising partners to obtain your
                            explicit, opt-in consent before doing so.
                        </p>
                        <p>3.5 Marketing</p>
                        <p>
                            In addition to advertising our Services, we use
                            Members' data and content for invitations and
                            communications promoting membership and network
                            growth, engagement and our Services, such as by
                            showing your connections that you have used a
                            feature on our Services.
                        </p>
                        <p>3.6 Developing Services and Research</p>
                        <p>We develop our Services and conduct research</p>
                        <p>Service Development</p>
                        <p>
                            We use data, including public feedback, to
                            conduct research and development for our Services in
                            order to provide you and others with a better, more
                            intuitive and personalized experience, drive
                            membership growth and engagement on our Services,
                            and help connect members to each other and to
                            business opportunities of finance partners.
                        </p>
                        <p>Other Research</p>
                        <p>
                            We seek to create economic opportunity for Members
                            &amp; we use the personal data available to us to
                            research social &amp; economic trends, across
                            industries and geographic areas. In some cases, we
                            work with trusted third parties to perform this
                            research, under controls that are designed to
                            protect your privacy. We publish or allow others to
                            publish economic insights, presented as aggregated
                            data rather than personal data.
                        </p>
                        <p>Surveys</p>
                        <p>
                            Polls and surveys are conducted by us and others
                            through our Services. You are not obligated to
                            respond to polls or surveys, and you have choices
                            about the information you provide. You
                            may opt-out of survey invitations.
                        </p>
                        <p>3.7 Customer Support</p>
                        <p>
                            We use data (which can include your communications)
                            to investigate, respond to and resolve complaints
                            and for Service issues (e.g., bugs).
                        </p>
                        <p>3.8 Insights That Do Not Identify You</p>
                        <p>
                            We use data to generate insights that do not
                            identify you.
                        </p>
                        <p>
                            We use your data to produce and share insights that
                            do not identify you. For example, we may use
                            your data to generate statistics about our members,
                            their profession or industry, to calculate numbers
                            of business opportunities in each sector or region
                            or to publish visitor demographics for a Service or
                            to calculate ad impressions served or clicked on.
                        </p>
                        <p>3.9 Security and Investigations</p>
                        <p>
                            We use data for security, fraud prevention and
                            investigations.
                        </p>
                        <p>
                            We use your data (including your communications) for
                            security purposes or to prevent or investigate
                            possible fraud or other violations of our User
                            Agreement and/or attempts to harm our Members,
                            Visitors or others.
                        </p>
                        <p>Key Terms </p>
                        <h2>4.0. How We Share Information </h2>
                        <p>4.1 Our Services</p>
                        <p>
                            Any data that you include on your profile and any
                            content you post or social action (e.g., Interested,
                            Just Watching, Not Interested etc) you take on our
                            Services will be seen by others, consistent with
                            your settings.
                        </p>
                        <p>Profile</p>
                        <p>
                            Your profile is fully visible to all Members and
                            customers of our Services. Subject to your settings,
                            it can also be visible to others on or off of our
                            Services (e.g., Visitors to our Services or users of
                            third- party search engines).{" "}
                        </p>
                        <p>
                            In a network group, content posts are visible to
                            others in the group.{" "}
                        </p>
                        <p>
                            Any information you share on a companies'
                            opportunity pages on our Services will be viewable
                            by it and others who visit those pages.
                        </p>
                        <p>
                            When you are 'Interested' or 'Just Watching' a
                            person or organization, your status as 'Interested'
                            or 'Just watching' is visible to others and that
                            "organisation page owner" as a follower.
                        </p>
                        <p>
                            We let senders know when you act on their message.
                        </p>
                        <p>
                            Subject to your settings, we let a Member know when
                            you view their profile.
                        </p>
                        <p>Enterprise Accounts</p>
                        <p>
                            If your employer pays for you to be a Premium User
                            of Fiinect, we will 'not' share your data or
                            anything about who is in your network but we will
                            share with your employer relevant 'anonymised' data
                            so they can have a 'cumulative' view of the strength
                            of their whole enterprise networks.
                        </p>
                        <p>4.1 Related Services</p>
                        <p>
                            We will share your personal data with our affiliates
                            to provide and develop our Services. We may combine
                            information internally across the different Services
                            covered by this Privacy Policy to help our Services
                            be more relevant and useful to you and others. For
                            example, we may personalize your feed or job
                            recommendations based on your learning history.
                        </p>
                        <p>4.2 Service Providers</p>
                        <p>We may use others to help us with our Services.</p>
                        <p>
                            We use others to help us provide our Services (e.g.,
                            maintenance, analysis, audit, payments, fraud
                            detection, marketing and development). They will
                            have access to your information as reasonably
                            necessary to perform these tasks on our behalf and
                            are obligated not to disclose or use it for other
                            purposes.
                        </p>
                        <p>4.3 Legal Disclosures</p>
                        <p>
                            We may need to share your data when we believe it's
                            required by law or to help protect the rights and
                            safety of you, us or others.
                        </p>
                        <p>
                            It is possible that we will need to disclose
                            information about you when required by law,
                            subpoena, or other legal process or if we have a
                            good faith belief that disclosure is reasonably
                            necessary to (1) investigate, prevent or take action
                            regarding suspected or actual illegal activities or
                            to assist government enforcement agencies; (2)
                            enforce our agreements with you; (3) investigate and
                            defend ourselves against any third-party claims or
                            allegations; (4) protect the security or integrity
                            of our Services (such as by sharing with companies
                            facing similar threats); or (5) exercise or protect
                            the rights and safety of Fiinect, our Members,
                            personnel or others. We attempt to notify Members
                            about legal demands for their personal data when
                            appropriate in our judgment, unless prohibited by
                            law or court order or when the request is an
                            emergency. We may dispute such demands when we
                            believe, in our discretion, that the requests are
                            overbroad, vague or lack proper authority, but we do
                            not promise to challenge every demand.{" "}
                        </p>
                        <p>4.4 Change in Control or Sale</p>
                        <p>
                            We may share your data when our business is sold to
                            others, but it must continue to be used in
                            accordance with this Privacy Policy.
                        </p>
                        <p>
                            We can also share your personal data as part of a
                            sale, merger or change in control, or in preparation
                            for any of these events. Any other entity which buys
                            us or part of our business will have the right to
                            continue to use your data, but only in the manner
                            set out in this Privacy Policy unless you agree
                            otherwise.
                        </p>
                        <h2>5.0. Your Choices &amp; Obligations </h2>
                        <p>5.1 Data Retention</p>
                        <p>
                            We keep most of your personal data for as long as
                            your account is open.
                        </p>
                        <p>
                            We generally retain your personal data as long as
                            you keep your account open or as needed to provide
                            you Services. This includes data you or others
                            provided to us and data generated or inferred from
                            your use of our Services. Even if you only use our
                            Services when looking for finance or business
                            opportunities every few years, we will retain your
                            information and keep your profile open, unless you
                            close your account. In some cases we choose to
                            retain certain information (e.g., insights about
                            Services use) in a depersonalized or aggregated
                            form.
                        </p>
                        <p>
                            5.2 Rights to Access and Control Your Personal Data
                        </p>
                        <p>
                            For personal data that we have about you, you can:
                        </p>
                        <p>
                            Delete Data: You can ask us to erase or delete all
                            or some of your personal data (e.g., if it is no
                            longer necessary to provide Services to you).
                        </p>
                        <p>
                            Change or Correct Data: You can edit some of your
                            personal data through your account. You can also ask
                            us to change, update or fix your data in certain
                            cases, particularly if it's inaccurate.
                        </p>
                        <p>
                            Object to, or Limit or Restrict, Use of Data: You
                            can ask us to stop using all or some of your
                            personal data (e.g., if we have no legal right to
                            keep using it) or to limit our use of it (e.g., if
                            your personal data is inaccurate or unlawfully
                            held).
                        </p>
                        <p>
                            Right to Access and/or Take Your Data: You can ask
                            us for a copy of your personal data and can ask for
                            a copy of personal data you provided.
                        </p>
                        <p>5.3 Account Closure</p>
                        <p>
                            We keep some of your data even after you close your
                            account.
                        </p>
                        <p>
                            If you choose to close your Fiinect account, your
                            personal data will generally stop being visible to
                            others on our Services within 24 hours. We generally
                            delete closed account information within 30 days of
                            account closure, except as noted below.
                        </p>
                        <p>
                            We retain your personal data even after you have
                            closed your account if reasonably necessary to
                            comply with our legal obligations (including law
                            enforcement requests), meet regulatory requirements,
                            resolve disputes, maintain security, prevent fraud
                            and abuse, enforce our User Agreement, or fulfill
                            your request to "unsubscribe" from further messages
                            from us. We will retain de-personalized information
                            after your account has been closed.
                        </p>
                        <p>
                            Information you have shared with others will remain
                            visible after you close your account or delete the
                            information from your own profile or mailbox, and we
                            do not control data that other Members have copied
                            out of our Services. Groups content and ratings or
                            review content associated with closed accounts will
                            show an unknown user as the source. Your profile may
                            continue to be displayed in the services of others
                            (e.g., search engine results) until they refresh
                            their cache.
                        </p>
                        <h2>6.0. Other Important Information </h2>
                        <p>6.1. Security</p>
                        <p>
                            We monitor for and try to prevent security breaches.{" "}
                        </p>
                        <p>
                            We implement security safeguards designed to protect
                            your data, such as HTTPS. We regularly monitor our
                            systems for possible vulnerabilities and attacks.
                            However, we cannot warrant the security of any
                            information that you send us. There is no guarantee
                            that data may not be accessed, disclosed, altered,
                            or destroyed by breach of any of our physical,
                            technical, or managerial safeguards.{" "}
                        </p>
                        <p>6.2. Cross-Border Data Transfers</p>
                        <p>We store and use your data outside your country.</p>
                        <p>
                            We process data both inside and outside of the UK
                            and rely on legally-provided mechanisms to lawfully
                            transfer data across borders. 
                        </p>
                        <p>6.3 Lawful Bases for Processing</p>
                        <p>
                            We have lawful bases to collect, use and share data
                            about you. You have choices about our use of your
                            data. At any time, you can withdraw consent you have
                            provided by going to settings.
                        </p>
                        <p>
                            We will only collect and process personal data about
                            you where we have lawful bases. Lawful bases
                            include consent(where you have given consent),
                            contract (where processing is necessary for the
                            performance of a contract with you (e.g., to deliver
                            the Fiinect Services you have requested) and
                            "legitimate interests." 
                        </p>
                        <p>
                            Where we rely on your consent to process personal
                            data, you have the right to withdraw or decline your
                            consent at any time and where we rely on legitimate
                            interests, you have the right to object.  If you
                            have any questions about the lawful bases upon which
                            we collect and use your personal data, please
                            contact our Data Protection Officer at
                            info@fiinect.com
                        </p>
                        <p>6.4. Direct Marketing and Do Not Track Signals</p>
                        <p>
                            We currently do not share personal data with third
                            parties for their direct marketing purposes without
                            your permission. 
                        </p>
                        <p>6.5. Contact Information</p>
                        <p>
                            You can contact us or use other options to resolve
                            any complaints.
                        </p>
                        <p>
                            If you have questions or complaints regarding this
                            Policy, please contact us at info@fiinect.com
                        </p>
                    </div>
                </MainContent>
            </>
        );
    }
}

export default Privacy;

import React, { Component } from "react";
import axios from "axios";
import { uniqueNamesGenerator, animals, colors } from "unique-names-generator";
import { API_BASE } from "../../../api";
import { connect } from "react-redux";

class OpportunityGenerator extends Component {
    randomDate(start, end) {
        return new Date(
            start.getTime() + Math.random() * (end.getTime() - start.getTime())
        );
    }

    generateOpportunities() {
        const { auxillary } = this.props;

        return axios
            .get(`/equitynet.json`)
            .then(({ data }) => {
                const opportunities = [];

                data.forEach((opportunity) => {
                    const loi = auxillary.levelOfInvestment[
                        opportunity.Currency
                    ].find(
                        (level) =>
                            parseInt(level.ticketSize) ===
                            parseInt(opportunity.TicketSize)
                    );
                    if (!loi) return;
                    const oppObject = {
                        ...opportunity,
                        FinanceStageId: null,
                        Id: uuidv4(),
                        LevelOfInvestmentId: loi.id
                    };

                    opportunities.push(oppObject);
                });

                console.log(opportunities);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        return (
            <button onClick={this.generateOpportunities.bind(this)}>
                Generate Opportunities
            </button>
        );
    }
}

export default connect(
    (state) => ({
        network: state.network,
        auxillary: state.auxillary
    }),
    {}
)(OpportunityGenerator);

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

import React, { Component } from "react";
import Button from "../button/Button";
import Modal from "../modal/Modal";

import "./InfoBubble.scss";

class InfoBubble extends Component {
    state = {
        modalOpen: false
    };

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        const { modalOpen } = this.state;
        const { content } = this.props;

        return (
            <div className="info-bubble">
                <i onClick={this.toggleModal.bind(this)}>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/question.png`}
                    />
                </i>
                <Modal fullScreen visible={modalOpen}>
                    <i>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/question.png`}
                        />
                    </i>
                    {content}
                    <Button onClick={this.toggleModal.bind(this)}>Close</Button>
                </Modal>
            </div>
        );
    }
}

export default InfoBubble;

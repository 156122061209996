import React, { Component } from "react";
import * as d3 from "d3";

import "./ClusteredBubbleChart.scss";
import ConnectionList from "../connection-list/ConnectionList";
import { abbreviations } from "../../data/data";

class ClusteredBubbleChart extends Component {
    state = {
        chartCreated: false
    };

    componentDidMount() {
        const { network, auxillary, filter } = this.props;

        if (auxillary[filter].length === 0 || network.connections.length === 0)
            return;
        this.createChart();
    }

    componentDidUpdate() {
        const { network, auxillary, filter } = this.props;

        if (
            auxillary[filter].length === 0 ||
            network.connections.length === 0 ||
            this.state.chartCreated
        )
            return;
        this.createChart();
    }

    buildData() {
        const { auth, network, auxillary, filter } = this.props;
        let total = 0;
        const filterData = {};

        network.connections.forEach((connection) => {
            connection[filter].forEach((filterValue) => {
                let auxData;
                if (filter === "levelOfInvestment") {
                    let ticketSize;
                    Object.keys(auxillary.levelOfInvestment).forEach(
                        (currency) => {
                            if (ticketSize) return;

                            ticketSize = auxillary.levelOfInvestment[
                                currency
                            ].find((loi) => loi.id === filterValue.id);
                        }
                    );

                    auxData = auxillary[filter][
                        auth.user.currencyPreference
                    ].find((data) => data.ticketSize === ticketSize.ticketSize);
                } else {
                    auxData = auxillary[filter].find(
                        (data) => data.id === filterValue.id
                    );
                }

                if (!auxData) return;
                const value = auxData.value;

                if (!filterData[value]) {
                    if (filter === "levelOfInvestment") {
                        filterData[value] = {
                            id: auxData.ticketSize,
                            title: value,
                            count: 0
                        };
                    } else {
                        filterData[value] = {
                            id: filterValue.id,
                            title: value,
                            count: 0
                        };
                    }
                }

                filterData[value].count++;
            });
            total++;
        });

        if (filter === "levelOfInvestment") {
            auxillary[filter][auth.user.currencyPreference].forEach(
                (auxValue) => {
                    if (!filterData[auxValue.value]) {
                        filterData[auxValue.value] = {
                            id: auxValue.ticketSize,
                            title: auxValue.value,
                            count: 0
                        };
                    }
                }
            );
        } else {
            auxillary[filter].forEach((auxValue) => {
                if (!filterData[auxValue.value]) {
                    filterData[auxValue.value] = {
                        id: auxValue.id,
                        title: auxValue.value,
                        count: 0
                    };
                }
            });
        }

        return Object.keys(filterData).map((filter) => {
            return {
                id: filterData[filter].id,
                value: filterData[filter].title,
                title: filterData[filter].title,
                count: filterData[filter].count,
                group: 1,
                size: filterData[filter].count,
                percentage: Math.round((filterData[filter].count / total) * 100)
            };
        });
    }

    createChart() {
        const {
            setNetworkFilterAction,
            getFilterSuggestionsAction,
            getFilteredConnectionsAction,
            filter
        } = this.props;

        const width = document.getElementById("bubble-chart").clientWidth,
            padding = 10, // separation between same-color nodes
            clusterPadding = 10, // separation between different-color nodes
            minRadius = 30,
            maxRadius = 70;

        const data = this.buildData();

        let height = 540;
        data.forEach(function (d) {
            d.size = +d.size;
        });

        const n = data.length,
            m = 1;

        //create clusters and nodes
        const clusters = new Array(m);
        const nodes = [];
        for (var i = 0; i < n; i++) {
            nodes.push(create_nodes(data, i));
        }

        const force = d3.layout
            .force()
            .nodes(nodes)
            .size([width, height])
            .gravity(0.03)
            .charge(0)
            .on("tick", tick)
            .start();

        const svg = d3
            .select("#bubble-chart")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const node = svg
            .selectAll("circle")
            .data(nodes)
            .enter()
            .append("g")
            .call(force.drag);

        node.append("circle")
            .style("fill", function (d) {
                if (d.count === 0) return "#cdcdcd33";
                return "#ff4e0033";
            })
            .style("stroke", function (d) {
                if (d.count === 0) return "#cdcdcd";
                return "#ff4e00";
            })
            .attr("r", function (d) {
                return d.radius;
            })
            .attr("data-data", function (d) {
                return JSON.stringify(d);
            })
            .on("mouseover", function (d) {
                const longText = d3
                    .select(this.parentNode)
                    .select("text.text-overflow");
                const shortText = d3
                    .select(this.parentNode)
                    .select("text.text-short");

                shortText.style("fill", "transparent");
                if (d.count !== 0) {
                    d3.select(this)
                        .style("fill", "#5046d233")
                        .style("stroke", "#5046d2");
                    longText.style("fill", "#5046d2");
                } else {
                    d3.select(this)
                        .style("fill", "#99999933")
                        .style("stroke", "#999999");
                    longText.style("fill", "#999999");
                }
            })
            .on("mouseout", function (d) {
                const longText = d3
                    .select(this.parentNode)
                    .select("text.text-overflow");
                const shortText = d3
                    .select(this.parentNode)
                    .select("text.text-short");

                longText.style("fill", "transparent");
                if (d.count === 0) {
                    d3.select(this)
                        .style("fill", "#cdcdcd33")
                        .style("stroke", "#cdcdcd");
                    shortText.style("fill", "#cdcdcd");
                } else {
                    d3.select(this)
                        .style("fill", "#ff4e0033")
                        .style("stroke", "#ff4e00");
                    shortText.style("fill", "#ff4e00");
                }
            })
            .on("click", function () {
                document
                    .querySelectorAll("g.selected")
                    .forEach((node) => node.classList.remove("selected"));
                this.parentNode.classList.add("selected");
                const filters = JSON.parse(d3.select(this).attr("data-data"));

                setNetworkFilterAction(filter, filters.filter);
                getFilterSuggestionsAction(filter, filters.filter.id);
                getFilteredConnectionsAction();
            });

        node.append("text")
            .attr("dy", ".3em")
            .style("text-anchor", "middle")
            .style("fill", "transparent")
            .style("font-family", "'Gibson SemiBold', sans-serif")
            .style("font-size", "13px")
            .text(function (d) {
                if (filter === "levelOfInvestment") return d.title;
                else return abbreviations[filter][d.id];
            })
            .each(function (d) {
                if (d.count === 0) {
                    d3.select(this).style("fill", "#cdcdcd");
                } else {
                    d3.select(this).style("fill", "#ff4e00");
                }
            })
            .each(function (d) {
                this.classList.add("text-short");
            });

        node.append("text")
            .attr("dy", ".3em")
            .style("text-anchor", "middle")
            .style("fill", "transparent")
            .style("font-family", "'Gibson SemiBold', sans-serif")
            .style("font-size", "13px")
            .text(function (d) {
                return d.title;
            })
            .each(function (d) {
                this.classList.add("text-overflow");
            });

        function create_nodes(data, node_counter) {
            let radius = minRadius + data[node_counter].size * 3;
            if (data[node_counter].size > 0) radius += 5;

            radius = radius > maxRadius ? maxRadius : radius;
            radius = radius < minRadius ? minRadius : radius;

            let i = 0,
                r =
                    Math.sqrt(((i + 1) / m) * -Math.log(Math.random())) *
                    maxRadius,
                d = {
                    cluster: i,
                    radius,
                    id: data[node_counter].id,
                    count: data[node_counter].size,
                    title: data[node_counter].title,
                    x:
                        Math.cos((i / m) * 2 * Math.PI) * 200 +
                        width / 2 +
                        Math.random(),
                    y:
                        Math.sin((i / m) * 2 * Math.PI) * 100 +
                        height / 2 +
                        Math.random(),
                    filter: data[node_counter]
                };
            if (!clusters[i] || r > clusters[i].radius) clusters[i] = d;
            return d;
        }

        function tick(e) {
            node.each(cluster(10 * e.alpha * e.alpha))
                .each(collide(1))
                .attr("transform", function (d) {
                    var k = "translate(" + d.x + "," + d.y + ")";
                    return k;
                });
        }

        // Move d to be adjacent to the cluster node.
        function cluster(alpha) {
            return function (d) {
                var cluster = clusters[d.cluster];
                if (cluster === d) return;
                var x = d.x - cluster.x,
                    y = d.y - cluster.y,
                    l = Math.sqrt((x * x) / 30 + y * y),
                    r = d.radius + cluster.radius;
                if (l != r) {
                    l = ((l - r) / l) * alpha;
                    d.x -= x *= l;
                    d.y -= y *= l;
                    cluster.x += x;
                    cluster.y += y;
                }
            };
        }

        // Resolves collisions between d and all other circles.
        function collide(alpha) {
            var quadtree = d3.geom.quadtree(nodes);

            return function (d) {
                var r =
                        d.radius +
                        maxRadius +
                        Math.max(padding, clusterPadding),
                    nx1 = d.x - r, // + Math.random(),
                    nx2 = d.x + r, // + Math.random(),
                    ny1 = d.y - r, // + Math.random(),
                    ny2 = d.y + r; // + Math.random();
                quadtree.visit(function (quad, x1, y1, x2, y2) {
                    if (quad.point && quad.point !== d) {
                        var x = d.x - quad.point.x,
                            y = d.y - quad.point.y,
                            l = Math.sqrt(x * x + y * y),
                            r =
                                d.radius +
                                quad.point.radius +
                                (d.cluster === quad.point.cluster
                                    ? padding
                                    : clusterPadding);
                        if (l < r) {
                            l = ((l - r) / l) * alpha;
                            d.x -= x *= l;
                            d.y -= y *= l;
                            quad.point.x += x;
                            quad.point.y += y;
                        }
                    }
                    return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
                });
            };
        }

        this.setState({
            chartCreated: true
        });
    }

    resetFilters() {
        const { resetNetworkFiltersAction } = this.props;

        resetNetworkFiltersAction();
    }

    render() {
        const {
            network,
            filter,
            premiumInvitesCount,
            setFocusedUserAction
        } = this.props;

        return (
            <>
                <div id="bubble-chart"></div>
                <ConnectionList
                    premiumInvitesCount={premiumInvitesCount}
                    network={network}
                    filterName={filter}
                    resetFilters={this.resetFilters.bind(this)}
                    setFocusedUserAction={setFocusedUserAction}
                />
            </>
        );
    }
}

export default ClusteredBubbleChart;

import React, { Component } from "react";

import PlainCard from "../plain-card/PlainCard";
import ProfileIcon from "../profile-icon/ProfileIcon";

import "./ProfileCard.scss";

class ProfileCard extends Component {
    onClick() {
        const { onClick, person } = this.props;

        onClick(person);
    }

    render() {
        const { person } = this.props;

        if (!person) return null;

        return (
            <PlainCard
                className="profile-card"
                onClick={this.onClick.bind(this)}
            >
                <ProfileIcon person={person} />
                <p>{person.name}</p>
            </PlainCard>
        );
    }
}

export default ProfileCard;

import React, { Component } from "react";

import "./Accordion.scss";

class Accordion extends Component {
    componentWillMount() {
        this.setState({
            open: this.props.open,
            fullyOpen: this.props.open
        });
    }

    toggle() {
        const open = this.state.open;
        if (!open) {
            this.setState({
                open: true
            });

            setTimeout(() => {
                this.setState({
                    fullyOpen: true
                });
            }, 400);
        } else {
            this.setState({
                fullyOpen: false
            });

            setTimeout(() => {
                this.setState({
                    open: false
                });
            }, 10);
        }
    }

    render() {
        const { header, cssClass, children } = this.props;

        const { open, fullyOpen } = this.state;

        let className = "accordion";
        if (cssClass) className += ` ${cssClass}`;
        if (open) className += " open";
        if (fullyOpen) className += " fully-open";

        return (
            <div className={className}>
                <h3
                    className="accordion-header"
                    onClick={this.toggle.bind(this)}
                >
                    {header}
                </h3>
                <div className="accordion-content">{children}</div>
            </div>
        );
    }
}

export default Accordion;

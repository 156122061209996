import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

import countries from "../../data/countries";

import "./NetworkMap.scss";
import ConnectionList from "../connection-list/ConnectionList";
import { regionCodes, regionNames } from "../../data/data";

class NetworkMap extends Component {
    state = {
        mapDrawn: false
    };

    componentDidMount() {
        const { network } = this.props;

        if (network.connections.length === 0) return;
        this.drawMap();
    }

    componentDidUpdate() {
        const { network } = this.props;

        if (network.connections.length === 0 || this.state.mapDrawn) return;
        this.drawMap();
    }

    drawMap() {
        const {
            locations,
            setNetworkCountryFilterAction,
            getLocationSuggestionsAction,
            getFilteredConnectionsAction
        } = this.props;

        am4core.useTheme(am4themes_animated);

        let map = am4core.create("network-map", am4maps.MapChart);
        map.geodata = am4geodata_worldLow;
        map.projection = new am4maps.projections.Miller();

        var polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        polygonSeries.useGeodata = true;
        polygonSeries.mapPolygons.template.stroke = am4core.color("#aaaaaa");

        // Add image series
        var imageSeries = map.series.push(new am4maps.MapImageSeries());
        imageSeries.tooltip.getFillFromObject = false;
        imageSeries.tooltip.background.fill = am4core.color("#ffffff");

        imageSeries.mapImages.template.cursorOverStyle =
            am4core.MouseCursorStyle.pointer;
        imageSeries.mapImages.template.propertyFields.longitude = "longitude";
        imageSeries.mapImages.template.propertyFields.latitude = "latitude";
        imageSeries.mapImages.template.tooltipHTML = `
            <div class="tooltip-content">
                <h4>{title}</h4>
                <p><span class="percentage">{percentage}%</span><span class="count">{count}</span></p>
            </div>
        `;
        imageSeries.mapImages.template.clickable = true;
        imageSeries.mapImages.template.propertyFields.url = "url";

        imageSeries.mapImages.template.events.on("hit", function (ev) {
            ev.target.series.chart.zoomToMapObject(ev.target);
            setNetworkCountryFilterAction(ev.target.dataItem.dataContext);

            const location = locations.find(
                (location) =>
                    location.code === ev.target.dataItem.dataContext.value
            );
            if (location) getLocationSuggestionsAction(location.id);
            getFilteredConnectionsAction();
        });

        var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle.propertyFields.radius = "radius";
        circle.propertyFields.fill = "color";

        var circle2 = imageSeries.mapImages.template.createChild(
            am4core.Circle
        );
        circle2.radius = 5;
        circle2.propertyFields.fill = "color";

        circle2.events.on("inited", function (event) {
            // animateBullet(event.target);
        });

        function animateBullet(circle) {
            var animation = circle.animate(
                [
                    { property: "scale", from: 1, to: 5 },
                    { property: "opacity", from: 1, to: 0 }
                ],
                5000,
                am4core.ease.circleOut
            );
            animation.events.on("animationended", function (event) {
                animateBullet(event.target.object);
            });
        }

        imageSeries.data = this.buildData();

        this.map = map;

        this.setState({
            mapDrawn: true
        });
    }

    buildData() {
        const { network, locations } = this.props;

        const countryData = {};
        let total = 0;
        network.connections.forEach((connection) => {
            if (!connection.country) return;

            let region = connection.country;
            if (!regionNames[region]) {
                const location = locations.find((l) => l.code === region);
                if (location) {
                    const regionId = location.regionId;
                    region = locations.find((l) => l.id === regionId).code;
                }
            }

            if (!countryData[region]) countryData[region] = 1;
            else countryData[region]++;
            total++;
        });

        return Object.keys(countryData).map((countryCode) => {
            const country = countries.ref_country_codes.find((country) => {
                if (country.alpha2 === countryCode) {
                    return country.alpha2 === countryCode;
                } else {
                    return country.alpha2 === regionCodes[countryCode];
                }
            });

            return {
                value: countryCode,
                title: regionNames[countryCode],
                latitude: country.latitude,
                longitude: country.longitude,
                color: "#ff4e00",
                radius:
                    countryData[countryCode] * 2 > 30
                        ? 30
                        : countryData[countryCode] * 2,
                count: countryData[countryCode],
                percentage: Math.round((countryData[countryCode] / total) * 100)
            };
        });
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.dispose();
        }
    }

    resetFilters() {
        const { resetNetworkFiltersAction } = this.props;

        this.map.goHome();
        resetNetworkFiltersAction();
    }

    render() {
        const {
            network,
            premiumInvitesCount,
            setFocusedUserAction
        } = this.props;

        return (
            <>
                <div id="network-map"></div>
                <ConnectionList
                    premiumInvitesCount={premiumInvitesCount}
                    network={network}
                    filterName="country"
                    resetFilters={this.resetFilters.bind(this)}
                    setFocusedUserAction={setFocusedUserAction}
                />
            </>
        );
    }
}

export default NetworkMap;

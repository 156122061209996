import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_continentsLow from "@amcharts/amcharts4-geodata/continentsLow";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

import "./RegionSelector.scss";
import { regionNames } from "../../data/data";

class RegionSelector extends Component {
    baseColour = am4core.color("#b2b2b2");
    hoverColour = am4core.color("#ff4e00");

    chart;
    pacificSeries;
    continentsSeries;
    ukSeries;
    menaSeries;
    menaSeriesBackdrop;
    latinSeries;
    latinSeriesBackdrop;

    state = {
        selectedRegions: [],
        initialRegions: [],
        globalSelected: false
    };

    pacificCountries = ["AU", "NZ", "PG"];
    latinCountries = [
        "AG",
        "AR",
        "BZ",
        "BO",
        "BR",
        "CO",
        "CL",
        "CR",
        "DM",
        "DO",
        "EC",
        "SV",
        "GD",
        "GT",
        "GY",
        "HT",
        "HN",
        "JM",
        "MX",
        "NI",
        "PA",
        "PY",
        "PE",
        "KN",
        "VC",
        "LC",
        "SR",
        "TT",
        "UY",
        "VE"
    ];
    menaCountries = [
        "DZ",
        "MA",
        "BH",
        "DJ",
        "EG",
        "IR",
        "IQ",
        "JO",
        "KW",
        "LB",
        "LY",
        "OM",
        "QA",
        "SA",
        "SY",
        "TN",
        "AE",
        "YE",
        "PS"
    ];

    componentDidMount() {
        const { initialRegions } = this.props;

        this.buildMap();
    }

    componentDidUpdate() {
        const { initialRegions } = this.props;

        if (
            initialRegions &&
            initialRegions.length !== this.state.initialRegions.length
        ) {
            this.setState({
                initialRegions
            });

            initialRegions.forEach((region) =>
                this.toggleRegion(region.location.country, false)
            );
        }
    }

    checkRegionSelected(region) {
        const { selectedRegions } = this.state;

        if (selectedRegions.indexOf(region) > -1) return this.hoverColour;

        return this.baseColour;
    }

    toggleRegion(region, toggle = true) {
        const { selectedRegions, globalSelected } = this.state;

        const { allowMultiple, onRegionToggle } = this.props;

        if (globalSelected) return;

        if (allowMultiple) {
            const regionIndex = selectedRegions.indexOf(region);
            if (regionIndex > -1) selectedRegions.splice(regionIndex, 1);
            else selectedRegions.push(region);
        } else {
            selectedRegions.length = 0;
            selectedRegions.push(region);
        }

        this.setState({
            selectedRegions
        });

        this.fillRegions();

        if (toggle) onRegionToggle(selectedRegions);
    }

    toggleSelectAll() {
        const { globalSelected } = this.state;
        const { onRegionToggle } = this.props;

        const selectedRegions = [];

        if (!globalSelected) {
            [
                "northAmerica",
                "southAmerica",
                "mena",
                "africa",
                "asia",
                "europe",
                "GB"
            ].forEach((region) => {
                selectedRegions.push(region);
            });
        }

        this.setState({
            selectedRegions,
            globalSelected: !globalSelected
        });

        setTimeout(() => {
            this.fillRegions();
        }, 5);

        onRegionToggle(["global"]);
    }

    buildMap() {
        am4core.useTheme(am4themes_animated);
        this.map = am4core.create("region-selector-map", am4maps.MapChart);
        this.map.projection = new am4maps.projections.Mercator();

        // Include Pacific regions
        this.pacificSeries = this.map.series.push(
            new am4maps.MapPolygonSeries()
        );
        this.pacificSeries.include = this.pacificCountries;
        this.pacificSeries.geodata = am4geodata_worldLow;
        this.pacificSeries.useGeodata = true;
        this.pacificSeries.tooltip.getFillFromObject = false;
        this.pacificSeries.tooltip.background.fill = am4core.color("#ffffff");
        this.pacificSeries.tooltip.label.fill = am4core.color("#000000");
        this.pacificSeries.setStateOnChildren = true;
        this.pacificSeries.calculateVisualCenter = true;

        let pacificTemplate = this.pacificSeries.mapPolygons.template;
        pacificTemplate.propertyFields.fill = "color";
        pacificTemplate.stroke = am4core.color("#ffffff00");
        pacificTemplate.nonScalingStroke = false;
        pacificTemplate.events.on("hit", (ev) => {
            this.toggleRegion("asia");
        });
        pacificTemplate.events.on("over", (event) => {
            this.continentsSeries.mapPolygons.each((mapPolygon) => {
                if (mapPolygon.dataItem.dataContext.id === "asia") {
                    mapPolygon.isHover = true;
                }
            });
            this.pacificSeries.mapPolygons.each((mapPolygon) => {
                mapPolygon.isHover = true;
            });
        });
        pacificTemplate.events.on("out", (event) => {
            this.continentsSeries.mapPolygons.each((mapPolygon) => {
                if (mapPolygon.dataItem.dataContext.id === "asia") {
                    mapPolygon.isHover = false;
                }
            });
            this.pacificSeries.mapPolygons.each((mapPolygon) => {
                mapPolygon.isHover = false;
            });
        });

        let pacificHover = pacificTemplate.states.create("hover");
        pacificHover.properties.stroke = this.hoverColour;

        // Include continents
        this.continentsSeries = this.map.series.push(
            new am4maps.MapPolygonSeries()
        );
        this.continentsSeries.geodata = am4geodata_continentsLow;
        this.continentsSeries.useGeodata = true;
        this.continentsSeries.tooltip.getFillFromObject = false;
        this.continentsSeries.tooltip.background.fill = am4core.color(
            "#ffffff"
        );
        this.continentsSeries.tooltip.label.fill = am4core.color("#000000");
        this.continentsSeries.exclude = ["antarctica", "oceania"];

        let continentTemplate = this.continentsSeries.mapPolygons.template;
        continentTemplate.propertyFields.fill = "color";
        continentTemplate.propertyFields.stroke = am4core.color("#ffffff");
        continentTemplate.nonScalingStroke = true;
        continentTemplate.events.on("hit", (ev) => {
            this.toggleRegion(ev.target.dataItem.dataContext.id);
        });

        continentTemplate.events.on("over", (event) => {
            if (event.target.dataItem.dataContext.id === "asia") {
                this.pacificSeries.mapPolygons.each((mapPolygon) => {
                    mapPolygon.isHover = true;
                });
            }
        });
        continentTemplate.events.on("out", (event) => {
            if (event.target.dataItem.dataContext.id === "asia") {
                this.pacificSeries.mapPolygons.each((mapPolygon) => {
                    mapPolygon.isHover = false;
                });
            }
        });
        let contintentHover = continentTemplate.states.create("hover");
        contintentHover.properties.stroke = this.hoverColour;

        // Include UK
        this.ukSeries = this.map.series.push(new am4maps.MapPolygonSeries());
        this.ukSeries.include = ["GB"];
        this.ukSeries.geodata = am4geodata_worldLow;
        this.ukSeries.useGeodata = true;
        this.ukSeries.tooltip.getFillFromObject = false;
        this.ukSeries.tooltip.background.fill = am4core.color("#ffffff");
        this.ukSeries.tooltip.label.fill = am4core.color("#000000");

        let ukTemplate = this.ukSeries.mapPolygons.template;
        ukTemplate.propertyFields.fill = "color";
        ukTemplate.nonScalingStroke = true;
        ukTemplate.events.on("hit", (ev) => {
            this.toggleRegion(ev.target.dataItem.dataContext.id);
        });

        let ukHover = ukTemplate.states.create("hover");
        ukHover.properties.stroke = this.hoverColour;

        // Include MENA regions
        this.menaSeriesBackdrop = this.map.series.push(
            new am4maps.MapPolygonSeries()
        );
        this.menaSeriesBackdrop.include = this.menaCountries;
        this.menaSeriesBackdrop.geodata = am4geodata_worldLow;
        this.menaSeriesBackdrop.useGeodata = true;

        this.menaSeries = this.map.series.push(new am4maps.MapPolygonSeries());
        this.menaSeries.include = this.menaCountries;
        this.menaSeries.geodata = am4geodata_worldLow;
        this.menaSeries.useGeodata = true;
        this.menaSeries.tooltip.getFillFromObject = false;
        this.menaSeries.tooltip.background.fill = am4core.color("#ffffff");
        this.menaSeries.tooltip.label.fill = am4core.color("#000000");
        this.menaSeries.setStateOnChildren = true;
        this.menaSeries.calculateVisualCenter = true;

        let menaBackdropTemplate = this.menaSeriesBackdrop.mapPolygons.template;
        menaBackdropTemplate.strokeWidth = 2;

        let menaTemplate = this.menaSeries.mapPolygons.template;
        menaTemplate.propertyFields.fill = "color";
        menaTemplate.stroke = am4core.color("#ffffff00");
        menaTemplate.nonScalingStroke = false;
        menaTemplate.events.on("hit", (ev) => {
            this.toggleRegion("mena");
        });
        menaTemplate.events.on("over", (event) => {
            this.menaSeriesBackdrop.mapPolygons.each((mapPolygon) => {
                mapPolygon.isHover = true;
            });
        });
        menaTemplate.events.on("out", (event) => {
            this.menaSeriesBackdrop.mapPolygons.each((mapPolygon) => {
                mapPolygon.isHover = false;
            });
        });

        let menaHover = menaBackdropTemplate.states.create("hover");
        menaHover.properties.stroke = this.hoverColour;

        // Include Latin America regions
        this.latinSeriesBackdrop = this.map.series.push(
            new am4maps.MapPolygonSeries()
        );
        this.latinSeriesBackdrop.include = this.latinCountries;
        this.latinSeriesBackdrop.geodata = am4geodata_worldLow;
        this.latinSeriesBackdrop.useGeodata = true;

        this.latinSeries = this.map.series.push(new am4maps.MapPolygonSeries());
        this.latinSeries.include = this.latinCountries;
        this.latinSeries.geodata = am4geodata_worldLow;
        this.latinSeries.useGeodata = true;
        this.latinSeries.tooltip.getFillFromObject = false;
        this.latinSeries.tooltip.background.fill = am4core.color("#ffffff");
        this.latinSeries.tooltip.label.fill = am4core.color("#000000");
        this.latinSeries.setStateOnChildren = true;
        this.latinSeries.calculateVisualCenter = true;

        let latinBackdropTemplate = this.latinSeriesBackdrop.mapPolygons
            .template;
        latinBackdropTemplate.strokeWidth = 2;

        let latinTemplate = this.latinSeries.mapPolygons.template;
        latinTemplate.propertyFields.fill = "color";
        latinTemplate.stroke = am4core.color("#ffffff00");
        latinTemplate.nonScalingStroke = false;
        latinTemplate.events.on("hit", (ev) => {
            this.toggleRegion("southAmerica");
        });
        latinTemplate.events.on("over", (event) => {
            this.latinSeriesBackdrop.mapPolygons.each((mapPolygon) => {
                mapPolygon.isHover = true;
            });
        });
        latinTemplate.events.on("out", (event) => {
            this.latinSeriesBackdrop.mapPolygons.each((mapPolygon) => {
                mapPolygon.isHover = false;
            });
        });

        let latinHover = latinBackdropTemplate.states.create("hover");
        latinHover.properties.stroke = this.hoverColour;

        // Tooltips
        continentTemplate.tooltipText = "{title}";
        ukTemplate.tooltipText = "{title}";
        menaTemplate.tooltipText = "{title}";
        pacificTemplate.tooltipText = "{title}";

        this.fillRegions();
    }

    fillRegions() {
        this.ukSeries.data = [
            {
                id: "GB",
                color: this.checkRegionSelected("GB"),
                title: "United Kingdom"
            }
        ];
        this.menaSeries.data = this.menaCountries.map((country) => ({
            id: country,
            color: this.checkRegionSelected("mena"),
            title: "Middle East and North Africa"
        }));
        this.latinSeries.data = this.latinCountries.map((country) => ({
            id: country,
            color: this.checkRegionSelected("southAmerica"),
            title: "Latin America and the Caribbean"
        }));
        this.pacificSeries.data = this.pacificCountries.map((country) => ({
            id: country,
            color: this.checkRegionSelected("asia"),
            title: "Asia and Pacific"
        }));
        this.continentsSeries.data = [
            {
                id: "africa",
                color: this.checkRegionSelected("africa"),
                title: "Africa"
            },
            {
                id: "asia",
                color: this.checkRegionSelected("asia"),
                title: "Asia and Pacific"
            },
            {
                id: "europe",
                color: this.checkRegionSelected("europe"),
                title: "Europe"
            },
            {
                id: "northAmerica",
                color: this.checkRegionSelected("northAmerica"),
                title: "North America"
            },
            {
                id: "southAmerica",
                color: this.checkRegionSelected("southAmerica"),
                title: "South America"
            }
        ];
    }

    render() {
        const { selectedRegions } = this.state;

        const prettySelected = selectedRegions.map(
            (region) => regionNames[region]
        );

        return (
            <div className="region-selector">
                <label>
                    <input
                        type="checkbox"
                        onChange={this.toggleSelectAll.bind(this)}
                    />{" "}
                    Select all
                </label>
                <header>
                    Selected: <span>{prettySelected.join(", ")}</span>
                </header>
                <div id="region-selector-map"></div>
            </div>
        );
    }
}

export default RegionSelector;

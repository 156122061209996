import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    occupations: [],
    expertise: [],
    locations: [],
    sectorInterests: [],
    levelOfInvestment: [],
    financeStages: [],
    businessTypes: [],
    financeTypes: [],
    news: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.GET_OCCUPATIONS_SUCCESS:
            return {
                ...state,
                occupations: action.occupations
            };
        case actionTypes.GET_EXPERTISES_SUCCESS:
            return {
                ...state,
                expertise: action.expertises
            };
        case actionTypes.GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.locations
            };
        case actionTypes.GET_SECTORS_SUCCESS:
            return {
                ...state,
                sectorInterests: action.sectors
            };
        case actionTypes.GET_FINANCE_STAGES_SUCCESS:
            return {
                ...state,
                financeStages: action.stages
            };
        case actionTypes.GET_FINANCE_TYPES_SUCCESS:
            return {
                ...state,
                financeTypes: action.types
            };
        case actionTypes.GET_LEVEL_OF_INVESTMENT_SUCCESS:
            return {
                ...state,
                levelOfInvestment: action.levels
            };
        case actionTypes.GET_BUSINESS_TYPES_SUCCESS:
            return {
                ...state,
                businessTypes: action.businessTypes
            };
        case actionTypes.GET_INDUSTRY_NEWS_SUCCESS:
            return {
                ...state,
                news: action.news
            };
        default:
            return state;
    }
};

import React, { component } from "react";

import "./PlainCard.scss";

class PlainCard extends React.Component {
    click() {
        const { onClick } = this.props;

        if (onClick) onClick();
    }
    render() {
        const { header, className, children } = this.props;

        return (
            <div
                className={`card ${className ? className : ""}`}
                onClick={this.click.bind(this)}
            >
                {header && <h4 className="card-header">{header}</h4>}
                <div className="card-content">{children}</div>
            </div>
        );
    }
}

export default PlainCard;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import "./OpportunityDetails.scss";

import {
    getOpportunityDetailsAction,
    setInterestStatusAction,
    getMatchesForOpportunityAction,
    shareOpportunityAction,
    toggleOpportunityActiveAction,
    setInboxViewAction,
    postOpportunityUpdateAction
} from "../../actions/opportunities/opportunityActions";
import {
    toggleMessageCenterAction,
    setMessageCenterViewAction,
    setCurrentMessageParticipantsAction,
    setCurrentMessageOpportunityAction,
    openMessageCenterAction,
    getCurrentChatAction,
    setMessageTutorialAction,
    getOpportunityDetailsForChatAction,
    leaveRoomAction
} from "../../actions/messaging/messagingActions";
import { setFocusedUserAction } from "../../actions/network/networkActions";
import { inviteToFiinectAction } from "../../actions/auth/authActions";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import OpportunityFilters from "../../components/opportunity-filters/OpportunityFilters";
import ProfileIcon from "../../components/profile-icon/ProfileIcon";
import { arraysEqual, degreeOrdinal, timeAgo, matomoTrack } from "../../util";
import { months } from "../../data/data";
import Button from "../../components/button/Button";
import LatestMessages from "../../components/latest-messages/LatestMessages";
import ShareBox from "../../components/share-box/ShareBox";
import PlainCard from "../../components/plain-card/PlainCard";
import { regionIcons, regionNames } from "../../data/data";
import Modal from "../../components/modal/Modal";
import Loader from "../../components/loader/Loader";
import CommentBox from "../../components/comment-box/CommentBox";
import InfoBubble from "../../components/info-bubble/InfoBubble";

class OpportunityDetails extends Component {
    state = {
        announcementView: "updates",
        isInvite: false,
        detailsLoaded: false,
        statusModal: false,
        mobileShareBoxModal: false,
        mobileChatBoxModal: false
    };

    folders = {
        Interested: "interested",
        Following: "following",
        "Not interested": "not-interested"
    };

    componentDidMount() {
        const { getOpportunityDetailsAction } = this.props;
        const id = this.props.opportunityId || this.props.match.params.id;

        if (this.props.opportunityId) {
            this.setState({
                isInvite: true
            });
        }

        const now = new Date();
        now.setHours(now.getHours() - 1);
        localStorage.setItem(`lastread_${id}`, now.toUTCString());

        getOpportunityDetailsAction(id);
    }

    componentDidUpdate() {
        const {
            auth,
            opportunityDetails,
            getMatchesForOpportunityAction,
            getOpportunityDetailsAction,
            inboxView,
            setInboxViewAction
        } = this.props;
        const { detailsLoaded } = this.state;
        const id = this.props.opportunityId || this.props.match.params.id;

        if (opportunityDetails.opportunityId !== id) {
            getOpportunityDetailsAction(id);
        }

        if (opportunityDetails.status && opportunityDetails.status.status) {
            let folder = this.folders[opportunityDetails.status.status];
            if (
                opportunityDetails.broker &&
                opportunityDetails.broker.id === auth.user.id
            )
                folder = "mine";
            if (folder !== inboxView) {
                setInboxViewAction(folder);
            }
        }

        if (opportunityDetails.opportunityId && !detailsLoaded) {
            this.setState({
                detailsLoaded: true
            });

            const opportunityMatchObject = {
                sectors: [{ id: opportunityDetails.sectorId }],
                levelOfInvestment: opportunityDetails.levelOfInvestmentId,
                locations: []
            };

            if (opportunityDetails.stage) {
                opportunityMatchObject.financeStages = [
                    { id: opportunityDetails.stage.id }
                ];
            }

            getMatchesForOpportunityAction(opportunityMatchObject);

            let folder = "opportunities";
            if (opportunityDetails.status && opportunityDetails.status.status) {
                folder = this.folders[opportunityDetails.status.status];
            }
            if (
                opportunityDetails.broker &&
                opportunityDetails.broker.id === auth.user.id
            )
                folder = "mine";
            setInboxViewAction(folder);
        }
    }

    changeannouncementView(announcementView) {
        this.setState({
            announcementView
        });
    }

    openDealChatWithBroker() {
        const {
            messaging,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            opportunityDetails
        } = this.props;

        toggleMessageCenterAction();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction([opportunityDetails.broker]);
        setCurrentMessageOpportunityAction(opportunityDetails.opportunityId);

        const chat = messaging.messages.find((room) => {
            const roomPeople = room.people.map((person) => person.id);
            return (
                arraysEqual(roomPeople, [opportunityDetails.broker.id]) &&
                ((!opportunityDetails.opportunityId && !room.opportunity) ||
                    (opportunityDetails.opportunityId &&
                        room.opportunity &&
                        room.opportunity.opportunityId ===
                            opportunityDetails.opportunityId))
            );
        });

        getCurrentChatAction(
            chat,
            [opportunityDetails.broker],
            opportunityDetails.opportunityId
        );
    }

    openDealChatWithInviter() {
        const {
            auth,
            messaging,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            opportunityDetails
        } = this.props;

        const inviter = auth.invitingUser;

        toggleMessageCenterAction();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction([inviter]);
        setCurrentMessageOpportunityAction(opportunityDetails.opportunityId);

        const chat = messaging.messages.find((room) => {
            const roomPeople = room.people.map((person) => person.id);
            return (
                arraysEqual(roomPeople, [inviter.id]) &&
                ((!opportunityDetails.opportunityId && !room.opportunity) ||
                    (opportunityDetails.opportunityId &&
                        room.opportunity &&
                        room.opportunity.opportunityId ===
                            opportunityDetails.opportunityId))
            );
        });

        getCurrentChatAction(chat, [inviter], opportunityDetails.opportunityId);
    }

    changeInterestStatus(status) {
        const {
            setInterestStatusAction,
            opportunityDetails,
            setInboxViewAction
        } = this.props;

        setInboxViewAction(this.folders[status]);

        setInterestStatusAction(
            opportunityDetails.opportunityId,
            status,
            opportunityDetails.status ? opportunityDetails.status.id : null
        );
    }

    inviteSetInterestStatus(status) {
        const { setMessageTutorialAction } = this.props;

        this.changeInterestStatus(status);
        this.openDealChatWithInviter();
        setMessageTutorialAction(true);
    }

    toggleStatusModal() {
        const { statusModal } = this.state;
        this.setState({
            statusModal: !statusModal
        });
    }

    openMessageCenter() {
        const { openMessageCenterAction } = this.props;
        const { mobileChatBoxModal } = this.state;

        this.setState({
            mobileChatBoxModal: !mobileChatBoxModal
        });

        openMessageCenterAction();
    }

    toggleMobileChatBoxModal() {
        const { mobileChatBoxModal } = this.state;
        this.setState({
            mobileChatBoxModal: !mobileChatBoxModal
        });
    }

    toggleMobileShareBoxModal() {
        const { mobileShareBoxModal } = this.state;
        this.setState({
            mobileShareBoxModal: !mobileShareBoxModal
        });
    }

    togglePageActive() {
        const {
            toggleOpportunityActiveAction,
            opportunityDetails
        } = this.props;

        this.setState({
            statusModal: false
        });
        toggleOpportunityActiveAction(opportunityDetails.opportunityId);
    }

    postUpdate(content) {
        const { opportunityDetails, postOpportunityUpdateAction } = this.props;

        postOpportunityUpdateAction({
            content,
            opportunityId: opportunityDetails.opportunityId,
            parentId: null
        });
    }

    postQuestion(content) {
        const { opportunityDetails, postOpportunityUpdateAction } = this.props;

        postOpportunityUpdateAction({
            content,
            opportunityId: opportunityDetails.opportunityId,
            parentId: 0
        });
    }

    postAnswer(questionId, content) {
        const { opportunityDetails, postOpportunityUpdateAction } = this.props;

        postOpportunityUpdateAction({
            content,
            opportunityId: opportunityDetails.opportunityId,
            parentId: questionId
        });
    }

    toComplete() {
        const { opportunityDetails } = this.props;

        const errors = [];

        if (!opportunityDetails.sector) errors.push("Sector");
        if (!opportunityDetails.country) errors.push("Location");
        if (!opportunityDetails.financeType) errors.push("Type of finance");
        if (!opportunityDetails.levelOfInvestmentId)
            errors.push("Finance required");
        //if (!opportunityDetails.stage) errors.push("Stage");
        if (!opportunityDetails.headline) errors.push("Headline");
        if (!opportunityDetails.opportunityDetails) errors.push("Details");
        if (!opportunityDetails.description) errors.push("Description");
        if (!opportunityDetails.summary) errors.push("Summary");
        if (!opportunityDetails.executiveBrochure)
            errors.push("Executive brochure");
        if (!opportunityDetails.investorDeck) errors.push("Investor deck");

        return errors;
    }

    render() {
        const id = this.props.opportunityId || this.props.match.params.id;
        const {
            auth,
            inboxView,
            messaging,
            connections,
            opportunityDetails,
            matches,
            saving,
            sharing,
            openMessageCenterAction,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            setFocusedUserAction,
            inviteToFiinectAction,
            shareOpportunityAction,
            getOpportunityDetailsForChatAction,
            leaveRoomAction,
            postOpportunityUpdateAction
        } = this.props;
        const d = new Date(opportunityDetails.datePosted);
        const {
            detailsLoaded,
            announcementView,
            statusModal,
            mobileShareBoxModal,
            mobileChatBoxModal
        } = this.state;
        let hour = d.getHours();
        let minute = d.getMinutes();
        if (hour < 10) hour = `0${hour}`;
        if (minute < 10) minute = `0${minute}`;
        const dateString = `${d.getDate()} ${
            months[d.getMonth()]
        } ${d.getFullYear()} ${hour}:${minute}`;
        const comingSoon = d.getFullYear() === 3000;

        return (
            <div className="opportunity-details-container">
                {(saving || !detailsLoaded) && <Loader />}
                <HighlightedContent
                    headline={
                        <>
                            <span>Opportunities</span>
                            <InfoBubble
                                content={
                                    <>
                                        <p>
                                            Your NEW folder shows business
                                            opportunities, specifically matched
                                            to your profile. You need to assign
                                            a 'status' to an opportunity by
                                            selecting one of the buttons on each
                                            opportunity page. This does two
                                            things:​
                                        </p>
                                        ​
                                        <p>
                                            It tells us (only us!) what updates
                                            to show you on which opportunities.
                                            (NB: this is not shared with the
                                            actual business so no-one will
                                            contact you!)​
                                        </p>
                                        ​
                                        <p>
                                            What updates do you receive? They
                                            are:​
                                        </p>
                                        ​
                                        <p>
                                            NEW folder : by leaving an
                                            opportunity in NEW, you will be
                                            shown 'no' updates.​
                                        </p>
                                        <p>
                                            Interested : you will be shown all
                                            updates ​
                                        </p>
                                        <p>
                                            Just Watching : shown just the major
                                            updates​
                                        </p>
                                        <p>
                                            Not Interested. : shown no updates &
                                            the opportunity is removed from your
                                            NEW folder​
                                        </p>
                                        <p>
                                            The 'Status' you assign to an
                                            opportunity also sends a private
                                            signal, to people in your network &
                                            the originator of that opportunity.
                                            This quickens the whole process by
                                            enabling conversations sooner and
                                            prevents wasted communication.​
                                        </p>
                                    </>
                                }
                            />
                        </>
                    }
                >
                    <OpportunityFilters
                        inboxView={inboxView}
                        opportunityId={id}
                    />
                </HighlightedContent>
                <MainContent>
                    <div className="content">
                        <div className="opportunity-details-basics">
                            <div className="user-details">
                                {opportunityDetails.broker && (
                                    <>
                                        <ProfileIcon
                                            person={opportunityDetails.broker}
                                            large
                                        />
                                        {opportunityDetails.broker &&
                                            opportunityDetails.broker.name !==
                                                "Fiinect Update" && (
                                                <>
                                                    <p>
                                                        From:{" "}
                                                        <strong>
                                                            {
                                                                opportunityDetails
                                                                    .broker.name
                                                            }
                                                        </strong>
                                                    </p>
                                                    <span className="degree">
                                                        {/* {degreeOrdinal(
                                                        opportunityDetails.broker.degree
                                                    )} */}
                                                    </span>
                                                    {opportunityDetails.broker
                                                        .id !==
                                                        auth.user.id && (
                                                        <div className="message-broker">
                                                            <span>
                                                                Message{" "}
                                                                {
                                                                    opportunityDetails
                                                                        .broker
                                                                        .name
                                                                }
                                                            </span>
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/message-icon.svg`}
                                                                onClick={this.openDealChatWithBroker.bind(
                                                                    this
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                    <span>|</span>
                                                </>
                                            )}
                                        <time
                                            dateTime={
                                                opportunityDetails.datePosted
                                            }
                                        >
                                            {/*comingSoon
                                                ? "Pre-Funding"
                                            : dateString*/}
                                            {dateString}
                                        </time>
                                        <div className="location">
                                            <img
                                                src={
                                                    regionIcons[
                                                        opportunityDetails
                                                            .country
                                                    ]
                                                }
                                            />
                                            <span>
                                                {
                                                    regionNames[
                                                        opportunityDetails
                                                            .country
                                                    ]
                                                }
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <main>
                            <section>
                                {/*comingSoon && (
                                    <h4 className="coming-soon">Pre-Funding</h4>
                                )*/}

                                <h4 className="sector">
                                    {opportunityDetails.sector &&
                                        opportunityDetails.sector.name}
                                </h4>
                                <h1>{opportunityDetails.headline}</h1>

                                <div className="key-details">
                                    <div className="detail">
                                        <label>Finance required</label>
                                        <span>
                                            {opportunityDetails.ticketSize}
                                        </span>
                                    </div>
                                    <div className="detail">
                                        <label>Type of finance</label>
                                        <span>
                                            {opportunityDetails.financeType &&
                                                opportunityDetails.financeType
                                                    .name}
                                        </span>
                                    </div>
                                    {opportunityDetails.broker &&
                                        opportunityDetails.broker.name !==
                                            "Fiinect Update" && (
                                            <>
                                                {/* <div className="detail">
                                                    <label>Stage</label>
                                                    <span>
                                                        {opportunityDetails.stage &&
                                                            opportunityDetails
                                                                .stage.name}
                                                    </span>
                                                </div> */}
                                            </>
                                        )}
                                </div>

                                {opportunityDetails.broker &&
                                    opportunityDetails.broker.id !==
                                        auth.user.id && (
                                        <div className="actions">
                                            <p>
                                                Add this opportunity to my
                                                folders:
                                            </p>
                                            <div
                                                onClick={this.changeInterestStatus.bind(
                                                    this,
                                                    "Interested"
                                                )}
                                                className={
                                                    opportunityDetails.status &&
                                                    opportunityDetails.status
                                                        .status === "Interested"
                                                        ? "action active"
                                                        : "action"
                                                }
                                            >
                                                <span>
                                                    {opportunityDetails.status &&
                                                    opportunityDetails.status
                                                        .status === "Interested"
                                                        ? "This is in your interested folder"
                                                        : 'Add to your "Interested" folder and receive updates on this opportunity'}
                                                </span>
                                                <img
                                                    src={
                                                        opportunityDetails.status &&
                                                        opportunityDetails
                                                            .status.status ===
                                                            "Interested"
                                                            ? `${process.env.PUBLIC_URL}/assets/interested.svg`
                                                            : `${process.env.PUBLIC_URL}/assets/interested_dark.svg`
                                                    }
                                                    onClick={() =>
                                                        matomoTrack(
                                                            "mark-opportunity-as-interested",
                                                            document.querySelector(
                                                                "div.content h1"
                                                            ).innerText
                                                        )
                                                    }
                                                />
                                                <em>Interested</em>
                                            </div>
                                            <div
                                                onClick={this.changeInterestStatus.bind(
                                                    this,
                                                    "Following"
                                                )}
                                                className={
                                                    opportunityDetails.status &&
                                                    opportunityDetails.status
                                                        .status === "Following"
                                                        ? "action active"
                                                        : "action"
                                                }
                                            >
                                                <span>
                                                    {opportunityDetails.status &&
                                                    opportunityDetails.status
                                                        .status === "Following"
                                                        ? "This is in your just watching folder"
                                                        : 'Add to your "Just Watching" folder and receive main updates on this opportunity'}
                                                </span>
                                                <img
                                                    src={
                                                        opportunityDetails.status &&
                                                        opportunityDetails
                                                            .status.status ===
                                                            "Following"
                                                            ? `${process.env.PUBLIC_URL}/assets/following.svg`
                                                            : `${process.env.PUBLIC_URL}/assets/following_dark.svg`
                                                    }
                                                    onClick={() =>
                                                        matomoTrack(
                                                            "mark-opportunity-as-following",
                                                            document.querySelector(
                                                                "div.content h1"
                                                            ).innerText
                                                        )
                                                    }
                                                />
                                                <em>Just watching</em>
                                            </div>
                                            <div
                                                onClick={this.changeInterestStatus.bind(
                                                    this,
                                                    "Not interested"
                                                )}
                                                className={
                                                    opportunityDetails.status &&
                                                    opportunityDetails.status
                                                        .status ===
                                                        "Not interested"
                                                        ? "action active"
                                                        : "action"
                                                }
                                            >
                                                <span>
                                                    {opportunityDetails.status &&
                                                    opportunityDetails.status
                                                        .status ===
                                                        "Not interested"
                                                        ? "This is in your not interested folder"
                                                        : 'Send to your "Not Interested" folder for archiving'}
                                                </span>
                                                <img
                                                    src={
                                                        opportunityDetails.status &&
                                                        opportunityDetails
                                                            .status.status ===
                                                            "Not interested"
                                                            ? `${process.env.PUBLIC_URL}/assets/not_interested.svg`
                                                            : `${process.env.PUBLIC_URL}/assets/not_interested_dark.svg`
                                                    }
                                                    onClick={() =>
                                                        matomoTrack(
                                                            "mark-opportunity-as-not-interested",
                                                            document.querySelector(
                                                                "div.content h1"
                                                            ).innerText
                                                        )
                                                    }
                                                />
                                                <em>Not interested</em>
                                            </div>
                                        </div>
                                    )}

                                <div className="mobile-share">
                                    <div className="mobile-share-actions">
                                        {opportunityDetails.interestedPeople &&
                                            opportunityDetails.otherPeople &&
                                            matches && (
                                                <div className="mobile-share-action">
                                                    <span>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/default-user.svg`}
                                                            alt="user count"
                                                        />
                                                        {opportunityDetails
                                                            .interestedPeople
                                                            .length +
                                                            opportunityDetails
                                                                .otherPeople
                                                                .length +
                                                            matches.length}
                                                    </span>
                                                    people match this
                                                    opportunity
                                                    <Button
                                                        onClick={this.toggleMobileShareBoxModal.bind(
                                                            this
                                                        )}
                                                    >
                                                        View all matches
                                                    </Button>
                                                </div>
                                            )}
                                        {messaging.messages && (
                                            <div className="mobile-share-action">
                                                <span>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/default-user.svg`}
                                                        alt="user count"
                                                    />
                                                    {
                                                        messaging.messages.filter(
                                                            (chat) =>
                                                                chat.opportunity &&
                                                                `${chat.opportunity.opportunityId}` ===
                                                                    `${opportunityDetails.opportunityId}`
                                                        ).length
                                                    }
                                                </span>
                                                related messages
                                                <Button
                                                    onClick={this.toggleMobileChatBoxModal.bind(
                                                        this
                                                    )}
                                                >
                                                    View messages
                                                </Button>
                                            </div>
                                        )}
                                    </div>

                                    <Modal
                                        fullScreen
                                        visible={mobileShareBoxModal}
                                    >
                                        <span
                                            onClick={this.toggleMobileShareBoxModal.bind(
                                                this
                                            )}
                                            className="close-modal"
                                        >
                                            X
                                        </span>
                                        <ShareBox
                                            user={auth.user}
                                            messaging={messaging}
                                            opportunityId={
                                                opportunityDetails.opportunityId
                                            }
                                            opportunity={opportunityDetails}
                                            matches={matches}
                                            toggleMessageCenterAction={
                                                toggleMessageCenterAction
                                            }
                                            setMessageCenterViewAction={
                                                setMessageCenterViewAction
                                            }
                                            getCurrentChatAction={
                                                getCurrentChatAction
                                            }
                                            setCurrentMessageParticipantsAction={
                                                setCurrentMessageParticipantsAction
                                            }
                                            setCurrentMessageOpportunityAction={
                                                setCurrentMessageOpportunityAction
                                            }
                                            setFocusedUserAction={
                                                setFocusedUserAction
                                            }
                                            inviteToFiinectAction={
                                                inviteToFiinectAction
                                            }
                                            shareAction={shareOpportunityAction}
                                            connections={connections}
                                            sharing={sharing}
                                            preview={
                                                !opportunityDetails.isActive
                                            }
                                            broker={opportunityDetails.broker}
                                        />
                                    </Modal>
                                    <Modal
                                        fullScreen
                                        visible={mobileChatBoxModal}
                                    >
                                        <span
                                            onClick={this.toggleMobileChatBoxModal.bind(
                                                this
                                            )}
                                            className="close-modal dark"
                                        >
                                            X
                                        </span>
                                        <LatestMessages
                                            opportunityFilter={
                                                opportunityDetails.opportunityId
                                            }
                                            latestMessages={messaging.messages}
                                            openMessageCenterAction={this.openMessageCenter.bind(
                                                this
                                            )}
                                            setMessageCenterViewAction={
                                                setMessageCenterViewAction
                                            }
                                            getCurrentChatAction={
                                                getCurrentChatAction
                                            }
                                            setCurrentMessageParticipantsAction={
                                                setCurrentMessageParticipantsAction
                                            }
                                            setCurrentMessageOpportunityAction={
                                                setCurrentMessageOpportunityAction
                                            }
                                            getOpportunityDetailsForChatAction={
                                                getOpportunityDetailsForChatAction
                                            }
                                            leaveRoomAction={leaveRoomAction}
                                        />
                                    </Modal>
                                </div>

                                <div className="opportunity-in-depth-details">
                                    {(opportunityDetails.webLink ||
                                        opportunityDetails.executiveBrochure ||
                                        opportunityDetails.investorDeck ||
                                        (opportunityDetails.documents &&
                                            opportunityDetails.documents
                                                .length > 0)) && (
                                        <aside className="downloads">
                                            <h6>Downloads &amp; links</h6>
                                            <div className="content">
                                                {opportunityDetails.executiveBrochure && (
                                                    <a
                                                        href={`${process.env.PUBLIC_URL}${opportunityDetails.executiveBrochure.url}`}
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/document.svg`}
                                                            alt="Executive brochure"
                                                        />
                                                        Executive brochure
                                                    </a>
                                                )}
                                                {opportunityDetails.investorDeck && (
                                                    <a
                                                        href={`${process.env.PUBLIC_URL}${opportunityDetails.investorDeck.url}`}
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/document.svg`}
                                                            alt="Investor deck"
                                                        />
                                                        Investor deck
                                                    </a>
                                                )}
                                                {opportunityDetails.documents &&
                                                    opportunityDetails.documents.map(
                                                        (doc) => (
                                                            <a
                                                                href={`${process.env.PUBLIC_URL}${doc.url}`}
                                                                target="_blank"
                                                            >
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/document.svg`}
                                                                    alt={
                                                                        doc.name
                                                                    }
                                                                />
                                                                {doc.name}
                                                            </a>
                                                        )
                                                    )}

                                                {opportunityDetails.webLink && (
                                                    <div
                                                        className="web-link"
                                                        onClick={() =>
                                                            matomoTrack(
                                                                "track-outbound-deal-click",
                                                                opportunityDetails.webLink
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/web.svg`}
                                                            alt="Web link"
                                                        />
                                                        <Button
                                                            href={
                                                                opportunityDetails.webLink
                                                            }
                                                            isExternal
                                                        >
                                                            Go to site
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </aside>
                                    )}
                                    <section>
                                        {opportunityDetails.youtubeId && (
                                            <iframe
                                                src={`https://www.youtube.com/embed/${opportunityDetails.youtubeId}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        )}
                                        {opportunityDetails.summary && (
                                            <>
                                                <h3>Summary</h3>
                                                <p>
                                                    {opportunityDetails.summary}
                                                </p>
                                            </>
                                        )}
                                        {opportunityDetails.opportunityDetails && (
                                            <>
                                                <h3>The opportunity</h3>
                                                <p>
                                                    {
                                                        opportunityDetails.opportunityDetails
                                                    }
                                                </p>
                                            </>
                                        )}
                                    </section>
                                </div>

                                <div className="announcements">
                                    <div className="announcement-toggler">
                                        <div
                                            className={
                                                announcementView === "updates"
                                                    ? "selected"
                                                    : ""
                                            }
                                            onClick={this.changeannouncementView.bind(
                                                this,
                                                "updates"
                                            )}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/announcement.svg`}
                                            />
                                            Updates (
                                            {opportunityDetails.updates
                                                ? opportunityDetails.updates
                                                      .length
                                                : 0}
                                            )
                                        </div>
                                        {opportunityDetails.broker &&
                                            opportunityDetails.broker.name !==
                                                "Fiinect Update" && (
                                                <div
                                                    className={
                                                        announcementView ===
                                                        "qa"
                                                            ? "selected"
                                                            : ""
                                                    }
                                                    onClick={this.changeannouncementView.bind(
                                                        this,
                                                        "qa"
                                                    )}
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/announcement.svg`}
                                                    />
                                                    Public Q&amp;A (
                                                    {opportunityDetails.qa
                                                        ? opportunityDetails.qa
                                                              .length
                                                        : 0}
                                                    )
                                                </div>
                                            )}
                                    </div>

                                    <div
                                        className={
                                            announcementView === "updates"
                                                ? "announcement-list visible"
                                                : "announcement-list"
                                        }
                                    >
                                        {opportunityDetails.broker &&
                                            opportunityDetails.broker.id ===
                                                auth.user.id && (
                                                <div className="post-update">
                                                    <CommentBox
                                                        placeholder="Share an update on your opportunity"
                                                        postCommentAction={this.postUpdate.bind(
                                                            this
                                                        )}
                                                        user={auth.user}
                                                    />
                                                </div>
                                            )}
                                        {opportunityDetails.updates &&
                                            opportunityDetails.updates.map(
                                                (update) => (
                                                    <div className="announcement">
                                                        <ProfileIcon
                                                            person={
                                                                update.users
                                                            }
                                                            large
                                                        />
                                                        <div className="announcement-content">
                                                            <time>
                                                                {timeAgo(
                                                                    update.created
                                                                )}
                                                            </time>
                                                            <h3>
                                                                {update.users &&
                                                                    update.users
                                                                        .name}
                                                            </h3>
                                                            <p>{update.text}</p>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                    {opportunityDetails.broker &&
                                        opportunityDetails.broker.name !==
                                            "Fiinect Update" && (
                                            <div
                                                className={
                                                    announcementView === "qa"
                                                        ? "announcement-list visible"
                                                        : "announcement-list"
                                                }
                                            >
                                                {opportunityDetails.broker &&
                                                    opportunityDetails.broker
                                                        .id !==
                                                        auth.user.id && (
                                                        <div className="post-update">
                                                            <CommentBox
                                                                placeholder="Ask a question about this opportunity"
                                                                postCommentAction={this.postQuestion.bind(
                                                                    this
                                                                )}
                                                                user={auth.user}
                                                            />
                                                        </div>
                                                    )}
                                                {opportunityDetails.qa &&
                                                    opportunityDetails.qa
                                                        .filter(
                                                            (update) =>
                                                                update.parentUpdateId ===
                                                                0
                                                        )
                                                        .map((update) => (
                                                            <>
                                                                <div className="announcement question">
                                                                    <ProfileIcon
                                                                        person={
                                                                            update.users
                                                                        }
                                                                        large
                                                                    />
                                                                    <div className="announcement-content">
                                                                        <time>
                                                                            {timeAgo(
                                                                                update.created
                                                                            )}
                                                                        </time>
                                                                        <h3>
                                                                            {update.users &&
                                                                                update
                                                                                    .users
                                                                                    .name}
                                                                        </h3>
                                                                        <p>
                                                                            {
                                                                                update.text
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                {opportunityDetails.broker &&
                                                                    !opportunityDetails.qa.find(
                                                                        (
                                                                            child
                                                                        ) =>
                                                                            child.parentUpdateId ===
                                                                            update.id
                                                                    ) &&
                                                                    opportunityDetails
                                                                        .broker
                                                                        .id ===
                                                                        auth
                                                                            .user
                                                                            .id && (
                                                                        <div className="post-update">
                                                                            <CommentBox
                                                                                placeholder="Answer this question"
                                                                                postCommentAction={this.postAnswer.bind(
                                                                                    this,
                                                                                    update.id
                                                                                )}
                                                                                user={
                                                                                    auth.user
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                {opportunityDetails.qa.find(
                                                                    (child) =>
                                                                        child.parentUpdateId ===
                                                                        update.id
                                                                ) &&
                                                                    [
                                                                        opportunityDetails.qa.find(
                                                                            (
                                                                                child
                                                                            ) =>
                                                                                child.parentUpdateId ===
                                                                                update.id
                                                                        )
                                                                    ].map(
                                                                        (
                                                                            child
                                                                        ) => (
                                                                            <div className="announcement answer">
                                                                                <div className="announcement question">
                                                                                    <ProfileIcon
                                                                                        person={
                                                                                            child.users
                                                                                        }
                                                                                        large
                                                                                    />
                                                                                    <div className="announcement-content">
                                                                                        <time>
                                                                                            {timeAgo(
                                                                                                child.created
                                                                                            )}
                                                                                        </time>
                                                                                        <h3>
                                                                                            {child.users &&
                                                                                                child
                                                                                                    .users
                                                                                                    .name}
                                                                                        </h3>
                                                                                        <p>
                                                                                            {
                                                                                                child.text
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                            </>
                                                        ))}
                                            </div>
                                        )}
                                </div>
                            </section>

                            <Modal fullScreen visible={statusModal}>
                                <div className="go-live-modal">
                                    <p>
                                        <strong>
                                            Are you sure you want to{" "}
                                            {opportunityDetails.isActive
                                                ? "CLOSE THIS OPPORTUNITY?"
                                                : auth.user.role ===
                                                  "Superadmin"
                                                ? "OPEN THIS OPPORTUNITY?"
                                                : "SHARE THIS OPPORTUNITY with Fiinect?"}
                                        </strong>
                                    </p>
                                    <Button
                                        onClick={this.toggleStatusModal.bind(
                                            this
                                        )}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        onClick={this.togglePageActive.bind(
                                            this
                                        )}
                                        isPrimary
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </Modal>

                            <aside>
                                {opportunityDetails.broker &&
                                    opportunityDetails.broker.id ===
                                        auth.user.id &&
                                    !opportunityDetails.isActive && (
                                        <>
                                            {this.toComplete().length > 0 && (
                                                <div className="incomplete">
                                                    <h3>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                                        />
                                                        Incomplete
                                                    </h3>
                                                    <strong>
                                                        Please complete
                                                    </strong>
                                                    <ul>
                                                        {this.toComplete().map(
                                                            (item) => (
                                                                <li>{item}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </>
                                    )}

                                <div className="opportunity-admin-actions">
                                    {opportunityDetails.broker &&
                                        (opportunityDetails.broker.id ===
                                            auth.user.id ||
                                            auth.user.role ===
                                                "Superadmin") && (
                                            <>
                                                <Button
                                                    href={`${process.env.PUBLIC_URL}/opportunities/edit/${opportunityDetails.opportunityId}`}
                                                >
                                                    Edit
                                                </Button>

                                                {opportunityDetails.isActive &&
                                                    (auth.user.role ===
                                                        "Superadmin" ||
                                                        this.toComplete()
                                                            .length === 0) && (
                                                        <Button
                                                            onClick={this.toggleStatusModal.bind(
                                                                this
                                                            )}
                                                        >
                                                            CLOSE
                                                        </Button>
                                                    )}
                                                {!opportunityDetails.isActive &&
                                                    auth.user.role ===
                                                        "Superadmin" && (
                                                        <Button
                                                            onClick={this.toggleStatusModal.bind(
                                                                this
                                                            )}
                                                        >
                                                            OPEN
                                                        </Button>
                                                    )}
                                            </>
                                        )}

                                    {/*opportunityDetails.broker &&
                                        opportunityDetails.broker.id ===
                                            auth.user.id &&
                                        this.toComplete().length === 0 && (
                                            <div className="complete">
                                                <p>
                                                    You can either share with
                                                    everyone on Fiinect or just
                                                    with your selected matches
                                                    from your network using the
                                                    orange box below.
                                                </p>

                                                {!opportunityDetails.isActive && (
                                                    <Button
                                                        onClick={this.toggleStatusModal.bind(
                                                            this
                                                        )}
                                                    >
                                                        Share opportunity on
                                                        Fiinect
                                                    </Button>
                                                )}

                                                {opportunityDetails.isActive && (
                                                    <Button disabled>
                                                        Opportunity shared with
                                                        Fiinect
                                                    </Button>
                                                )}
                                            </div>
                                                )*/}
                                </div>

                                {opportunityDetails.broker &&
                                    auth.user &&
                                    !auth.user.profileComplete &&
                                    this.state.isInvite && (
                                        <PlainCard className="invite-like">
                                            <h4>
                                                Tell{" "}
                                                {auth.invitingUser &&
                                                    auth.invitingUser.name}{" "}
                                                what you think of this
                                                opportunity
                                            </h4>
                                            <div className="invite-actions">
                                                <div
                                                    onClick={this.inviteSetInterestStatus.bind(
                                                        this,
                                                        "Interested"
                                                    )}
                                                    className={
                                                        opportunityDetails.status &&
                                                        opportunityDetails
                                                            .status.status ===
                                                            "Interested"
                                                            ? "invite-action active"
                                                            : "invite-action"
                                                    }
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/interested.svg`}
                                                    />
                                                    <span>Like it</span>
                                                </div>
                                                <div
                                                    onClick={this.inviteSetInterestStatus.bind(
                                                        this,
                                                        "Not interested"
                                                    )}
                                                    className={
                                                        opportunityDetails.status &&
                                                        opportunityDetails
                                                            .status.status ===
                                                            "Not interested"
                                                            ? "invite-action active"
                                                            : "invite-action"
                                                    }
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/not_interested.svg`}
                                                    />
                                                    <span>Don't like it</span>
                                                </div>
                                            </div>
                                        </PlainCard>
                                    )}

                                <ShareBox
                                    user={auth.user}
                                    messaging={messaging}
                                    opportunityId={
                                        opportunityDetails.opportunityId
                                    }
                                    opportunity={opportunityDetails}
                                    matches={matches}
                                    toggleMessageCenterAction={
                                        toggleMessageCenterAction
                                    }
                                    setMessageCenterViewAction={
                                        setMessageCenterViewAction
                                    }
                                    getCurrentChatAction={getCurrentChatAction}
                                    setCurrentMessageParticipantsAction={
                                        setCurrentMessageParticipantsAction
                                    }
                                    setCurrentMessageOpportunityAction={
                                        setCurrentMessageOpportunityAction
                                    }
                                    setFocusedUserAction={setFocusedUserAction}
                                    inviteToFiinectAction={
                                        inviteToFiinectAction
                                    }
                                    shareAction={shareOpportunityAction}
                                    connections={connections}
                                    sharing={sharing}
                                    preview={!opportunityDetails.isActive}
                                    broker={opportunityDetails.broker}
                                />
                                <LatestMessages
                                    opportunityFilter={
                                        opportunityDetails.opportunityId
                                    }
                                    latestMessages={messaging.messages}
                                    openMessageCenterAction={
                                        openMessageCenterAction
                                    }
                                    setMessageCenterViewAction={
                                        setMessageCenterViewAction
                                    }
                                    getCurrentChatAction={getCurrentChatAction}
                                    setCurrentMessageParticipantsAction={
                                        setCurrentMessageParticipantsAction
                                    }
                                    setCurrentMessageOpportunityAction={
                                        setCurrentMessageOpportunityAction
                                    }
                                    getOpportunityDetailsForChatAction={
                                        getOpportunityDetailsForChatAction
                                    }
                                    leaveRoomAction={leaveRoomAction}
                                />
                            </aside>
                        </main>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        connections: state.network.connections,
        messaging: state.messaging,
        inboxView: state.opportunities.inboxView,
        opportunityDetails: state.opportunities.opportunityDetails,
        matches: state.opportunities.opportunityMatches,
        saving: state.opportunities.savingChanges,
        sharing: state.opportunities.sharing
    }),
    {
        getOpportunityDetailsAction,
        toggleMessageCenterAction,
        setMessageCenterViewAction,
        setCurrentMessageParticipantsAction,
        setCurrentMessageOpportunityAction,
        openMessageCenterAction,
        getCurrentChatAction,
        setFocusedUserAction,
        setInterestStatusAction,
        setMessageTutorialAction,
        inviteToFiinectAction,
        getMatchesForOpportunityAction,
        shareOpportunityAction,
        toggleOpportunityActiveAction,
        getOpportunityDetailsForChatAction,
        setInboxViewAction,
        leaveRoomAction,
        postOpportunityUpdateAction
    }
)(withRouter(OpportunityDetails));

import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

class Button extends React.Component {
    onClick() {
        this.props.onClick();
    }

    overrideLinkClick(e) {
        if (e.target !== e.currentTarget) {
            // Info bubble etc
            e.preventDefault();
        }
    }

    render() {
        const {
            isPrimary,
            isExternal,
            href,
            children,
            onClick,
            cssClass,
            icon,
            disabled,
            submit
        } = this.props;

        let className = "button";
        if (cssClass) className += ` ${cssClass}`;
        if (isPrimary) className += " primary";
        if (icon) className += " icon";
        if (disabled) className += " disabled";

        if (submit) {
            return (
                <input type="submit" className={className} value={children} />
            );
        }

        if (onClick) {
            return (
                <span className={className} onClick={this.onClick.bind(this)}>
                    {icon && (
                        <img
                            src={`${process.env.PUBLIC_URL}${icon}`}
                            className="icon"
                        />
                    )}
                    {children}
                </span>
            );
        }

        if (isExternal) {
            return (
                <a href={href} className={className} target="_blank">
                    {icon && (
                        <img
                            src={`${process.env.PUBLIC_URL}${icon}`}
                            className="icon"
                        />
                    )}
                    {children}
                </a>
            );
        }

        return (
            <Link
                to={`${process.env.PUBLIC_URL}${href}`}
                className={className}
                onClick={this.overrideLinkClick.bind(this)}
            >
                {icon && (
                    <img
                        src={`${process.env.PUBLIC_URL}${icon}`}
                        className="icon"
                    />
                )}
                {children}
            </Link>
        );
    }
}

export default Button;

import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./containers/home/Home";
import MatrixTest from "./containers/matrix-test/MatrixTest";
import UserGenerator from "./containers/generators/user-generator/UserGenerator";
import OpportunityGenerator from "./containers/generators/opportunity-generator/OpportunityGenerator";
import OpportunitiesInbox from "./containers/opportunities-inbox/OpportunitiesInbox";
import NotFound from "./containers/not-found/NotFound";
import OpportunityDetails from "./containers/opportunity-details/OpportunityDetails";
import MyNetwork from "./containers/my-network/MyNetwork";
import CreateOpportunity from "./containers/create-opportunity/CreateOpportunity";
import EditOpportunity from "./containers/edit-opportunity/EditOpportunity";
import Marketplace from "./containers/marketplace/Marketplace";
import MyPreferences from "./containers/my-preferences/MyPreferences";
import Invite from "./containers/invite/Invite";
import MyDetails from "./containers/my-details/MyDetails";
import CompleteProfile from "./containers/complete-profile/CompleteProfile";
import CreateBusiness from "./containers/create-business/CreateBusiness";
import MyBusiness from "./containers/my-business/MyBusiness";
import BusinessDetails from "./containers/business-details/BusinessDetails";
import SearchResults from "./containers/search-results/SearchResults";
import SendInvite from "./containers/send-invite/SendInvite";
import PublicPage from "./containers/public-page/PublicPage";
import ResetPassword from "./containers/reset-password/ResetPassword";
import AboutUs from "./containers/static-pages/AboutUs";
import FAQs from "./containers/static-pages/FAQs";
import Privacy from "./containers/static-pages/Privacy";
import Cookies from "./containers/static-pages/Cookies";
import UserAgreement from "./containers/static-pages/UserAgreement";
import Notifications from "./containers/notifications/Notifications";
import NetworkOverview from "./containers/network-overview/NetworkOverview";

export default function Routes() {
    return (
        <Switch>
            <Route
                path={`${process.env.PUBLIC_URL}/`}
                exact
                component={Home}
                key="home-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/notifications`}
                exact
                component={Notifications}
                key="home-route"
            />

            <Route
                path={`${process.env.PUBLIC_URL}/opportunity-generator`}
                exact
                component={OpportunityGenerator}
                key="opportunity-generator-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities`}
                exact
                component={OpportunitiesInbox}
                key="opporunities-inbox-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/interested`}
                exact
                component={OpportunitiesInbox}
                key="opporunities-interested-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/following`}
                exact
                component={OpportunitiesInbox}
                key="opporunities-following-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/not-interested`}
                exact
                component={OpportunitiesInbox}
                key="opporunities-not-interested-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/mine`}
                exact
                component={OpportunitiesInbox}
                key="opporunities-mine-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/create`}
                exact
                component={CreateOpportunity}
                key="opporunity-create-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/edit/:id`}
                exact
                component={EditOpportunity}
                key="opporunity-edit-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/opportunities/:id`}
                exact
                component={OpportunityDetails}
                key="opporunity-details-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network`}
                exact
                component={MyNetwork}
                key="my-network-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network/overview`}
                exact
                component={NetworkOverview}
                key="my-network-overview-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network/sectors`}
                exact
                component={MyNetwork}
                key="my-network-sectors-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network/expertise`}
                exact
                component={MyNetwork}
                key="my-network-expertise-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network/investment`}
                exact
                component={MyNetwork}
                key="my-network-investment-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network/influence`}
                exact
                component={MyNetwork}
                key="my-network-influence-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/network/requests`}
                exact
                component={MyNetwork}
                key="my-network-requests-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/marketplace`}
                exact
                component={Marketplace}
                key="marketplace-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/my-preferences`}
                exact
                component={MyPreferences}
                key="preferences-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/my-details`}
                exact
                component={MyDetails}
                key="details-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/complete-profile`}
                exact
                component={CompleteProfile}
                key="complete-profile-route"
            />
            {/* <Route
                path={`${process.env.PUBLIC_URL}/create-business`}
                exact
                component={CreateBusiness}
                key="create-business-route"
            /> */}
            <Route
                path={`${process.env.PUBLIC_URL}/my-business`}
                exact
                component={MyBusiness}
                key="my-business-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/invite/:key`}
                exact
                component={Invite}
                key="invite-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/businesses/:id`}
                exact
                component={BusinessDetails}
                key="business-details-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/search/:query`}
                exact
                component={SearchResults}
                key="search-results-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/send-invite`}
                exact
                component={SendInvite}
                key="send-invite-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/about-us`}
                exact
                component={AboutUs}
                key="about-us-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/faqs`}
                exact
                component={FAQs}
                key="faqs-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/privacy`}
                exact
                component={Privacy}
                key="privacy-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/cookies`}
                exact
                component={Cookies}
                key="cookies-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/user-agreement`}
                exact
                component={UserAgreement}
                key="user-agreement-route"
            />

            <Route component={NotFound} key="not-found-route" />
        </Switch>
    );
}

import React, { Component } from "react";

import OptionButton from "../option-button/OptionButton";

import "./OptionSelector.scss";

class OptionSelector extends Component {
    selectAction(value) {
        const { action, options, selectedValues } = this.props;

        const selectedOption = options.find((option) => option.id === value);
        const newValues = [...selectedValues];
        newValues.push(selectedOption);

        action(newValues);
    }

    deselectAction(value) {
        const { action, selectedValues, preventDeselect } = this.props;

        if (preventDeselect) return;

        const newValues = selectedValues.filter((item) => item.id !== value);

        action(newValues);
    }

    toggleAction(value) {
        const { action } = this.props;

        action(!!value);
    }

    render() {
        const {
            selectedValues,
            options,
            isBinary,
            binaryTrueText,
            binaryFalseText,
            isBinarySelected,
            vertical,
            getImage,
            imageFormat,
            noIcon
        } = this.props;

        if (isBinary) {
            return (
                <div className="option-selector-wrapper">
                    <div className="option-selector binary">
                        <OptionButton
                            option={{
                                id: 1,
                                value: "Yes"
                            }}
                            text={binaryTrueText}
                            selected={isBinarySelected}
                            selectAction={this.toggleAction.bind(this)}
                            deselectAction={this.toggleAction.bind(this)}
                        ></OptionButton>
                        <OptionButton
                            option={{
                                id: 0,
                                value: "No"
                            }}
                            text={binaryFalseText}
                            selected={isBinarySelected === false}
                            selectAction={this.toggleAction.bind(this)}
                            deselectAction={this.toggleAction.bind(this)}
                        ></OptionButton>
                    </div>
                </div>
            );
        }

        return (
            <div
                className={
                    vertical
                        ? "option-selector-wrapper vertical"
                        : "option-selector-wrapper"
                }
            >
                <div className="option-selector">
                    {options.map((option) => (
                        <OptionButton
                            hexCode={option.hexCode}
                            getImage={getImage}
                            noIcon={noIcon}
                            imageFormat={imageFormat}
                            option={option}
                            selectAction={this.selectAction.bind(this)}
                            deselectAction={this.deselectAction.bind(this)}
                            selected={
                                selectedValues &&
                                selectedValues.find((sv) => sv.id === option.id)
                                    ? true
                                    : false
                            }
                            key={option.id}
                        ></OptionButton>
                    ))}
                </div>
            </div>
        );
    }
}

export default OptionSelector;

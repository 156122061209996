import React, { Component } from "react";

import "./ContentToggler.scss";

class ContentToggler extends Component {
    state = {
        openTab: ""
    };

    componentDidMount() {
        const { tabs } = this.props;

        this.setState({
            openTab: tabs[0].id
        });
    }

    switchTab(tabId) {
        this.setState({
            openTab: tabId
        });
    }

    render() {
        const { tabs } = this.props;

        const { openTab } = this.state;

        return (
            <div className="content-toggler">
                <div className="content-toggler-tabs">
                    {tabs.map((tab) => (
                        <div
                            className={openTab === tab.id ? "selected" : ""}
                            onClick={this.switchTab.bind(this, tab.id)}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
                <div className="content-toggler-content">
                    {tabs.map((tab) => (
                        <div className={openTab === tab.id ? "open" : ""}>
                            {tab.content}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default ContentToggler;

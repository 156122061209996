import { LOCATION_CHANGE } from "connected-react-router";
import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    user: null,
    matrixAccessToken: null,
    authCheck: false,
    welcomeModal: false,
    authLoading: false,
    loginError: null,
    invitingUser: null,
    resetEmailSent: false,
    passwordReset: false,
    verified: null,
    premiumInvitesCount: 0,
    invitedOpportunity: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state
            };
        case actionTypes.LOGIN:
            return {
                ...state,
                authLoading: true,
                loginError: null
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                matrixAccessToken: action.user.matrixAccessToken,
                authLoading: false,
                authCheck: true
            };
        case actionTypes.LOGIN_ERROR:
            return {
                ...state,
                loginError: action.error,
                authLoading: false,
                authCheck: true
            };
        case actionTypes.CHECK_IF_USER_LOGGED_IN_ERROR:
            return {
                ...state,
                authCheck: true
            };
        case actionTypes.GET_INVITE_DETAILS_SUCCESS:
            return {
                ...state,
                user: {
                    profileComplete: false,
                    firstName: action.invitedUser.firstName,
                    surname: action.invitedUser.surname,
                    invitingUserId: action.invitedUser.invitingUserId,
                    name:
                        action.invitedUser.firstName +
                        " " +
                        action.invitedUser.surname,
                    email: action.invitedUser.email
                },
                invitingUser: action.invitedUser.invitingUser,
                invitedOpportunity: action.invitedUser.opportunityId
            };
        case actionTypes.REGISTER_MATRIX_USER_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    matrixUserID: action.matrixUserID
                },
                matrixAccessToken: action.matrixAccessToken
            };
        }
        case actionTypes.SET_WELCOME_MODAL: {
            return {
                ...state,
                welcomeModal: action.value
            };
        }
        case actionTypes.BEGIN_REGISTRATION: {
            return {
                ...state,
                authLoading: true
            };
        }
        case actionTypes.LOGOUT:
            return {
                ...INITIAL_STATE,
                authCheck: true,
                authLoading: false
            };
        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                resetEmailSent: true
            };
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordReset: true
            };
        case actionTypes.VERIFY_RECAPTCHA_TOKEN_SUCCESS:
            return {
                ...state,
                verified: action.verificationSuccess
            };
        case actionTypes.CHECK_PREMIUM_INVITES_SUCCESS:
            return {
                ...state,
                premiumInvitesCount: action.premiumInvitesCount
            };
        default:
            return state;
    }
};

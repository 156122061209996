import React, { Component, useReducer } from "react";
import { Link } from "react-router-dom";

import ProfileIcon from "../profile-icon/ProfileIcon";

import "./CurrentUser.scss";

class CurrentUser extends Component {
    state = {
        menuOpen: false
    };

    logout() {
        const { logoutAction } = this.props;

        logoutAction();
    }

    toggleMenu() {
        const { user } = this.props;

        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    render() {
        const { user } = this.props;
        const { menuOpen } = this.state;

        if (!user) {
            return null;
        } else {
            return (
                <div
                    className="current-user logged-in"
                    onClick={this.toggleMenu.bind(this)}
                >
                    <p>{user.name}</p>
                    <ProfileIcon person={user} large />
                    {menuOpen &&
                        (user.role === "Business" || user.profileComplete) && (
                            <ul className="menu">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    className="close"
                                    alt="Close"
                                />
                                <li>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/my-details`}
                                    >
                                        About Me
                                    </Link>
                                </li>
                                {user.role === "Investor" && (
                                    <li>
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/my-preferences`}
                                        >
                                            I am looking for
                                        </Link>
                                    </li>
                                )}
                                {user.role === "Business" && (
                                    <li>
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/my-business`}
                                        >
                                            Manage my business
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <a onClick={this.logout.bind(this)}>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        )}
                    {menuOpen &&
                        (user.role === "Investor" ||
                            user.role === "Professional") &&
                        !user.profileComplete && (
                            <ul className="menu">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    className="close"
                                    alt="Close"
                                />
                                <li>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/complete-profile`}
                                    >
                                        Complete profile
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={this.logout.bind(this)}>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        )}
                    {menuOpen &&
                        (user.role === "Investor" ||
                            user.role === "Professional") &&
                        user.profileComplete && (
                            <ul className="menu">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    className="close"
                                    alt="Close"
                                />
                                <li>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/my-details`}
                                    >
                                        About Me
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/my-preferences`}
                                    >
                                        I am looking for
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={this.logout.bind(this)}>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        )}
                </div>
            );
        }
    }
}

export default CurrentUser;

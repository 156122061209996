import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./BusinessDetails.scss";

import {
    toggleMessageCenterAction,
    setMessageCenterViewAction,
    setCurrentMessageParticipantsAction,
    setCurrentMessageOpportunityAction,
    openMessageCenterAction,
    getCurrentChatAction,
    setMessageTutorialAction,
    getOpportunityDetailsForChatAction,
    leaveRoomAction
} from "../../actions/messaging/messagingActions";
import { setFocusedUserAction } from "../../actions/network/networkActions";
import { inviteToFiinectAction } from "../../actions/auth/authActions";
import {
    getBusinessDetailsAction,
    getMatchesForBusinessAction,
    shareBusinessAction
} from "../../actions/business/businessActions";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import ProfileIcon from "../../components/profile-icon/ProfileIcon";
import { months } from "../../data/data";
import { Link } from "react-router-dom";
import Button from "../../components/button/Button";
import LatestMessages from "../../components/latest-messages/LatestMessages";
import ShareBox from "../../components/share-box/ShareBox";
import Modal from "../../components/modal/Modal";

class businessDetails extends Component {
    state = {
        detailsLoaded: false
    };

    componentDidMount() {
        const { getBusinessDetailsAction } = this.props;
        const id = this.props.opportunityId || this.props.match.params.id;

        getBusinessDetailsAction(id);
    }

    componentDidUpdate() {
        const { businessDetails, getMatchesForBusinessAction } = this.props;
        const { detailsLoaded } = this.state;

        if (businessDetails.id && !detailsLoaded) {
            this.setState({
                detailsLoaded: true
            });

            getMatchesForBusinessAction({
                sectors: [{ id: businessDetails.sectorId }],
                financeStages: [{ id: businessDetails.financeStageId }],
                levelOfInvestment: businessDetails.levelOfInvestmentId
            });
        }
    }

    render() {
        const {
            auth,
            messaging,
            connections,
            businessDetails,
            businessMatches,
            sharing,
            openMessageCenterAction,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            setFocusedUserAction,
            inviteToFiinectAction,
            shareBusinessAction,
            getOpportunityDetailsForChatAction,
            leaveRoomAction
        } = this.props;

        const d = new Date(businessDetails.updated);
        let hour = d.getHours();
        let minute = d.getMinutes();
        if (hour < 10) hour = `0${hour}`;
        if (minute < 10) minute = `0${minute}`;
        const dateString = `${d.getDate()} ${
            months[d.getMonth()]
        } ${d.getFullYear()} ${hour}:${minute}`;

        return (
            <div className="business-details-container">
                <Modal visible={businessDetails.status === "closed"}>
                    <div className="go-live-modal">
                        <p>
                            The information on this page has now expired. Please
                            message the owner of this page if you have any
                            questions.
                        </p>
                    </div>
                </Modal>

                <HighlightedContent
                    headline={businessDetails.name}
                    small
                ></HighlightedContent>
                <MainContent>
                    <div
                        className={
                            businessDetails.status === "closed"
                                ? "closed content"
                                : "content"
                        }
                    >
                        <div className="business-details-basics">
                            <div className="user-details">
                                {businessDetails.managingUser && (
                                    <>
                                        <ProfileIcon
                                            person={
                                                businessDetails.managingUser
                                            }
                                            large
                                        />
                                        <p>
                                            From:{" "}
                                            <strong>
                                                {
                                                    businessDetails.managingUser
                                                        .name
                                                }
                                            </strong>
                                        </p>

                                        <span>|</span>
                                        <time
                                            dateTime={businessDetails.updated}
                                        >
                                            {dateString}
                                        </time>
                                    </>
                                )}
                            </div>
                        </div>

                        <main>
                            <section>
                                <h4 className="sector">
                                    {businessDetails.sector &&
                                        businessDetails.sector.name}
                                </h4>
                                <h1>{businessDetails.headline}</h1>

                                <div className="key-details">
                                    <div className="detail">
                                        <label>No. of employees</label>
                                        <span>
                                            {businessDetails.numberOfEmployees}
                                        </span>
                                    </div>
                                    <div className="detail">
                                        <label>Plan to raise timeline</label>
                                        <span>{businessDetails.timescale}</span>
                                    </div>
                                </div>

                                <div className="business-in-depth-details">
                                    {(businessDetails.websiteURL ||
                                        (businessDetails.documents &&
                                            businessDetails.documents.length >
                                                0)) && (
                                        <aside className="downloads">
                                            <h6>Downloads &amp; links</h6>
                                            <div className="content">
                                                {businessDetails.documents &&
                                                    businessDetails.documents.map(
                                                        (doc) => (
                                                            <Link to={doc.link}>
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/document.svg`}
                                                                    alt={
                                                                        doc.title
                                                                    }
                                                                />
                                                                {doc.title}
                                                            </Link>
                                                        )
                                                    )}

                                                {businessDetails.websiteURL && (
                                                    <div className="web-link">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/web.svg`}
                                                            alt="Web link"
                                                        />
                                                        <Button
                                                            href={
                                                                businessDetails.websiteURL
                                                            }
                                                            isExternal
                                                        >
                                                            Go to site
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </aside>
                                    )}
                                    <section>
                                        {businessDetails.youtubeURL && (
                                            <iframe
                                                src={`https://www.youtube.com/embed/${businessDetails.youtubeURL}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        )}
                                        {businessDetails.summary && (
                                            <>
                                                <h3>Summary</h3>
                                                <p>{businessDetails.summary}</p>
                                            </>
                                        )}
                                        {businessDetails.businessDetails && (
                                            <>
                                                <h3>The opportunity</h3>
                                                <p>
                                                    {
                                                        businessDetails.businessDetails
                                                    }
                                                </p>
                                            </>
                                        )}
                                    </section>
                                </div>
                            </section>
                            <aside>
                                <div className="my-business-status">
                                    {businessDetails.status === "closed" && (
                                        <h3 className="inactive">
                                            Inactive page
                                        </h3>
                                    )}
                                </div>
                                <ShareBox
                                    user={auth.user}
                                    messaging={messaging}
                                    businessId={businessDetails.id}
                                    business={businessDetails}
                                    matches={businessMatches}
                                    toggleMessageCenterAction={
                                        toggleMessageCenterAction
                                    }
                                    setMessageCenterViewAction={
                                        setMessageCenterViewAction
                                    }
                                    getCurrentChatAction={getCurrentChatAction}
                                    setCurrentMessageParticipantsAction={
                                        setCurrentMessageParticipantsAction
                                    }
                                    setCurrentMessageOpportunityAction={
                                        setCurrentMessageOpportunityAction
                                    }
                                    setFocusedUserAction={setFocusedUserAction}
                                    inviteToFiinectAction={
                                        inviteToFiinectAction
                                    }
                                    shareAction={shareBusinessAction}
                                    connections={connections}
                                    sharing={sharing}
                                />
                                <LatestMessages
                                    opportunityFilter={businessDetails.id}
                                    latestMessages={messaging.messages}
                                    openMessageCenterAction={
                                        openMessageCenterAction
                                    }
                                    setMessageCenterViewAction={
                                        setMessageCenterViewAction
                                    }
                                    getCurrentChatAction={getCurrentChatAction}
                                    setCurrentMessageParticipantsAction={
                                        setCurrentMessageParticipantsAction
                                    }
                                    setCurrentMessageOpportunityAction={
                                        setCurrentMessageOpportunityAction
                                    }
                                    getOpportunityDetailsForChatAction={
                                        getOpportunityDetailsForChatAction
                                    }
                                    leaveRoomAction={leaveRoomAction}
                                />
                            </aside>
                        </main>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        connections: state.network.connections,
        messaging: state.messaging,
        inboxView: state.opportunities.inboxView,
        businessDetails: state.business.businessDetails,
        businessMatches: state.business.businessMatches,
        sharing: state.opportunities.sharing
    }),
    {
        getBusinessDetailsAction,
        toggleMessageCenterAction,
        setMessageCenterViewAction,
        setCurrentMessageParticipantsAction,
        setCurrentMessageOpportunityAction,
        openMessageCenterAction,
        getCurrentChatAction,
        setFocusedUserAction,
        setMessageTutorialAction,
        inviteToFiinectAction,
        getMatchesForBusinessAction,
        shareBusinessAction,
        getOpportunityDetailsForChatAction,
        leaveRoomAction
    }
)(withRouter(businessDetails));

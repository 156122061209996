import React, { Component } from "react";

import Button from "../button/Button";
import TextInput from "../text-input/TextInput";
import Loader from "../loader/Loader";
import ProfileIcon from "../profile-icon/ProfileIcon";
import { arraysEqual } from "../../util";

import "./ShareBox.scss";
import Connection from "../connection/Connection";
import InfoBubble from "../info-bubble/InfoBubble";
import { matomoTrack } from "../../util";

class ShareBox extends Component {
    state = {
        addedUsers: [],
        selectedUsers: [],
        shareOpen: false,
        shareConfirm: false,
        addDropdownOpen: false,
        addFromWithinFiinect: false,
        addFromOutsideFiinect: false,
        nameFilter: "",
        filtersOpen: false,
        connectionFilter: "",
        externalUserDetails: {
            firstName: "",
            surname: "",
            email: ""
        },
        deletedUsers: []
    };

    hideConfirm;

    selectAll() {
        const { connectionFilter } = this.state;

        const { matches } = this.props;

        const filteredMatches = matches.filter((match) => {
            if (
                connectionFilter &&
                match.status.toLowerCase() === connectionFilter.toLowerCase()
            )
                return true;
            if (connectionFilter === "") return true;
        });

        this.setState({
            selectedUsers: filteredMatches.map((match) => match)
        });
    }

    toggleUser(user) {
        const { selectedUsers } = this.state;
        const exists = selectedUsers.findIndex((su) => su.id === user.id);

        if (exists > -1) {
            selectedUsers.splice(exists, 1);
        } else {
            selectedUsers.push(user);
        }

        this.setState({
            selectedUsers
        });
    }

    toggleAddUser(user) {
        const { addedUsers, deletedUsers } = this.state;
        const exists = addedUsers.findIndex((au) => au.id === user.id);

        if (exists > -1) {
            addedUsers.splice(exists, 1);
        } else {
            addedUsers.push(user);
        }

        const deletedExists = deletedUsers.findIndex((du) => du.id === user.id);
        if (deletedExists > -1) {
            deletedUsers.splice(deletedExists, 1);
        }

        this.setState({
            addedUsers,
            deletedUsers
        });
    }

    deleteUsers() {
        const { selectedUsers, deletedUsers } = this.state;

        this.setState({
            deletedUsers: [...deletedUsers, ...selectedUsers],
            selectedUsers: []
        });
    }

    messageSelectedUsers() {
        const {
            messaging,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            opportunityId,
            opportunity,
            businessId,
            business
        } = this.props;

        const { selectedUsers } = this.state;

        const usersToMessage = selectedUsers.sort((a, b) =>
            a.id > b.id ? 1 : -1
        );

        let entityId = opportunityId;
        let entity = opportunity;
        if (businessId) {
            entityId = `business-${businessId}`;
            entity = business;
        }

        toggleMessageCenterAction();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction(usersToMessage);
        setCurrentMessageOpportunityAction(entity);

        const chat = messaging.messages.find(
            (room) =>
                arraysEqual(room.people, usersToMessage) &&
                ((!opportunityId && !room.opportunity) ||
                    (opportunityId &&
                        room.opportunity &&
                        room.opportunity.opportunityId === opportunityId))
        );

        getCurrentChatAction(chat, usersToMessage, entityId);

        this.setState({
            selectedUsers: []
        });
    }

    openShare() {
        matomoTrack(
            "share-opportunity",
            document.querySelector("div.content h1").innerText
        );
        this.setState({
            shareOpen: true
        });
    }

    closeShare() {
        this.setState({
            shareOpen: false
        });
    }

    resetView() {
        this.setState({
            selectedUsers: [],
            shareOpen: false,
            shareConfirm: false,
            addDropdownOpen: false,
            addFromWithinFiinect: false,
            addFromOutsideFiinect: false,
            nameFilter: "",
            externalUserDetails: {
                firstName: "",
                surname: "",
                email: ""
            }
        });
    }

    checkForHideConfirm() {
        const { sharing } = this.props;

        if (!sharing) {
            setTimeout(() => {
                this.setState({
                    shareConfirm: false
                });
            }, 1000);
            clearInterval(this.hideConfirm);
        }
    }

    shareToSelectedUsers() {
        const { opportunity, business, shareAction, sharing } = this.props;

        const { selectedUsers } = this.state;

        this.setState({
            shareOpen: false,
            shareConfirm: true,
            selectedUsers: []
        });

        this.hideConfirm = setInterval(() => {
            this.checkForHideConfirm();
        }, 100);

        let entity = opportunity;
        if (business) entity = business;

        shareAction(
            entity,
            selectedUsers.map((user) => user.id)
        );
    }

    enterUserDetails(key, value) {
        const { externalUserDetails } = this.state;

        externalUserDetails[key] = value;

        this.setState({
            externalUserDetails
        });
    }

    shareToExternalUser() {
        const { opportunityId, inviteToFiinectAction } = this.props;

        const { externalUserDetails } = this.state;

        // TODO MED validate
        inviteToFiinectAction(externalUserDetails, opportunityId);

        this.setState({
            addFromOutsideFiinect: false,
            shareOpen: false,
            shareConfirm: true,
            selectedUsers: []
        });

        setTimeout(() => {
            this.setState({
                shareConfirm: false
            });
        }, 2000);
    }

    toggleAddDropdown() {
        this.setState({
            addDropdownOpen: !this.state.addDropdownOpen
        });
    }

    addFromWithinFiinect() {
        this.setState({
            addDropdownOpen: false,
            addFromWithinFiinect: true
        });
    }

    addFromOutsideFiinect() {
        this.setState({
            addDropdownOpen: false,
            addFromOutsideFiinect: true
        });
    }

    changeNameFilter(nameFilter) {
        this.setState({
            nameFilter
        });
    }

    toggleFilters() {
        this.setState({
            filtersOpen: !this.state.filtersOpen
        });
    }

    changeConnectionFilter(connectionFilter) {
        this.setState({
            connectionFilter,
            filtersOpen: false
        });
    }

    copyLink() {
        var copyText = document.querySelector("#link");
        copyText.style.display = "block";
        copyText.select();
        document.execCommand("copy");
        copyText.style.display = "none";
    }

    render() {
        const {
            selectedUsers,
            addedUsers,
            shareOpen,
            shareConfirm,
            addDropdownOpen,
            addFromWithinFiinect,
            addFromOutsideFiinect,
            nameFilter,
            filtersOpen,
            connectionFilter,
            deletedUsers
        } = this.state;

        const {
            user,
            matches,
            connections,
            opportunity,
            business,
            sharing,
            setFocusedUserAction,
            preview,
            broker
        } = this.props;

        let entity = opportunity;
        if (business) entity = business;

        let userShareOptions = [];

        if (opportunity.interestedPeople) {
            opportunity.interestedPeople.forEach((person) => {
                if (
                    person.id !== user.id &&
                    !userShareOptions.find((uso) => uso.id === person.id) &&
                    !deletedUsers.find((du) => du.id === person.id) &&
                    connections.find((c) => c.id === person.id)
                ) {
                    userShareOptions.push(person);
                }
            });
            opportunity.otherPeople.forEach((person) => {
                if (
                    person.id !== user.id &&
                    !userShareOptions.find((uso) => uso.id === person.id) &&
                    !deletedUsers.find((du) => du.id === person.id) &&
                    connections.find((c) => c.id === person.id)
                ) {
                    userShareOptions.push(person);
                }
            });
        }

        addedUsers.forEach((person) => {
            if (
                person.id !== user.id &&
                !userShareOptions.find((uso) => uso.id === person.id) &&
                !deletedUsers.find((du) => du.id === person.id) &&
                connections.find((c) => c.id === person.id)
            ) {
                userShareOptions.push(person);
            }
        });
        matches.forEach((person) => {
            if (
                person.id !== user.id &&
                !userShareOptions.find((uso) => uso.id === person.id) &&
                !deletedUsers.find((du) => du.id === person.id) &&
                connections.find((c) => c.id === person.id)
            ) {
                userShareOptions.push(person);
            }
        });
        if (broker) {
            userShareOptions = userShareOptions.filter(
                (uso) => uso.id !== broker.id
            );
        }

        return (
            <div className="share-box">
                {!addFromWithinFiinect && !addFromOutsideFiinect && (
                    <>
                        <div className={shareOpen ? "overlay open" : "overlay"}>
                            <p>Share this opportunity with the selected?</p>
                            <Button
                                isPrimary
                                icon="/assets/send_white.svg"
                                onClick={this.shareToSelectedUsers.bind(this)}
                            >
                                Share
                            </Button>
                            <Button onClick={this.closeShare.bind(this)}>
                                Cancel
                            </Button>
                        </div>

                        <div
                            className={
                                shareConfirm
                                    ? "overlay confirmation open"
                                    : "overlay confirmation"
                            }
                        >
                            {sharing && <Loader small />}
                            {!sharing && (
                                <p>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/check.svg`}
                                        alt="Success"
                                    />
                                    Opportunity shared
                                </p>
                            )}
                        </div>

                        <div
                            className={
                                !user.profileComplete
                                    ? "overlay first-time open"
                                    : "overlay first-time"
                            }
                        >
                            <p>
                                Your network matches for this opportunity
                                feature here
                            </p>
                        </div>

                        <h4>
                            Start a conversation with your network
                            <InfoBubble
                                content={
                                    <>
                                        <p>
                                            This is your communications box. We
                                            show you contacts from YOUR network
                                            who match this opportunity using our
                                            Algorithms.
                                        </p>
                                        <p>
                                            YOU see this information – it is not
                                            publicly available.
                                        </p>
                                        <p>
                                            You can share the opportunity with
                                            your contacts – simply click on
                                            their faces and select "share". When
                                            they receive it, they will assign a
                                            status.
                                        </p>
                                        <p>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/send_white.svg`}
                                                className="infobubble-icon"
                                            />{" "}
                                            = you have shared
                                            <br />
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/interested.svg`}
                                                className="infobubble-icon"
                                            />{" "}
                                            = they are interested
                                            <br />
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/following.svg`}
                                                className="infobubble-icon"
                                            />{" "}
                                            = they are just watching
                                            <br />
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/not_interested.svg`}
                                                className="infobubble-icon"
                                            />{" "}
                                            = they are not interested
                                            <br />
                                        </p>
                                        <p>
                                            You can add other people ("Add
                                            People" button) from within Fiinect
                                            or outside (by email) that you think
                                            may also be interested.
                                        </p>
                                    </>
                                }
                            />
                        </h4>
                        <p className="match-count">
                            ({userShareOptions ? userShareOptions.length : 0})
                            people below also match this opportunity
                        </p>
                        <div className="share-box-filters">
                            <div className="add-people">
                                <Button
                                    onClick={this.toggleAddDropdown.bind(this)}
                                >
                                    Add people
                                </Button>
                                {addDropdownOpen && (
                                    <ul className="add-people-source">
                                        <li
                                            onClick={this.addFromWithinFiinect.bind(
                                                this
                                            )}
                                        >
                                            From within Fiinect
                                        </li>
                                        <li
                                            onClick={this.addFromOutsideFiinect.bind(
                                                this
                                            )}
                                        >
                                            From outside Fiinect
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div className="filter-people">
                                <div
                                    className={
                                        filtersOpen
                                            ? "hamburger open"
                                            : "hamburger"
                                    }
                                    onClick={this.toggleFilters.bind(this)}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                                <div
                                    className={
                                        filtersOpen ? "filters open" : "filters"
                                    }
                                >
                                    <p>Show...</p>
                                    <ul>
                                        <li
                                            className={
                                                connectionFilter === ""
                                                    ? "selected filter"
                                                    : "filter"
                                            }
                                            onClick={this.changeConnectionFilter.bind(
                                                this,
                                                ""
                                            )}
                                        >
                                            Everybody
                                        </li>
                                        <li
                                            className={
                                                connectionFilter ===
                                                "Not yet shared"
                                                    ? "selected filter"
                                                    : "filter"
                                            }
                                            onClick={this.changeConnectionFilter.bind(
                                                this,
                                                "Not yet shared"
                                            )}
                                        >
                                            Not yet shared
                                        </li>
                                        <li
                                            className={
                                                connectionFilter === "Shared"
                                                    ? "selected filter"
                                                    : "filter"
                                            }
                                            onClick={this.changeConnectionFilter.bind(
                                                this,
                                                "Shared"
                                            )}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/send_white.svg`}
                                            />
                                            Shared
                                        </li>
                                        <li
                                            className={
                                                connectionFilter === "Following"
                                                    ? "selected filter"
                                                    : "filter"
                                            }
                                            onClick={this.changeConnectionFilter.bind(
                                                this,
                                                "Following"
                                            )}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/following.svg`}
                                            />
                                            Just watching
                                        </li>
                                        <li
                                            className={
                                                connectionFilter ===
                                                "Interested"
                                                    ? "selected filter"
                                                    : "filter"
                                            }
                                            onClick={this.changeConnectionFilter.bind(
                                                this,
                                                "Interested"
                                            )}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/interested.svg`}
                                            />
                                            Interested
                                        </li>
                                        <li
                                            className={
                                                connectionFilter ===
                                                "Not interested"
                                                    ? "selected filter"
                                                    : "filter"
                                            }
                                            onClick={this.changeConnectionFilter.bind(
                                                this,
                                                "Not interested"
                                            )}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/not_interested.svg`}
                                            />
                                            Not interested
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="matches">
                            <div
                                className="match select-all"
                                onClick={this.selectAll.bind(this)}
                            >
                                <span>Select all</span>
                            </div>
                            {userShareOptions &&
                                userShareOptions.map((match) => {
                                    let className = "match";

                                    if (!match.status)
                                        match.status = "Not yet shared";

                                    if (entity && entity.interestedPeople) {
                                        const interested = entity.interestedPeople.find(
                                            (interested) =>
                                                interested.id === match.id
                                        );
                                        if (interested)
                                            match.status = "Interested";
                                    }

                                    if (entity && entity.otherPeople) {
                                        const other = entity.otherPeople.find(
                                            (interested) =>
                                                interested.id === match.id
                                        );
                                        if (other) match.status = other.status;
                                    }

                                    if (
                                        selectedUsers.find(
                                            (su) => su.id === match.id
                                        )
                                    )
                                        className += " selected";
                                    if (
                                        connectionFilter &&
                                        match.status.toLowerCase() !==
                                            connectionFilter.toLowerCase()
                                    )
                                        return;

                                    return (
                                        <div
                                            key={match.id}
                                            className={className}
                                            onClick={this.toggleUser.bind(
                                                this,
                                                match
                                            )}
                                        >
                                            <div className="profile-icon-holder">
                                                <ProfileIcon person={match} />
                                            </div>
                                            <p>{match.firstName}</p>
                                            {match.status &&
                                                match.status !==
                                                    "Not yet shared" && (
                                                    <div className="match-status">
                                                        {match.status ===
                                                            "Interested" && (
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/interested.svg`}
                                                            />
                                                        )}
                                                        {match.status ===
                                                            "Following" && (
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/following.svg`}
                                                            />
                                                        )}
                                                        {match.status ===
                                                            "Not interested" && (
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/not_interested.svg`}
                                                            />
                                                        )}
                                                        {match.status ===
                                                            "Shared" && (
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/send_white.svg`}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                        </div>

                        <div className="actions">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/dustbin.png`}
                                className={
                                    selectedUsers.length === 0 ? "disabled" : ""
                                }
                                onClick={this.deleteUsers.bind(this)}
                            />

                            <Button
                                icon="/assets/message.svg"
                                onClick={this.messageSelectedUsers.bind(this)}
                                disabled={selectedUsers.length === 0}
                            >
                                Message
                            </Button>

                            <Button
                                isPrimary
                                icon="/assets/send_white.svg"
                                disabled={selectedUsers.length === 0}
                                onClick={this.openShare.bind(this)}
                            >
                                Share
                            </Button>
                        </div>
                    </>
                )}

                {addFromWithinFiinect && (
                    <div className="share-box-add-within">
                        <h4>
                            <span onClick={this.resetView.bind(this)}>
                                &#9666;
                            </span>
                            Add people from within Fiinect
                        </h4>

                        <TextInput
                            type="text"
                            placeholder="Search by name"
                            onInput={this.changeNameFilter.bind(this)}
                        />

                        <div className="connections">
                            {connections.map((connection) => {
                                if (
                                    connection.name
                                        .toLowerCase()
                                        .indexOf(nameFilter.toLowerCase()) ===
                                    -1
                                )
                                    return "";

                                return (
                                    <Connection
                                        connection={connection}
                                        selectable
                                        selected={userShareOptions.find(
                                            (au) => au.id === connection.id
                                        )}
                                        onClick={this.toggleAddUser.bind(
                                            this,
                                            connection
                                        )}
                                        setFocusedUserAction={
                                            setFocusedUserAction
                                        }
                                    />
                                );
                            })}
                        </div>

                        <div className="actions">
                            <Button
                                isPrimary
                                onClick={this.resetView.bind(this)}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                )}

                {addFromOutsideFiinect && (
                    <div className="share-box-add-outside">
                        <h4>
                            <span onClick={this.resetView.bind(this)}>
                                &#9666;
                            </span>
                            Add people from outside Fiinect
                        </h4>

                        <form>
                            <p>Add their details here</p>

                            <label htmlFor="firstName">First name</label>
                            <TextInput
                                type="text"
                                onInput={this.enterUserDetails.bind(
                                    this,
                                    "firstName"
                                )}
                            />

                            <label htmlFor="surname">Surname</label>
                            <TextInput
                                type="text"
                                onInput={this.enterUserDetails.bind(
                                    this,
                                    "surname"
                                )}
                            />

                            <label htmlFor="email">Email address</label>
                            <TextInput
                                type="text"
                                onInput={this.enterUserDetails.bind(
                                    this,
                                    "email"
                                )}
                            />

                            <div className="actions">
                                <Button
                                    isPrimary
                                    onClick={this.shareToExternalUser.bind(
                                        this
                                    )}
                                >
                                    Add
                                </Button>
                            </div>

                            {/*<p>Share via LinkedIn or Slack</p>
                            <p
                                className="copy-link"
                                onClick={this.copyLink.bind(this)}
                            >
                                <input
                                    type="text"
                                    id="link"
                                    value={window.location.href}
                                    style={{ display: "none" }}
                                />
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/link.png`}
                                />{" "}
                                Copy link
                            </p>*/}
                        </form>
                    </div>
                )}
            </div>
        );
    }
}

export default ShareBox;

import axios from "axios";

import * as actionTypes from "../actionTypes";
import { updateUserDetailsAction } from "../preferences/preferencesActions";
import { API_BASE } from "../../api";

export const registerBusinessAction = (registrationDetails, userId) => {
    return (dispatch) => {
        const body = {
            Name: registrationDetails.businessName,
            ManagingUserId: userId,
            Status: "draft",
            NumberOfEmployees: registrationDetails.numberOfEmployees,
            SectorId:
                registrationDetails.sectors.length > 0
                    ? registrationDetails.sectors[0].id
                    : null,
            Summary: "",
            FinanceStageId:
                registrationDetails.financeStage.length > 0
                    ? registrationDetails.financeStage[0].id
                    : null,
            WebsiteURL: "",
            BusinessDetails: "",
            YoutubeURL: "",
            Timings: registrationDetails.timings || "",
            BusinessRegistrationLocationId:
                registrationDetails.registrationLocation,
            IsPreRevenue: registrationDetails.turnover === "Pre Revenue",
            MakingProfit: registrationDetails.MakingProfit,
            Turnover:
                registrationDetails.turnover.length > 0
                    ? registrationDetails.turnover[0].value
                    : null,
            Timescale:
                registrationDetails.timescales.length > 0
                    ? registrationDetails.timescales[0].value
                    : null,
            RoundOfFinance: registrationDetails.roundOfFinance.length
                ? registrationDetails.roundOfFinance[0].value
                : null,
            Headline: registrationDetails.Headline,
            Summary: registrationDetails.Summary,
            BusinessDetails: registrationDetails.BusinessDetails,
            YoutubeURL: registrationDetails.YoutubeURL,
            WebsiteURL: registrationDetails.WebsiteURL
        };

        return axios
            .post(`${API_BASE}/Business`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*"
                }
            })
            .then(({ data }) => {
                dispatch(registerBusinessSuccessAction(data));

                registrationDetails.locations.forEach((location) =>
                    dispatch(
                        updateBusinessLocationAction(location, data.id, "post")
                    )
                );
            })
            .catch((error) => {
                dispatch(registerBusinessErrorAction(error));
            });
    };
};

export const registerBusinessSuccessAction = (businessDetails) => ({
    type: actionTypes.REGISTER_BUSINESS_SUCCESS,
    businessDetails
});

export const registerBusinessErrorAction = (error) => ({
    type: actionTypes.REGISTER_BUSINESS_ERROR,
    error
});

export const getMyBusinessDetailsAction = () => {
    const accessToken = localStorage.getItem("accessToken");
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Business/my-business`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const businessDetails = {
                    ...data,
                    interestedPeople: data.connections
                        .filter(
                            (connection) => connection.status === "Interested"
                        )
                        .map((connection) => connection.users),
                    otherPeople: data.connections
                        .filter(
                            (connection) => connection.status !== "Interested"
                        )
                        .map((connection) => {
                            const user = connection.users;
                            user.status = connection.status;
                            return user;
                        })
                };
                dispatch(getMyBusinessDetailsSuccessAction(businessDetails));
            })
            .catch((error) => {
                dispatch(getMyBusinessDetailsErrorAction(error));
            });
    };
};

export const getMyBusinessDetailsSuccessAction = (business) => ({
    type: actionTypes.GET_MY_BUSINESS_DETAILS_SUCCESS,
    business
});

export const getMyBusinessDetailsErrorAction = (error) => ({
    type: actionTypes.GET_MY_BUSINESS_DETAILS_ERROR,
    error
});

export const updateBusinessAction = (businessDetails) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        const body = {
            Id: businessDetails.BusinessID,
            Name: businessDetails.BusinessName,
            ManagingUserId: user.id,
            Status: businessDetails.status,
            Headline: businessDetails.Headline,
            NumberOfEmployees: businessDetails.NumberOfEmployees,
            SectorId: businessDetails.sectors[0].id,
            Summary: businessDetails.Summary,
            FinanceStageId: businessDetails.financeStages[0].id,
            LevelOfInvestmentId: businessDetails.levelOfInvestment[0].id,
            WebsiteURL: businessDetails.WebsiteURL,
            BusinessDetails: businessDetails.BusinessDetails,
            YoutubeURL: businessDetails.YoutubeURL,
            IsPreRevenue: businessDetails.IsPreRevenue ? 1 : 0,
            MakingProfit: businessDetails.MakingProfit ? 1 : 0,
            Timescale: businessDetails.Timescales[0].value,
            Timings: businessDetails.timings,
            IsActive: 1
        };

        return axios
            .post(`${API_BASE}/Business/edit/id`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(updateBusinessSuccessAction(data));
                dispatch(updateUserDetailsAction({ profileComplete: true }));

                businessDetails.locationAdditions.forEach((addition) =>
                    dispatch(
                        updateBusinessLocationAction(
                            addition,
                            businessDetails.BusinessID,
                            "post"
                        )
                    )
                );
                businessDetails.locationSubtractions.forEach((subtraction) =>
                    dispatch(
                        updateBusinessLocationAction(
                            subtraction,
                            businessDetails.BusinessID,
                            "delete"
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(updateBusinessErrorAction(error));
            });
    };
};

export const updateBusinessSuccessAction = (businessDetails) => ({
    type: actionTypes.UPDATE_BUSINESS_SUCCESS,
    businessDetails
});

export const updateBusinessErrorAction = (error) => ({
    type: actionTypes.UPDATE_BUSINESS_ERROR,
    error
});

export const updateBusinessLocationAction = (
    locationCode,
    businessId,
    method
) => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");
        const idMapping = {
            GB: 1,
            europe: 37,
            asia: 39,
            africa: 41,
            mena: 42,
            northAmerica: 43,
            southAmerica: 44
        };
        const body = {
            LocationId: idMapping[locationCode],
            BusinessId: businessId
        };

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/BusinessLocation`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(updateBusinessLocationActionSuccess(data));
                });
        } else {
            return axios
                .post(`${API_BASE}/BusinessLocation`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(updateBusinessLocationActionSuccess(data));
                });
        }
    };
};

export const updateBusinessLocationActionSuccess = () => ({
    type: actionTypes.UPDATE_BUSINESS_LOCATION_SUCCESS
});

export const updateBusinessLocationActionError = (error) => ({
    type: actionTypes.UPDATE_BUSINESS_LOCATION_ERROR,
    error
});

export const getBusinessDetailsAction = (businessId) => {
    const accessToken = localStorage.getItem("accessToken");
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Business/${businessId}`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const businessDetails = {
                    ...data,
                    interestedPeople: data.connections
                        .filter(
                            (connection) => connection.status === "Interested"
                        )
                        .map((connection) => connection.users),
                    otherPeople: data.connections
                        .filter(
                            (connection) => connection.status !== "Interested"
                        )
                        .map((connection) => {
                            const user = connection.users;
                            user.status = connection.status;
                            return user;
                        })
                };
                dispatch(getBusinessDetailsSuccessAction(businessDetails));
            })
            .catch((error) => {
                dispatch(getBusinessDetailsErrorAction(error));
            });
    };
};

export const getBusinessDetailsSuccessAction = (business) => ({
    type: actionTypes.GET_BUSINESS_DETAILS_SUCCESS,
    business
});

export const getBusinessDetailsErrorAction = (error) => ({
    type: actionTypes.GET_BUSINESS_DETAILS_ERROR,
    error
});

export const getMatchesForBusinessAction = (filters) => {
    return (dispatch, getState) => {
        const accessToken = localStorage.getItem("accessToken");
        let location = null;
        if (filters.locations) {
            getState().auxillary.locations.find(
                (location) => location.code === filters.locations[0]
            );
        }
        const body = {};
        if (location) body.locationId = location.id;
        if (filters.sectors.length > 0) body.sectorId = filters.sectors[0].id;
        if (filters.financeStages.length > 0)
            body.financeStageId = filters.financeStages[0].id;
        if (filters.levelOfInvestment)
            body.LevelOfInvestmentId = filters.levelOfInvestment;

        return axios
            .post(`${API_BASE}/Opportunity/matches`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(getMatchesForBusinessSuccessAction(data));
            })
            .catch((error) => {
                dispatch(getMatchesForBusinessErrorAction(error));
            });
    };
};

export const getMatchesForBusinessSuccessAction = (matches) => ({
    type: actionTypes.GET_MATCHES_FOR_BUSINESS_SUCCESS,
    matches
});

export const getMatchesForBusinessErrorAction = (error) => ({
    type: actionTypes.GET_MATCHES_FOR_BUSINESS_ERROR,
    error
});

export const shareBusinessAction = (business, userIds) => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");

        return axios
            .post(`${API_BASE}/Share/business/${business.id}`, userIds, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(shareBusinessSuccessAction());
                dispatch(getBusinessDetailsAction(business.id));
                dispatch(
                    getMatchesForBusinessAction({
                        sectors: [{ id: business.sectorId }],
                        financeStages: [{ id: business.financeStageId }],
                        levelOfInvestment: business.levelOfInvestmentId
                    })
                );
            })
            .catch((error) => {
                dispatch(shareBusinessErrorAction(error));
            });
    };
};

export const shareBusinessSuccessAction = () => ({
    type: actionTypes.SHARE_BUSINESS_SUCCESS
});

export const shareBusinessErrorAction = (error) => ({
    type: actionTypes.SHARE_BUSINESS_ERROR,
    error
});

export const getAllBusinessesAction = () => {
    const accessToken = localStorage.getItem("accessToken");
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Business`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(getAllBusinessesSuccessAction(data));
            })
            .catch((error) => {
                dispatch(getAllBusinessesErrorAction(error));
            });
    };
};
export const getAllBusinessesSuccessAction = (businesses) => ({
    type: actionTypes.GET_ALL_BUSINESSES_SUCCESS,
    businesses
});

export const getAllBusinessesErrorAction = (error) => ({
    type: actionTypes.GET_ALL_BUSINESSES_ERROR,
    error
});

import React, { Component } from "react";

import "./MainContent.scss";

class MainContent extends Component {
    render() {
        const { children } = this.props;

        return <section className="main-content">{children}</section>;
    }
}

export default MainContent;

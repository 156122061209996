import React, { Component } from "react";
import Button from "../button/Button";
import ProfileIcon from "../profile-icon/ProfileIcon";

import "./MatchBox.scss";

class MatchBox extends Component {
    state = {
        sticky: false
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    onShare() {
        const { onShare } = this.props;

        onShare();
    }

    handleScroll(e) {
        const { stickyOffset } = this.props;
        if (window.pageYOffset > stickyOffset) {
            this.setState({
                sticky: true
            });
        } else {
            this.setState({
                sticky: false
            });
        }
    }

    render() {
        const { matches, readyToShare, sharedToMatches } = this.props;

        const { sticky } = this.state;

        return (
            <div className={sticky ? "match-box sticky" : "match-box"}>
                <div
                    className={
                        sharedToMatches
                            ? "overlay confirmation open"
                            : "overlay confirmation"
                    }
                >
                    <p>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/check.svg`}
                            alt="Success"
                        />
                        Opportunity shared
                    </p>
                </div>
                <h4>
                    Connections who match this opportunity ({matches.length})
                </h4>
                <div className="matches">
                    {matches.map((match) => {
                        let className = "match";

                        if (readyToShare) className += " selected";

                        return (
                            <div className={className}>
                                <div className="profile-icon-holder">
                                    <ProfileIcon person={match} />
                                </div>
                                <p>{match.firstName}</p>
                            </div>
                        );
                    })}
                </div>

                {readyToShare && (
                    <div className="matches-actions">
                        <Button
                            onClick={this.onShare.bind(this)}
                            icon={`${process.env.PUBLIC_URL}/assets/send_white.svg`}
                            isPrimary
                        >
                            Share with my matched connections
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default MatchBox;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Connection from "../connection/Connection";
import ContentToggler from "../content-toggler/ContentToggler";
import Loader from "../loader/Loader";

import "./ConnectionList.scss";

class ConnectionList extends Component {
    closeConnectionList() {
        const { resetFilters } = this.props;

        resetFilters();
    }

    render() {
        const {
            network,
            filterName,
            premiumInvitesCount,
            setFocusedUserAction
        } = this.props;

        const filter = network.filters[filterName];

        return (
            <div
                className={
                    filter.title ? "connection-list show" : "connection-list"
                }
            >
                <img
                    src={`${process.env.PUBLIC_URL}/assets/close_white.svg`}
                    onClick={this.closeConnectionList.bind(this)}
                    className="close"
                    alt="Close"
                />
                <header>
                    <h4>
                        <small>Fiinect people in</small> {filter.title}
                    </h4>
                    <p>
                        <span className="percentage">
                            <strong>{filter.percentage}%</strong> of my total
                            connections{" "}
                        </span>
                        <span className="count">
                            <strong>{filter.count}</strong> people in total{" "}
                        </span>
                    </p>
                </header>

                <div className="connections">
                    <ContentToggler
                        tabs={[
                            {
                                id: "in_network",
                                title: "In my network",
                                content: network.filteredConnections.map(
                                    (filteredConnection) => (
                                        <Connection
                                            connection={filteredConnection}
                                            setFocusedUserAction={
                                                setFocusedUserAction
                                            }
                                        />
                                    )
                                )
                            },
                            {
                                id: "new",
                                title: "New connections",
                                content: (
                                    <>
                                        {premiumInvitesCount < 10 && (
                                            <p>
                                                To unlock this feature please
                                                send out your{" "}
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/send-invite`}
                                                >
                                                    10 FREE PREMIUM INVITES
                                                </Link>
                                            </p>
                                        )}
                                        {premiumInvitesCount >= 10 && (
                                            <p>
                                                Congratulations! You have
                                                unlocked this feature for new
                                                introductions which will launch
                                                soon.
                                            </p>
                                        )}
                                        {network.suggestionsLoading && (
                                            <Loader small />
                                        )}
                                        {!network.suggestionsLoading &&
                                            network.suggestions.length ===
                                                0 && (
                                                <>
                                                    <p>
                                                        Sorry, there are no
                                                        suggestions for this
                                                        filter.
                                                    </p>
                                                </>
                                            )}
                                        {network.suggestions.map(
                                            (newConnection) => (
                                                <Connection
                                                    isSuggestion
                                                    premiumInvitesCount={
                                                        premiumInvitesCount
                                                    }
                                                    connection={newConnection}
                                                    setFocusedUserAction={
                                                        setFocusedUserAction
                                                    }
                                                />
                                            )
                                        )}
                                    </>
                                )
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default ConnectionList;

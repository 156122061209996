import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    unread: 0,
    total: 0,
    messages: [],
    messageCenterOpen: false,
    messageCenterView: "list",
    currentMessageParticipants: [],
    currentMessageOpportunity: null,
    currentChat: null,
    roomLoading: false,
    messageTutorial: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.LIST_MESSAGES:
            return {
                ...state,
                messages: action.chats
            };
        case actionTypes.OPEN_MESSAGE_CENTER:
            return {
                ...state,
                messageCenterOpen: true
            };
        case actionTypes.CLOSE_MESSAGE_CENTER:
            return {
                ...state,
                messageCenterOpen: false,
                messageCenterView: INITIAL_STATE.messageCenterView,
                currentMessageParticipants:
                    INITIAL_STATE.currentMessageParticipants,
                currentMessageOpportunity:
                    INITIAL_STATE.currentMessageOpportunity,
                currentChat: INITIAL_STATE.currentChat
            };
        case actionTypes.TOGGLE_MESSAGE_CENTER:
            return {
                ...state,
                messageCenterOpen: !state.messageCenterOpen,
                messageCenterView: INITIAL_STATE.messageCenterView,
                currentMessageParticipants:
                    INITIAL_STATE.currentMessageParticipants,
                currentMessageOpportunity:
                    INITIAL_STATE.currentMessageOpportunity,
                currentChat: INITIAL_STATE.currentChat
            };
        case actionTypes.SET_MESSAGE_CENTER_VIEW:
            return {
                ...state,
                messageCenterView: action.value
            };
        case actionTypes.SET_CURRENT_MESSAGE_PARTICIPANTS:
            return {
                ...state,
                currentMessageParticipants: action.participants
            };
        case actionTypes.SET_CURRENT_MESSAGE_OPPORTUNITY:
            return {
                ...state,
                currentMessageOpportunity: action.opportunity
            };
        case actionTypes.SET_CURRENT_CHAT:
            return {
                ...state,
                currentChat: action.chat
            };
        case actionTypes.SEND_MESSAGE:
            return {
                ...state
            };
        case actionTypes.UPDATE_MESSAGE_COUNT:
            return {
                ...state,
                total: action.value
            };
        case actionTypes.UPDATE_UNREAD_MESSAGE_COUNT:
            return {
                ...state,
                unread: action.value
            };
        case actionTypes.CREATE_ROOM_LOADING:
            return {
                ...state,
                roomLoading: true
            };
        case actionTypes.CREATE_ROOM_SUCCESS:
            return {
                ...state,
                roomLoading: false
            };
        case actionTypes.SET_MESSAGE_TUTORIAL:
            return {
                ...state,
                messageTutorial: action.value
            };
        case actionTypes.LOGOUT:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};

import React, { Component } from "react";
import Button from "../button/Button";
import ProfileIcon from "../profile-icon/ProfileIcon";

import "./ProfilePhotoUploader.scss";

class ProfilePhotoUploader extends Component {
    maxSize = 4 * 1024 * 1024;
    state = {
        oversize: false
    };

    upload(e) {
        const { uploadProfilePictureAction } = this.props;

        const file = e.target.files[0];
        if (file.size > this.maxSize) {
            this.setState({
                oversize: true
            });
            return;
        } else {
            this.setState({
                oversize: false
            });
        }
        uploadProfilePictureAction(file);
    }

    openFileUpload() {
        document.getElementById("profile-photo-upload").click();
    }

    render() {
        const { oversize } = this.state;
        const { user } = this.props;

        return (
            <div className="profile-photo-uploader">
                <ProfileIcon person={user} extraLarge></ProfileIcon>
                <div className="uploader">
                    {oversize && (
                        <p>
                            File is too large. Please ensure it does not exceed
                            4MB.
                        </p>
                    )}
                    <input
                        type="file"
                        onChange={this.upload.bind(this)}
                        accept="image/png, image/jpeg"
                        id="profile-photo-upload"
                    />

                    <Button onClick={this.openFileUpload.bind(this)}>
                        Upload new profile photo
                    </Button>
                </div>
            </div>
        );
    }
}

export default ProfilePhotoUploader;

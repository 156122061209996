import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { regionIcons } from "../../data/data";
import countries from "../../data/countries";

import {
    setLocationPreferencesAction,
    setSectorPreferencesAction,
    setUserBusinessTypeAction,
    setUserFinanceStageAction,
    setUserLevelOfInvestmentAction,
    updateUserDetailsAction,
    uploadProfilePictureAction,
    setUserExpertiseAction
} from "../../actions/preferences/preferencesActions";
import {
    getSectorsAction,
    getLocationsAction,
    getFinanceStagesAction,
    getLevelOfInvestmentAction,
    getBusinessTypesAction,
    getOccupationsAction
} from "../../actions/auxillary/auxillaryActions";
import Button from "../../components/button/Button";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import OptionSelector from "../../components/option-selector/OptionSelector";
import TextInput from "../../components/text-input/TextInput";

import "./CompleteProfile.scss";
import ProfilePhotoUploader from "../../components/profile-photo-uploader/ProfilePhotoUploader";

class CompleteProfile extends Component {
    state = {
        formData: {
            FirstName: "",
            LastName: "",
            Email: "",
            Biography: "",
            LocationID: null,
            newPassword: "",
            confirmPassword: "",
            CurrencyPreference: "",
            OccupationID: ""
        },
        defaultData: {
            FirstName: "",
            LastName: "",
            Email: "",
            Biography: ""
        },
        LocationID: null,
        OccupationID: "",
        sectors: [],
        financeStages: [],
        levelOfInvestment: [],
        locations: [],
        businessTypes: [],
        WantsPreRevenue: null,
        WantsProfitOnly: false,
        errors: [],
        expertises: [],
        sectorsExpertise: [],
        financeExpertise: [],
        CurrencyPreference: [
            {
                id: "GBP",
                value: "GBP"
            }
        ]
    };

    componentDidMount() {
        const { currentUser } = this.props;

        this.setState({
            formData: {
                FirstName: currentUser.firstName,
                LastName: currentUser.surname,
                Email: currentUser.email,
                Biography: currentUser.biography,
                LocationID: currentUser.locationId,
                OccupationID: currentUser.occupationId
            },
            defaultData: {
                FirstName: currentUser.firstName,
                LastName: currentUser.surname,
                Email: currentUser.email,
                Biography: currentUser.biography
            },
            LocationID: currentUser.locationId,
            sectors: currentUser.sectorInterests,
            financeStages: currentUser.financeStages,
            levelOfInvestment: currentUser.levelOfInvestment,
            businessTypes: currentUser.businessTypes,
            locations: currentUser.interestedLocations,
            WantsPreRevenue: currentUser.wantsPreRevenue,
            WantsProfitOnly: currentUser.wantsProfitOnly,
            CurrencyPreference: currentUser.CurrencyPreference
                ? [
                      {
                          id: currentUser.CurrencyPreference,
                          value: currentUser.CurrencyPreference
                      }
                  ]
                : [{ id: "GBP", value: "GBP" }]
        });
    }

    update(key, action, value) {
        this.setState({
            [key]: value
        });

        if (typeof value === "boolean") {
            action({ [key]: value });
        } else {
            const arr1 = this.state[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));
            let subtractions = arr1.filter((x) => !arr2.includes(x));

            if (additions.length > 0) action(additions[0].id, "post");
            if (subtractions.length > 0) action(subtractions[0].id, "delete");
        }
    }

    updateCurrency(key, action, value) {
        const arr1 = this.state[key];
        const arr2 = value;

        let additions = arr2.filter((x) => !arr1.includes(x));

        this.setState({
            [key]: additions
        });

        action({ [key]: additions[0].id });
    }

    countryFlagForList(countryName) {
        var country = countries.ref_country_codes.find(
            (c) => c.country === countryName
        );
        if (!country) {
            return "";
        }

        return `https://flagpedia.net/data/flags/h24/${country.alpha2.toLowerCase()}.png`;
    }

    regionIconForList(countryName) {
        return regionIcons[countryName];
    }

    updateForm(key, value) {
        const { defaultData, formData } = this.state;

        let newValue = value;
        if (!newValue) newValue = defaultData[key];

        this.setState({
            formData: {
                ...formData,
                [key]: newValue
            }
        });
    }

    updateLocation(key, value) {
        const { defaultData, formData } = this.state;

        let newValue = value;
        if (!newValue) newValue = defaultData[key];

        if (newValue.length > 0) newValue = newValue[newValue.length - 1];

        this.setState({
            formData: {
                ...formData,
                [key]: newValue.id
            },
            LocationID: newValue
        });
    }

    updateSelector(key, value) {
        const { formData } = this.state;

        this.setState({
            formData: {
                ...formData,
                [key]: value[1].id
            }
        });
    }

    save() {
        const { updateUserDetailsAction } = this.props;
        const { formData } = this.state;

        if (formData.newPassword) {
            const errors = [];
            const regex = new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
            );
            if (!regex.test(formData.newPassword))
                errors.push("passwordTooWeak");
            if (formData.newPassword !== formData.confirmPassword)
                errors.push("passwordMismatch");

            this.setState({
                errors
            });

            if (errors.length > 0) return;

            formData.Password = formData.newPassword;
        }

        formData.ProfileComplete = true;

        updateUserDetailsAction(formData);
    }

    render() {
        const {
            currentUser,
            auxillary,
            setUserBusinessTypeAction,
            setSectorPreferencesAction,
            setUserFinanceStageAction,
            setUserLevelOfInvestmentAction,
            setLocationPreferencesAction,
            updateUserDetailsAction,
            uploadProfilePictureAction
        } = this.props;

        const {
            LocationID,
            formData,
            sectors,
            financeStages,
            levelOfInvestment,
            locations,
            businessTypes,
            expertises,
            WantsPreRevenue,
            WantsProfitOnly,
            sectorsExpertise,
            financeExpertise,
            CurrencyPreference
        } = this.state;

        if (currentUser.profileComplete)
            return <Redirect to={`${process.env.PUBLIC_URL}/`} />;

        let aboutYouPlaceholder = null;

        if (currentUser.role === "Investor") {
            aboutYouPlaceholder = (
                <>
                    This section is for you to say what opportunities you like
                    (types of businesses, sectors etc) as well as outlining your
                    experience. This will give users an idea of what you do &
                    what you are looking for, ensuring you make the right
                    connections quickly.
                    <br />
                    <br />
                    For example: "I have 10 years' experience investing in
                    scale-ups and tech, including Widget Co Ltd and Example
                    Funds. I am looking to back scale-up businesses in the
                    Medical and Health sectors which have traction. My sweet
                    spot is pre-Series A, usually companies raising between
                    £0.5M and £1M." `
                </>
            );
        } else if (currentUser.role === "Business") {
            aboutYouPlaceholder = (
                <>
                    This section is for you to say a little about your business
                    & your experience so you can match with people that can help
                    you achieve your objectives.
                </>
            );
        } else if (currentUser.role === "Professional") {
            aboutYouPlaceholder = (
                <>
                    This section is for you to say what opportunities you like
                    (types of businesses, sectors etc) as well as outlining your
                    experience. This will give users an idea of what you do &
                    what you are looking for, ensuring you make the right
                    connections quickly.
                    <br />
                    <br />
                    {(currentUser.occupationId === 1 ||
                        formData.OccupationID === 1) && (
                        <>
                            For example: "I have 10 years' experience working
                            for Sample Brokerage in London, specialising in
                            mezzanine finance. My clients have a preference for
                            long term investment in portfolio businesses with a
                            steady income strip. My sweet spot is in the
                            £10M-£20M range."
                        </>
                    )}
                    {(currentUser.occupationId === 2 ||
                        formData.OccupationID === 2) && (
                        <>
                            For example: "I am a forensic accountant at Sample
                            Accountancy Firm. We focus on financial planning and
                            audit preparation for companies, with a particular
                            focus on shipping and transport. We are happy to
                            introduce our clients to vetted opportunities on a
                            discretionary basis."
                        </>
                    )}
                    {(currentUser.occupationId === 3 ||
                        formData.OccupationID === 3) && (
                        <>
                            For example: "I have 10 years' experience working
                            for Sample Brokerage in London, specialising in
                            mezzanine finance. My clients have a preference for
                            long term investment in portfolio businesses with a
                            steady income strip. My sweet spot is in the
                            £10M-£20M range."
                        </>
                    )}
                </>
            );
        }

        return (
            <div className="details-container">
                <HighlightedContent
                    headline="Complete your profile"
                    small
                ></HighlightedContent>
                <MainContent>
                    <div className="details">
                        <fieldset>
                            <div>
                                <label for="firstName">Your first name</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "FirstName"
                                    )}
                                    placeholder={currentUser.firstName}
                                    keepPlaceholder
                                />
                            </div>

                            <div>
                                <label for="firstName">Your surname</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "LastName"
                                    )}
                                    placeholder={currentUser.surname}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label for="email">Email address</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "Email"
                                    )}
                                    placeholder={currentUser.email}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        {currentUser.role === "Professional" && (
                            <>
                                <h4>I am a...</h4>
                                <OptionSelector
                                    selectedValues={[
                                        { id: formData.OccupationID }
                                    ]}
                                    noIcon
                                    options={auxillary.occupations}
                                    action={this.updateSelector.bind(
                                        this,
                                        "OccupationID"
                                    )}
                                />
                            </>
                        )}

                        {formData.OccupationID !== "" &&
                            currentUser.role === "Professional" && (
                                <>
                                    <h4>
                                        I can provide services in the following
                                        areas{" "}
                                    </h4>
                                    <OptionSelector
                                        selectedValues={expertises}
                                        noIcon
                                        options={auxillary.expertise.filter(
                                            (e) =>
                                                e.occupationId ===
                                                formData.OccupationID
                                        )}
                                        action={this.update.bind(
                                            this,
                                            "expertises",
                                            setUserExpertiseAction
                                        )}
                                    />
                                </>
                            )}

                        {currentUser.role !== "Professional" && (
                            <h4>I am a...</h4>
                        )}
                        {currentUser.role === "Professional" && (
                            <h4>My clients include...</h4>
                        )}
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={businessTypes}
                            options={auxillary.businessTypes}
                            action={this.update.bind(
                                this,
                                "businessTypes",
                                setUserBusinessTypeAction
                            )}
                        />

                        {currentUser.role !== "Professional" && (
                            <h4>I am looking for...</h4>
                        )}
                        {currentUser.role === "Professional" && (
                            <h4>My clients are looking for...</h4>
                        )}
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={financeStages}
                            options={auxillary.financeStages}
                            action={this.update.bind(
                                this,
                                "financeStages",
                                setUserFinanceStageAction
                            )}
                        />

                        <h4>Currency preference</h4>

                        <OptionSelector
                            selectedValues={CurrencyPreference}
                            options={Object.keys(
                                auxillary.levelOfInvestment
                            ).map((key) => ({ id: key, value: key }))}
                            action={this.updateCurrency.bind(
                                this,
                                "CurrencyPreference",
                                updateUserDetailsAction
                            )}
                            noIcon
                            vertical
                            preventDeselect
                        />

                        <h4>I am looking for companies which are raising</h4>
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={levelOfInvestment}
                            options={
                                auxillary.levelOfInvestment[
                                    CurrencyPreference[0].id
                                ]
                            }
                            action={this.update.bind(
                                this,
                                "levelOfInvestment",
                                setUserLevelOfInvestmentAction
                            )}
                            noIcon
                        />
                        <h4>I'm interested in these sectors</h4>
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={sectors}
                            options={auxillary.sectorInterests}
                            sectorVariant
                            imageFormat="svg"
                            action={this.update.bind(
                                this,
                                "sectors",
                                setSectorPreferencesAction
                            )}
                        />

                        <h4>Would you like to see Pre-Revenue business?</h4>
                        <p></p>

                        <OptionSelector
                            isBinary
                            isBinarySelected={WantsPreRevenue}
                            binaryTrueText=""
                            binaryFalseText=""
                            action={this.update.bind(
                                this,
                                "WantsPreRevenue",
                                updateUserDetailsAction
                            )}
                        />
                        <h4>
                            Do you ONLY want to see companies making a profit?
                        </h4>
                        <p></p>

                        <OptionSelector
                            isBinary
                            isBinarySelected={WantsProfitOnly}
                            binaryTrueText=""
                            binaryFalseText="Show me all companies which meet my profile"
                            action={this.update.bind(
                                this,
                                "WantsProfitOnly",
                                updateUserDetailsAction
                            )}
                        />

                        <h4>I'm interested in these locations</h4>
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            vertical
                            getImage={this.regionIconForList.bind(this)}
                            selectedValues={locations}
                            options={auxillary.locations.filter(
                                (location) =>
                                    location.isRegion &&
                                    location.code !== "global"
                            )}
                            action={this.update.bind(
                                this,
                                "locations",
                                setLocationPreferencesAction
                            )}
                        />

                        <h4>I am located in</h4>

                        <OptionSelector
                            vertical
                            getImage={this.countryFlagForList.bind(this)}
                            selectedValues={LocationID ? [LocationID] : []}
                            options={auxillary.locations
                                .filter((location) => !location.isRegion)
                                .sort((a, b) => (a.value < b.value ? -1 : 1))}
                            action={this.updateLocation.bind(
                                this,
                                "LocationID"
                            )}
                        />

                        <fieldset>
                            <div>
                                <label for="email">About you</label>
                                <p>
                                    This brief introduction will appear in your
                                    profile card.
                                </p>

                                <p>
                                    <small>{aboutYouPlaceholder}</small>
                                </p>

                                <TextInput
                                    multiline
                                    maxLength="500"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "Biography"
                                    )}
                                    placeholder={currentUser.biography}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <h4>Profile photo</h4>
                        <ProfilePhotoUploader
                            user={currentUser}
                            uploadProfilePictureAction={
                                uploadProfilePictureAction
                            }
                        />

                        <h4>Skills and expertise</h4>

                        <p>
                            Coming soon - we will have an area called 'Fiinect
                            Marketplace' where members can offer their expertise
                            to others. If you'd like to participate, please
                            select your areas of expertise below to provide
                            advisory services. Your details will appear as an
                            advert on Fiinect Marketplace and members who need
                            help will contact you via private messaging on the
                            site.
                        </p>

                        {/* TODO link these up as expertises for investors */}
                        <h4>I have expertise in these industry sectors</h4>

                        <OptionSelector
                            selectedValues={sectorsExpertise}
                            options={auxillary.sectorInterests}
                            sectorVariant
                            imageFormat="svg"
                            action={this.update.bind(
                                this,
                                "sectorsExpertise",
                                () => {}
                            )}
                        />

                        <h4>I have expertise in these areas of finance</h4>

                        <OptionSelector
                            selectedValues={financeExpertise}
                            options={auxillary.financeStages}
                            action={this.update.bind(
                                this,
                                "financeExpertise",
                                () => {}
                            )}
                        />

                        <div className="actions">
                            <Button isPrimary onClick={this.save.bind(this)}>
                                Save details
                            </Button>
                        </div>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentUser: state.auth.user,
        auxillary: state.auxillary
    }),
    {
        updateUserDetailsAction,
        getOccupationsAction,
        getSectorsAction,
        getLocationsAction,
        getFinanceStagesAction,
        getLevelOfInvestmentAction,
        getBusinessTypesAction,
        setSectorPreferencesAction,
        setLocationPreferencesAction,
        setUserBusinessTypeAction,
        setUserFinanceStageAction,
        setUserLevelOfInvestmentAction,
        uploadProfilePictureAction,
        setUserExpertiseAction
    }
)(CompleteProfile);

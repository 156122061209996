import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { resetPasswordAction } from "../../actions/auth/authActions";
import Loader from "../../components/loader/Loader";
import TextInput from "../../components/text-input/TextInput";

import "./ResetPassword.scss";

class ResetPassword extends Component {
    state = {
        email: "",
        key: "",
        password: "",
        confirmPassword: "",
        errors: []
    };

    componentDidMount() {
        const { passwordResetKey } = this.props;

        this.setState({
            key: passwordResetKey
        });
    }

    setEmail(value) {
        this.setState({
            email: value
        });
    }

    setPassword(value) {
        this.setState({
            password: value
        });
    }

    setConfirmPassword(value) {
        this.setState({
            confirmPassword: value
        });
    }

    submit(e) {
        e.preventDefault();

        const { resetPasswordAction } = this.props;
        const { email, key, password, confirmPassword } = this.state;

        const errors = [];

        const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
        );
        if (password && !regex.test(password)) errors.push("passwordTooWeak");
        if (!password) errors.push("passwordRequired");
        if (password !== confirmPassword) errors.push("passwordMismatch");

        this.setState({
            errors
        });
        if (errors.length > 0) return;

        resetPasswordAction(email, key, password);
    }

    render() {
        const { auth } = this.props;
        const { errors } = this.state;

        if (!auth.authCheck) {
            return <Loader />;
        }

        if (auth.passwordReset) {
            return <Redirect to="/" />;
        }

        return (
            <div className="wrapper">
                <h1>Reset your password</h1>
                <div className="form-container">
                    <form
                        onSubmit={this.submit.bind(this)}
                        className="forgotten-password-form"
                    >
                        {auth.resetEmailSent && (
                            <p>
                                Thanks. We've sent you an email to reset your
                                password.
                            </p>
                        )}
                        {!auth.resetEmailSent && (
                            <>
                                <label>Email address</label>
                                <TextInput
                                    type="text"
                                    name="email"
                                    onInput={this.setEmail.bind(this)}
                                />

                                <div>
                                    <label htmlFor="password">
                                        Create a password
                                    </label>
                                    <p>
                                        <small>
                                            Passwords should be at least 10
                                            characters long, have at least one
                                            lowercase, uppercase, numeric and
                                            punctuation character
                                        </small>
                                    </p>
                                    {errors.find(
                                        (error) => error === "passwordRequired"
                                    ) && (
                                        <p className="error">
                                            Please enter a password
                                        </p>
                                    )}
                                    {errors.find(
                                        (error) => error === "passwordTooWeak"
                                    ) && (
                                        <p className="error">
                                            Your password is too weak, please
                                            try another
                                        </p>
                                    )}
                                    <TextInput
                                        type="password"
                                        onInput={this.setPassword.bind(this)}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="confirmPassword">
                                        Confirm your password
                                    </label>
                                    {errors.find(
                                        (error) => error === "passwordMismatch"
                                    ) && (
                                        <p className="error">
                                            Passwords do not match
                                        </p>
                                    )}
                                    <TextInput
                                        type="password"
                                        onInput={this.setConfirmPassword.bind(
                                            this
                                        )}
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="button primary"
                                >
                                    Reset password
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth
    }),
    {
        resetPasswordAction
    }
)(ResetPassword);

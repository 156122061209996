import React, { Component } from "react";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";

class UserAgreement extends Component {
    render() {
        return (
            <>
                <HighlightedContent
                    small
                    headline="Fiinect User Agreement"
                ></HighlightedContent>
                <MainContent>
                    <div className="container">
                        <h2>1. Introduction</h2>
                        <p>1.1 Contract</p>
                        <p>
                            When you use our Services you agree to all of these
                            terms. Your use of our Services is also subject to
                            our Cookie Policy and our Privacy Policy, which
                            covers how we collect, use, share, and store your
                            personal information.
                        </p>
                        <p>
                            You agree that by clicking "Join Fiinect", "Sign Up"
                            or similar or accepting an invitation to Join
                            Fiinect, you are agreeing to enter into a legally
                            binding contract with Fiinect (even if you are using
                            our Services on behalf of a company). If you do not
                            agree to this contract ("Contract" or "User
                            Agreement"), do not click "Join Now" (or similar)
                            and do not access or otherwise use any of our
                            Services. If you wish to terminate this contract, at
                            any time you can do so by closing your account and
                            no longer accessing or using our Services.
                        </p>
                        <p>Services</p>
                        <p>
                            This Contract applies to Fiinect.com as well as any
                            Fiinect-branded apps, communications and other
                            services that state that they are offered under this
                            Contract ("Services"), including the offsite
                            collection of data for those Services, such as our
                            ads and any future Fiinect" plugins. Registered
                            users of our Services are "Members" and unregistered
                            users are "Visitors".
                        </p>
                        <p>Fiinect</p>
                        <p>
                            You are entering into this Contract with Fiinect Ltd
                            (also referred to as "we" and "us").
                        </p>
                        <p>This Contract applies to Members and Visitors.</p>
                        <p>
                            As a Visitor or Member of our Services, the
                            collection, use and sharing of your personal data is
                            subject to this Privacy Policy (which includes
                            our Cookie Policy and other documents referenced in
                            this Privacy Policy) and updates.
                        </p>
                        <p>1.2 Members and Visitors</p>
                        <p>
                            When you register and join the Fiinect Services, you
                            become a Member. If you have chosen not to register
                            for our Services, you may access certain features as
                            a "Visitor."
                        </p>
                        <p>1.3 Change</p>
                        <p>We may make changes to the Contract.</p>
                        <p>
                            We may modify this Contract, our Privacy Policy and
                            our Cookies Policy from time to time. If we make
                            material changes to it, we will provide you notice
                            through our Services, or by other means, to provide
                            you the opportunity to review the changes before
                            they become effective. We agree that changes cannot
                            be retroactive. If you object to any changes, you
                            may close your account. Your continued use of our
                            Services after we publish or send a notice about our
                            changes to these terms means that you are consenting
                            to the updated terms as of their effective date.
                        </p>
                        <h2>2. Obligations</h2>
                        <p>2.1 Service Eligibility</p>
                        <p>
                            Here are some promises that you make to us in this
                            Contract:
                        </p>
                        <p>
                            You're eligible to enter into this Contract and you
                            are at least our "Minimum Age."
                        </p>
                        <p>
                            The Services are not for use by anyone under the age
                            of 16.
                        </p>
                        <p>
                            To use the Services, you agree that: (1) you must be
                            the "Minimum Age"(described above) or older; (2) you
                            will only have one Fiinect account, which must be in
                            your real name; and (3) you are not already
                            restricted by Fiinect from using the Services.
                            Creating an account with false information is a
                            violation of our terms, including accounts
                            registered on behalf of others or persons under the
                            age of 16.
                        </p>
                        <p>
                            "Minimum Age" means 16 years old. However, if law
                            requires that you must be older in order for Fiinect
                            to lawfully provide the Services to you without
                            parental consent (including using of your personal
                            data) then the Minimum Age is such older age.
                        </p>
                        <p>2.2 Your Account</p>
                        <p>You will keep your password a secret</p>
                        <p>
                            You will not share an account with anyone else and
                            will follow our rules and the law.
                        </p>
                        <p>
                            Members are account holders. You agree to: (1) use a
                            strong password and keep it confidential; (2) not
                            transfer any part of your account (e.g.,
                            connections). You are responsible for anything that
                            happens through your account unless you close it or
                            report misuse.
                        </p>
                        <p>
                            Your account belongs to you. However, if the Premium
                            Services were purchased by another party for you to
                            use, the party paying for such Service has the right
                            to control access to and get reports on your use of
                            such paid Service; however, they do not have rights
                            to your personal account
                        </p>
                        <p>2.3 Payment</p>
                        <p>
                            You'll honour your payment obligations and you are
                            okay with us storing your payment information. You
                            understand that there may be fees and taxes that are
                            added to our prices.
                        </p>
                        <p>Refunds are subject to our policy.</p>
                        <p>
                            If you buy any of our paid Services ("Premium
                            Services"), you agree to pay us the applicable fees
                            and taxes and to terms specific to the paid
                            Services. Failure to pay these fees will result in
                            the termination of your paid Services. Also, you
                            agree that:
                        </p>
                        <p>
                            Your purchase may be subject to foreign exchange
                            fees or differences in prices based on location
                            (e.g. exchange rates).
                        </p>
                        <p>
                            We may store and continue billing your payment
                            method (e.g. credit card) even after it has expired,
                            to avoid interruptions in your Services and to use
                            to pay other Services you may buy.
                        </p>
                        <p>
                            If you purchase a subscription, your payment method
                            automatically will be charged at the start of each
                            subscription period for the fees and taxes
                            applicable to that period. To avoid future charges,
                            cancel before the renewal date.
                        </p>
                        <p>
                            We may calculate taxes payable by you based on the
                            billing information that you provide us at the time
                            of purchase.
                        </p>
                        <p>
                            You can get a copy of your invoice through your
                            Fiinect.
                        </p>
                        <p>2.4 Notices and Messages</p>
                        <p>
                            You're okay with us providing notices and messages
                            to you through our websites, apps, and contact
                            information. If your contact information is out of
                            date, you may miss out on important notices.
                        </p>
                        <p>
                            You agree that we will provide notices and messages
                            to you in the following ways: (1) within the
                            Service, or (2) sent to the contact information you
                            provided us (e.g., email, mobile number). You agree
                            to keep your contact information up to date.
                        </p>
                        <p>
                            Please review your settings regularly to ensure they
                            are correct.
                        </p>
                        <p>2.5 Sharing</p>
                        <p>
                            When you share information on our Services, others
                            can see, copy and use that information.
                        </p>
                        <p>
                            Our Services allow messaging and sharing of
                            information in many ways, such as your profile,
                            articles, business opportunities, links to news
                            articles, messages and eMails. Information and
                            content that you share or post will be seen by other
                            Members. Where we have made settings available, we
                            will honour the choices you make about who can see
                            content or information (e.g., message content to
                            your addressees, sharing content only to Fiinect
                            connections, restricting your profile visibility, or
                            opting not to notify others of your Fiinect profile
                            update.{" "}
                        </p>
                        <p>
                            We are not obligated to publish any information or
                            content on our Service and can remove it with or
                            without notice.
                        </p>
                        <p>Key terms </p>
                        <h2>3. Rights and Limits</h2>
                        <p>3.1. Your License to Fiinect</p>
                        <p>
                            You own all of the content you create, all the
                            feedback and personal information you provide to us,
                            but you also grant us a non-exclusive license to it.{" "}
                        </p>
                        <p>
                            As between you and Fiinect, you own the content and
                            information that you submit or post to the Services,
                            and you are granting Fiinect the following
                            non-exclusive license:
                        </p>
                        <p>
                            A worldwide, transferable and sublicensable right to
                            use, copy, modify, distribute, publish and process,
                            information and content that you provide through our
                            Services and the services of others, without any
                            further consent, notice and/or compensation to you
                            or others. These rights are limited in the following
                            ways:
                        </p>
                        <p>
                            You can end this license by closing your account but
                            content which has already been shared with others as
                            part of the Service and re-shared will remain until
                            you request it is removed.
                        </p>
                        <p>
                            We will not include your content in advertisements
                            for the products and services of third parties to
                            others without your separate consent (including
                            sponsored content). However, we have the right,
                            without payment to you or others, to serve ads near
                            your content and information, and your social
                            actions may be visible and included with ads, as
                            noted in the Privacy Policy. If you use a Service
                            feature, we may mention that with your name or photo
                            to promote that feature within our Services, subject
                            to your settings.
                        </p>
                        <p>
                            We will get your consent if we want to give others
                            the right to publish your content beyond the
                            Services. However, if you choose to share your post
                            as "public, everyone or similar" we enable search
                            engines to make that public content findable though
                            their services. {" "}
                        </p>
                        <p>
                            While we may edit and make format changes to your
                            content (such as translating or transcribing it,
                            modifying the size, layout or file type or removing
                            metadata), we will not modify the meaning of your
                            expression.
                        </p>
                        <p>
                            You and Fiinect agree that if content includes
                            personal data, it is subject to our Privacy Policy.
                        </p>
                        <p>
                            You and Fiinect agree that we may access, store,
                            process and use any information and personal data
                            that you provide in accordance with, the terms of
                            the Privacy Policy and your choices (including
                            settings).
                        </p>
                        <p>
                            By submitting suggestions or other feedback
                            regarding our Services to Fiinect, you agree that
                            Fiinect can use and share (but does not have to)
                            such feedback for any purpose without compensation
                            to you.
                        </p>
                        <p>
                            You promise to only provide information and content
                            that you have the right to share, and that your
                            Fiinect profile will be truthful.
                        </p>
                        <p>
                            You agree to only provide content or information
                            that does not violate the law nor anyone's rights
                            (including intellectual property rights). You also
                            agree that your profile information will be
                            truthful. Fiinect may be required by law to remove
                            certain information or content in certain countries.
                        </p>
                        <p>3.2 Service Availability</p>
                        <p>
                            We may change or end any Service or modify our
                            prices prospectively.
                        </p>
                        <p>
                            We may change, suspend or discontinue any of our
                            Services. We may also modify our prices effective
                            prospectively upon reasonable notice to the extent
                            allowed under the law.
                        </p>
                        <p>
                            We don't promise to store or keep showing any
                            information and content that you've posted. Fiinect
                            is not a storage service. You agree that we have no
                            obligation to store, maintain or provide you a copy
                            of any content or information that you or others
                            provide, except to the extent required by applicable
                            law and as noted in our Privacy Policy.
                        </p>
                        <p>3.3 Other Content, Sites and Apps</p>
                        <p>
                            Your use of others' content and information posted
                            on our Services, is at your own risk.
                        </p>
                        <p>
                            Others may offer their own products and services
                            through our Services, and we aren't responsible for
                            those third-party activities.
                        </p>
                        <p>
                            By using the Services, you may encounter content or
                            information that might be inaccurate, incomplete,
                            delayed, misleading, illegal, offensive or otherwise
                            harmful. Fiinect generally does not review content
                            provided by our Members or others or content on
                            links to third party sites. You agree that we are
                            not responsible for others' (including other
                            Members') content or information. We cannot always
                            prevent this misuse of our Services, and you agree
                            that we are not responsible for any such misuse. You
                            also acknowledge the risk that you or your
                            organization may be mistakenly associated with
                            content about others when we let connections and
                            followers know you or your organization were
                            mentioned in the news.{" "}
                        </p>
                        <p>
                            The Fiinect site will also link you to other sites
                            on the Internet or otherwise include references to
                            companies, information, documents, software,
                            materials and/or services provided by other parties.
                            These sites may contain information or material that
                            some people may find inappropriate or offensive.
                            These other sites and parties are not under our
                            control, and you acknowledge that we are not
                            responsible for the accuracy, copyright compliance,
                            legality, decency, or any other aspect of the
                            content of such sites, nor are we responsible for
                            errors or omissions in any references to other
                            parties or their products and services. The
                            inclusion of such a link or reference is provided
                            merely as a convenience and does not imply
                            endorsement of, or association with, the site or
                            party by us, or any warranty of any kind, either
                            express or implied
                        </p>
                        <p>
                            In respect of the above, there is more detail in the
                            section 'Disclaimer of Warranties' and to clarify
                            without doubt, Fiinect is not a finance company but
                            is a technology company specialising in
                            communication and aggregation of business & finance
                            sites. It does NOT assess or verify any information,
                            whether it is shared privately across the member
                            Networks on Fiinect or links to other third party
                            sites & so does not endorse any User or Third party
                            data on Fiinect and so this unequivocally does not
                            imply endorsement of, or association with, any site
                            or party by us, or any warranty of any kind, either
                            express or implied.{" "}
                        </p>
                        <p>
                            Fiinect may help connect Members offering their
                            services (career coaching, accounting, etc.) through
                            the Fiinect Marketplace with Members seeking
                            services. Fiinect does not perform nor employs
                            individuals to perform these services. You must be
                            at least 18 years of age to offer, perform or
                            procure these services. You acknowledge that Fiinect
                            does not supervise, direct, control or monitor
                            Members in the performance of these services and
                            agree that (1) Fiinect is not responsible for the
                            offering, performance or procurement of these
                            services, (2) Fiinect does not endorse any
                            particular Member's offered services, and (3)
                            nothing shall create an employment, agency, or joint
                            venture relationship between Fiinect and any Member
                            offering services. If you are a Member offering
                            services, you represent and warrant that you have
                            all the required licenses to provide the services in
                            accordance with your professional body standards.{" "}
                        </p>
                        <p>
                            You agree that (1) Fiinect is not responsible for
                            the conduct of any of the Members (2) Fiinect does
                            not endorse any particular event listed on our site,
                            (3) Fiinect does not review and/or vet any of these
                            events.
                        </p>
                        <p>3.4 Limits</p>
                        <p>
                            We have the right to limit how you connect and
                            interact on our Services.
                        </p>
                        <p>
                            Fiinect reserves the right to limit your use of the
                            Services, including the number of your connections
                            and your ability to contact other Members. Fiinect
                            reserves the right to restrict, suspend, or
                            terminate your account if you breach this Contract
                            or the law or are misusing the Services.
                        </p>
                        <p>3.5 Intellectual Property Rights</p>
                        <p>
                            We're providing you notice about our intellectual
                            property rights.
                        </p>
                        <p>
                            Fiinect reserves all of its intellectual property
                            rights & service marks in the Services.{" "}
                        </p>
                        <p>3.6 Automated Processing</p>
                        <p>
                            We use data and information about you to make
                            relevant suggestions to you and others.
                        </p>
                        <p>
                            We use the information and data that you provide and
                            that we have about Members to make recommendations
                            for connections, business opportunities, content and
                            features that may be useful to you. Keeping your
                            profile accurate and up to date helps us to make
                            these recommendations more accurate and relevant.
                        </p>
                        <p>Key Terms </p>
                        <h2>4. Disclaimer and Limit of Liability</h2>
                        <p>.1 No Warranty</p>
                        <p>
                            This is our disclaimer of legal liability for the
                            quality, safety, or reliability of our Services.
                        </p>
                        <p>
                            ALL MATERIALS AND SERVICES ON THIS SITE ARE PROVIDED
                            ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT
                            WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                            WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                            PARTICULAR PURPOSE, OR THE WARRANTY OF
                            NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE
                            MAKE NO WARRANTY THAT (A) THE SERVICES AND MATERIALS
                            WILL MEET YOUR REQUIREMENTS, (B) THE SERVICES AND
                            MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                            ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED
                            FROM THE USE OF THE SERVICES OR MATERIALS WILL BE
                            EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY
                            OF ANY PRODUCTS, SERVICES, OR INFORMATION PURCHASED
                            OR OBTAINED BY YOU FROM THE SITE FROM US OR OUR
                            AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE
                            FROM MISTAKES, ERRORS OR DEFECTS.
                        </p>
                        <p>
                            THIS SITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES,
                            INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE
                            CHANGES TO THE MATERIALS AND SERVICES AT THIS SITE,
                            INCLUDING THE PRICES AND DESCRIPTIONS OF ANY
                            PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE.
                            THE MATERIALS OR SERVICES AT THIS SITE MAY BE OUT OF
                            DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH
                            MATERIALS OR SERVICES.
                        </p>
                        <p>
                            THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER
                            ACQUISITION OF ANY MATERIALS THROUGH THIS SITE IS
                            DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR
                            AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR
                            ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
                            THAT RESULTS FROM SUCH ACTIVITIES.
                        </p>
                        <p>
                            Through your use of the site, you may have the
                            opportunities to engage in commercial transactions
                            with other users and vendors. You acknowledge that
                            all transactions relating to any merchandise or
                            services offered by any party, including, but not
                            limited to the purchase terms, payment terms,
                            warranties, guarantees, maintenance and delivery
                            terms relating to such transactions, are agreed to
                            solely between the seller or purchaser of such
                            merchandize and services and you.
                        </p>
                        <p>
                            WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS
                            EXECUTED THROUGH, OR IN CONNECTION WITH THIS SITE,
                            AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS
                            ARE CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY
                            WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY
                            PRODUCTS, SERVICES, MATERIALS, OR INFORMATION
                            AVAILABLE ON OR THROUGH THIS SITE FROM A THIRD PARTY
                            IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY
                            US OR ANY OTHER OF OUR AFFILIATES.
                        </p>
                        <p>
                            Content available through this site often represents
                            the opinions and judgments of an information
                            provider, site user, or other person or entity not
                            connected with us. We do not endorse, nor are we
                            responsible for the accuracy or reliability of, any
                            opinion, advice, or statement made by anyone other
                            than an authorized Fiinect person  speaking in
                            his/her official capacity. You understand and agree
                            that the services available on this site are
                            provided "AS IS" and that we assume no
                            responsibility for the timeliness, accuracy,
                            deletion, mis-delivery or failure to store any user
                            communications or personalization settings.
                        </p>
                        <p>4.2 Exclusion of Liability</p>
                        <p>
                            These are the limits of legal liability we may have
                            to you.
                        </p>
                        <p>
                            To the fullest extent permitted by law (and unless
                            Fiinect has entered into a separate written
                            agreement that overrides this contract), Fiinect,
                            including its affiliates, will not be liable in
                            connection with this contract for lost profits or
                            lost business opportunities, reputation (e.g.,
                            offensive or defamatory statements), loss of data
                            (e.g., down time or loss, use of, or changes to,
                            your information or content) or any indirect,
                            incidental, consequential, special or punitive
                            damages.
                        </p>
                        <p>
                            Fiinect and its affiliates will not be liable to you
                            in connection with this contract for any amount that
                            exceeds (a) the total fees paid or payable by you to
                            Fiinect for the services during the term of this
                            contract, if any, or (b) UK £1000.
                        </p>
                        <p>4.3 Basis of the Bargain; Exclusions</p>
                        <p>
                            The limitations of liability in this Section 4 are
                            part of the basis of the bargain between you and
                            Fiinect and shall apply to all claims of liability
                            (e.g., warranty, tort, negligence, contract and law)
                            even if Fiinect or its affiliates has been told of
                            the possibility of any such damage, and even if
                            these remedies fail their essential purpose.
                        </p>
                        <p>
                            These limitations of liability do not apply to
                            liability for death or personal injury or for fraud,
                            gross negligence or intentional misconduct, or in
                            cases of negligence where a material obligation has
                            been breached, a material obligation being such
                            which forms a prerequisite to our delivery of
                            services and on which you may reasonably rely, but
                            only to the extent that the damages were directly
                            caused by the breach and were foreseeable upon
                            conclusion of this Contract and to the extent that
                            they are typical in the context of this Contract.
                        </p>
                        <h2>5. Termination</h2>
                        <p>
                            We can each end this Contract, but some rights and
                            obligations survive.
                        </p>
                        <p>
                            Both you and Fiinect may terminate this Contract at
                            any time with notice to the other. On termination,
                            you lose the right to access or use the Services.
                            The following shall survive termination:
                        </p>
                        <p>Our rights to use and disclose your feedback;</p>
                        <p>
                            Members and/or Visitors' rights to further re-share
                            content and information you shared through the
                            Services;
                        </p>
                        <p>Sections 4, 6, 7, and 8.2 of this Contract;</p>
                        <p>
                            Any amounts owed by either party prior to
                            termination remain owed after termination.
                        </p>
                        <p>
                            You can email us on info@Fiinect.com to close your
                            account.
                        </p>
                        <h2>6. Governing Law and Dispute Resolution</h2>
                        <p>
                            In the unlikely event we end up in a legal dispute,
                            these terms and conditions will be governed by and
                            construed in accordance with UK law, and any
                            disputes relating to these terms and conditions will
                            be subject to the non-exclusive jurisdiction of the
                            courts of England and Wales, Scotland and Northern
                            Ireland. and you and Fiinect each agree to personal
                            jurisdiction in those courts
                        </p>
                        <h2>7. General Terms</h2>
                        <p>
                            Here are some important details about the Contract.
                        </p>
                        <p>
                            If a court with authority over this Contract finds
                            any part of it unenforceable, you and we agree that
                            the court should modify the terms to make that part
                            enforceable while still achieving its intent. If the
                            court cannot do that, you and we agree to ask the
                            court to remove that unenforceable part and still
                            enforce the rest of this Contract.
                        </p>
                        <p>
                            This Contract (including additional terms that may
                            be provided by us when you engage with a feature of
                            the Services) is the only agreement between us
                            regarding the Services and supersedes all prior
                            agreements for the Services.
                        </p>
                        <p>
                            If we don't act to enforce a breach of this
                            Contract, that does not mean that Fiinect has waived
                            its right to enforce this Contract. You may not
                            assign or transfer this Contract (or your membership
                            or use of Services) to anyone without our consent.
                            However, you agree that Fiinect may assign this
                            Contract to its affiliates or a party that buys it
                            without your consent. There are no third-party
                            beneficiaries to this Contract.
                        </p>
                        <p>
                            You agree that the only way to provide us legal
                            notice is at the addresses provided in Section 10.
                        </p>
                        <h2>8. Fiinect "Dos and Don'ts"</h2>
                        <p>
                            Fiinect is a community of professionals. This list
                            of "Dos and Don'ts" of what you can and cannot do on
                            our Services.
                        </p>
                        <p>8.1. Dos</p>
                        <p>You agree that you will:</p>
                        <p>
                            Comply with all applicable laws, including, without
                            limitation, privacy laws, intellectual property
                            laws, anti-spam laws, export control laws, tax laws,
                            and regulatory requirements;
                        </p>
                        <p>
                            Provide accurate information to us and keep it
                            updated;
                        </p>
                        <p>Use your real name on your profile; and</p>
                        <p>Use the Services in a professional manner.</p>
                        <p>8.2. Don'ts</p>
                        <p>You agree that you will not:</p>
                        <p>
                            Create a false identity on Fiinect, misrepresent
                            your identity, create a Member profile for anyone
                            other than yourself (a real person), or use or
                            attempt to use another's account;
                        </p>
                        <p>
                            Develop, support or use software, devices, scripts,
                            robots or any other means or processes (including
                            crawlers, browser plugins and add-ons or any other
                            technology) to scrape the Services or otherwise copy
                            profiles and other data from the Services;
                        </p>
                        <p>
                            Override any security feature or bypass or
                            circumvent any access controls or use limits of the
                            Service (such as caps on keyword searches or profile
                            views);
                        </p>
                        <p>
                            Copy, use, disclose or distribute any information
                            obtained from the Services, whether directly or
                            through third parties (such as search engines),
                            without the consent of Fiinect;
                        </p>
                        <p>
                            Disclose information that you do not have the
                            consent to disclose (such as confidential
                            information of others
                        </p>
                        <p>
                            Violate the intellectual property rights of others,
                            including copyrights, patents, trademarks, trade
                            secrets or other proprietary rights. For example, do
                            not copy or distribute (except through the available
                            sharing functionality) the posts or other content of
                            others without their permission.
                        </p>
                        <p>
                            Violate the intellectual property or other rights of
                            Fiinect, including, without limitation, (i) copying
                            or distributing our learning videos or other
                            materials or (ii) copying or distributing our
                            technology, unless it is released under open source
                            licenses; (iii) using the word "Fiinect" or our
                            logos in any business name, email, or URL except as
                            provided in the normal course of using the Fiinect
                            services.  
                        </p>
                        <p>
                            Post anything that contains software viruses, worms,
                            or any other harmful code;
                        </p>
                        <p>
                            Reverse engineer, decompile, disassemble, decipher
                            or otherwise attempt to derive the source code for
                            the Services or any related technology that is not
                            open source;
                        </p>
                        <p>
                            Imply or state that you are affiliated with or
                            endorsed by Fiinect without our express consent.
                        </p>
                        <p>
                            Rent, lease, loan, trade, sell/re-sell or otherwise
                            monetize the Services or related data or access to
                            the same, without Fiinect's consent;
                        </p>
                        <p>
                            Deep-link to our Services for any purpose other than
                            to promote your profile or a Group on our Services,
                            without Fiinect's consent;
                        </p>
                        <p>
                            Use bots or other automated methods to access the
                            Services, add or download contacts, send or redirect
                            messages;
                        </p>
                        <p>
                            Monitor the Services' availability, performance or
                            functionality for any competitive purpose;
                        </p>
                        <p>
                            Engage in "framing," "mirroring," or otherwise
                            simulating the appearance or function of the
                            Services;
                        </p>
                        <p>
                            Overlay or otherwise modify the Services or their
                            appearance (such as by inserting elements into the
                            Services or removing, covering, or obscuring an
                            advertisement included on the Services);
                        </p>
                        <p>
                            Interfere with the operation of, or place an
                            unreasonable load on, the Services (e.g., spam,
                            denial of service attack, viruses, gaming
                            algorithms); and/or
                        </p>
                        <h2>9. Complaints Regarding Content</h2>
                        <p>
                            Contact information for complaint about content
                            provided by our Members.
                        </p>
                        <p>
                            We respect the intellectual property rights of
                            others. We require that information posted by
                            Members be accurate and not in violation of the
                            intellectual property rights or other rights of
                            third parties. If there are any concerns in respect
                            of content posted by our members please email
                            info@fiinect.com
                        </p>
                        <h2>10. How To Contact Us</h2>
                        <p>
                            For general inquiries, you may email at
                            info@fiinect.com{" "}
                        </p>
                    </div>
                </MainContent>
            </>
        );
    }
}

export default UserAgreement;

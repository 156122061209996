import React, { Component } from "react";

import "./StepProgressBar.scss";

class StepProgressBar extends Component {
    render() {
        const { step } = this.props;

        return (
            <ol className="step-progress-bar">
                <li className={step === 1 ? "active" : ""}></li>
                <li className={step === 2 ? "active" : ""}></li>
                <li className={step === 3 ? "active" : ""}></li>
            </ol>
        );
    }
}

export default StepProgressBar;

import axios from "axios";

import { API_BASE } from "../../api";
import * as actionTypes from "../actionTypes";
import { getLoggedInUserDetailsAction } from "../auth/authActions";
import { getProfiledOpportunitiesAction } from "../opportunities/opportunityActions";
import { mapPerson } from "../../data/people";
import { setFocusedUserAction } from "../network/networkActions";

export const setSectorPreferencesAction = (sectorId, method, userId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            SectorId: sectorId,
            UserId: user.userId || userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/SectorsInterested`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setSectorPreferencesSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setSectorPreferencesErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/SectorsInterested`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setSectorPreferencesSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setSectorPreferencesErrorAction(error));
                });
        }
    };
};

export const setSectorPreferencesSuccessAction = () => ({
    type: actionTypes.SET_SECTOR_PREFERENCES_SUCCESS
});

export const setSectorPreferencesErrorAction = (error) => ({
    type: actionTypes.SET_SECTOR_PREFERENCES_ERROR,
    error
});

export const setSectorExpertiseAction = (sectorId, method, userId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            SectorId: sectorId,
            UserId: user.userId || userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/UserSectorExpertise`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setSectorExpertiseSuccessAction(data));
                })
                .catch((error) => {
                    dispatch(setSectorExpertiseErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/UserSectorExpertise`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setSectorExpertiseSuccessAction(data));
                })
                .catch((error) => {
                    dispatch(setSectorExpertiseErrorAction(error));
                });
        }
    };
};

export const setSectorExpertiseSuccessAction = () => ({
    type: actionTypes.SET_SECTOR_EXPERTISE_SUCCESS
});

export const setSectorExpertiseErrorAction = (error) => ({
    type: actionTypes.SET_SECTOR_EXPERTISE_ERROR,
    error
});

export const setFinanceStageExpertiseAction = (
    financeStageId,
    method,
    userId
) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            FinanceStageId: financeStageId,
            UserId: user.userId || userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/UserFinanceStageExpertise`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setFinanceStageExpertiseSuccessAction(data));
                })
                .catch((error) => {
                    dispatch(setFinanceStageExpertiseErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/UserFinanceStageExpertise`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setFinanceStageExpertiseSuccessAction(data));
                })
                .catch((error) => {
                    dispatch(setFinanceStageExpertiseErrorAction(error));
                });
        }
    };
};

export const setFinanceStageExpertiseSuccessAction = () => ({
    type: actionTypes.SET_FINANCE_STAGE_EXPERTISE_SUCCESS
});

export const setFinanceStageExpertiseErrorAction = (error) => ({
    type: actionTypes.SET_FINANCE_STAGE_EXPERTISE_ERROR,
    error
});

export const setUserBusinessTypeAction = (businessTypeId, method) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            BusinessTypeId: businessTypeId,
            UserId: user.userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/UserBusinessType`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserBusinessTypeSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                })
                .catch((error) => {
                    dispatch(setUserBusinessTypeErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/UserBusinessType`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserBusinessTypeSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                })
                .catch((error) => {
                    dispatch(setUserBusinessTypeErrorAction(error));
                });
        }
    };
};

export const setUserBusinessTypeSuccessAction = () => ({
    type: actionTypes.SET_USER_BUSINESS_TYPE_SUCCESS
});

export const setUserBusinessTypeErrorAction = (error) => ({
    type: actionTypes.SET_USER_BUSINESS_TYPE_ERROR,
    error
});

export const setUserFinanceStageAction = (financeStageId, method) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            FinanceStageId: financeStageId,
            UserId: user.userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/UserFinanceStage`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserFinanceStageSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setUserFinanceStageErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/UserFinanceStage`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserFinanceStageSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setUserFinanceStageErrorAction(error));
                });
        }
    };
};

export const setUserFinanceStageSuccessAction = () => ({
    type: actionTypes.SET_USER_FINANCE_STAGE_SUCCESS
});

export const setUserFinanceStageErrorAction = (error) => ({
    type: actionTypes.SET_USER_FINANCE_STAGE_ERROR,
    error
});

export const setUserLevelOfInvestmentAction = (levelOfInvestmentId, method) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            LevelOfInvestmentId: levelOfInvestmentId,
            UserId: user.userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/UserLevelInvestment`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserLevelOfInvestmentSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setUserLevelOfInvestmentErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/UserLevelInvestment`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserLevelOfInvestmentSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setUserLevelOfInvestmentErrorAction(error));
                });
        }
    };
};

export const setUserLevelOfInvestmentSuccessAction = () => ({
    type: actionTypes.SET_USER_LEVEL_OF_INVESTMENT_SUCCESS
});

export const setUserLevelOfInvestmentErrorAction = (error) => ({
    type: actionTypes.SET_USER_LEVEL_OF_INVESTMENT_ERROR,
    error
});

export const setLocationPreferencesAction = (locationId, method) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            locationid: locationId,
            UserId: user.userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/LocationsInterested`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setLocationPreferencesSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setLocationPreferencesErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/LocationsInterested`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setLocationPreferencesSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                    dispatch(getProfiledOpportunitiesAction());
                })
                .catch((error) => {
                    dispatch(setLocationPreferencesErrorAction(error));
                });
        }
    };
};

export const setLocationPreferencesSuccessAction = () => ({
    type: actionTypes.SET_LOCATION_PREFERENCES_SUCCESS
});

export const setLocationPreferencesErrorAction = (error) => ({
    type: actionTypes.SET_LOCATION_PREFERENCES_ERROR,
    error
});

export const updateUserDetailsAction = (changedDetails, preview = false) => {
    // TODO LOW move to auth actions?
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            id: user.id,
            FirstName: user.firstName,
            LastName: user.surname,
            Email: user.email,
            PhoneNumber: user.phoneNumber,
            ProfilePhoto: user.profilePhoto,
            matrixUserID: user.matrixUserID,
            MatrixAccessToken: user.matrixAccessToken,
            OccupationID: user.occupationId,
            LocationID: user.locationId,
            WantsPreRevenue: user.wantsPreRevenue,
            WantsProfitOnly: user.wantsProfitOnly,
            LinkedInProfile: user.linkedInProfile,
            Biography: user.biography,
            ProfileComplete: user.profileComplete,
            CurrencyPreference: user.currencyPreference,
            ...changedDetails
        };

        const accessToken = localStorage.getItem("accessToken");

        return axios
            .post(`${API_BASE}/Users/edit`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(updateUserDetailsSuccessAction());
                dispatch(getLoggedInUserDetailsAction(user));

                if (preview) {
                    dispatch(setFocusedUserAction(user));
                }
            })
            .catch((error) => {
                dispatch(updateUserDetailsErrorAction(error));
            });
    };
};

export const updateUserDetailsSuccessAction = () => ({
    type: actionTypes.UPDATE_USER_DETAILS_SUCCESS
});

export const updateUserDetailsErrorAction = () => ({
    type: actionTypes.UPDATE_USER_DETAILS_ERROR
});

export const uploadProfilePictureAction = (file) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const formData = new FormData();
        formData.append("profile", file);
        return axios
            .post(`${API_BASE}/Users/editphoto/${user.userId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(({ data }) => {
                dispatch(uploadProfilePictureSuccessAction());
                dispatch(getLoggedInUserDetailsAction(user));
            })
            .catch(({ error }) => {
                dispatch(uploadProfilePictureErrorAction(error));
            });
    };
};

export const uploadProfilePictureSuccessAction = () => ({
    type: actionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS
});

export const uploadProfilePictureErrorAction = (error) => ({
    type: actionTypes.UPLOAD_PROFILE_PICTURE_ERROR,
    error
});

export const setUserExpertiseAction = (expertiseId, method, userId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const body = {
            ExpertiseId: expertiseId,
            UserId: user.userId || userId
        };

        const accessToken = localStorage.getItem("accessToken");

        if (method === "delete") {
            return axios
                .delete(`${API_BASE}/UserExpertise`, {
                    data: {
                        ...body
                    },
                    crossdomain: true,
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserExpertiseSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                })
                .catch((error) => {
                    dispatch(setUserExpertiseErrorAction(error));
                });
        } else {
            return axios
                .post(`${API_BASE}/UserExpertise`, body, {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(({ data }) => {
                    dispatch(setUserExpertiseSuccessAction(data));
                    dispatch(getLoggedInUserDetailsAction(user));
                })
                .catch((error) => {
                    dispatch(setUserExpertiseErrorAction(error));
                });
        }
    };
};

export const setUserExpertiseSuccessAction = () => ({
    type: actionTypes.SET_USER_EXPERTISE_SUCCESS
});

export const setUserExpertiseErrorAction = (error) => ({
    type: actionTypes.SET_USER_EXPERTISE_ERROR,
    error
});

import React, { Component } from "react";
import { connect } from "react-redux";

import Loader from "../../components/loader/Loader";

import "./EditOpportunity.scss";

import {
    getMatchesForOpportunityAction,
    getOpportunityDetailsAction,
    resetDraftMatchesAction,
    shareOpportunityAction,
    editOpportunityAction,
    uploadDocumentAction,
    opportunityDocumentAction,
    removeDocumentAction
} from "../../actions/opportunities/opportunityActions";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import PlainCard from "../../components/plain-card/PlainCard";
import Button from "../../components/button/Button";
import OptionSelector from "../../components/option-selector/OptionSelector";
import RegionSelector from "../../components/region-selector/RegionSelector";
import RangeGroup from "../../components/range-group/RangeGroup";
import TextInput from "../../components/text-input/TextInput";
import MatchBox from "../../components/match-box/MatchBox";
import DocumentUploader from "../../components/document-uploader/DocumentUploader";

import { Redirect } from "react-router-dom";
import {
    employeeRanges,
    financeRoundOptions,
    timescaleOptions
} from "../../data/data";

class EditOpportunity extends Component {
    state = {
        id: "",
        detailsLoaded: false,
        timings: "",
        sectors: [],
        financeTypes: [],
        financeStages: [],
        locations: [],
        levelOfInvestment: 0,
        financeDontKnow: [],
        investmentBracket: 0,
        headline: "",
        summary: "",
        description: "",
        youtubeUrl: "",
        websiteUrl: "",
        invalidUrl: false,
        invalidYoutube: false,
        active: true,
        sharedToMatches: false,
        saved: false,
        numberOfEmployees: 1,
        MakingProfit: null,
        timescales: [],
        roundOfFinance: [],
        currency: [{ id: "GBP", value: "GBP" }],
        investorDeck: null,
        executiveBrochure: null
    };

    componentDidMount() {
        const {
            getOpportunityDetailsAction,
            resetDraftMatchesAction
        } = this.props;
        const id = this.props.opportunityId || this.props.match.params.id;

        resetDraftMatchesAction();
        getOpportunityDetailsAction(id);
    }

    componentDidUpdate() {
        const { detailsLoaded } = this.state;
        const { opportunityDetails, opportunityDocumentAction } = this.props;

        if (!detailsLoaded) {
            if (opportunityDetails.opportunityId) {
                const startDate = new Date(opportunityDetails.startDate);

                const financeRound = financeRoundOptions.find(
                    (o) => o.value === opportunityDetails.roundOfFinance
                );
                const timescales = timescaleOptions.find(
                    (o) => o.value === opportunityDetails.timescales
                );
                const numEmployees = employeeRanges.findIndex(
                    (o) => o === opportunityDetails.numberOfEmployees
                );

                this.setState({
                    id: opportunityDetails.opportunityId,
                    active: opportunityDetails.isActive,
                    detailsLoaded: true,
                    timings: startDate.getFullYear() === 3000 ? "Soon" : "Now",
                    sectors: [
                        {
                            id: opportunityDetails.sectorId
                        }
                    ],
                    financeTypes: [
                        {
                            ...opportunityDetails.financeType
                        }
                    ],
                    financeStages: [
                        {
                            ...opportunityDetails.stage
                        }
                    ],
                    locations: [opportunityDetails.country],
                    currency: opportunityDetails.currency
                        ? [
                              {
                                  id: opportunityDetails.currency,
                                  value: opportunityDetails.currency
                              }
                          ]
                        : this.state.currency,
                    investmentBracket: opportunityDetails.investmentBracket
                        ? parseInt(opportunityDetails.investmentBracket) - 1
                        : 0,
                    levelOfInvestment: opportunityDetails.levelOfInvestment,
                    financeDontKnow: opportunityDetails.investmentBracket
                        ? []
                        : [{ id: "", value: "I don't know yet" }],
                    headline: opportunityDetails.headline,
                    summary: opportunityDetails.summary,
                    description: opportunityDetails.opportunityDetails,
                    youtubeUrl: opportunityDetails.youtubeId
                        ? `youtu.be/${opportunityDetails.youtubeId}`
                        : "",
                    websiteUrl: opportunityDetails.webLink,
                    executiveBrochure: opportunityDetails.executiveBrochure
                        ? [opportunityDetails.executiveBrochure]
                        : null,
                    investorDeck: opportunityDetails.investorDeck
                        ? [opportunityDetails.investorDeck]
                        : null,

                    numberOfEmployees: numEmployees
                        ? parseInt(numEmployees.id) - 1
                        : 0,
                    MakingProfit: opportunityDetails.MakingProfit,
                    timescales: timescales
                        ? [
                              {
                                  id: timescales.id,
                                  value: opportunityDetails.timescales
                              }
                          ]
                        : [],
                    roundOfFinance: financeRound
                        ? [
                              {
                                  id: financeRound.id,
                                  value: opportunityDetails.roundOfFinance
                              }
                          ]
                        : []
                });

                getMatchesForOpportunityAction(this.state);

                if (opportunityDetails.executiveBrochure)
                    opportunityDocumentAction(
                        opportunityDetails.executiveBrochure,
                        "executiveBrochure"
                    );
                if (opportunityDetails.investorDeck)
                    opportunityDocumentAction(
                        opportunityDetails.investorDeck,
                        "investorDeck"
                    );
                if (
                    opportunityDetails.documents &&
                    opportunityDetails.documents.length > 0
                ) {
                    opportunityDetails.documents.forEach((document) => {
                        opportunityDocumentAction(document, "documents");
                    });
                }
            }
        }
    }

    setTimings(timings) {
        this.setState({
            timings
        });
    }

    update(key, value) {
        const { getMatchesForOpportunityAction } = this.props;
        if (typeof value === "boolean") {
            this.setState({
                [key]: value
            });
        } else {
            const arr1 = this.state[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));

            this.setState({
                [key]: additions
            });

            getMatchesForOpportunityAction({
                ...this.state,
                [key]: additions
            });
        }
    }

    updateRange(lookup, key, value, lookupValue, lookupIdValue) {
        const { getMatchesForOpportunityAction } = this.props;
        let updateValue = value;

        this.setState({
            [key]: lookupIdValue
        });

        getMatchesForOpportunityAction({
            ...this.state,
            [key]: updateValue
        });
    }

    updateEmployees(lookup, key, value) {
        this.setState({
            [key]: lookup[value]
        });
    }

    updateText(key, value) {
        this.setState({
            [key]: value
        });
    }

    updateUrl(key, value) {
        const urlValidator = new RegExp(
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
        );
        if (value !== "" && !urlValidator.test(value)) {
            this.setState({
                invalidUrl: true
            });
        } else {
            this.setState({
                invalidUrl: false,
                [key]: value
            });
        }
    }

    updateYoutube(key, value) {
        const urlValidator = new RegExp(
            /^(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+)/i
        );
        const match = value.match(urlValidator);

        if (value !== "" && !match) {
            this.setState({
                invalidYoutube: true
            });
        } else {
            this.setState({
                invalidYoutube: false,
                [key]: match[1]
            });
        }
    }

    shareToMatches() {
        const {
            shareOpportunityAction,
            editdOpportunityId,
            draftMatches
        } = this.props;

        shareOpportunityAction(
            { opportunityId: editdOpportunityId },
            draftMatches.map((match) => match.id)
        );
        this.setState({
            sharedToMatches: true
        });
    }

    saveChanges() {
        const { editOpportunityAction } = this.props;

        editOpportunityAction(this.state);
        this.setState({
            saved: true
        });
    }

    removeDocument(id, key) {
        this.setState({
            [key]: null
        });
    }

    render() {
        const {
            auxillary,
            draftMatches,
            opportunityDetails,
            uploadDocumentAction,
            removeDocumentAction,
            documentUploading,
            documents,
            executiveBrochure,
            investorDeck,
            currentUser
        } = this.props;
        const {
            timings,
            sectors,
            financeTypes,
            financeStages,
            locations,
            levelOfInvestment,
            investmentBracket,
            headline,
            summary,
            description,
            youtubeUrl,
            websiteUrl,
            sharedToMatches,
            invalidUrl,
            invalidYoutube,
            saved,
            currency,
            MakingProfit,
            timescales,
            roundOfFinance,
            numberOfEmployees,
            financeDontKnow
        } = this.state;

        if (saved) {
            return (
                <Redirect
                    to={`${process.env.PUBLIC_URL}/opportunities/${opportunityDetails.opportunityId}`}
                />
            );
        }

        if (
            opportunityDetails.broker &&
            opportunityDetails.broker.id !== currentUser.id
        ) {
            return <Redirect to={process.env.PUBLIC_URL} />;
        }

        if (!opportunityDetails.opportunityId) {
            return <Loader />;
        }
        return (
            <div className="home-container">
                <HighlightedContent
                    headline={`Editing "${opportunityDetails.headline}"`}
                    small
                ></HighlightedContent>
                <MainContent>
                    <div className="edit-opportunity">
                        <div className="timings">
                            <p>I am...</p>
                            <PlainCard
                                onClick={this.setTimings.bind(this, "Soon")}
                                className={timings === "Soon" ? "selected" : ""}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/soon.png`}
                                    alt="Looking for finance soon"
                                />
                                Looking for finance <strong>soon</strong>
                            </PlainCard>
                            <PlainCard
                                onClick={this.setTimings.bind(this, "Now")}
                                className={timings === "Now" ? "selected" : ""}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/now.png`}
                                    alt="Looking for finance now"
                                />
                                Looking for finance <strong>now</strong>
                            </PlainCard>
                        </div>
                        <div className="edit-opportunity-form">
                            <article>
                                <small>
                                    <span className="mandatory">*</span>{" "}
                                    mandatory
                                </small>

                                <h4>
                                    {sectors.length === 0 && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> Select
                                    a sector
                                </h4>
                                <OptionSelector
                                    selectedValues={sectors}
                                    options={auxillary.sectorInterests}
                                    sectorVariant
                                    imageFormat="svg"
                                    action={this.update.bind(this, "sectors")}
                                />
                                <h4>
                                    {financeTypes.length === 0 && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> Type of
                                    finance
                                </h4>
                                <OptionSelector
                                    selectedValues={financeTypes}
                                    options={auxillary.financeTypes}
                                    action={this.update.bind(
                                        this,
                                        "financeTypes"
                                    )}
                                />

                                {/* <h4>
                                    {financeStages.length === 0 && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> Stage
                                    of finance
                                </h4>
                                <OptionSelector
                                    selectedValues={financeStages}
                                    options={auxillary.financeStages}
                                    action={this.update.bind(
                                        this,
                                        "financeStages"
                                    )}
                                /> */}

                                <h4>
                                    {locations.length === 0 && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span>{" "}
                                    Location of the opportunity
                                </h4>

                                {locations.length > 0 && (
                                    <RegionSelector
                                        // ewww
                                        initialRegions={[
                                            {
                                                location: {
                                                    country:
                                                        locations.length > 0
                                                            ? locations[0]
                                                            : null
                                                }
                                            }
                                        ]}
                                        onRegionToggle={this.update.bind(
                                            this,
                                            "locations"
                                        )}
                                    />
                                )}

                                <h4>
                                    {!levelOfInvestment && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> Finance
                                    required
                                </h4>
                                <>
                                    {timings === "Soon" && (
                                        <OptionSelector
                                            selectedValues={financeDontKnow}
                                            options={[
                                                {
                                                    id: "",
                                                    value: "I don't know yet"
                                                }
                                            ]}
                                            action={this.update.bind(
                                                this,
                                                "financeDontKnow"
                                            )}
                                            vertical
                                            noIcon
                                        ></OptionSelector>
                                    )}
                                    {(financeDontKnow.length === 0 ||
                                        timings === "Now") && (
                                        <>
                                            <OptionSelector
                                                selectedValues={currency}
                                                options={Object.keys(
                                                    auxillary.levelOfInvestment
                                                ).map((key) => ({
                                                    id: key,
                                                    value: key
                                                }))}
                                                action={this.update.bind(
                                                    this,
                                                    "currency"
                                                )}
                                                noIcon
                                                vertical
                                                preventDeselect
                                            />
                                            <RangeGroup
                                                smallIcon={`${process.env.PUBLIC_URL}/assets/pennies.png`}
                                                largeIcon={`${process.env.PUBLIC_URL}/assets/notes.png`}
                                                initialValue={
                                                    isNaN(investmentBracket)
                                                        ? 0
                                                        : investmentBracket
                                                }
                                                idKey="financeRequired"
                                                onChange={this.updateRange.bind(
                                                    this,
                                                    auxillary.levelOfInvestment[
                                                        currency[0].id
                                                    ],
                                                    "levelOfInvestment"
                                                )}
                                                lookup={auxillary.levelOfInvestment[
                                                    currency[0].id
                                                ].map((level) => level.value)}
                                                lookupId={auxillary.levelOfInvestment[
                                                    currency[0].id
                                                ].map((level) => level.id)}
                                                notches
                                            />
                                        </>
                                    )}
                                </>

                                <h4>
                                    {!headline && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> Title
                                    of your opportunity
                                </h4>
                                <p>
                                    Top-line description of the opportunity in
                                    one sentence. Try and include all the
                                    important keywords here.
                                </p>
                                <TextInput
                                    type="text"
                                    placeholder={headline}
                                    onInput={this.updateText.bind(
                                        this,
                                        "headline"
                                    )}
                                />

                                <h4>
                                    {!summary && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> Brief
                                    summary
                                </h4>
                                <p>
                                    This paragraph will appear on the
                                    opportunity's info card to give prospective
                                    investors an at-a-glance understandng of
                                    your offer
                                </p>
                                <TextInput
                                    type="text"
                                    multiline
                                    placeholder={summary}
                                    onInput={this.updateText.bind(
                                        this,
                                        "summary"
                                    )}
                                />

                                <h4>
                                    {!description && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span> A
                                    detailed description of the opportunity
                                </h4>
                                <p>
                                    This text will appear on an opportunity page
                                    to give prospective investors a more
                                    in-depth understanding of the ooffer
                                </p>
                                <TextInput
                                    type="text"
                                    multiline
                                    placeholder={description}
                                    onInput={this.updateText.bind(
                                        this,
                                        "description"
                                    )}
                                />

                                <h4>
                                    {!opportunityDetails.executiveBrochure && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span>Upload
                                    executive summary/brochure
                                </h4>
                                <DocumentUploader
                                    uploadDocumentAction={uploadDocumentAction}
                                    removeDocumentAction={removeDocumentAction}
                                    documentUploading={documentUploading}
                                    documents={
                                        this.state.executiveBrochure
                                            ? this.state.executiveBrochure
                                            : executiveBrochure
                                    }
                                    keyName="executiveBrochure"
                                />
                                <h4>
                                    {!opportunityDetails.investorDeck && (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/alert.svg`}
                                            className="mandatory-missing"
                                        />
                                    )}
                                    <span className="mandatory">*</span>Upload
                                    investor deck/supporting material
                                </h4>
                                <DocumentUploader
                                    uploadDocumentAction={uploadDocumentAction}
                                    removeDocumentAction={this.removeDocument.bind(
                                        this
                                    )}
                                    documentUploading={documentUploading}
                                    documents={
                                        this.state.investorDeck
                                            ? this.state.investorDeck
                                            : investorDeck
                                    }
                                    keyName="investorDeck"
                                />
                                <h4>Upload other documents</h4>
                                <DocumentUploader
                                    uploadDocumentAction={uploadDocumentAction}
                                    removeDocumentAction={removeDocumentAction}
                                    documentUploading={documentUploading}
                                    documents={documents}
                                    keyName="documents"
                                    multiUpload
                                />

                                <h4>Add a YouTube video link to your page</h4>
                                <p>
                                    Upload a video to YouTube and copy and paste
                                    the link here
                                </p>
                                {invalidYoutube && (
                                    <p className="error">
                                        Please check that your link is valid,
                                        e.g.
                                        http://www.youtube.com/watch?v=iwGFalTRHDA.
                                    </p>
                                )}
                                <TextInput
                                    type="text"
                                    placeholder={
                                        opportunityDetails.youtubeId &&
                                        youtubeUrl
                                    }
                                    onInput={this.updateYoutube.bind(
                                        this,
                                        "youtubeUrl"
                                    )}
                                />
                                <h4>Add a website link to your page</h4>
                                <p>
                                    You can add a link to the opportunity's
                                    website. Just copy and paste the address
                                    below
                                </p>
                                {invalidUrl && (
                                    <p className="error">
                                        Please check that your link is valid,
                                        e.g. http://www.fiinect.com.
                                    </p>
                                )}
                                <TextInput
                                    type="text"
                                    placeholder={websiteUrl}
                                    onInput={this.updateUrl.bind(
                                        this,
                                        "websiteUrl"
                                    )}
                                />

                                <h4>Are you making a profit?</h4>
                                <OptionSelector
                                    isBinary
                                    isBinarySelected={MakingProfit}
                                    binaryTrueText=""
                                    binaryFalseText=""
                                    action={this.update.bind(
                                        this,
                                        "MakingProfit"
                                    )}
                                />
                                <h4>Which round of finance are you raising?</h4>
                                <OptionSelector
                                    vertical
                                    selectedValues={roundOfFinance}
                                    options={financeRoundOptions}
                                    action={this.update.bind(
                                        this,
                                        "roundOfFinance"
                                    )}
                                />
                                {timings === "Soon" && (
                                    <>
                                        <h4>When do you plan to raise?</h4>
                                        <OptionSelector
                                            selectedValues={timescales}
                                            options={timescaleOptions}
                                            noIcon
                                            action={this.update.bind(
                                                this,
                                                "timescales"
                                            )}
                                        />
                                    </>
                                )}

                                <div className="actions">
                                    <Button
                                        onClick={this.saveChanges.bind(this)}
                                        isPrimary
                                    >
                                        Save and preview
                                    </Button>
                                </div>
                            </article>
                            <aside></aside>
                        </div>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentUser: state.auth.user,
        opportunityDetails: state.opportunities.opportunityDetails,
        auxillary: state.auxillary,
        draftMatches: state.opportunities.draftMatches,
        documentUploading: state.opportunities.documentUploading,
        documents: state.opportunities.documents,
        executiveBrochure: state.opportunities.executiveBrochure,
        investorDeck: state.opportunities.investorDeck
    }),
    {
        getOpportunityDetailsAction,
        getMatchesForOpportunityAction,
        resetDraftMatchesAction,
        shareOpportunityAction,
        editOpportunityAction,
        uploadDocumentAction,
        opportunityDocumentAction,
        removeDocumentAction
    }
)(EditOpportunity);

import React, { Component } from "react";

import Button from "../button/Button";
import TextInput from "../text-input/TextInput";
import MessageList from "../message-list/MessageList";
import NewMessageButton from "../new-message-button/NewMessageButton";
import PlainCard from "../plain-card/PlainCard";

import "./LatestMessages.scss";
import InfoBubble from "../info-bubble/InfoBubble";

class LatestMessages extends Component {
    state = {
        textFilter: "",
        searchOpen: false
    };

    openSearch() {
        const { searchOpen } = this.state;

        this.setState({
            searchOpen: !searchOpen
        });
    }

    searchMessages(value) {
        this.setState({
            textFilter: value
        });
    }

    render() {
        const {
            latestMessages,
            openMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            getOpportunityDetailsForChatAction,
            opportunityFilter,
            leaveRoomAction
        } = this.props;

        const { textFilter, searchOpen } = this.state;

        let chats = latestMessages;
        if (opportunityFilter) {
            chats = latestMessages.filter(
                (chat) =>
                    chat.opportunity &&
                    `${chat.opportunity.opportunityId}` ===
                        `${opportunityFilter}`
            );
        }

        const text = textFilter.toLowerCase();

        if (textFilter) {
            chats = chats.filter((chat) => {
                if (
                    chat.people.find(
                        (person) => person.name.toLowerCase().indexOf(text) > -1
                    )
                )
                    return true;
                if (
                    chat.chat.find(
                        (message) =>
                            message.message.toLowerCase().indexOf(text) > -1
                    )
                )
                    return true;
                if (
                    chat.opportunity &&
                    chat.opportunity.headline.toLowerCase().indexOf(text) > -1
                )
                    return true;

                return false;
            });
        }

        return (
            <PlainCard className="latest-messages">
                <h4>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/message-icon.svg`}
                    />
                    {opportunityFilter
                        ? `Messages related to this opportunity (${chats.length})`
                        : "Latest private messages"}

                    {opportunityFilter ? (
                        ""
                    ) : (
                        <InfoBubble
                            content={
                                <p>
                                    This is a view of ALL your messages
                                    (received & sent) including general
                                    correspondence & those tagged to a specific
                                    business opportunity. These may also be
                                    found on each of the relevant business
                                    opportunity pages.​
                                </p>
                            }
                        />
                    )}
                </h4>
                <div className="actions">
                    <NewMessageButton
                        openMessageCenterAction={openMessageCenterAction}
                        setMessageCenterViewAction={setMessageCenterViewAction}
                        opportunity={opportunityFilter}
                        setCurrentMessageOpportunityAction={
                            setCurrentMessageOpportunityAction
                        }
                    />
                    <Button
                        onClick={this.openSearch.bind(this)}
                        icon={`${process.env.PUBLIC_URL}/assets/search_black.svg`}
                    >
                        Search messages
                    </Button>
                </div>
                <div className={searchOpen ? "search open" : "search"}>
                    <TextInput onInput={this.searchMessages.bind(this)} />
                </div>
                <MessageList
                    chats={chats}
                    setMessageCenterViewAction={setMessageCenterViewAction}
                    openMessageCenterAction={openMessageCenterAction}
                    setCurrentMessageParticipantsAction={
                        setCurrentMessageParticipantsAction
                    }
                    setCurrentMessageOpportunityAction={
                        setCurrentMessageOpportunityAction
                    }
                    getCurrentChatAction={getCurrentChatAction}
                    getOpportunityDetailsForChatAction={
                        getOpportunityDetailsForChatAction
                    }
                    leaveRoomAction={leaveRoomAction}
                />
            </PlainCard>
        );
    }
}

export default LatestMessages;

import React, { Component } from "react";

import "./Range.scss";

class Range extends Component {
    state = {
        backgroundGradient: this.getBackgroundGradient(this.props.min),
        value: this.props.initialValue,
        initialValue: this.props.initialValue
    };

    componentWillMount() {
        const { initialValue, min } = this.props;

        this.setState({
            backgroundGradient: this.getBackgroundGradient(initialValue || min)
        });
    }

    componentDidUpdate() {
        // hacky lololololol
        const { initialValue, idKey } = this.props;

        if (!initialValue) return;

        if (initialValue !== this.state.initialValue) {
            this.setState({
                initialValue
            });

            this.onChange({ target: { value: initialValue } });
            document.getElementById(`${idKey}_range`).value = initialValue;
        }
    }

    getBackgroundGradient(value) {
        const { min, max } = this.props;

        const range = max - min;
        const offsetValue = value - min;
        const percentValue = (offsetValue / range) * 100;

        return `linear-gradient(to right, #ff4e00 0%, #ff4e00 ${percentValue}%, #5046d2 ${percentValue}%, #5046d2 100%)`;
    }

    onChange(e) {
        const { idKey, step, lookup, onChange } = this.props;

        let decimalPlaces = 0,
            splitStep = step.toString().split(".");

        if (splitStep.length > 1) {
            decimalPlaces = splitStep[1].length;
        }

        const value = e.target.value;
        if (!lookup) {
            document.getElementById(`${idKey}_text`).value = parseFloat(
                value
            ).toFixed(decimalPlaces);
        } else {
            onChange(e.target.value);
        }

        this.setState({
            backgroundGradient: this.getBackgroundGradient(value),
            value
        });
    }

    onBlur(e) {
        this.setState({
            backgroundGradient: this.getBackgroundGradient(e.target.value),
            value: e.target.value
        });
    }

    onMouseUp(e) {
        const { onChange } = this.props;

        this.setState({
            backgroundGradient: this.getBackgroundGradient(e.target.value),
            value: e.target.value
        });

        onChange(e.target.value);
    }

    drawNotches() {
        const { min, max, notches, idKey } = this.props;
        const notchCount = notches ? max - min + 1 : 0;

        const value = parseInt(this.state.value);

        const notchEls = [];
        for (let i = 0; i < notchCount; i++) {
            let className = "notch";
            if (i === value) className += " current";
            else if (i < value) className += " previous";
            notchEls.push(
                <div
                    className={className}
                    onClick={this.notchClick.bind(this)}
                    data-value={i}
                    key={`${idKey}_notch_${i}`}
                ></div>
            );
        }
        return notchEls;
    }

    notchClick(e) {
        const { onChange, idKey } = this.props;

        const value = e.target.dataset.value;
        this.setState({
            backgroundGradient: this.getBackgroundGradient(value),
            value
        });
        const rangeInput = document.getElementById(`${idKey}_range`);
        rangeInput.value = value;
        rangeInput.dispatchEvent(new Event("blur", { bubbles: true }));
        onChange(value);
    }

    render() {
        const { min, max, step, initialValue, idKey, notches } = this.props;

        return (
            <>
                <div className="notches">{this.drawNotches()}</div>
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    onMouseUp={this.onMouseUp.bind(this)}
                    onTouchEnd={this.onMouseUp.bind(this)}
                    defaultValue={initialValue}
                    style={{
                        background: this.state.backgroundGradient
                    }}
                    id={`${idKey}_range`}
                />
            </>
        );
    }
}

export default Range;

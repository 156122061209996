import { findLevelOfInvestment } from "./levels";

export const mapOpportunity = (data, user = {}) => ({
    opportunityId: data.id,
    sectorId: data.sectorId,
    sector: data.sector ? data.sector.name : "",
    sectorHex: data.sector ? data.sector.hexCode : "",
    datePosted: data.created,
    startDate: data.startDate,
    headline: data.headline,
    ticketSize: data.levelOfInvestment ? data.levelOfInvestment.name : "",
    investmentBracket: data.levelOfInvestment
        ? data.levelOfInvestment.ticketSize
        : findLevelOfInvestment(data.levelOfInvestmentId),
    currency: data.levelOfInvestment ? data.levelOfInvestment.currency : "",
    levelOfInvestmentId: data.levelOfInvestmentId,
    description: data.summary,
    country: data.location ? data.location.country : "",
    locationId: data.locationId,
    broker: data.broker,
    interestedPeople: removeDuplicateUsers(
        data.connections.sort((a, b) => (b.updated > a.updated ? 1 : -1))
    )
        .filter((connection) => connection.status === "Interested")
        .map((connection) => connection.users),
    otherPeople: removeDuplicateUsers(
        data.connections.sort((a, b) => (b.updated > a.updated ? 1 : -1))
    )
        .filter((connection) => connection.status !== "Interested")
        .map((connection) => {
            const user = connection.users;
            user.status = connection.status;
            return user;
        }),
    connections: data.connections,
    statusChanges: data.connections
        .sort((a, b) => (b.updated > a.updated ? 1 : -1))
        .filter((connection) => {
            const lastRead = localStorage.getItem(`lastread_${data.id}`);

            let lastReadDate = null;
            if (lastRead) {
                lastReadDate = new Date(lastRead);
            }

            let updateDate = null;
            if (connection.created) updateDate = new Date(connection.created);
            else updateDate = new Date(connection.updated);
            return lastReadDate === null || updateDate > lastReadDate;
        })
        .map((connection) => connection.users),
    financeType: data.financeType,
    stage: data.financeStage,
    summary: data.summary,
    opportunityDetails: data.oppertunityDetails, // not my typo
    status: data.connections
        .sort((a, b) => (b.updated > a.updated ? 1 : -1))
        .find((connection) => connection.userId === user.userId),
    webLink: data.websiteURL,
    webLink: data.websiteURL,
    youtubeId: data.youtubeURL,
    isBusiness: false,
    isActive: data.isActive,
    updates: data.updates
        .filter((update) => update.parentUpdateId === null)
        .sort((a, b) => (a.created < b.created ? 1 : -1)),
    qa: data.updates.filter((update) => update.parentUpdateId !== null),
    documents: data.documents,
    investorDeck: data.investDeck,
    executiveBrochure: data.execBrochure,
    updateNotifications: data.updates.filter((update) => {
        const lastRead = localStorage.getItem(`lastread_${data.id}`);
        let lastReadDate = null;
        if (lastRead) {
            lastReadDate = new Date(lastRead);
        }
        const updateDate = new Date(update.created);

        return (
            update.parentUpdateId === null &&
            (lastReadDate === null || updateDate > lastReadDate)
        );
    }),
    qaNotifications: data.updates.filter((update) => {
        const lastRead = localStorage.getItem(`lastread_${data.id}`);
        let lastReadDate = null;
        if (lastRead) {
            lastReadDate = new Date(lastRead);
        }
        const updateDate = new Date(update.created);

        return (
            update.parentUpdateId !== null &&
            (lastReadDate === null || updateDate > lastReadDate)
        );
    }),

    numberOfEmployees: data.numberOfEmployees,
    MakingProfit: data.makingProfit,
    PreRevenue: data.isPreRevenue,
    timescales: data.timescale,
    roundOfFinance: data.roundOfFinance
});

export const mapBusinessOpportunity = (data, user = {}) => ({
    opportunityId: data.id,
    sectorId: data.sectorId,
    sector: data.sector.name,
    sectorHex: data.sector.hexCode,
    country:
        data.businessLocations.length > 0
            ? data.businessLocations.length > 1
                ? "Multi"
                : data.businessLocations[0].location.country
            : [],
    datePosted: data.created,
    headline: data.headline,
    ticketSize: data.levelOfInvestment ? data.levelOfInvestment.name : "",
    levelOfInvestmentId: data.levelOfInvestmentId,
    description: data.summary,
    broker: data.managingUser,
    interestedPeople: data.connections
        .filter((connection) => connection.status === "Interested")
        .map((connection) => connection.users),
    otherPeople: data.connections
        .filter((connection) => connection.status !== "Interested")
        .map((connection) => {
            const user = connection.users;
            user.status = connection.status;
            return user;
        }),
    financeType: data.financeType,
    stage: data.financeStage,
    summary: data.summary,
    opportunityDetails: data.businessDetails,
    status: data.status,
    webLink: data.websiteURL,
    youtubeId: data.youtubeURL,
    isBusiness: true,
    isActive: data.isActive
});

const removeDuplicateUsers = (connections) => {
    const seen = [];

    connections.forEach((connection) => {
        const userId = connection.users.id;
        if (
            !seen.find((seenConnection) => seenConnection.users.id === userId)
        ) {
            seen.push(connection);
        }
    });

    return seen;
};

import React, { Component } from "react";

import "./PublicPage.scss";

class PublicPage extends Component {
    render() {
        const { children } = this.props;

        return (
            <>
                <header className="site-header no-background">
                    <div className="logo-container">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/fiinect.svg`}
                            className="logo"
                            alt="Fiinect"
                        />
                    </div>
                </header>
                <div className="public-page-container">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/fiinect-floater-1.svg`}
                        className="floater floater-left"
                        alt="Fiinect"
                    />
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/fiinect-floater-2.svg`}
                        className="floater floater-right"
                        alt="Fiinect"
                    />
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/fiinect-floater-1.svg`}
                        className="floater floater-up"
                        alt="Fiinect"
                    />

                    {children}
                </div>
            </>
        );
    }
}

export default PublicPage;

import React, { Component } from "react";

import MessagePreview from "../message-preview/MessagePreview";
import NewMessageButton from "../new-message-button/NewMessageButton";

import "./MessageList.scss";

class MessageList extends Component {
    render() {
        const {
            chats,
            openMessageCenterAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            setMessageCenterViewAction,
            getOpportunityDetailsForChatAction,
            leaveRoomAction
        } = this.props;

        return (
            <div className="messages">
                {chats
                    .filter((chat) => chat.people.length > 0)
                    .map((chat) => (
                        <MessagePreview
                            key={`${chat.chatId}`}
                            chat={chat}
                            people={chat.people}
                            snippet={chat.snippet}
                            sender={chat.sender}
                            opportunity={chat.opportunity}
                            opportunityId={chat.opportunityId}
                            date={chat.date}
                            unread={chat.unread}
                            setMessageCenterViewAction={
                                setMessageCenterViewAction
                            }
                            openMessageCenterAction={openMessageCenterAction}
                            setCurrentMessageParticipantsAction={
                                setCurrentMessageParticipantsAction
                            }
                            setCurrentMessageOpportunityAction={
                                setCurrentMessageOpportunityAction
                            }
                            getCurrentChatAction={getCurrentChatAction}
                            getOpportunityDetailsForChatAction={
                                getOpportunityDetailsForChatAction
                            }
                            leaveRoomAction={leaveRoomAction}
                        />
                    ))}
            </div>
        );
    }
}

export default MessageList;

import React, { Component } from "react";
import { connect } from "react-redux";

import { regionIcons } from "../../data/data";

import {
    setLocationPreferencesAction,
    setSectorPreferencesAction,
    setUserBusinessTypeAction,
    setUserFinanceStageAction,
    setUserLevelOfInvestmentAction,
    updateUserDetailsAction
} from "../../actions/preferences/preferencesActions";
import {
    getSectorsAction,
    getLocationsAction,
    getFinanceStagesAction,
    getLevelOfInvestmentAction,
    getBusinessTypesAction
} from "../../actions/auxillary/auxillaryActions";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import OptionSelector from "../../components/option-selector/OptionSelector";

import "./MyPreferences.scss";
import Button from "../../components/button/Button";

class MyPreferences extends Component {
    state = {
        sectors: [],
        financeStages: [],
        levelOfInvestment: [],
        locations: [],
        businessTypes: [],
        WantsPreRevenue: null,
        WantsProfitOnly: null
    };

    componentDidMount() {
        const { currentUser } = this.props;

        this.setState({
            sectors: currentUser.sectorInterests,
            financeStages: currentUser.financeStages,
            levelOfInvestment: currentUser.levelOfInvestment || [],
            CurrencyPreference: [
                {
                    id: currentUser.currencyPreference,
                    value: currentUser.currencyPreference
                }
            ],
            businessTypes: currentUser.businessTypes,
            locations: currentUser.interestedLocations,
            WantsPreRevenue: currentUser.wantsPreRevenue,
            WantsProfitOnly: currentUser.wantsProfitOnly
        });
    }

    countryFlagForList(countryName) {
        return regionIcons[countryName];
    }

    update(key, action, value) {
        this.setState({
            [key]: value
        });

        if (typeof value === "boolean") {
            action({ [key]: value });
        } else {
            const arr1 = this.state[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));
            let subtractions = arr1.filter((x) => !arr2.includes(x));

            if (additions.length > 0) action(additions[0].id, "post");
            if (subtractions.length > 0) action(subtractions[0].id, "delete");
        }
    }

    updateCurrency(key, action, value) {
        const arr1 = this.state[key];
        const arr2 = value;

        let additions = arr2.filter((x) => !arr1.includes(x));

        this.setState({
            [key]: additions
        });

        action({ [key]: additions[0].id });
    }

    render() {
        const {
            auxillary,
            setUserBusinessTypeAction,
            setSectorPreferencesAction,
            setUserFinanceStageAction,
            setUserLevelOfInvestmentAction,
            setLocationPreferencesAction,
            updateUserDetailsAction,
            currentUser
        } = this.props;

        const {
            sectors,
            financeStages,
            CurrencyPreference,
            levelOfInvestment,
            locations,
            businessTypes,
            WantsPreRevenue,
            WantsProfitOnly
        } = this.state;

        return (
            <div className="preferences-container">
                <HighlightedContent
                    headline="I am looking for"
                    small
                ></HighlightedContent>
                <MainContent>
                    <div className="preferences">
                        {currentUser.role === "Professional" && (
                            <p>
                                Tell us what you/your clients are looking for
                                and we will show you appropriate opportunities.
                            </p>
                        )}

                        {currentUser.role !== "Professional" && (
                            <h4>I am a...</h4>
                        )}
                        {currentUser.role === "Professional" && (
                            <h4>My clients include...</h4>
                        )}

                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={businessTypes}
                            options={auxillary.businessTypes}
                            action={this.update.bind(
                                this,
                                "businessTypes",
                                setUserBusinessTypeAction
                            )}
                        />

                        <h4>I am looking for...</h4>
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={financeStages}
                            options={auxillary.financeStages}
                            action={this.update.bind(
                                this,
                                "financeStages",
                                setUserFinanceStageAction
                            )}
                        />

                        {CurrencyPreference && (
                            <>
                                <h4>Currency preference</h4>

                                <OptionSelector
                                    selectedValues={CurrencyPreference}
                                    options={Object.keys(
                                        auxillary.levelOfInvestment
                                    ).map((key) => ({ id: key, value: key }))}
                                    action={this.updateCurrency.bind(
                                        this,
                                        "CurrencyPreference",
                                        updateUserDetailsAction
                                    )}
                                    noIcon
                                    vertical
                                    preventDeselect
                                />

                                <h4>
                                    I am looking for companies which are raising
                                </h4>
                                <p>(Can choose more than one)</p>

                                <OptionSelector
                                    selectedValues={levelOfInvestment}
                                    options={
                                        auxillary.levelOfInvestment[
                                            CurrencyPreference[0].id
                                        ]
                                    }
                                    action={this.update.bind(
                                        this,
                                        "levelOfInvestment",
                                        setUserLevelOfInvestmentAction
                                    )}
                                    noIcon
                                />
                            </>
                        )}

                        <h4>I'm interested in these sectors</h4>
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            selectedValues={sectors}
                            options={auxillary.sectorInterests}
                            sectorVariant
                            imageFormat="svg"
                            action={this.update.bind(
                                this,
                                "sectors",
                                setSectorPreferencesAction
                            )}
                        />

                        <h4>Would you like to see Pre-Revenue business?</h4>
                        <p>This applies only if relevant data is available.</p>

                        <OptionSelector
                            isBinary
                            isBinarySelected={WantsPreRevenue}
                            binaryTrueText=""
                            binaryFalseText=""
                            action={this.update.bind(
                                this,
                                "WantsPreRevenue",
                                updateUserDetailsAction
                            )}
                        />
                        <h4>
                            Do you ONLY want to see companies making a profit?
                        </h4>
                        <p>This applies only if relevant data is available.</p>

                        <OptionSelector
                            isBinary
                            isBinarySelected={WantsProfitOnly}
                            binaryTrueText=""
                            binaryFalseText="Show me all"
                            action={this.update.bind(
                                this,
                                "WantsProfitOnly",
                                updateUserDetailsAction
                            )}
                        />

                        <h4>I'm interested in these locations</h4>
                        <p>(Can choose more than one)</p>

                        <OptionSelector
                            vertical
                            getImage={this.countryFlagForList.bind(this)}
                            selectedValues={locations}
                            options={auxillary.locations.filter(
                                (location) =>
                                    location.isRegion &&
                                    location.code !== "global"
                            )}
                            action={this.update.bind(
                                this,
                                "locations",
                                setLocationPreferencesAction
                            )}
                        />

                        <div className="actions">
                            <Button
                                href={`${process.env.PUBLIC_URL}/`}
                                isPrimary
                            >
                                Save preferences
                            </Button>
                        </div>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentUser: state.auth.user,
        auxillary: state.auxillary
    }),
    {
        getSectorsAction,
        getLocationsAction,
        getFinanceStagesAction,
        getLevelOfInvestmentAction,
        getBusinessTypesAction,
        setSectorPreferencesAction,
        setLocationPreferencesAction,
        setUserBusinessTypeAction,
        setUserFinanceStageAction,
        setUserLevelOfInvestmentAction,
        updateUserDetailsAction
    }
)(MyPreferences);

import React, { Component } from "react";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";

class AboutUs extends Component {
    render() {
        return (
            <>
                <HighlightedContent
                    small
                    headline="About us"
                ></HighlightedContent>
                <MainContent>
                    <div className="container">
                        <h2>Connecting the Alternative Finance Market</h2>
                        <p>
                            "You have to kiss a lot of frogs." It doesn't matter
                            if you're an entrepreneur wanting seed capital, an
                            established business raising finance or even a VC
                            raising a fund, it's the same - raising and
                            investing money takes time.
                        </p>

                        <p>
                            We've experienced it both sourcing finance &amp;
                            finding investment opportunities. 90% of everyone's
                            time is wasted.
                        </p>

                        <p>So we started to imagine what it could be...</p>

                        <h3>The Vision </h3>

                        <p>
                            Imagine if... everyone on the planet had the same
                            opportunity to put their business in front of
                            finance providers.{" "}
                        </p>

                        <p>
                            {" "}
                            Imagine if... you could see the business
                            opportunities you have been missing &amp; they were
                            all accessible in one place{" "}
                        </p>

                        <p>
                            {" "}
                            Imagine if... the latest technology (AI) only showed
                            you the opportunities &amp; people relevant to you{" "}
                        </p>

                        <p> So we built Fiinect which helps you: </p>

                        <p>
                            Accelerate the introduction of business to
                            'interested capital', saving valuable time &amp;
                            resource for both parties{" "}
                        </p>

                        <p>
                            Connect existing investment / business networks,
                            creating a route to share &amp; syndicate
                            opportunities{" "}
                        </p>

                        <p>
                            {" "}
                            Increase Communication deliver tools to manage the
                            sharing &amp; feedback of opportunities{" "}
                        </p>

                        <p>
                            {" "}
                            Manage / Analyse 'referral' Networks to optimise
                            &amp; grow your network{" "}
                        </p>

                        <p>
                            {" "}
                            Track future deals: Fiinect provides a business page
                            that can be followed by interested parties to
                            increase interaction.{" "}
                        </p>

                        <p>
                            {" "}
                            And it's free at the point of use! We take no
                            commission or equity in any of the opportunities
                            which pass through our site.{" "}
                        </p>

                        <p> Finance. Connect. Fiinect. </p>
                    </div>
                </MainContent>
            </>
        );
    }
}

export default AboutUs;

import React, { Component } from "react";
import { connect } from "react-redux";

import {
    getOccupationsAction,
    getExpertisesAction,
    getLocationsAction
} from "../../actions/auxillary/auxillaryActions";
import {
    getMarketplaceResultsAction,
    resetMarketplaceAction,
    setFocusedUserAction
} from "../../actions/network/networkActions";
import Button from "../../components/button/Button";
import Connection from "../../components/connection/Connection";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import IndustryNews from "../../components/industry-news/IndustryNews";
import InfoBubble from "../../components/info-bubble/InfoBubble";
import Loader from "../../components/loader/Loader";
import MainContent from "../../components/main-content/MainContent";

import "./Marketplace.scss";

class Marketplace extends Component {
    state = {
        occupation: "",
        expertise: "",
        location: ""
    };

    componentDidMount() {
        const {
            getOccupationsAction,
            getExpertisesAction,
            getLocationsAction
        } = this.props;

        getOccupationsAction();
        getExpertisesAction();
        getLocationsAction();

        this.resetMarketplace();
    }

    changeForm(p, e) {
        this.setState({
            [p]: e.target.value
        });
    }

    onSubmit() {
        const { getMarketplaceResultsAction } = this.props;
        const { occupation, expertise, location } = this.state;

        getMarketplaceResultsAction(occupation, expertise, location);
    }

    generateSearchResultTitle() {
        const { occupations, expertises, locations } = this.props;
        const { occupation, expertise, location } = this.state;

        let title = "";
        if (occupation) {
            title = occupations.find(
                (o) => o.occupationId.toString() === occupation.toString()
            ).occupationName;
        }
        if (expertise) {
            title += ` in ${
                expertises.find((e) => e.id.toString() === expertise.toString())
                    .value
            }`;
        }
        if (location) {
            title += ` in ${
                locations.find((l) => l.id.toString() === location.toString())
                    .value
            }`;
        }

        return title;
    }

    resetMarketplace() {
        const { resetMarketplaceAction } = this.props;

        resetMarketplaceAction();
        this.setState({
            occupation: "",
            expertise: "",
            location: ""
        });
    }

    render() {
        const {
            occupations,
            expertises,
            locations,
            network,
            setFocusedUserAction
        } = this.props;

        const { occupation } = this.state;

        const searchResultTitle = this.generateSearchResultTitle();

        return (
            <div className="marketplace-container">
                {network.loading && <Loader />}
                <HighlightedContent
                    headline={
                        <>
                            Marketplace
                            <InfoBubble
                                content={
                                    <>
                                        <p>What's Fiinect Marketplace?​</p>
                                        <p>
                                            This is where you can search for
                                            Fiinect members from around the
                                            world who can provide you with the
                                            relevant resources and expertise
                                            involved in your raise, investment
                                            or managing your business.​
                                        </p>
                                        ​
                                        <p>
                                            If you have certain skillsets that
                                            you wish to add to the Marketplace
                                            so people can contact you, go to 'MY
                                            DETAILS' in the drop down (top right
                                            of the site) and complete your
                                            expertise.​
                                        </p>
                                        ​
                                        <p>
                                            NB: if you are a Premium Member you
                                            will receive priority listing​
                                        </p>
                                        ​ ​
                                    </>
                                }
                            />
                        </>
                    }
                    small
                ></HighlightedContent>
                <MainContent>
                    {!network.marketplaceResults && (
                        <div className="marketplace-search">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/marketplace_illustration.png`}
                            />
                            <form>
                                <h3>Coming soon</h3>
                                <fieldset>
                                    <label>I am looking for a...</label>
                                    <select
                                        onChange={this.changeForm.bind(
                                            this,
                                            "occupation"
                                        )}
                                    >
                                        <option>Please select</option>
                                        {occupations.map((occupation) => (
                                            <option
                                                value={occupation.occupationId}
                                            >
                                                {occupation.occupationName}
                                            </option>
                                        ))}
                                    </select>
                                </fieldset>
                                {occupation !== "" && (
                                    <fieldset>
                                        <label>Skillset / expertise</label>
                                        <select
                                            onChange={this.changeForm.bind(
                                                this,
                                                "expertise"
                                            )}
                                        >
                                            <option>Please select</option>
                                            {expertises
                                                .filter(
                                                    (expertise) =>
                                                        expertise.occupationId.toString() ===
                                                        occupation
                                                )
                                                .map((expertise) => (
                                                    <option
                                                        value={expertise.id}
                                                    >
                                                        {expertise.value}
                                                    </option>
                                                ))}
                                        </select>
                                    </fieldset>
                                )}
                                <fieldset>
                                    <label>Located in</label>
                                    <select
                                        onChange={this.changeForm.bind(
                                            this,
                                            "location"
                                        )}
                                    >
                                        <option>Please select</option>
                                        {locations.map((location) => (
                                            <option value={location.id}>
                                                {location.value}
                                            </option>
                                        ))}
                                    </select>
                                </fieldset>

                                {occupation !== "" && (
                                    <Button
                                        isPrimary
                                        onClick={this.onSubmit.bind(this)}
                                    >
                                        Search
                                    </Button>
                                )}
                            </form>
                        </div>
                    )}
                    {network.marketplaceResults && (
                        <div className="marketplace-results">
                            <article>
                                <header>
                                    <h3>{searchResultTitle}</h3>
                                    <Button
                                        onClick={this.resetMarketplace.bind(
                                            this
                                        )}
                                    >
                                        Search again
                                    </Button>
                                </header>
                                {network.marketplaceResults.connectionResults
                                    .length === 0 &&
                                    network.marketplaceResults.communityResults
                                        .length === 0 && (
                                        <p>
                                            Sorry, there were no results
                                            matching your criteria.
                                        </p>
                                    )}

                                {network.marketplaceResults.connectionResults
                                    .length > 0 && (
                                    <div className="results fiinection-results">
                                        <h4>
                                            From your connections (
                                            {
                                                network.marketplaceResults
                                                    .connectionResults.length
                                            }{" "}
                                            {network.marketplaceResults
                                                .connectionResults.length > 1
                                                ? "results"
                                                : "result"}
                                            )
                                        </h4>
                                        {network.marketplaceResults.connectionResults.map(
                                            (connection) => (
                                                <Connection
                                                    connection={connection}
                                                    expanded
                                                    setFocusedUserAction={
                                                        setFocusedUserAction
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                                {network.marketplaceResults.communityResults
                                    .length > 0 && (
                                    <div className="results community-results">
                                        <h4>
                                            From the Fiinect community (
                                            {
                                                network.marketplaceResults
                                                    .communityResults.length
                                            }{" "}
                                            {network.marketplaceResults
                                                .communityResults.length > 1
                                                ? "results"
                                                : "result"}
                                            )
                                        </h4>
                                        {network.marketplaceResults.communityResults.map(
                                            (connection) => (
                                                <Connection
                                                    connection={connection}
                                                    expanded
                                                    setFocusedUserAction={
                                                        setFocusedUserAction
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </article>
                            <aside>
                                <IndustryNews news={network.news} />
                            </aside>
                        </div>
                    )}
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        occupations: state.auxillary.occupations,
        expertises: state.auxillary.expertise,
        locations: state.auxillary.locations,
        network: state.network
    }),
    {
        getOccupationsAction,
        getExpertisesAction,
        getLocationsAction,
        getMarketplaceResultsAction,
        resetMarketplaceAction,
        setFocusedUserAction
    }
)(Marketplace);

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./OpportunityCard.scss";
import { months } from "../../data/data";
import { regionNames, regionIcons } from "../../data/data";

import ProfileIcon from "../profile-icon/ProfileIcon";
import Button from "../button/Button";
import { uuidv4 } from "../../util";

const INTERESTED_PARTIES_TO_BE_SHOWN = 6;

class OpportunityCard extends Component {
    state = {
        hovered: false,
        forceNotInterested: false,
        redirect: false
    };

    showLargeBox() {
        this.setState({
            hovered: true
        });
    }

    hideLargeBox() {
        this.setState({
            hovered: false
        });
    }

    notInterested() {
        const {
            setInterestStatusAction,
            opportunityId,
            hideSlide
        } = this.props;

        setTimeout(() => {
            setInterestStatusAction(opportunityId, "Not interested", null);
        }, 300);

        if (hideSlide) hideSlide();

        this.setState({
            forceNotInterested: true
        });
    }

    goToOpportunity(e) {
        if (e.target.innerText !== "Not interested") {
            this.setState({
                redirect: true
            });
        }
    }

    render() {
        const {
            opportunityId,
            sector,
            sectorHex,
            datePosted,
            headline,
            ticketSize,
            locationId,
            description,
            broker,
            connections,
            interestedPeople,
            otherPeople,
            currentUser,
            messaging,
            showIndications,
            comingSoon,
            updates,
            isBusiness,
            isActive,
            auxillary,
            statusChanges
        } = this.props;

        const { forceNotInterested, redirect } = this.state;

        const location = auxillary.locations.find((l) => l.id === locationId);
        const country = location ? location.code : "";

        if (redirect) {
            return (
                <Redirect
                    to={
                        isBusiness
                            ? `/businesses/${opportunityId}`
                            : `/opportunities/${opportunityId}`
                    }
                />
            );
        }

        const d = new Date(datePosted);
        let hour = d.getHours();
        let minute = d.getMinutes();
        if (hour < 10) hour = `0${hour}`;
        if (minute < 10) minute = `0${minute}`;
        const dateString = `${d.getDate()} ${
            months[d.getMonth()]
        } ${d.getFullYear()} ${hour}:${minute}`;
        const interestedPeopleOverflow =
            interestedPeople.length - INTERESTED_PARTIES_TO_BE_SHOWN;

        const messages = messaging
            ? messaging.messages.filter((chat) => {
                  return (
                      chat.opportunity &&
                      chat.opportunity.opportunityId === opportunityId &&
                      chat.unread
                  );
              })
            : [];

        let className = "opportunity-card";
        if (comingSoon) className += " coming-soon";
        if (!isActive) className += " inactive";

        let notInterested = false;
        if (otherPeople) {
            const userStatus = otherPeople.find(
                (user) => user.id === currentUser.id
            );
            if (userStatus && userStatus.status === "Not interested") {
                notInterested = true;
            }
        }

        if (forceNotInterested) {
            className += " not-interested";
        }

        const interestedConnections = interestedPeople.filter((user) =>
            connections.find((connection) => connection.id === user.id)
        );

        return (
            <div
                className={className}
                onMouseOver={this.showLargeBox.bind(this)}
                onMouseLeave={this.hideLargeBox.bind(this)}
            >
                {showIndications && (
                    <div className="indications">
                        {messages.length > 0 && (
                            <div className="indication messages">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/message.svg`}
                                />
                                {messages.length}
                            </div>
                        )}
                        {updates > 0 && (
                            <div className="indication announcments">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/announcement.svg`}
                                />
                                {updates}
                            </div>
                        )}
                        {statusChanges.length > 0 && (
                            <span className="interested-people">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/people.svg`}
                                />
                                {statusChanges.length}
                            </span>
                        )}
                    </div>
                )}
                <div className="sector" style={{ backgroundColor: sectorHex }}>
                    <img
                        src={`${
                            process.env.PUBLIC_URL
                        }/assets/icons/${sector
                            .toLowerCase()
                            .replace("/", "_")}.svg`}
                        alt={sector}
                    />
                    <span>{sector}</span>
                </div>

                <div className="broker">
                    <ProfileIcon person={broker} large />
                </div>

                <time dateTime={datePosted}>{dateString}</time>
                <h3>{headline}</h3>

                <div className="ellipsis">&bull;&bull;&bull;</div>

                <div
                    className={
                        comingSoon
                            ? "ticket-details coming-soon"
                            : "ticket-details"
                    }
                >
                    {!comingSoon && (
                        <div className="ticket-size">
                            <span>Finance required</span>
                            <h4>{ticketSize}</h4>
                        </div>
                    )}
                    <div className="ticket-location">
                        <img src={regionIcons[country]} />
                        <span>{regionNames[country]}</span>
                    </div>
                </div>

                <div
                    className={
                        this.state.hovered
                            ? "opportunity-card large hovered"
                            : "opportunity-card large"
                    }
                    onMouseOver={this.showLargeBox.bind(this)}
                    onMouseLeave={this.hideLargeBox.bind(this)}
                    onClick={this.goToOpportunity.bind(this)}
                >
                    {showIndications && (
                        <div className="indications">
                            {messages.length > 0 && (
                                <div className="indication messages">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/message.svg`}
                                    />
                                    {messages.length}
                                </div>
                            )}
                            {updates > 0 && (
                                <div className="indication announcments">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/announcement.svg`}
                                    />
                                    {updates}
                                </div>
                            )}
                            {statusChanges.length > 0 && (
                                <span className="interested-people">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/people.svg`}
                                    />
                                    {statusChanges.length}
                                </span>
                            )}
                        </div>
                    )}

                    <div
                        className="sector"
                        style={{ backgroundColor: sectorHex }}
                    >
                        <img
                            src={`${
                                process.env.PUBLIC_URL
                            }/assets/icons/${sector
                                .toLowerCase()
                                .replace("/", "_")}.svg`}
                            alt={sector}
                        />
                        {sector.length > 20 && (
                            <marquee scrollamount="4" direction="right">
                                {sector}
                            </marquee>
                        )}
                        {sector.length <= 20 && <span>{sector}</span>}
                    </div>

                    <div className="broker">
                        <ProfileIcon person={broker} large />
                    </div>

                    <time dateTime={datePosted}>{dateString}</time>
                    <h3>{headline}</h3>
                    <p>{description}</p>

                    <div className="ellipsis">&bull;&bull;&bull;</div>

                    <div
                        className={
                            comingSoon
                                ? "ticket-details coming-soon"
                                : "ticket-details"
                        }
                    >
                        {!comingSoon && (
                            <div className="ticket-size">
                                <span>Finance required</span>
                                <h4>{ticketSize}</h4>
                            </div>
                        )}
                        <div className="ticket-location">
                            <img src={regionIcons[country]} />
                            <span>{regionNames[country]}</span>
                        </div>
                    </div>

                    <footer>
                        <div className="interested-parties">
                            {interestedPeople.length > 0 && (
                                <small>
                                    Connections who match this opportunity
                                </small>
                            )}
                            {interestedConnections
                                .slice(0, INTERESTED_PARTIES_TO_BE_SHOWN)
                                .map((person) => (
                                    <ProfileIcon
                                        person={person}
                                        key={person.name + uuidv4()}
                                    />
                                ))}
                            {interestedConnections.length >
                                INTERESTED_PARTIES_TO_BE_SHOWN && (
                                <span>+{interestedPeopleOverflow} more</span>
                            )}
                        </div>

                        <div className="actions">
                            {!notInterested && broker.id !== currentUser.id && (
                                <Button onClick={this.notInterested.bind(this)}>
                                    Not interested
                                </Button>
                            )}
                            <Button
                                href={
                                    isBusiness
                                        ? `/businesses/${opportunityId}`
                                        : `/opportunities/${opportunityId}`
                                }
                                isPrimary
                            >
                                View details
                            </Button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default OpportunityCard;

import React, { Component } from "react";
import Button from "../button/Button";
import InfoBubble from "../info-bubble/InfoBubble";
import { matomoTrack } from "../../util";

import "./OpportunityFilters.scss";

class OpportunityFilters extends Component {
    displayNames = {
        opportunities: "Inbox",
        interested: "Interested",
        mine: "My created opportunities",
        following: "Just watching",
        "not-interested": "Not interested"
    };

    render() {
        const { inboxView, opportunityId } = this.props;

        const focusedClass = opportunityId
            ? "focused opportunity-page"
            : "focused";

        return (
            <div className="opportunity-filters">
                <p>{this.displayNames[inboxView]}</p>
                <Button
                    href="/opportunities"
                    icon={
                        inboxView === "opportunities"
                            ? "/assets/inbox_orange.svg"
                            : "/assets/inbox_dark.svg"
                    }
                    cssClass={inboxView === "opportunities" ? focusedClass : ""}
                >
                    <span>New</span>
                </Button>
                <Button
                    href="/opportunities/interested"
                    icon={
                        inboxView === "interested"
                            ? "/assets/interested_orange.svg"
                            : "/assets/interested_dark.svg"
                    }
                    cssClass={inboxView === "interested" ? focusedClass : ""}
                >
                    <span>Interested</span>
                </Button>

                <Button
                    href="/opportunities/mine"
                    icon={
                        inboxView === "mine"
                            ? "/assets/pencil_orange.svg"
                            : "/assets/pencil_dark.svg"
                    }
                    cssClass={inboxView === "mine" ? focusedClass : ""}
                >
                    <span>My Created Opportunties</span>
                </Button>
                <Button
                    href="/opportunities/following"
                    icon={
                        inboxView === "following"
                            ? "/assets/following_orange.svg"
                            : "/assets/following_dark.svg"
                    }
                    cssClass={inboxView === "following" ? focusedClass : ""}
                >
                    <span>Just watching</span>
                </Button>
                <Button
                    href="/opportunities/not-interested"
                    icon={
                        inboxView === "not-interested"
                            ? "/assets/not_interested_orange.svg"
                            : "/assets/not_interested_dark.svg"
                    }
                    cssClass={
                        inboxView === "not-interested" ? focusedClass : ""
                    }
                >
                    <span>Not interested</span>
                </Button>
            </div>
        );
    }
}

export default OpportunityFilters;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    searchAction,
    resetSearchAction,
    setFocusedUserAction
} from "../../actions/network/networkActions";
import Connection from "../../components/connection/Connection";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import PlainCard from "../../components/plain-card/PlainCard";
import { regionIcons, regionNames } from "../../data/data";
import { months } from "../../data/data";
import { mapOpportunity } from "../../data/opportunities";
import { mapPerson } from "../../data/people";

import "./SearchResults.scss";

class SearchResults extends Component {
    componentDidMount() {
        const { searchResults, searchAction } = this.props;
        const query = this.props.match.params.query;

        if (
            searchResults.users.length === 0 &&
            searchResults.opportunities.length === 0 &&
            searchResults.businesses.length === 0
        ) {
            searchAction(query);
        }
    }
    render() {
        const { searchResults, setFocusedUserAction } = this.props;
        const query = this.props.match.params.query;

        return (
            <div className="search-container">
                <HighlightedContent
                    headline={`Search results for "${query}"`}
                    small
                ></HighlightedContent>
                <MainContent>
                    {searchResults.users.length > 0 && (
                        <PlainCard>
                            <h3>People</h3>
                            {searchResults.users.map((person) => (
                                <div className="search-result">
                                    <Connection
                                        connection={mapPerson(person)}
                                        setFocusedUserAction={
                                            setFocusedUserAction
                                        }
                                        expanded
                                    />
                                </div>
                            ))}
                        </PlainCard>
                    )}
                    {searchResults.opportunities.length > 0 && (
                        <PlainCard>
                            <h3>Opportunities</h3>
                            {searchResults.opportunities.map((opportunity) => {
                                const d = new Date(opportunity.created);
                                let hour = d.getHours();
                                let minute = d.getMinutes();
                                if (hour < 10) hour = `0${hour}`;
                                if (minute < 10) minute = `0${minute}`;
                                const dateString = `${d.getDate()} ${
                                    months[d.getMonth()]
                                } ${d.getFullYear()} ${hour}:${minute}`;

                                return (
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/opportunities/${opportunity.id}`}
                                    >
                                        <div className="search-result">
                                            <h4>{opportunity.headline}</h4>
                                            <p className="search-result-details">
                                                <time
                                                    dateTime={
                                                        opportunity.created
                                                    }
                                                >
                                                    {dateString}
                                                </time>
                                                <img
                                                    src={
                                                        regionIcons[
                                                            opportunity.location
                                                                .country
                                                        ]
                                                    }
                                                />
                                                <span>
                                                    {
                                                        regionNames[
                                                            opportunity.location
                                                                .country
                                                        ]
                                                    }
                                                </span>
                                            </p>
                                            <p>{opportunity.summary}</p>
                                        </div>
                                    </Link>
                                );
                            })}
                        </PlainCard>
                    )}
                    {searchResults.businesses.length > 0 && (
                        <PlainCard>
                            <h3>Businesses</h3>
                            {searchResults.businesses.map((business) => {
                                const d = new Date(business.created);

                                let hour = d.getHours();
                                let minute = d.getMinutes();
                                if (hour < 10) hour = `0${hour}`;
                                if (minute < 10) minute = `0${minute}`;
                                const dateString = `${d.getDate()} ${
                                    months[d.getMonth()]
                                } ${d.getFullYear()} ${hour}:${minute}`;

                                const country =
                                    business.businessLocations.length > 0
                                        ? business.businessLocations.length > 1
                                            ? "Multi"
                                            : business.businessLocations[0]
                                                  .location.country
                                        : "";

                                return (
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/businesses/${business.id}`}
                                    >
                                        <div className="search-result">
                                            <h4>
                                                {business.headline ||
                                                    business.name}
                                            </h4>
                                            <p className="search-result-details">
                                                <time
                                                    dateTime={business.created}
                                                >
                                                    {dateString}
                                                </time>
                                                <img
                                                    src={regionIcons[country]}
                                                />
                                                <span>
                                                    {regionNames[country]}
                                                </span>
                                            </p>
                                            <p>{business.summary}</p>
                                        </div>
                                    </Link>
                                );
                            })}
                        </PlainCard>
                    )}
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        searchResults: state.network.searchResults
    }),
    {
        searchAction,
        resetSearchAction,
        setFocusedUserAction
    }
)(SearchResults);

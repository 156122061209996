import React, { Component } from "react";
import Button from "../button/Button";

import "./OpportunityCounter.scss";

class OpportunityCounter extends Component {
    render() {
        const { base, total, unlocked } = this.props;

        const additional = total - base;

        return (
            <div className="opportunity-counter">
                {!unlocked && (
                    <>
                        <strong>
                            LIVE: <span>{base.toLocaleString()}</span>
                        </strong>{" "}
                        opportunities
                        <Button href={`${process.env.PUBLIC_URL}/send-invite`}>
                            Unlock FREE
                        </Button>
                        <strong>
                            additional{" "}
                            <span>{additional.toLocaleString()}</span>{" "}
                            opportunities
                        </strong>
                    </>
                )}
                {unlocked && (
                    <>
                        Status: <strong>Unlocked</strong>
                        &nbsp;&nbsp;Number of deals:{" "}
                        <strong>{total.toLocaleString()}</strong>
                    </>
                )}
            </div>
        );
    }
}

export default OpportunityCounter;

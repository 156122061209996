import axios from "axios";

import { API_BASE } from "../../api";
import * as actionTypes from "../actionTypes";
import { getUnconnectedButMessagedUserDetailsAction } from "../network/networkActions";
import { mapOpportunity } from "../../data/opportunities";

export const listMessagesAction = (andThenLoad = null) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const connections = getState().network.connections;
        const unconnectedButMessaged = getState().network
            .unconnectedButMessaged;
        const unconnectedButMessagedFailures = getState().network
            .unconnectedButMessagedFailures;
        const unconnectedLoading = getState().network.unconnectedLoading;
        const opportunities = getState().opportunities.all;
        const businesses = getState().business.all;

        const matrixRooms = window.matrixClient.getRooms();
        const chats = [];
        const unconnectedUsers = [];
        let unreadCount = 0;

        matrixRooms.forEach((room) => {
            let membership =
                room.currentState.members[user.matrixUserID].membership;
            if (
                room.currentState &&
                room.currentState.members &&
                room.currentState.members[user.matrixUserID] &&
                room.currentState.members[user.matrixUserID].membership ===
                    "invite"
            ) {
                dispatch(joinRoomAction(room.roomId));
            }

            let readMarker;
            if (
                room.receipts &&
                room.receipts["m.read"] &&
                room.receipts["m.read"][user.matrixUserID]
            )
                readMarker = room.receipts["m.read"][user.matrixUserID].eventId;

            const people = Object.keys(room.currentState.members).reduce(
                (result, member) => {
                    let person = connections.find(
                        (connection) => connection.matrixUserID === member
                    );

                    if (person) result.push(person);
                    else {
                        person = unconnectedButMessaged.find(
                            (user) => user.matrixUserID === member
                        );
                        if (person) result.push(person);
                        else if (
                            member !== user.matrixUserID &&
                            unconnectedUsers.indexOf(member) === -1
                        ) {
                            unconnectedUsers.push(member);
                        }
                    }
                    return result;
                },
                []
            );

            const mostRecentEvent = room.timeline[room.timeline.length - 1];
            const textMessages = room.timeline
                .filter(
                    (event) =>
                        event.event.content.msgtype &&
                        event.event.content.msgtype === "m.text"
                )
                .sort((a, b) =>
                    a._localTimestamp < b._localTimestamp ? 1 : -1
                );

            let mostRecentMessage = textMessages[0];
            if (!mostRecentMessage) {
                mostRecentMessage = {
                    _localTimestamp: null,
                    event: {
                        content: {
                            body: ""
                        }
                    }
                };
            }

            const chat = textMessages.reverse().map((message) => {
                let messenger = people.find(
                    (person) => message.event.sender === person.matrixUserID
                );

                if (!messenger) messenger = user;
                const now = new Date();
                const date = new Date(message.localTimestamp);

                return {
                    eventId: message.event.event_id,
                    messenger,
                    date: message.localTimestamp
                        ? message.localTimestamp
                        : now.toUTCString(),
                    message: message.event.content.body
                };
            });

            let unread =
                readMarker && mostRecentEvent.event.event_id !== readMarker;

            if (people.length === 0) unread = false;

            const opportunityId = room.name.split("__")[1];

            let opportunity;
            if (opportunityId && opportunityId.indexOf("business") === 0) {
                const businessId = opportunityId.split("-")[1];
                opportunity = businesses.find((d) => `${d.id}` === businessId);
                if (opportunity) {
                    opportunity.opportunityId = businessId;
                    opportunity.isBusiness = true;
                }
            } else {
                opportunity = opportunities.find(
                    (d) => `${d.opportunityId}` === opportunityId
                );
            }

            let sender;
            if (mostRecentMessage.sender) {
                sender =
                    mostRecentMessage.sender.userId === user.matrixUserID
                        ? "user"
                        : mostRecentMessage.sender.userId;
            }

            const now = new Date();

            chats.push({
                chatId: room.roomId,
                people,
                date: mostRecentMessage.localTimestamp
                    ? mostRecentMessage.localTimestamp
                    : now.toUTCString(),
                snippet: mostRecentMessage.event.content.body,
                unread,
                opportunity,
                opportunityId,
                chat,
                mostRecentEvent,
                membership,
                sender
            });
        });

        unconnectedUsers.forEach((unconnectedUser) => {
            if (
                unconnectedLoading ||
                unconnectedButMessaged.find(
                    (user) =>
                        user.matrixUserID.toLowerCase() ===
                        unconnectedUser.toLowerCase()
                ) ||
                unconnectedButMessagedFailures.indexOf(unconnectedUser) > -1
            )
                return;

            /*dispatch(
                getUnconnectedButMessagedUserDetailsAction(unconnectedUser)
            );*/
        });

        const filteredChats = chats
            .filter((chat) => chat.people.length > 0)
            .filter((chat) => chat.chat.length > 0)
            .filter((chat) => chat.membership !== "leave")
            .sort((a, b) => (a.date < b.date ? 1 : -1));

        dispatch(listMessagesSuccessAction(filteredChats));
        dispatch(updateMessageCountAction(filteredChats.length));
        dispatch(
            updateUnreadMessageCountAction(
                filteredChats.filter((chat) => chat.unread).length
            )
        );
        if (andThenLoad) {
            dispatch(getCurrentChatByRoomIdAction(andThenLoad, chats));
        }
    };
};

export const listMessagesSuccessAction = (chats) => ({
    type: actionTypes.LIST_MESSAGES,
    chats
});

export const openMessageCenterAction = () => ({
    type: actionTypes.OPEN_MESSAGE_CENTER
});

export const closeMessageCenterAction = () => ({
    type: actionTypes.CLOSE_MESSAGE_CENTER
});

export const toggleMessageCenterAction = () => ({
    type: actionTypes.TOGGLE_MESSAGE_CENTER
});

export const setMessageCenterViewAction = (value) => ({
    type: actionTypes.SET_MESSAGE_CENTER_VIEW,
    value
});

export const setCurrentMessageParticipantsAction = (participants) => ({
    type: actionTypes.SET_CURRENT_MESSAGE_PARTICIPANTS,
    participants
});

export const setCurrentMessageOpportunityAction = (opportunity) => ({
    type: actionTypes.SET_CURRENT_MESSAGE_OPPORTUNITY,
    opportunity
});

export const getCurrentChatAction = (chat, people, opportunity) => {
    let currentChat = chat;
    if (!currentChat) {
        // If chat has not been started yet
        return (dispatch) => {
            dispatch(createRoomAction(opportunity, people));
        };
    }

    setTimeout(() => {
        const chatView = document.querySelector(
            ".message-center .message-center-container .chat-view .view-body"
        );
        if (chatView) chatView.scrollTop = chatView.scrollHeight;
    }, 100);

    if (currentChat) {
        const mostRecentMessage = currentChat.chat[currentChat.chat.length - 1];
        if (mostRecentMessage) {
            window.matrixClient.setRoomReadMarkersHttpRequest(
                currentChat.chatId, //.replace("!", `${String.fromCharCode(92)}u0021`),
                mostRecentMessage.eventId,
                mostRecentMessage.eventId
            );
        }
    }

    return (dispatch, getState) => {
        const opportunity = getState().messaging.currentMessageOpportunity;
        if (opportunity)
            dispatch(
                getOpportunityDetailsForChatAction(
                    currentChat.chatId,
                    opportunity
                )
            );
        dispatch(joinRoomAction(currentChat.chatId));
        dispatch(
            setCurrentChatAction(
                currentChat || {
                    chatId: null,
                    people,
                    chat: []
                }
            )
        );
    };
};

export const setCurrentChatAction = (chat) => ({
    type: actionTypes.SET_CURRENT_CHAT,
    chat
});

export const getCurrentChatByRoomIdAction = (roomId, chats = null) => {
    return (dispatch, getState) => {
        const chatPool = chats || getState().messaging.messages;
        const chat = chatPool.find((room) => room.chatId === roomId);
        if (!chat.opportunity && chat.opportunityId) {
            dispatch(
                getOpportunityDetailsForChatAction(
                    chat.chatId,
                    chat.opportunityId
                )
            );
        }
        dispatch(setCurrentChatAction(chat));
    };
};

export const sendMessageAction = (chatId, message, user) => {
    const content = {
        body: message,
        msgtype: "m.text"
    };

    return (dispatch) => {
        window.matrixClient
            .sendEvent(chatId, "m.room.message", content, "")
            .then((res) => {
                dispatch(listMessagesAction());
                dispatch(getCurrentChatByRoomIdAction(chatId));
                dispatch({
                    type: actionTypes.SEND_MESSAGE
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };
};

export const updateMessageCountAction = (value) => ({
    type: actionTypes.UPDATE_MESSAGE_COUNT,
    value
});

export const updateUnreadMessageCountAction = (value) => ({
    type: actionTypes.UPDATE_UNREAD_MESSAGE_COUNT,
    value
});

export const createRoomAction = (opportunity = "", people = []) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const name = `fiinect__${opportunity}__${user.name.replace(
            / /g,
            ""
        )}__${people.map((person) => person.name.replace(/ /g, "")).join("_")}`;
        const roomToCreate = {
            room_alias_name: `${name}_${Math.random()}`,
            visibility: "private",
            invite: people.map((person) => person.matrixUserID),
            name,
            topic: `${opportunity}`,
            preset: "private_chat"
        };

        dispatch(createRoomLoadingAction());

        window.matrixClient
            .createRoom(roomToCreate, () => {})
            .then((res) => {
                dispatch(createRoomSuccessAction(res.room_id));
                dispatch(listMessagesAction(res.room_id));
            });
    };
};

export const createRoomLoadingAction = () => ({
    type: actionTypes.CREATE_ROOM_LOADING
});

export const createRoomSuccessAction = (roomId) => ({
    type: actionTypes.CREATE_ROOM_SUCCESS,
    roomId
});

export const joinRoomAction = (roomId) => {
    return (dispatch) => {
        window.matrixClient.joinRoom(roomId, {}, () => {
            dispatch(createRoomSuccessAction());
        });
    };
};

export const setMessageTutorialAction = (value) => ({
    type: actionTypes.SET_MESSAGE_TUTORIAL,
    value
});

export const getOpportunityDetailsForChatAction = (chatId, opportunityId) => (
    dispatch,
    getState
) => {
    const user = getState().auth.user;
    return axios
        .get(`${API_BASE}/Opportunity/${opportunityId}`)
        .then(({ data }) => {
            if (!data) {
                dispatch(
                    getOpportunityDetailsForChatErrorAction(
                        `No data for ${opportunityId}`
                    )
                );
                return;
            }
            const opportunity = mapOpportunity(data, user);
            const chats = getState().messaging.messages;
            const currentChat = getState().messaging.currentChat;
            const chatById = chats.find((chat) => chat.chatId === chatId);

            if (chatById) chatById.opportunity = opportunity;

            dispatch(listMessagesSuccessAction(chats));
            dispatch(getOpportunityDetailsForChatSuccessAction(opportunity));
            if (currentChat && currentChat.chatId === chatId) {
                currentChat.opportunity = opportunity;
                dispatch(getCurrentChatByRoomIdAction(chatId));
            }
        })
        .catch((error) => {
            dispatch(getOpportunityDetailsForChatErrorAction(error));
        });
};

export const getOpportunityDetailsForChatSuccessAction = (opportunity) => ({
    type: actionTypes.GET_OPPORTUNITY_DETAILS_FOR_CHAT_SUCCESS,
    opportunity
});

export const getOpportunityDetailsForChatErrorAction = (error) => ({
    type: actionTypes.GET_OPPORTUNITY_DETAILS_FOR_CHAT_ERROR,
    error
});

export const leaveRoomAction = (roomId) => {
    return (dispatch) => {
        window.matrixClient.leave(roomId, () => {
            dispatch(listMessagesAction());
        });
    };
};

import React, { Component } from "react";

import Button from "../button/Button";
import ProfileIcon from "../profile-icon/ProfileIcon";
import { degreeOrdinal } from "../../util";

import "./Connection.scss";

class Connection extends Component {
    click() {
        const { onClick } = this.props;

        if (onClick) onClick();
    }

    viewDetails() {
        const { setFocusedUserAction, connection } = this.props;

        setFocusedUserAction(connection);
    }

    render() {
        const {
            connection,
            selectable,
            selected,
            expanded,
            premiumInvitesCount,
            buttonText,
            isSuggestion
        } = this.props;

        let className = "connection";
        if (selectable) className += " selectable";
        if (selected) className += " selected";
        if (isSuggestion)
            // && premiumInvitesCount !== null && premiumInvitesCount < 10)
            className += " invite-locked";

        return (
            <div className={className} onClick={this.click.bind(this)}>
                <div className="profile-icon-holder">
                    <ProfileIcon person={connection}></ProfileIcon>
                </div>
                <div className="details">
                    <h6>
                        {connection.name}{" "}
                        {/* <span>{degreeOrdinal(connection.degree)}</span> */}
                    </h6>
                    {expanded && <small>{connection.occupation}</small>}
                    <small>{connection.company}</small>
                    {expanded && <p>{connection.biography}</p>}
                </div>
                {!selectable && (
                    <Button onClick={this.viewDetails.bind(this)}>
                        {buttonText ? buttonText : "View"}
                    </Button>
                )}
            </div>
        );
    }
}

export default Connection;

export const findLevelOfInvestment = (id, currencies = {}) => {
    let found = false;
    let level = {};

    Object.keys(currencies).forEach((currency) => {
        if (found) return;

        const find = currencies[currency].find((loi) => loi.id === id);
        if (find) {
            level = find;
            found = true;
        }
    });

    return level;
};

import React, { Component } from "react";

import TextInput from "../text-input/TextInput";

import "./CommentBox.scss";

class CommentBox extends Component {
    state = {
        comment: ""
    };

    onInput(value) {
        this.setState({
            comment: value
        });
    }

    postComment() {
        const { postCommentAction } = this.props;
        const { comment } = this.state;

        postCommentAction(comment);
        this.setState({
            comment: ""
        });

        document.querySelector(".comment-box input").value = "";
    }

    render() {
        const { placeholder } = this.props;

        return (
            <div className="comment-box">
                <TextInput
                    type="text"
                    onInput={this.onInput.bind(this)}
                    onEnter={this.postComment.bind(this)}
                    placeholder={placeholder}
                />
                <button onClick={this.postComment.bind(this)}>Post</button>
            </div>
        );
    }
}

export default CommentBox;

import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    all: [],
    myBusiness: {},
    businessDetails: {},
    businessMatches: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.GET_MY_BUSINESS_DETAILS_SUCCESS:
            return {
                ...state,
                myBusiness: action.business
            };
        case actionTypes.GET_BUSINESS_DETAILS_SUCCESS:
            return {
                ...state,
                businessDetails: action.business
            };
        case actionTypes.GET_MATCHES_FOR_BUSINESS_SUCCESS:
            return {
                ...state,
                businessMatches: action.matches
            };
        case actionTypes.GET_ALL_BUSINESSES_SUCCESS:
            return {
                ...state,
                all: action.businesses
            };
        case actionTypes.LOGOUT:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};

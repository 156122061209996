import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { forgottenPasswordAction } from "../../actions/auth/authActions";
import Loader from "../../components/loader/Loader";
import TextInput from "../../components/text-input/TextInput";

import "./ForgottenPassword.scss";

class ForgottenPassword extends Component {
    state = {
        email: ""
    };

    setEmail(value) {
        this.setState({
            email: value
        });
    }

    submit(e) {
        e.preventDefault();

        const { forgottenPasswordAction } = this.props;
        const { email } = this.state;

        forgottenPasswordAction(email);
    }

    render() {
        const { auth } = this.props;

        if (!auth.authCheck) {
            return <Loader />;
        }

        return (
            <div className="wrapper">
                <h1>Forgotten your password?</h1>
                <div className="form-container">
                    <form
                        onSubmit={this.submit.bind(this)}
                        className="forgotten-password-form"
                    >
                        {auth.resetEmailSent && (
                            <p>
                                Thanks. We've sent you an email to reset your
                                password.
                            </p>
                        )}
                        {!auth.resetEmailSent && (
                            <>
                                <p>
                                    No problem. Enter your email address below
                                    and we'll send you a link to reset your
                                    password.
                                </p>
                                <label>Email address</label>
                                <TextInput
                                    type="text"
                                    name="email"
                                    onInput={this.setEmail.bind(this)}
                                />

                                <button
                                    type="submit"
                                    className="button primary"
                                >
                                    Reset password
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth
    }),
    {
        forgottenPasswordAction
    }
)(ForgottenPassword);

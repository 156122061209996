import React, { Component } from "react";
import * as d3 from "d3";

import "./MatchClusterBubbleChart.scss";

class MatchClusterBubbleChart extends Component {
    componentDidMount() {
        this.createChart();
    }

    buildData() {
        const { network, filters } = this.props;

        const matchingConnections = [];
        network.connections.forEach((connection) => {
            let match = 0;

            Object.keys(filters).forEach((filter) => {
                if (!filters[filter]) return;

                if (filters[filter] === connection[filter]) match += 2;
                if (
                    Array.isArray(connection[filter]) &&
                    connection[filter].find(
                        (item) => item.id === parseInt(filters[filter])
                    )
                )
                    match += 2;
            });

            if (match > 0)
                matchingConnections.push({
                    value: connection.id, // this will become ID
                    title: connection.name,
                    group: 1,
                    size: match * 10,
                    connection
                });
        });

        return matchingConnections;
    }

    createChart() {
        const { setFocusedUserAction } = this.props;

        const width = 960,
            height = 500,
            padding = 10, // separation between same-color nodes
            clusterPadding = 10, // separation between different-color nodes
            maxRadius = 50;

        const data = this.buildData();

        data.forEach(function (d) {
            d.size = +d.size;
        });

        const n = data.length,
            m = 1;

        //create clusters and nodes
        const clusters = new Array(m);
        const nodes = [];
        for (var i = 0; i < n; i++) {
            nodes.push(create_nodes(data, i));
        }

        const force = d3.layout
            .force()
            .nodes(nodes)
            .size([width, height])
            .gravity(0.03)
            .charge(0)
            .on("tick", tick)
            .start();

        const svg = d3
            .select("#match-bubble-chart")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const node = svg
            .selectAll("circle")
            .data(nodes)
            .enter()
            .append("g")
            .call(force.drag);

        node.append("circle")
            .style("fill", function (d) {
                return "#ff4e0033";
            })
            .style("stroke", function () {
                return "#ff4e00";
            })
            .attr("r", function (d) {
                return d.radius;
            })
            .attr("data-data", function (d) {
                return JSON.stringify(d);
            })
            .on("mouseover", function (d) {
                d3.select(this)
                    .style("fill", "#5046d233")
                    .style("stroke", "#5046d2");
                d3.select(this.parentNode)
                    .select("text")
                    .style("fill", "#5046d2");
            })
            .on("mouseout", function (d) {
                d3.select(this)
                    .style("fill", "#ff4e0033")
                    .style("stroke", "#ff4e00");
                const text = d3.select(this.parentNode).select("text");
                const textWidth = text[0][0].getComputedTextLength();
                if (textWidth > d.radius * 4) {
                    text.style("fill", "transparent");
                } else {
                    text.style("fill", "#ff4e00");
                }
            })
            .on("click", function () {
                document
                    .querySelectorAll("g.selected")
                    .forEach((node) => node.classList.remove("selected"));
                this.parentNode.classList.add("selected");
                const data = JSON.parse(d3.select(this).attr("data-data"));

                setFocusedUserAction(data.data.connection);
            });

        node.append("text")
            .attr("dy", ".3em")
            .style("text-anchor", "middle")
            .style("fill", "transparent")
            .style("font-family", "'Gibson SemiBold', sans-serif")
            .style("font-size", "13px")
            .text(function (d) {
                return d.title;
            })
            .each(function (d) {
                const textWidth = this.getComputedTextLength();
                if (textWidth > d.radius * 4) {
                    d3.select(this).style("fill", "transparent");
                    this.classList.add("text-overflow");
                } else {
                    d3.select(this).style("fill", "#ff4e00");
                }
            });

        function create_nodes(data, node_counter) {
            let i = 0,
                r =
                    Math.sqrt(((i + 1) / m) * -Math.log(Math.random())) *
                    maxRadius,
                d = {
                    cluster: i,
                    radius: data[node_counter].size * 1.5,
                    title: data[node_counter].title,
                    x:
                        Math.cos((i / m) * 2 * Math.PI) * 200 +
                        width / 2 +
                        Math.random(),
                    y:
                        Math.sin((i / m) * 2 * Math.PI) * 200 +
                        height / 2 +
                        Math.random(),
                    data: data[node_counter]
                };
            if (!clusters[i] || r > clusters[i].radius) clusters[i] = d;
            return d;
        }

        function tick(e) {
            node.each(cluster(10 * e.alpha * e.alpha))
                .each(collide(1))
                .attr("transform", function (d) {
                    var k = "translate(" + d.x + "," + d.y + ")";
                    return k;
                });
        }

        // Move d to be adjacent to the cluster node.
        function cluster(alpha) {
            return function (d) {
                var cluster = clusters[d.cluster];
                if (cluster === d) return;
                var x = d.x - cluster.x,
                    y = d.y - cluster.y,
                    l = Math.sqrt((x * x) / 20 + y * y),
                    r = d.radius + cluster.radius;
                if (l != r) {
                    l = ((l - r) / l) * alpha;
                    d.x -= x *= l;
                    d.y -= y *= l;
                    cluster.x += x;
                    cluster.y += y;
                }
            };
        }

        // Resolves collisions between d and all other circles.
        function collide(alpha) {
            var quadtree = d3.geom.quadtree(nodes);
            return function (d) {
                var r =
                        d.radius +
                        maxRadius +
                        Math.max(padding, clusterPadding),
                    nx1 = d.x - r,
                    nx2 = d.x + r,
                    ny1 = d.y - r,
                    ny2 = d.y + r;
                quadtree.visit(function (quad, x1, y1, x2, y2) {
                    if (quad.point && quad.point !== d) {
                        var x = d.x - quad.point.x,
                            y = d.y - quad.point.y,
                            l = Math.sqrt(x * x + y * y),
                            r =
                                d.radius +
                                quad.point.radius +
                                (d.cluster === quad.point.cluster
                                    ? padding
                                    : clusterPadding);
                        if (l < r) {
                            l = ((l - r) / l) * alpha;
                            d.x -= x *= l;
                            d.y -= y *= l;
                            quad.point.x += x;
                            quad.point.y += y;
                        }
                    }
                    return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
                });
            };
        }
    }

    render() {
        return (
            <>
                <div id="match-bubble-chart"></div>
            </>
        );
    }
}

export default MatchClusterBubbleChart;

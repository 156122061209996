import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ThreeDCarousel.scss";

class ThreeDCarousel extends Component {
    state = {
        slidesToShow: 7
    };

    componentDidMount() {
        setInterval(() => {
            const slides = document.querySelectorAll(".slick-active");
            let slideVariant = 0;

            slides.forEach((slide) => {
                for (let i = 0; i <= slides.length; i++) {
                    slide.classList.remove(`variant-${i}`);
                }

                if (slide.classList.contains(".slick-center")) {
                    return;
                }

                slide.classList.add(`variant-${slideVariant}`);
                slideVariant++;
            });

            let slidesToShow = 7;
            if (window.innerWidth < 768) slidesToShow = 1;

            this.setState({
                slidesToShow
            });
        }, 10);
    }

    render() {
        const { children, loading } = this.props;
        const { slidesToShow } = this.state;

        return (
            <div
                className={
                    loading ? "three-d-carousel loading" : "three-d-carousel"
                }
            >
                <Slider centerMode infinite slidesToShow={slidesToShow}>
                    {children}
                    {children}
                    {children}
                </Slider>
            </div>
        );
    }
}

export default ThreeDCarousel;

import React, { Component } from "react";
import { connect } from "react-redux";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import FeedCard from "../../components/feed-card/FeedCard";
import CommentBox from "../../components/comment-box/CommentBox";
import InfoBubble from "../../components/info-bubble/InfoBubble";

import { postCommentAction } from "../../actions/network/networkActions";
import {
    openMessageCenterAction,
    setMessageCenterViewAction,
    setCurrentMessageParticipantsAction,
    setCurrentMessageOpportunityAction,
    getCurrentChatAction
} from "../../actions/messaging/messagingActions";

class Notifications extends Component {
    render() {
        const {
            auth,
            network,
            messaging,
            postCommentAction,
            openMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction
        } = this.props;

        return (
            <>
                <HighlightedContent
                    small
                    headline="Notifications"
                ></HighlightedContent>
                <MainContent>
                    <h3>
                        Latest notifications{" "}
                        <InfoBubble
                            content={
                                <p>
                                    This is an instant summary view of ALL your
                                    notifications, received & sent. These could
                                    be from the opportunities you have marked as
                                    'Interested' or 'Watching', as well as
                                    notifications from people in your network.
                                </p>
                            }
                        />
                    </h3>
                    {auth.user && (
                        <CommentBox
                            placeholder="Post a comment to your network"
                            postCommentAction={postCommentAction}
                            user={auth.user}
                        />
                    )}
                    {network.feed.map((feedItem) => (
                        <FeedCard
                            auth={auth}
                            key={feedItem.id}
                            people={[feedItem.person]}
                            sharedTo={feedItem.sharedTo}
                            type={feedItem.type.name}
                            content={feedItem.content}
                            opportunity={feedItem.opportunity}
                            business={feedItem.business}
                            datePosted={feedItem.created}
                            messaging={messaging}
                            openMessageCenterAction={openMessageCenterAction}
                            setMessageCenterViewAction={
                                setMessageCenterViewAction
                            }
                            getCurrentChatAction={getCurrentChatAction}
                            setCurrentMessageParticipantsAction={
                                setCurrentMessageParticipantsAction
                            }
                            setCurrentMessageOpportunityAction={
                                setCurrentMessageOpportunityAction
                            }
                        />
                    ))}
                </MainContent>
            </>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        network: state.network,
        messaging: state.messaging
    }),
    {
        postCommentAction,
        openMessageCenterAction,
        setMessageCenterViewAction,
        setCurrentMessageParticipantsAction,
        setCurrentMessageOpportunityAction,
        getCurrentChatAction
    }
)(Notifications);

import React, { Component } from "react";
import { Link } from "react-router-dom";

import Button from "../button/Button";
import Carousel from "../carousel/Carousel";
import Connection from "../connection/Connection";
import Modal from "../modal/Modal";
import ProfileCard from "../profile-card/ProfileCard";

import "./FiinectionSummary.scss";

class FiinectionSummary extends Component {
    state = {
        listOpen: false
    };

    toggleList() {
        this.setState({
            listOpen: !this.state.listOpen
        });
    }

    mapSlides() {
        const { suggestions, setFocusedUserAction } = this.props;

        if (suggestions.length === 0) return [];

        const columns = [];
        let rows = [];

        suggestions.forEach((suggestion, index) => {
            if (rows.length === 2) {
                columns.push(rows);
                rows = [];
            }
            rows.push(suggestion);
        });

        columns.push(rows);

        const slides = columns.map((row) => (
            <div key={row[0].name}>
                <ProfileCard person={row[0]} onClick={setFocusedUserAction} />
                <ProfileCard person={row[1]} onClick={setFocusedUserAction} />
            </div>
        ));

        return slides;
    }

    render() {
        const {
            connections,
            setFocusedUserAction,
            premiumInvitesCount
        } = this.props;

        return (
            <div className="fiinection-summary">
                <h5>Global breakdown</h5>
                <Link to={`${process.env.PUBLIC_URL}/network`}>
                    <video
                        src={`${process.env.PUBLIC_URL}/assets/map.mp4`}
                        loop
                        autoPlay
                        className="map"
                    />
                </Link>

                <p
                    onClick={this.toggleList.bind(this)}
                    className="open-connection-list"
                >
                    <strong>
                        You have {connections.length}{" "}
                        {connections.length === 1
                            ? "connection"
                            : "connections"}
                    </strong>
                </p>
                {/* TODO MED Get top region and sector % */}

                <Button href={`${process.env.PUBLIC_URL}/network`}>
                    Detailed connection data breakdown
                </Button>

                <div
                    className="" // premiumInvitesCount < 10 ? "" : "invites-unlocked"
                >
                    <h5>
                        Based on your data, you may want to connect with these
                        people
                    </h5>
                    {premiumInvitesCount < 10 && (
                        <p>
                            To unlock this feature please send out your{" "}
                            <Link to={`${process.env.PUBLIC_URL}/send-invite`}>
                                10 FREE PREMIUM INVITES
                            </Link>
                        </p>
                    )}

                    {premiumInvitesCount >= 10 && (
                        <p>
                            Congratulations! You have unlocked this feature for
                            new introductions which will launch soon.
                        </p>
                    )}
                    <Carousel idKey="fiinection-suggestions" small>
                        {this.mapSlides()}
                    </Carousel>
                </div>

                <Modal visible={this.state.listOpen}>
                    <h4>
                        <span onClick={this.toggleList.bind(this)}>
                            &#9666;
                        </span>
                        My connections
                    </h4>
                    {connections.map((c) => (
                        <Connection
                            key={c.name}
                            connection={c}
                            setFocusedUserAction={setFocusedUserAction}
                        />
                    ))}
                </Modal>
            </div>
        );
    }
}

export default FiinectionSummary;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "../../util";

import "./Navigation.scss";

class Navigation extends Component {
    state = {
        open: false
    };

    links = [
        {
            name: "Home",
            url: "/",
            image: `${process.env.PUBLIC_URL}/assets/home.svg`,
            activeImage: `${process.env.PUBLIC_URL}/assets/home_active.svg`,
            mobileImage: `${process.env.PUBLIC_URL}/assets/home_mobile.svg`,
            roles: ["Investor", "Business", "Professional", "Superadmin"],
            invitee: true
        },
        {
            name: "Opportunities",
            url: "/opportunities",
            image: `${process.env.PUBLIC_URL}/assets/opportunities.svg`,
            activeImage: `${process.env.PUBLIC_URL}/assets/opportunities_active.svg`,
            mobileImage: `${process.env.PUBLIC_URL}/assets/opportunities_mobile.svg`,
            roles: ["Investor", "Professional", "Superadmin"],
            invitee: true
        },
        {
            name: "My Business",
            url: "/my-business",
            image: `${process.env.PUBLIC_URL}/assets/opportunities.svg`,
            activeImage: `${process.env.PUBLIC_URL}/assets/opportunities_active.svg`,
            mobileImage: `${process.env.PUBLIC_URL}/assets/opportunities_mobile.svg`,
            roles: ["Business"],
            invitee: false
        },
        {
            name: "My Network",
            url: isMobile() ? "/network/overview" : "/network",
            image: `${process.env.PUBLIC_URL}/assets/network.svg`,
            activeImage: `${process.env.PUBLIC_URL}/assets/network_active.svg`,
            mobileImage: `${process.env.PUBLIC_URL}/assets/network_mobile.svg`,
            roles: ["Investor", "Business", "Professional"],
            invitee: true
        },
        {
            name: "Marketplace",
            url: "/marketplace",
            image: `${process.env.PUBLIC_URL}/assets/marketplace.svg`,
            activeImage: `${process.env.PUBLIC_URL}/assets/marketplace_active.svg`,
            mobileImage: `${process.env.PUBLIC_URL}/assets/marketplace_mobile.svg`,
            roles: ["Investor", "Business", "Professional"],
            invitee: true
        }
    ];

    open() {
        this.setState({
            open: true
        });
    }

    close() {
        this.setState({
            open: false
        });
    }

    render() {
        const { user, closeParent } = this.props;
        const { open } = this.state;

        return (
            <nav
                onMouseEnter={this.open.bind(this)}
                onMouseLeave={this.close.bind(this)}
                className={open ? "open" : ""}
            >
                <ul>
                    {this.links.map((link) => {
                        if (user && link.roles.indexOf(user.role) === -1)
                            return;
                        else if (!user && !link.invitee) return;
                        let active =
                            window.location.pathname.indexOf(link.url) > -1;
                        if (link.url === "/")
                            active = link.url === window.location.pathname;

                        return (
                            <li
                                key={link.url}
                                className={active ? "active" : ""}
                            >
                                <Link
                                    to={`${process.env.PUBLIC_URL}${link.url}`}
                                    onClick={closeParent}
                                >
                                    <div className="icon">
                                        <img
                                            src={
                                                isMobile()
                                                    ? link.mobileImage
                                                    : active
                                                    ? link.activeImage
                                                    : link.image
                                            }
                                            alt={link.name}
                                        />
                                    </div>
                                    <span>{link.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        );
    }
}

export default Navigation;

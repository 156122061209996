import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { beginRegistrationAction } from "../../actions/auth/authActions";
import {
    getSectorsAction,
    getFinanceStagesAction
} from "../../actions/auxillary/auxillaryActions";

import Button from "../../components/button/Button";
import OptionSelector from "../../components/option-selector/OptionSelector";
import PlainCard from "../../components/plain-card/PlainCard";
import RangeGroup from "../../components/range-group/RangeGroup";
import RegionSelector from "../../components/region-selector/RegionSelector";
import TextInput from "../../components/text-input/TextInput";
import {
    employeeRanges,
    timescaleOptions,
    financeRoundOptions,
    turnoverOptions
} from "../../data/data";
import countries from "../../data/countries";

import "./Register.scss";

class Register extends Component {
    state = {
        view: "userType",
        userType: "",
        timings: "",
        formValues: {
            firstName: "",
            surname: "",
            email: "",
            password: "",
            confirmPassword: "",
            businessName: "",
            headline: "",
            summary: "",
            description: "",
            youtubeUrl: "",
            websiteUrl: "",
            sectors: [],
            locations: [],
            financeStages: [],
            registrationLocation: [],
            numberOfEmployees: 1,
            IsPreRevenue: null,
            MakingProfit: null,
            terms: false,
            turnover: [],
            timescales: [],
            roundOfFinance: [],
            levelOfInvestment: [],
            occupations: [],
            expertises: [],
            CurrencyPreference: [{ id: "GBP", value: "GBP" }]
        },
        errors: []
    };

    selectUserType(userType) {
        this.setState({
            userType
        });
    }

    selectTimings(timings) {
        this.setState({
            timings
        });
    }

    setView(view) {
        this.setState({
            view
        });
    }

    countryFlagForList(countryName) {
        var country = countries.ref_country_codes.find(
            (c) => c.country === countryName
        );
        if (!country) {
            return "";
        }

        return `https://flagpedia.net/data/flags/h24/${country.alpha2.toLowerCase()}.png`;
    }

    updateForm(key, value) {
        const { formValues } = this.state;

        formValues[key] = value;
        this.setState({
            formValues
        });
    }

    updateSelectors(key, value) {
        const { formValues } = this.state;

        if (typeof value === "boolean") {
            this.setState({
                formValues: {
                    ...formValues,
                    [key]: value
                }
            });
        } else if (key === "locations") {
            this.setState({
                formValues: {
                    ...formValues,
                    locations: value
                }
            });
        } else {
            const arr1 = formValues[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));

            this.setState({
                formValues: {
                    ...formValues,
                    [key]: additions
                }
            });
        }
    }

    updateSelectorsMulti(key, value) {
        const { formValues } = this.state;

        this.setState({
            formValues: {
                ...formValues,
                [key]: value
            }
        });
    }

    updateRange(lookup, key, value) {
        const { formValues } = this.state;

        this.setState({
            formValues: {
                ...formValues,
                [key]: lookup[value]
            }
        });
    }

    acceptTerms() {
        const { formValues } = this.state;

        this.setState({
            formValues: {
                ...formValues,
                terms: !formValues.terms
            }
        });
    }

    registerInvestor(e) {
        e.preventDefault();
        const { beginRegistrationAction } = this.props;
        const { formValues } = this.state;

        const {
            firstName,
            surname,
            email,
            password,
            confirmPassword,
            terms
        } = formValues;

        e.preventDefault();

        const errors = [];
        if (!firstName) errors.push("firstNameRequired");
        if (!surname) errors.push("surnameRequired");
        if (!email) errors.push("emailRequired");
        const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
        );
        if (password && !regex.test(password)) errors.push("passwordTooWeak");
        if (!password) errors.push("passwordRequired");
        if (password !== confirmPassword) errors.push("passwordMismatch");
        if (!terms) errors.push("termsRequired");

        this.setState({
            errors
        });
        if (errors.length > 0) return;

        beginRegistrationAction(formValues);
    }

    registerProfessional(e) {
        e.preventDefault();
        const { beginRegistrationAction } = this.props;
        const { formValues } = this.state;

        const {
            firstName,
            surname,
            email,
            password,
            confirmPassword,
            terms
        } = formValues;

        e.preventDefault();

        const errors = [];
        if (!firstName) errors.push("firstNameRequired");
        if (!surname) errors.push("surnameRequired");
        if (!email) errors.push("emailRequired");
        const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
        );
        if (password && !regex.test(password)) errors.push("passwordTooWeak");
        if (!password) errors.push("passwordRequired");
        if (password !== confirmPassword) errors.push("passwordMismatch");
        if (!terms) errors.push("termsRequired");

        this.setState({
            errors
        });
        if (errors.length > 0) return;

        beginRegistrationAction(formValues, "professional");
    }

    registerBusiness(e) {
        e.preventDefault();
        const { beginRegistrationAction } = this.props;
        const { formValues, timings } = this.state;

        const {
            firstName,
            surname,
            email,
            password,
            confirmPassword,
            terms
        } = formValues;

        e.preventDefault();

        const errors = [];
        if (!firstName) errors.push("firstNameRequired");
        if (!surname) errors.push("surnameRequired");
        if (!email) errors.push("emailRequired");
        const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
        );
        if (password && !regex.test(password)) errors.push("passwordTooWeak");
        if (!password) errors.push("passwordRequired");
        if (password !== confirmPassword) errors.push("passwordMismatch");
        if (!terms) errors.push("termsRequired");

        this.setState({
            errors
        });
        if (errors.length > 0) return;

        if (timings !== "RegisterOnly") formValues.timings = timings;

        beginRegistrationAction(formValues, "business");
    }

    render() {
        const { auth, auxillary } = this.props;
        const { view, userType, timings, errors, formValues } = this.state;

        if (auth.user && auth.user.userId)
            return <Redirect to={process.env.PUBLIC_URL} />;

        return (
            <div className="wrapper">
                <h1>Join Fiinect</h1>
                <div className="form-container">
                    {view === "userType" && (
                        <>
                            <h3>I am...</h3>
                            <div className="user-types">
                                <PlainCard
                                    onClick={this.selectUserType.bind(
                                        this,
                                        "Investor"
                                    )}
                                    className={
                                        userType === "Investor"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    An investor
                                </PlainCard>
                                <PlainCard
                                    onClick={this.selectUserType.bind(
                                        this,
                                        "Business"
                                    )}
                                    className={
                                        userType === "Business"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    A business
                                </PlainCard>
                                <PlainCard
                                    onClick={this.selectUserType.bind(
                                        this,
                                        "Professional"
                                    )}
                                    className={
                                        userType === "Professional"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    A professional services provider
                                </PlainCard>
                            </div>
                            <div
                                className={
                                    userType === "Business"
                                        ? "user-types business-actions show"
                                        : "user-types business-actions"
                                }
                            >
                                <PlainCard
                                    onClick={this.selectTimings.bind(
                                        this,
                                        "Now"
                                    )}
                                    className={
                                        timings === "Now" ? "selected" : ""
                                    }
                                >
                                    Seeking finance <strong>now</strong>
                                </PlainCard>
                                <PlainCard
                                    onClick={this.selectTimings.bind(
                                        this,
                                        "Soon"
                                    )}
                                    className={
                                        timings === "Soon" ? "selected" : ""
                                    }
                                >
                                    Seeking finance <strong>soon</strong>
                                </PlainCard>
                                <PlainCard
                                    onClick={this.selectTimings.bind(
                                        this,
                                        "RegisterOnly"
                                    )}
                                    className={
                                        timings === "RegisterOnly"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    Just register
                                </PlainCard>
                            </div>
                            <div className="user-types">
                                <Button
                                    onClick={this.setView.bind(
                                        this,
                                        `register${userType}${
                                            userType === "Business"
                                                ? timings
                                                : ""
                                        }`
                                    )}
                                    isPrimary
                                    disabled={
                                        !userType ||
                                        (userType === "Business" && !timings)
                                    }
                                >
                                    Continue
                                </Button>
                            </div>
                        </>
                    )}
                    {view === "registerInvestor" && (
                        <>
                            <h3>Register as an investor</h3>
                            <h5>
                                Sign up is easy. You can save progress and
                                return at any time.
                            </h5>
                            <form
                                className={
                                    auth.authLoading
                                        ? "register-form loading"
                                        : "register-form"
                                }
                                onSubmit={this.registerInvestor.bind(this)}
                            >
                                <fieldset>
                                    <div>
                                        <label htmlFor="firstName">
                                            Your first name
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "firstNameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your first name
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "firstName"
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="surname">
                                            Your surname
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "surnameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your surname
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "surname"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="email">
                                            Your email
                                        </label>
                                        {errors.find(
                                            (error) => error === "emailRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter an email address
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "email"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="password">
                                            Create a password
                                        </label>
                                        <p>
                                            <small>
                                                Passwords should be at least 10
                                                characters long, have at least
                                                one lowercase, uppercase,
                                                numeric and punctuation
                                                character
                                            </small>
                                        </p>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter a password
                                            </p>
                                        )}
                                        {errors.find(
                                            (error) =>
                                                error === "passwordTooWeak"
                                        ) && (
                                            <p className="error">
                                                Your password is too weak,
                                                please try another
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "password"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="confirmPassword">
                                            Confirm your password
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordMismatch"
                                        ) && (
                                            <p className="error">
                                                Passwords do not match
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "confirmPassword"
                                            )}
                                        />
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <label className="terms">
                                        {errors.find(
                                            (error) => error === "termsRequired"
                                        ) && (
                                            <p className="error">
                                                Please indicate you have read
                                                the terms and conditions
                                            </p>
                                        )}
                                        <input
                                            type="checkbox"
                                            name="terms"
                                            onChange={this.acceptTerms.bind(
                                                this
                                            )}
                                        />
                                        I have read the{" "}
                                        <a href="/terms" target="_blank">
                                            Terms and conditions
                                        </a>
                                    </label>
                                </fieldset>

                                <Button isPrimary submit>
                                    Confirm
                                </Button>
                            </form>
                        </>
                    )}
                    {view === "registerProfessional" && (
                        <>
                            <h3>
                                Register as a professional services provider
                            </h3>
                            <h5>
                                Sign up is easy. You can save progress and
                                return at any time.
                            </h5>
                            <form
                                className={
                                    auth.authLoading
                                        ? "register-form loading"
                                        : "register-form"
                                }
                                onSubmit={this.registerProfessional.bind(this)}
                            >
                                <fieldset>
                                    <div>
                                        <label htmlFor="firstName">
                                            Your first name
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "firstNameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your first name
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "firstName"
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="surname">
                                            Your surname
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "surnameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your surname
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "surname"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="email">
                                            Your email
                                        </label>
                                        {errors.find(
                                            (error) => error === "emailRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter an email address
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "email"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="password">
                                            Create a password
                                        </label>
                                        <p>
                                            <small>
                                                Passwords should be at least 10
                                                characters long, have at least
                                                one lowercase, uppercase,
                                                numeric and punctuation
                                                character
                                            </small>
                                        </p>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter a password
                                            </p>
                                        )}
                                        {errors.find(
                                            (error) =>
                                                error === "passwordTooWeak"
                                        ) && (
                                            <p className="error">
                                                Your password is too weak,
                                                please try another
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "password"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="confirmPassword">
                                            Confirm your password
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordMismatch"
                                        ) && (
                                            <p className="error">
                                                Passwords do not match
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "confirmPassword"
                                            )}
                                        />
                                    </div>
                                </fieldset>

                                <h4>I am a...</h4>
                                <OptionSelector
                                    selectedValues={formValues.occupations}
                                    options={auxillary.occupations}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "occupations"
                                    )}
                                />
                                <h4>I am based in...</h4>
                                <OptionSelector
                                    vertical
                                    getImage={this.countryFlagForList.bind(
                                        this
                                    )}
                                    selectedValues={
                                        formValues.registrationLocation
                                    }
                                    options={auxillary.locations.filter(
                                        (location) => !location.isRegion
                                    )}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "registrationLocation"
                                    )}
                                />

                                <h4>This is where I operate</h4>
                                <RegionSelector
                                    allowMultiple
                                    onRegionToggle={this.updateSelectors.bind(
                                        this,
                                        "locations"
                                    )}
                                />

                                {formValues.occupations.length > 0 && (
                                    <>
                                        <h4>
                                            I can provide services in the
                                            following areas{" "}
                                        </h4>
                                        <OptionSelector
                                            selectedValues={
                                                formValues.expertises
                                            }
                                            noIcon
                                            options={auxillary.expertise.filter(
                                                (e) =>
                                                    e.occupationId ===
                                                    formValues.occupations[0].id
                                            )}
                                            action={this.updateSelectorsMulti.bind(
                                                this,
                                                "expertises"
                                            )}
                                        />
                                    </>
                                )}

                                <h4>I work in these sectors</h4>
                                <OptionSelector
                                    selectedValues={formValues.sectors}
                                    options={auxillary.sectorInterests}
                                    sectorVariant
                                    imageFormat="svg"
                                    action={this.updateSelectorsMulti.bind(
                                        this,
                                        "sectors"
                                    )}
                                />
                                <h4>I work with clients in these areas</h4>
                                <OptionSelector
                                    selectedValues={formValues.financeStages}
                                    options={auxillary.financeStages}
                                    action={this.updateSelectorsMulti.bind(
                                        this,
                                        "financeStages"
                                    )}
                                />

                                <fieldset>
                                    <label className="terms">
                                        {errors.find(
                                            (error) => error === "termsRequired"
                                        ) && (
                                            <p className="error">
                                                Please indicate you have read
                                                the terms and conditions
                                            </p>
                                        )}
                                        <input
                                            type="checkbox"
                                            name="terms"
                                            onChange={this.acceptTerms.bind(
                                                this
                                            )}
                                        />
                                        I have read the{" "}
                                        <a href="/terms" target="_blank">
                                            Terms and conditions
                                        </a>
                                    </label>
                                </fieldset>

                                <Button isPrimary submit>
                                    Confirm
                                </Button>
                            </form>
                        </>
                    )}
                    {view === "registerBusinessRegisterOnly" && (
                        <>
                            <h3>Register as a business</h3>
                            <h5>
                                Sign up is easy. You can save progress and
                                return at any time.
                            </h5>
                            <form
                                className={
                                    auth.authLoading
                                        ? "register-form loading"
                                        : "register-form"
                                }
                                onSubmit={this.registerBusiness.bind(this)}
                            >
                                <fieldset>
                                    <div>
                                        <label htmlFor="firstName">
                                            Your first name
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "firstNameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your first name
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "firstName"
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="surname">
                                            Your surname
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "surnameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your surname
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "surname"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="email">
                                            Your email
                                        </label>
                                        {errors.find(
                                            (error) => error === "emailRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter an email address
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "email"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="password">
                                            Create a password
                                        </label>
                                        <p>
                                            <small>
                                                Passwords should be at least 10
                                                characters long, have at least
                                                one lowercase, uppercase,
                                                numeric and punctuation
                                                character
                                            </small>
                                        </p>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter a password
                                            </p>
                                        )}
                                        {errors.find(
                                            (error) =>
                                                error === "passwordTooWeak"
                                        ) && (
                                            <p className="error">
                                                Your password is too weak,
                                                please try another
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "password"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="confirmPassword">
                                            Confirm your password
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordMismatch"
                                        ) && (
                                            <p className="error">
                                                Passwords do not match
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "confirmPassword"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="headline">
                                            Name of your business
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "headlineRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter the name of your
                                                business
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "headline"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <h4>In which sector is your business?</h4>
                                <OptionSelector
                                    selectedValues={formValues.sectors}
                                    options={auxillary.sectorInterests}
                                    sectorVariant
                                    imageFormat="svg"
                                    action={this.updateSelectors.bind(
                                        this,
                                        "sectors"
                                    )}
                                />
                                <h4>Where do you operate?</h4>
                                <RegionSelector
                                    allowMultiple
                                    onRegionToggle={this.updateSelectors.bind(
                                        this,
                                        "locations"
                                    )}
                                />
                                <h4>
                                    In what country is your company
                                    incorporated/registered?
                                </h4>
                                <OptionSelector
                                    vertical
                                    getImage={this.countryFlagForList.bind(
                                        this
                                    )}
                                    selectedValues={
                                        formValues.registrationLocation
                                    }
                                    options={auxillary.locations.filter(
                                        (location) => !location.isRegion
                                    )}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "registrationLocation"
                                    )}
                                />
                                <h4>
                                    Do you know what type of finance you may
                                    want?
                                </h4>
                                <OptionSelector
                                    selectedValues={formValues.financeStages}
                                    options={auxillary.financeStages}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "financeStages"
                                    )}
                                />
                                <h4>Number of employees</h4>
                                <RangeGroup
                                    smallIcon={`${process.env.PUBLIC_URL}/assets/single-employee.svg`}
                                    largeIcon={`${process.env.PUBLIC_URL}/assets/many-employees.svg`}
                                    initialValue={0}
                                    onChange={this.updateRange.bind(
                                        this,
                                        employeeRanges,
                                        "numberOfEmployees"
                                    )}
                                    lookup={employeeRanges}
                                    notches
                                />{" "}
                                <fieldset>
                                    <label className="terms">
                                        {errors.find(
                                            (error) => error === "termsRequired"
                                        ) && (
                                            <p className="error">
                                                Please indicate you have read
                                                the terms and conditions
                                            </p>
                                        )}
                                        <input
                                            type="checkbox"
                                            name="terms"
                                            onChange={this.acceptTerms.bind(
                                                this
                                            )}
                                        />
                                        I have read the{" "}
                                        <a href="/terms" target="_blank">
                                            Terms and conditions
                                        </a>
                                    </label>
                                </fieldset>
                                <Button isPrimary submit>
                                    Confirm
                                </Button>
                            </form>
                        </>
                    )}
                    {(view === "registerBusinessSoon" ||
                        view === "registerBusinessNow") && (
                        <>
                            <h3>Register as a business</h3>
                            <h5>
                                Sign up is easy. You can save progress and
                                return at any time.
                            </h5>
                            <form
                                className={
                                    auth.authLoading
                                        ? "register-form loading"
                                        : "register-form"
                                }
                                onSubmit={this.registerBusiness.bind(this)}
                            >
                                <fieldset>
                                    <div>
                                        <label htmlFor="firstName">
                                            Your first name
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "firstNameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your first name
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "firstName"
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="surname">
                                            Your surname
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "surnameRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter your surname
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "surname"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="email">
                                            Your email
                                        </label>
                                        {errors.find(
                                            (error) => error === "emailRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter an email address
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "email"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="password">
                                            Create a password
                                        </label>
                                        <p>
                                            <small>
                                                Passwords should be at least 10
                                                characters long, have at least
                                                one lowercase, uppercase,
                                                numeric and punctuation
                                                character
                                            </small>
                                        </p>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter a password
                                            </p>
                                        )}
                                        {errors.find(
                                            (error) =>
                                                error === "passwordTooWeak"
                                        ) && (
                                            <p className="error">
                                                Your password is too weak,
                                                please try another
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "password"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="confirmPassword">
                                            Confirm your password
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "passwordMismatch"
                                        ) && (
                                            <p className="error">
                                                Passwords do not match
                                            </p>
                                        )}
                                        <TextInput
                                            type="password"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "confirmPassword"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <label htmlFor="headline">
                                            Name of your business
                                        </label>
                                        {errors.find(
                                            (error) =>
                                                error === "headlineRequired"
                                        ) && (
                                            <p className="error">
                                                Please enter the name of your
                                                business
                                            </p>
                                        )}
                                        <TextInput
                                            type="text"
                                            onInput={this.updateForm.bind(
                                                this,
                                                "headline"
                                            )}
                                        />
                                    </div>
                                </fieldset>
                                <h4>Are you?</h4>
                                <OptionSelector
                                    selectedValues={formValues.financeStages}
                                    options={auxillary.financeStages}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "financeStages"
                                    )}
                                />
                                {view === "registerBusinessSoon" && (
                                    <>
                                        <h4>What is your annual turnover?</h4>
                                        <OptionSelector
                                            selectedValues={formValues.turnover}
                                            options={turnoverOptions}
                                            action={this.updateSelectors.bind(
                                                this,
                                                "turnover"
                                            )}
                                        />
                                    </>
                                )}
                                {view === "registerBusinessNow" && (
                                    <>
                                        <h4>Are you pre-revenue?</h4>
                                        <OptionSelector
                                            isBinary
                                            isBinarySelected={
                                                formValues.IsPreRevenue
                                            }
                                            binaryTrueText=""
                                            binaryFalseText=""
                                            action={this.updateSelectors.bind(
                                                this,
                                                "IsPreRevenue"
                                            )}
                                        />
                                    </>
                                )}
                                <h4>Are you making a profit?</h4>
                                <OptionSelector
                                    isBinary
                                    isBinarySelected={formValues.MakingProfit}
                                    binaryTrueText=""
                                    binaryFalseText=""
                                    action={this.updateSelectors.bind(
                                        this,
                                        "MakingProfit"
                                    )}
                                />

                                {view === "registerBusinessSoon" && (
                                    <>
                                        <h4>When do you plan to raise?</h4>
                                        <OptionSelector
                                            selectedValues={
                                                formValues.timescales
                                            }
                                            options={timescaleOptions}
                                            noIcon
                                            action={this.updateSelectors.bind(
                                                this,
                                                "timescales"
                                            )}
                                        />
                                    </>
                                )}
                                <h4>Currency preference</h4>
                                <OptionSelector
                                    selectedValues={
                                        formValues.CurrencyPreference
                                    }
                                    options={Object.keys(
                                        auxillary.levelOfInvestment
                                    ).map((key) => ({ id: key, value: key }))}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "CurrencyPreference"
                                    )}
                                    noIcon
                                    vertical
                                    preventDeselect
                                />
                                <h4>How much will you be raising?</h4>
                                <OptionSelector
                                    selectedValues={
                                        formValues.levelOfInvestment
                                    }
                                    options={
                                        Object.keys(auxillary.levelOfInvestment)
                                            .length > 0
                                            ? [
                                                  {
                                                      id: 0,
                                                      ticketSize: "0",
                                                      value: "I don't know yet"
                                                  },
                                                  ...auxillary
                                                      .levelOfInvestment[
                                                      formValues
                                                          .CurrencyPreference[0]
                                                          .id
                                                  ]
                                              ]
                                            : []
                                    }
                                    action={this.updateSelectors.bind(
                                        this,
                                        "levelOfInvestment"
                                    )}
                                />
                                <h4>Which round of finance are you raising?</h4>
                                <OptionSelector
                                    vertical
                                    selectedValues={formValues.roundOfFinance}
                                    options={financeRoundOptions}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "roundOfFinance"
                                    )}
                                />
                                {view === "registerBusinessSoon" && (
                                    <>
                                        <h4>Number of employees</h4>
                                        <RangeGroup
                                            smallIcon={`${process.env.PUBLIC_URL}/assets/single-employee.svg`}
                                            largeIcon={`${process.env.PUBLIC_URL}/assets/many-employees.svg`}
                                            initialValue={0}
                                            onChange={this.updateRange.bind(
                                                this,
                                                employeeRanges,
                                                "numberOfEmployees"
                                            )}
                                            lookup={employeeRanges}
                                            notches
                                        />
                                    </>
                                )}
                                <h4>In which sector is your business?</h4>
                                <OptionSelector
                                    selectedValues={formValues.sectors}
                                    options={auxillary.sectorInterests}
                                    sectorVariant
                                    imageFormat="svg"
                                    action={this.updateSelectors.bind(
                                        this,
                                        "sectors"
                                    )}
                                />
                                <h4>Where do you operate?</h4>
                                <RegionSelector
                                    allowMultiple
                                    onRegionToggle={this.updateSelectors.bind(
                                        this,
                                        "locations"
                                    )}
                                />
                                <h4>
                                    In what country is your company
                                    incorporated/registered?
                                </h4>
                                <OptionSelector
                                    vertical
                                    getImage={this.countryFlagForList.bind(
                                        this
                                    )}
                                    selectedValues={
                                        formValues.registrationLocation
                                    }
                                    options={auxillary.locations.filter(
                                        (location) => !location.isRegion
                                    )}
                                    action={this.updateSelectors.bind(
                                        this,
                                        "registrationLocation"
                                    )}
                                />
                                {view === "registerBusinessNow" && (
                                    <>
                                        <fieldset>
                                            {/* <div>
                                                <label htmlFor="headline">
                                                    Title of your business
                                                </label>
                                                <p>
                                                    Top-line description of your
                                                    business in one sentence.
                                                    Try to include all the
                                                    important keywords here
                                                </p>
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateForm.bind(
                                                        this,
                                                        "Headline"
                                                    )}
                                                />
                                            </div> */}
                                        </fieldset>

                                        <fieldset>
                                            <div>
                                                <label htmlFor="summary">
                                                    A brief summary of your
                                                    business
                                                </label>
                                                <p>
                                                    This paragraph will appear
                                                    on your business's info card
                                                    to help prospective buyers
                                                    an at-a-glance understanding
                                                    of your offer
                                                </p>
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateForm.bind(
                                                        this,
                                                        "summary"
                                                    )}
                                                    multiline
                                                />
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div>
                                                <label htmlFor="description">
                                                    A detailed description of
                                                    your business
                                                </label>
                                                <p>
                                                    This paragraph will appear
                                                    on your business's page to
                                                    give prospective investors a
                                                    more in-depth understanding
                                                    of your offer
                                                </p>
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateForm.bind(
                                                        this,
                                                        "description"
                                                    )}
                                                    multiline
                                                />
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div>
                                                <label htmlFor="youtubeUrl">
                                                    Add a video link
                                                </label>
                                                <p>
                                                    Upload a video to YouTube
                                                    and copy and paste the link
                                                    here
                                                </p>

                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateForm.bind(
                                                        this,
                                                        "youtubeUrl"
                                                    )}
                                                />
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div>
                                                <label htmlFor="websiteUrl">
                                                    Add a website link
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateForm.bind(
                                                        this,
                                                        "websiteUrl"
                                                    )}
                                                />
                                            </div>
                                        </fieldset>
                                    </>
                                )}
                                <fieldset>
                                    <label className="terms">
                                        {errors.find(
                                            (error) => error === "termsRequired"
                                        ) && (
                                            <p className="error">
                                                Please indicate you have read
                                                the terms and conditions
                                            </p>
                                        )}
                                        <input
                                            type="checkbox"
                                            name="terms"
                                            onChange={this.acceptTerms.bind(
                                                this
                                            )}
                                        />
                                        I have read the{" "}
                                        <a href="/terms" target="_blank">
                                            Terms and conditions
                                        </a>
                                    </label>
                                </fieldset>
                                <Button isPrimary submit>
                                    Confirm
                                </Button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        auxillary: state.auxillary
    }),
    {
        beginRegistrationAction,
        getSectorsAction,
        getFinanceStagesAction
    }
)(Register);

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { mapPerson } from "../../data/people";
import Button from "../button/Button";
import InfoBubble from "../info-bubble/InfoBubble";
import TextInput from "../text-input/TextInput";

import "./Search.scss";

class Search extends Component {
    state = {
        view: "All",
        query: "",
        open: false,
        redirect: null,
        fullResults: false
    };

    timeout;

    componentDidUpdate() {
        const { redirect, fullResults } = this.state;

        if (redirect !== null) {
            this.setState({
                redirect: null
            });
        }

        if (window.location.href.indexOf("/search") === -1 && fullResults) {
            this.setState({
                fullResults: false
            });
        }
    }

    onInput(query, e) {
        const { fullResults } = this.state;
        const { searchAction, resetSearchAction } = this.props;

        clearTimeout(this.timeout);
        this.setState({
            query
        });

        if (query.length >= 3) {
            this.timeout = setTimeout(() => {
                searchAction(query);
                if (fullResults) return;
                this.setState({
                    open: true
                });
            }, 500);
        } else {
            resetSearchAction();
        }
    }

    setView(view) {
        this.setState({
            view
        });
    }

    resetSearch() {
        const { resetSearchAction } = this.props;

        resetSearchAction();
        this.setState({
            open: false
        });
    }

    focusUser(user) {
        const { setFocusedUserAction } = this.props;

        this.resetSearch();
        setFocusedUserAction(mapPerson(user));
    }

    viewAll() {
        const { query } = this.state;
        const { openFullResultsAction } = this.props;

        openFullResultsAction();
        this.setState({
            fullResults: true,
            open: false,
            redirect: `/search/${query}`
        });
    }

    render() {
        const { view, query, open, redirect } = this.state;
        const { searchResults } = this.props;

        if (redirect) return <Redirect to={redirect} />;

        const regex = new RegExp(query, "ig");

        return (
            <div className="search">
                <label htmlFor="search">Search</label>
                <TextInput
                    type="text"
                    onInput={this.onInput.bind(this)}
                    onEnter={this.viewAll.bind(this)}
                />
                <span
                    onClick={this.viewAll.bind(this)}
                    className="search-button-proxy"
                ></span>
                {open &&
                    (searchResults.users.length > 0 ||
                        searchResults.opportunities.length > 0 ||
                        searchResults.businesses.length > 0) && (
                        <div className="search-results">
                            <header>
                                <Button
                                    onClick={this.setView.bind(this, "All")}
                                    cssClass={view === "All" ? "selected" : ""}
                                >
                                    All
                                </Button>
                                <Button
                                    onClick={this.setView.bind(this, "People")}
                                    cssClass={
                                        view === "People" ? "selected" : ""
                                    }
                                >
                                    People
                                </Button>
                                <Button
                                    onClick={this.setView.bind(
                                        this,
                                        "Opportunities"
                                    )}
                                    cssClass={
                                        view === "Opportunities"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    Opportunities
                                </Button>
                                <Button
                                    onClick={this.setView.bind(
                                        this,
                                        "Businesses"
                                    )}
                                    cssClass={
                                        view === "Businesses" ? "selected" : ""
                                    }
                                >
                                    Businesses
                                </Button>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    className="close"
                                    alt="Close"
                                    onClick={this.resetSearch.bind(this)}
                                />
                            </header>
                            <section>
                                <ul>
                                    {view === "All" && (
                                        <>
                                            {searchResults.users
                                                .slice(0, 2)
                                                .map((user) => {
                                                    const result = user.name.replace(
                                                        regex,
                                                        `<strong>${query}</strong>`
                                                    );
                                                    // TODO MED Degree
                                                    return (
                                                        <li
                                                            onClick={this.focusUser.bind(
                                                                this,
                                                                user
                                                            )}
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${result} - <small>People</small>`
                                                            }}
                                                        ></li>
                                                    );
                                                })}
                                            {searchResults.opportunities
                                                .slice(0, 2)
                                                .map((opportunity) => {
                                                    const result = opportunity.headline.replace(
                                                        regex,
                                                        `<strong>${query}</strong>`
                                                    );
                                                    return (
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}/opportunities/${opportunity.id}`}
                                                            onClick={this.resetSearch.bind(
                                                                this
                                                            )}
                                                        >
                                                            <li
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${result} - <small>Opportunity</small>`
                                                                }}
                                                            ></li>
                                                        </Link>
                                                    );
                                                })}
                                            {searchResults.businesses
                                                .slice(0, 2)
                                                .map((business) => {
                                                    let result;
                                                    if (
                                                        business.name.indexOf(
                                                            query
                                                        ) > -1 ||
                                                        !business.headline
                                                    ) {
                                                        result = business.name.replace(
                                                            regex,
                                                            `<strong>${query}</strong>`
                                                        );
                                                    } else {
                                                        result = business.headline.replace(
                                                            regex,
                                                            `<strong>${query}</strong>`
                                                        );
                                                    }
                                                    return (
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}/businesses/${business.id}`}
                                                            onClick={this.resetSearch.bind(
                                                                this
                                                            )}
                                                        >
                                                            <li
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${result} - <small>Business</small>`
                                                                }}
                                                            ></li>
                                                        </Link>
                                                    );
                                                })}
                                        </>
                                    )}
                                    {view === "People" &&
                                        searchResults.users
                                            .slice(0, 2)
                                            .map((user) => {
                                                const result = user.name.replace(
                                                    regex,
                                                    `<strong>${query}</strong>`
                                                );
                                                // TODO MED Degree
                                                return (
                                                    <li
                                                        onClick={this.focusUser.bind(
                                                            this,
                                                            user
                                                        )}
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${result} - <small>People</small>`
                                                        }}
                                                    ></li>
                                                );
                                            })}
                                    {view === "Opportunities" &&
                                        searchResults.opportunities
                                            .slice(0, 2)
                                            .map((opportunity) => {
                                                const result = opportunity.headline.replace(
                                                    regex,
                                                    `<strong>${query}</strong>`
                                                );
                                                return (
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/opportunities/${opportunity.id}`}
                                                        onClick={this.resetSearch.bind(
                                                            this
                                                        )}
                                                    >
                                                        <li
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${result} - <small>Opportunity</small>`
                                                            }}
                                                        ></li>
                                                    </Link>
                                                );
                                            })}
                                    {view === "Businesses" &&
                                        searchResults.businesses
                                            .slice(0, 2)
                                            .map((business) => {
                                                let result;
                                                if (
                                                    business.name.indexOf(
                                                        query
                                                    ) > -1 ||
                                                    !business.headline
                                                ) {
                                                    result = business.name.replace(
                                                        regex,
                                                        `<strong>${query}</strong>`
                                                    );
                                                } else {
                                                    result = business.headline.replace(
                                                        regex,
                                                        `<strong>${query}</strong>`
                                                    );
                                                }
                                                return (
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/businesses/${business.id}`}
                                                        onClick={this.resetSearch.bind(
                                                            this
                                                        )}
                                                    >
                                                        <li
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${result} - <small>Business</small>`
                                                            }}
                                                        ></li>
                                                    </Link>
                                                );
                                            })}
                                </ul>
                            </section>
                            <footer>
                                <Button onClick={this.viewAll.bind(this)}>
                                    View all results
                                </Button>
                            </footer>
                        </div>
                    )}
            </div>
        );
    }
}

export default Search;

import { LOCATION_CHANGE } from "connected-react-router";

import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    all: [],
    latest: [],
    inbox: {},
    interested: {
        "Profiled opportunities you have selected": []
    },
    mine: [],
    comingSoon: [],
    inboxView: "",
    opportunityDetails: {},
    draftMatches: [],
    opportunityMatches: [],
    executiveBrochure: [],
    investorDeck: [],
    documents: [],
    documentUploading: false,
    createdOpportunityId: "",
    inboxLoading: false,
    savingChanges: false,
    sharing: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                inbox: INITIAL_STATE.inbox
            };
        case actionTypes.GET_PROFILED_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                inbox: action.opportunities
            };
        case actionTypes.UPDATE_INBOX:
        case actionTypes.GET_FOLLOWED_OPPORTUNITIES_SUCCESS:
        case actionTypes.GET_NOT_INTERESTED_OPPORTUNITIES_SUCCESS:
        case actionTypes.GET_INTERESTED_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                inbox: action.opportunities
            };
        case actionTypes.GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_SUCCESS:
            return {
                ...state,
                interested: action.opportunities
            };
        case actionTypes.GET_OPPORTUNITIES_COMING_SOON_SUCCESS:
            return {
                ...state,
                comingSoon: action.opportunities
            };
        case actionTypes.SET_INBOX_VIEW:
            return {
                ...state,
                inboxView: action.view
            };
        case actionTypes.GET_OPPORTUNITY_DETAILS_SUCCESS:
            return {
                ...state,
                opportunityDetails: action.opportunity,
                executiveBrochure: INITIAL_STATE.executiveBrochure,
                investorDeck: INITIAL_STATE.investorDeck,
                documents: INITIAL_STATE.documents
            };
        case actionTypes.UPDATE_LATEST_OPPORTUNITIES:
        case actionTypes.GET_LATEST_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                latest: action.opportunities
            };
        case actionTypes.GET_ALL_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                all: action.opportunities
            };
        case actionTypes.GET_OPPORTUNITY_DETAILS_FOR_CHAT_SUCCESS:
            return {
                ...state,
                all: [...state.all, action.opportunity]
            };
        case actionTypes.RESET_DRAFT_MATCHES:
            return {
                ...state,
                createdOpportunityId: INITIAL_STATE.createdOpportunityId,
                draftMatches: INITIAL_STATE.draftMatches,
                documents: INITIAL_STATE.documents
            };
        case actionTypes.GET_MATCHES_FOR_OPPORTUNITY_SUCCESS:
            return {
                ...state,
                draftMatches: action.matches,
                opportunityMatches: action.matches
            };
        case actionTypes.CREATE_OPPORTUNITY:
            return {
                ...state,
                createdOpportunityId: INITIAL_STATE.createdOpportunityId
            };
        case actionTypes.CREATE_OPPORTUNITY_SUCCESS:
            return {
                ...state,
                createdOpportunityId: action.opportunityId
            };
        case actionTypes.EDIT_OPPORTUNITY:
        case actionTypes.TOGGLE_OPPORTUNITY_ACTIVE:
            return {
                ...state,
                savingChanges: true
            };
        case actionTypes.EDIT_OPPORTUNITY_SUCCESS:
        case actionTypes.TOGGLE_OPPORTUNITY_ACTIVE_SUCCESS:
            return {
                ...state,
                savingChanges: false
            };
        case actionTypes.SET_INTEREST_STATUS:
            return {
                ...state,
                inboxLoading: true
            };
        case actionTypes.SET_INTEREST_STATUS_SUCCESS:
            return {
                ...state,
                opportunityDetails: {
                    ...state.opportunityDetails,
                    status: action.status
                },
                inboxLoading: false
            };
        case actionTypes.SHARE_OPPORTUNITY:
            return {
                ...state,
                sharing: true
            };
        case actionTypes.SHARE_OPPORTUNITY_SUCCESS:
        case actionTypes.SHARE_OPPORTUNITY_ERROR:
            return {
                ...state,
                sharing: false
            };
        case actionTypes.GET_MY_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                mine: action.opportunities,
                inbox: action.matches
            };
        case actionTypes.UPLOAD_DOCUMENT:
            return {
                ...state,
                documentUploading: true
            };
        case actionTypes.UPLOAD_DOCUMENT_SUCCESS:
        case actionTypes.OPPORTUNITY_DOCUMENT:
            return {
                ...state,
                documentUploading: false,
                [action.key]: [...state[action.key], action.file]
            };
        case actionTypes.UPLOAD_DOCUMENT_ERROR:
            return {
                ...state,
                documentUploading: false
            };
        case actionTypes.REMOVE_DOCUMENT:
            return {
                ...state,
                documents: action.documents
            };
        case actionTypes.LOGOUT:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};

import React, { Component } from "react";
import { connect } from "react-redux";

import {
    getInvitedUserAction,
    beginRegistrationAction
} from "../../actions/auth/authActions";
import Button from "../../components/button/Button";
import Loader from "../../components/loader/Loader";
import Modal from "../../components/modal/Modal";
import TextInput from "../../components/text-input/TextInput";
import OpportunityDetails from "../opportunity-details/OpportunityDetails";
import Home from "../home/Home";

import "./Invite.scss";
import PlainCard from "../../components/plain-card/PlainCard";

class Invite extends Component {
    state = {
        modalOpen: false,
        firstName: null,
        surname: null,
        email: null,
        password: null,
        confirmPassword: null,
        terms: false,
        errors: [],
        loginLoading: false,
        userType: ""
    };

    selectUserType(userType) {
        this.setState({
            userType
        });
    }

    componentDidMount() {
        const { getInvitedUserAction } = this.props;
        const key = this.props.match.params.key;

        getInvitedUserAction(key);

        setTimeout(() => {
            this.setState({
                modalOpen: true
            });
        }, 100);
    }

    componentDidUpdate() {
        const { user } = this.props;

        if (!user) return;
        if (this.state.modalOpen && user.userId) {
            this.setState({
                modalOpen: false
            });
        }
        if (this.state.firstName !== null) return;

        const newStateObject = {
            firstName: user.firstName,
            surname: user.surname,
            email: user.email
        };

        this.setState(newStateObject);
    }

    updateForm(key, value) {
        const { user } = this.props;

        let newValue = value;
        if (newValue === "") {
            newValue = user[key];
        }

        this.setState({
            [key]: value
        });
    }

    acceptTerms() {
        this.setState({
            terms: !this.state.terms
        });
    }

    submit(e) {
        const { beginRegistrationAction } = this.props;
        const {
            userType,
            firstName,
            surname,
            email,
            password,
            confirmPassword,
            terms
        } = this.state;

        e.preventDefault();

        const errors = [];
        if (!userType) errors.push("userTypeRequired");
        if (!firstName) errors.push("firstNameRequired");
        if (!surname) errors.push("surnameRequired");
        if (!email) errors.push("emailRequired");
        const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
        );
        if (password && !regex.test(password)) errors.push("passwordTooWeak");
        if (!password) errors.push("passwordRequired");
        if (password !== confirmPassword) errors.push("passwordMismatch");
        if (!terms) errors.push("termsRequired");

        this.setState({
            errors
        });
        if (errors.length > 0) return;

        this.setState({
            loginLoading: true
        });

        beginRegistrationAction(this.state, userType);
    }

    render() {
        const { user, welcomeModal, invitedOpportunity } = this.props;
        const { errors, loginLoading, modalOpen, userType } = this.state;

        if (!user) return null;

        return (
            <>
                {loginLoading && modalOpen && <Loader />}
                <Modal fullScreen visible={welcomeModal}>
                    <div className="welcome">
                        <h2>Welcome to Fiinect</h2>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/opportunities_active.svg`}
                            />
                        </div>
                        <p>Other opportunities match your profile</p>
                        <Button href={`${process.env.PUBLIC_URL}/`} isPrimary>
                            Access now
                        </Button>
                    </div>
                </Modal>
                <Modal fullScreen visible={modalOpen}>
                    <form
                        className="invite-form"
                        onSubmit={this.submit.bind(this)}
                    >
                        <h3>Please confirm your details below</h3>

                        <fieldset>
                            <div className="user-types">
                                <label for="userType">I am a...</label>
                                {errors.find(
                                    (error) => error === "userTypeRequired"
                                ) && (
                                    <p className="error">
                                        Please let us know what you are
                                    </p>
                                )}
                                <PlainCard
                                    onClick={this.selectUserType.bind(
                                        this,
                                        "investor"
                                    )}
                                    className={
                                        userType === "investor"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    An investor
                                </PlainCard>
                                <PlainCard
                                    onClick={this.selectUserType.bind(
                                        this,
                                        "business"
                                    )}
                                    className={
                                        userType === "business"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    A business
                                </PlainCard>
                                <PlainCard
                                    onClick={this.selectUserType.bind(
                                        this,
                                        "professional"
                                    )}
                                    className={
                                        userType === "professional"
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    A professional services provider
                                </PlainCard>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label for="firstName">Your first name</label>
                                {errors.find(
                                    (error) => error === "firstNameRequired"
                                ) && (
                                    <p className="error">
                                        Please enter your first name
                                    </p>
                                )}
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "firstName"
                                    )}
                                    placeholder={user.firstName}
                                    keepPlaceholder
                                />
                            </div>
                            <div>
                                <label for="surname">Your surname</label>
                                {errors.find(
                                    (error) => error === "surnameRequired"
                                ) && (
                                    <p className="error">
                                        Please enter your surname
                                    </p>
                                )}
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "surname"
                                    )}
                                    placeholder={user.surname}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div>
                                <label for="email">Your email</label>
                                {errors.find(
                                    (error) => error === "emailRequired"
                                ) && (
                                    <p className="error">
                                        Please enter an email address
                                    </p>
                                )}
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "email"
                                    )}
                                    placeholder={user.email}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div>
                                <label for="password">Create a password</label>
                                <p>
                                    <small>
                                        Passwords should be at least 10
                                        characters long, have at least one
                                        lowercase, uppercase, numeric and
                                        punctuation character
                                    </small>
                                </p>
                                {errors.find(
                                    (error) => error === "passwordRequired"
                                ) && (
                                    <p className="error">
                                        Please enter a password
                                    </p>
                                )}
                                {errors.find(
                                    (error) => error === "passwordTooWeak"
                                ) && (
                                    <p className="error">
                                        Your password is too weak, please try
                                        another
                                    </p>
                                )}
                                <TextInput
                                    type="password"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "password"
                                    )}
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div>
                                <label for="confirmPassword">
                                    Confirm your password
                                </label>
                                {errors.find(
                                    (error) => error === "passwordMismatch"
                                ) && (
                                    <p className="error">
                                        Passwords do not match
                                    </p>
                                )}
                                <TextInput
                                    type="password"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "confirmPassword"
                                    )}
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <label class="terms">
                                {errors.find(
                                    (error) => error === "termsRequired"
                                ) && (
                                    <p className="error">
                                        Please indicate you have read the terms
                                        and conditions
                                    </p>
                                )}
                                <input
                                    type="checkbox"
                                    name="terms"
                                    onChange={this.acceptTerms.bind(this)}
                                />
                                I have read the{" "}
                                <a href="/terms" target="_blank">
                                    Terms and conditions
                                </a>
                            </label>
                        </fieldset>

                        <Button isPrimary submit>
                            Confirm
                        </Button>
                    </form>
                </Modal>
                {invitedOpportunity && (
                    <OpportunityDetails opportunityId={invitedOpportunity} />
                )}
                {!invitedOpportunity && <Home />}
            </>
        );
    }
}

export default connect(
    (state) => ({
        welcomeModal: state.auth.welcomeModal,
        user: state.auth.user,
        invitedOpportunity: state.auth.invitedOpportunity
    }),
    {
        getInvitedUserAction,
        beginRegistrationAction
    }
)(Invite);

export const objectsEqual = (o1, o2) =>
    o1 && o2 && typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

export const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const degreeOrdinal = (num) => {
    let ordinal = "";
    switch (num) {
        case 1:
            ordinal = "1st";
            break;
        case 2:
            ordinal = "2nd";
            break;
        case 3:
            ordinal = "3rd";
            break;
    }
    return ordinal;
};

export const timeAgo = (d) => {
    const now = new Date();
    now.setHours(now.getHours() - 1);
    const then = new Date(d);
    const msDiff = now - then;
    let diff;
    let unit = "seconds";
    diff = Math.floor(msDiff / 1000);
    if (diff > 60) {
        unit = "minutes";
        diff = Math.floor(msDiff / (1000 * 60));
        if (diff > 60) {
            unit = "hours";
            diff = Math.floor(msDiff / (1000 * 60 * 60));
            if (diff > 24) {
                unit = "days";
                diff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
                if (diff > 365) {
                    unit = "years";
                    diff = Math.floor(msDiff / (1000 * 60 * 60 * 24 * 365));
                } else if (diff > 28) {
                    unit = "months";
                    diff = Math.floor(msDiff / (1000 * 60 * 60 * 24 * 28));
                } else if (diff > 7) {
                    unit = "weeks";
                    diff = Math.floor(msDiff / (1000 * 60 * 60 * 24 * 7));
                }
            }
        }
    } else {
        return "Just now";
    }

    if (diff === 1) unit = unit.slice(0, -1);

    return `${diff} ${unit} ago`;
};

export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
};

export const isMobile = () => {
    return window.innerWidth < 1024;
};

export const matomoTrack = (tagName, value) => {
    const tagLookup = {
        "track-outbound-deal-click": [
            "trackEvent",
            "Outbound",
            "Deal link click",
            "Deal url"
        ],
        "share-opportunity": [
            "trackEvent",
            "Opportunities",
            "Share opportunity",
            "Opportunity"
        ],
        "create-opportunity": [
            "trackEvent",
            "Opportunities",
            "Create opportunity",
            "User"
        ],
        "mark-opportunity-as-interested": [
            "trackEvent",
            "Opportunities",
            "Mark opportunity as interested",
            "Opportunity"
        ],
        "mark-opportunity-as-following": [
            "trackEvent",
            "Opportunities",
            "Mark opportunity as following",
            "Opportunity"
        ],
        "mark-opportunity-as-not-interested": [
            "trackEvent",
            "Opportunities",
            "Mark opportunity as not interested",
            "Opportunity"
        ],
        "send-invite-link": [
            "trackEvent",
            "Invites",
            "Send invite link",
            "User"
        ]
    };
    window._paq.push([...tagLookup[tagName], value]);
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { mapPerson } from "../../data/people";
import Connection from "../../components/connection/Connection";
import OpportunityCard from "../../components/opportunity-card/OpportunityCard";
import ThreeDCarousel from "../../components/three-d-carousel/ThreeDCarousel";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import FeedCard from "../../components/feed-card/FeedCard";
import PlainCard from "../../components/plain-card/PlainCard";
import Button from "../../components/button/Button";
import CommentBox from "../../components/comment-box/CommentBox";
import FiinectionSummary from "../../components/fiinection-summary/FiinectionSummary";
import LatestMessages from "../../components/latest-messages/LatestMessages";
import IndustryNews from "../../components/industry-news/IndustryNews";
import Loader from "../../components/loader/Loader";
import InfoBubble from "../../components/info-bubble/InfoBubble";

import {
    postCommentAction,
    refreshFeedAction,
    loadMoreAction,
    getConnectionRequestsAction,
    connectToUserAction,
    getConnectionSuggestionsAction,
    setFocusedUserAction
} from "../../actions/network/networkActions";
import {
    openMessageCenterAction,
    setMessageCenterViewAction,
    setCurrentMessageParticipantsAction,
    setCurrentMessageOpportunityAction,
    getCurrentChatAction,
    getOpportunityDetailsForChatAction,
    leaveRoomAction
} from "../../actions/messaging/messagingActions";
import {
    setInterestStatusAction,
    getLatestOpportunitiesAction
} from "../../actions/opportunities/opportunityActions";
import { getIndustryNewsAction } from "../../actions/auxillary/auxillaryActions";
import { findLevelOfInvestment } from "../../data/levels";

import "./Home.scss";
import OpportunityCounter from "../../components/opportunity-counter/OpportunityCounter";

class Home extends Component {
    state = {
        introVideoOpen: true,
        introVideoClosed: false,
        dontShowIntroAgain: false
    };

    componentDidMount() {
        const {
            refreshFeedAction,
            getConnectionRequestsAction,
            getLatestOpportunitiesAction,
            getConnectionSuggestionsAction,
            auth
        } = this.props;

        if (localStorage.getItem("hideIntroVideo")) {
            this.setState({
                introVideoOpen: false,
                introVideoClosed: true
            });
        }

        refreshFeedAction();
        if (auth.user.role !== "Superadmin") {
            getConnectionSuggestionsAction();
            getConnectionRequestsAction();
        }
        getLatestOpportunitiesAction();

        setInterval(() => {
            refreshFeedAction();
        }, 15000);
    }

    loadMore() {
        const { loadMoreAction } = this.props;

        loadMoreAction();
    }

    closeIntroVideo() {
        this.setState({
            introVideoOpen: false
        });

        if (this.state.dontShowIntroAgain) {
            localStorage.setItem("hideIntroVideo", true);
        }

        setTimeout(() => {
            this.setState({
                introVideoClosed: true
            });
        }, 400);
    }

    toggleIntroVideoCookie() {
        this.setState({
            dontShowIntroAgain: !this.state.dontShowIntroAgain
        });
    }

    setFocusedUser(user) {
        const { setFocusedUserAction } = this.props;

        setFocusedUserAction(mapPerson(user));
    }

    openMessageCenter() {
        this.props.openMessageCenterAction();
    }

    render() {
        const {
            auth,
            network,
            messaging,
            auxillary,
            opportunities,
            postCommentAction,
            openMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            setFocusedUserAction,
            setInterestStatusAction,
            getOpportunityDetailsForChatAction,
            getIndustryNewsAction,
            leaveRoomAction
        } = this.props;

        const { introVideoOpen, introVideoClosed } = this.state;

        return (
            <div className="home-container">
                {auth.user.role === "Business" && (
                    <HighlightedContent
                        headline="Welcome to Fiinect"
                        hideTitleOnMobile
                    ></HighlightedContent>
                )}
                {auth.user.role !== "Business" && (
                    <HighlightedContent
                        hideTitleOnMobile
                        headline={
                            <>
                                Latest opportunities{" "}
                                <InfoBubble
                                    content={
                                        <>
                                            <p>
                                                This is your Carousel of latest
                                                business opportunities that
                                                match your profile. It's
                                                important to keep your profile
                                                up to date to see business
                                                opportunities relevant for you.{" "}
                                            </p>
                                            <p>
                                                To see all your opportunity
                                                matches you can go to the
                                                Opportunities Folder in the left
                                                menu bar where they are detailed
                                                by sectors​
                                            </p>
                                        </>
                                    }
                                />
                            </>
                        }
                        large
                    >
                        {(opportunities.latest.length === 0 ||
                            opportunities.inboxLoading) && <Loader small />}
                        {!auth.user.profileComplete &&
                            auth.user.role !== "Professional" && (
                                <p className="complete-profile-prompt">
                                    Get profiled opportunities by completing
                                    your profile
                                </p>
                            )}
                        {!opportunities.inboxLoading && (
                            <ThreeDCarousel idKey="latest-opportunities">
                                {opportunities.latest.map((opportunity) => (
                                    <OpportunityCard
                                        key={opportunity.opportunityId}
                                        opportunityId={
                                            opportunity.opportunityId
                                        }
                                        sector={
                                            auxillary.sectorInterests.find(
                                                (sector) =>
                                                    sector.id ===
                                                    opportunity.sectorId
                                            ).value
                                        }
                                        sectorHex={
                                            auxillary.sectorInterests.find(
                                                (sector) =>
                                                    sector.id ===
                                                    opportunity.sectorId
                                            ).hexCode
                                        }
                                        datePosted={opportunity.datePosted}
                                        headline={opportunity.headline}
                                        ticketSize={
                                            findLevelOfInvestment(
                                                opportunity.levelOfInvestmentId,
                                                auxillary.levelOfInvestment
                                            ).value
                                        }
                                        description={opportunity.description}
                                        country={opportunity.country}
                                        locationId={opportunity.locationId}
                                        broker={opportunity.broker}
                                        interestedPeople={
                                            opportunity.interestedPeople
                                        }
                                        otherPeople={opportunity.otherPeople}
                                        currentUser={auth.user}
                                        messaging={messaging}
                                        isBusiness={opportunity.isBusiness}
                                        setInterestStatusAction={
                                            setInterestStatusAction
                                        }
                                        isActive={opportunity.isActive}
                                        connections={network.connections}
                                        auxillary={auxillary}
                                    />
                                ))}
                            </ThreeDCarousel>
                        )}
                        {opportunities.inboxLoading && (
                            <ThreeDCarousel
                                idKey="latest-opportunities"
                                loading
                            >
                                {opportunities.latest.map((opportunity) => (
                                    <OpportunityCard
                                        key={opportunity.opportunityId}
                                        opportunityId={
                                            opportunity.opportunityId
                                        }
                                        sector={opportunity.sector}
                                        sectorHex={opportunity.sectorHex}
                                        datePosted={opportunity.datePosted}
                                        headline={opportunity.headline}
                                        ticketSize={opportunity.ticketSize}
                                        description={opportunity.description}
                                        country={opportunity.country}
                                        locationId={opportunity.locationId}
                                        broker={opportunity.broker}
                                        interestedPeople={
                                            opportunity.interestedPeople
                                        }
                                        otherPeople={opportunity.otherPeople}
                                        currentUser={auth.user}
                                        messaging={messaging}
                                        isBusiness={opportunity.isBusiness}
                                        setInterestStatusAction={
                                            setInterestStatusAction
                                        }
                                        isActive={opportunity.isActive}
                                        connections={network.connections}
                                        auxillary={auxillary}
                                    />
                                ))}
                            </ThreeDCarousel>
                        )}
                    </HighlightedContent>
                )}
                <MainContent>
                    <div className="content mobile-content">
                        <Link to={`${process.env.PUBLIC_URL}/opportunities`}>
                            <PlainCard>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/opportunities_mobile.svg`}
                                />
                                <p>New opportunities</p>
                            </PlainCard>
                        </Link>
                        <Link
                            to={`${process.env.PUBLIC_URL}/opportunities/interested`}
                        >
                            <PlainCard>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/interested.svg`}
                                />
                                <p>Interested</p>
                            </PlainCard>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/network/overview`}>
                            <PlainCard>
                                {network.requests.length > 0 && (
                                    <span class="notifications">
                                        {network.requests.length}
                                    </span>
                                )}
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/network_mobile.svg`}
                                />
                                <p>My Network</p>
                            </PlainCard>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/notifications`}>
                            <PlainCard>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/bell.png`}
                                />
                                <p>Notifications</p>
                            </PlainCard>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/marketplace`}>
                            <PlainCard>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/marketplace.svg`}
                                    className="marketplace"
                                />
                                <p>Marketplace</p>
                            </PlainCard>
                        </Link>
                        <PlainCard onClick={this.openMessageCenter.bind(this)}>
                            <span class="notifications messaging">
                                {messaging.unread}
                            </span>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/message-icon.svg`}
                                className="messages"
                            />
                            <p>My Messages</p>
                        </PlainCard>
                    </div>
                    <div className="content desktop-content">
                        <aside>
                            {network.requests.length > 0 && (
                                <PlainCard
                                    className="incoming-connections"
                                    header={`Latest invitations (${network.requests.length})`}
                                >
                                    {network.requests
                                        .slice(0, 2)
                                        .map((request) => (
                                            <Connection
                                                connection={request}
                                                setFocusedUserAction={this.setFocusedUser.bind(
                                                    this,
                                                    request
                                                )}
                                                buttonText="View details"
                                            />
                                        ))}
                                    <div className="actions">
                                        <Button
                                            href={`${process.env.PUBLIC_URL}/network/requests`}
                                        >
                                            View all {network.requests.length}
                                        </Button>
                                    </div>
                                </PlainCard>
                            )}
                            {auth.user &&
                                auth.user.role !== "Business" &&
                                !auth.user.profileComplete && (
                                    <PlainCard
                                        className="complete-profile"
                                        header="Complete your profile to unlock tailored opportunities and connect with the right people"
                                    >
                                        <Button
                                            href={`${process.env.PUBLIC_URL}/complete-profile`}
                                            isPrimary
                                        >
                                            Complete your profile
                                        </Button>
                                    </PlainCard>
                                )}
                            <PlainCard header="Your connections">
                                <FiinectionSummary
                                    premiumInvitesCount={
                                        auth.premiumInvitesCount
                                    }
                                    setFocusedUserAction={setFocusedUserAction}
                                    connections={network.connections}
                                    suggestions={network.suggestions}
                                />
                            </PlainCard>
                        </aside>
                        <article>
                            {auth.user &&
                                !auth.user.profileComplete &&
                                !introVideoClosed && (
                                    <div
                                        className={
                                            introVideoOpen
                                                ? "introductory-video hidden"
                                                : "introductory-video hidden"
                                        }
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                            className="close"
                                            alt="Close"
                                            onClick={this.closeIntroVideo.bind(
                                                this
                                            )}
                                        />
                                        <iframe
                                            src={`https://www.youtube.com/embed/fTTGALaRZoc`}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                        <label>
                                            <input
                                                type="checkbox"
                                                onChange={this.toggleIntroVideoCookie.bind(
                                                    this
                                                )}
                                            />
                                            Don't show again
                                        </label>
                                    </div>
                                )}
                            {auth.user.role !== "Business" && (
                                <OpportunityCounter
                                    unlocked={auth.premiumInvitesCount >= 10}
                                    base={10000}
                                    total={38534}
                                />
                            )}
                            <h3>
                                Latest notifications{" "}
                                <InfoBubble
                                    content={
                                        <p>
                                            This is an instant summary view of
                                            ALL your notifications, received &
                                            sent. These could be from the
                                            opportunities you have marked as
                                            'Interested' or 'Watching', as well
                                            as notifications from people in your
                                            network. ​
                                        </p>
                                    }
                                />
                            </h3>
                            {auth.user && (
                                <CommentBox
                                    placeholder="Post a comment to your network"
                                    postCommentAction={postCommentAction}
                                    user={auth.user}
                                />
                            )}
                            {network.feed.map((feedItem) => (
                                <FeedCard
                                    auth={auth}
                                    key={feedItem.id}
                                    people={[feedItem.person]}
                                    sharedTo={feedItem.sharedTo}
                                    type={feedItem.type.name}
                                    content={feedItem.content}
                                    opportunity={feedItem.opportunity}
                                    business={feedItem.business}
                                    datePosted={feedItem.created}
                                    messaging={messaging}
                                    openMessageCenterAction={
                                        openMessageCenterAction
                                    }
                                    setMessageCenterViewAction={
                                        setMessageCenterViewAction
                                    }
                                    getCurrentChatAction={getCurrentChatAction}
                                    setCurrentMessageParticipantsAction={
                                        setCurrentMessageParticipantsAction
                                    }
                                    setCurrentMessageOpportunityAction={
                                        setCurrentMessageOpportunityAction
                                    }
                                />
                            ))}
                            {/* <Button onClick={this.loadMore.bind(this)}>
                                Load more
                            </Button> */}
                        </article>
                        <aside>
                            <LatestMessages
                                latestMessages={messaging.messages}
                                openMessageCenterAction={
                                    openMessageCenterAction
                                }
                                setMessageCenterViewAction={
                                    setMessageCenterViewAction
                                }
                                getCurrentChatAction={getCurrentChatAction}
                                setCurrentMessageParticipantsAction={
                                    setCurrentMessageParticipantsAction
                                }
                                setCurrentMessageOpportunityAction={
                                    setCurrentMessageOpportunityAction
                                }
                                getOpportunityDetailsForChatAction={
                                    getOpportunityDetailsForChatAction
                                }
                                leaveRoomAction={leaveRoomAction}
                            />
                            <IndustryNews
                                news={auxillary.news}
                                getIndustryNewsAction={getIndustryNewsAction}
                            />
                        </aside>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        network: state.network,
        messaging: state.messaging,
        opportunities: state.opportunities,
        auxillary: state.auxillary
    }),
    {
        postCommentAction,
        refreshFeedAction,
        loadMoreAction,
        openMessageCenterAction,
        setMessageCenterViewAction,
        setCurrentMessageParticipantsAction,
        setCurrentMessageOpportunityAction,
        getCurrentChatAction,
        getConnectionRequestsAction,
        connectToUserAction,
        getConnectionSuggestionsAction,
        setFocusedUserAction,
        setInterestStatusAction,
        getOpportunityDetailsForChatAction,
        leaveRoomAction,
        getLatestOpportunitiesAction,
        getIndustryNewsAction
    }
)(Home);

export const NewsFeed = [
    {
        url: "https://www.bbc.co.uk/news/uk-57464097",
        date: "2021-06-14 08:45",
        headline:
            "Covid: Lockdown easing in England to be delayed by four weeks",
        snippet:
            "Most coronavirus rules will remain in place in England for another four weeks after the planned 21 June unlocking, government sources have told the BBC."
    },
    {
        url: "https://www.bbc.co.uk/sport/football/57468439",
        date: "2021-06-14 12:22",
        headline:
            "Christian Eriksen: Resuming Denmark-Finland match was 'least bad decision', says Martin Braithwaite",
        snippet:
            "The decision to resume Denmark's Euro 2020 opener against Finland following Christian Eriksen's cardiac arrest was the 'least bad one', says Denmark forward Martin Braithwaite."
    }
];

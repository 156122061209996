import React, { Component } from "react";

import ProfileIcon from "../profile-icon/ProfileIcon";
import { months } from "../../data/data";
import { objectsEqual } from "../../util";

import "./Message.scss";

class Message extends Component {
    render() {
        const { user, message } = this.props;

        const isCurrentUserMessage = message.messenger.id === user.id;
        const d = new Date(message.date);
        let hour = d.getHours();
        let minute = d.getMinutes();
        if (hour < 10) hour = `0${hour}`;
        if (minute < 10) minute = `0${minute}`;
        const dateString = `${d.getDate()} ${
            months[d.getMonth()]
        } ${hour}:${minute}`;

        return (
            <div
                className={
                    isCurrentUserMessage
                        ? "current-user-message message"
                        : "message"
                }
            >
                <ProfileIcon person={message.messenger} />
                <div className="message-wrapper">
                    <time>{dateString}</time>
                    <div className="message-content">{message.message}</div>
                </div>
            </div>
        );
    }
}

export default Message;

import React, { Component } from "react";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";

class FAQs extends Component {
    render() {
        return (
            <>
                <HighlightedContent small headline="FAQs"></HighlightedContent>
                <MainContent>
                    <div className="container">
                        <table cellSpacing={0}>
                            <thead>
                                <th>Question</th>
                                <th>Answer</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Are you just another deal flow platform?{" "}
                                    </td>
                                    <td>
                                        Fiinect is the Rightmove or Booking.com
                                        of Alternative Finance. We feature deals
                                        from all the platforms around the world
                                        and do not charge any fees or commission{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Is your site free to use? </td>
                                    <td>
                                        Yes. You can view and share deal flow
                                        and build your network without any fees.
                                        If you choose to upgrade to Premium,
                                        this will incur a nominal charge.{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>What do I get for Premium?</td>
                                    <td>
                                        Premium membership gives you:
                                        <ul>
                                            <li>
                                                Early bird access to
                                                opportunities{" "}
                                            </li>

                                            <li>
                                                Unlimited updates on future
                                                business opportunities{" "}
                                            </li>

                                            <li>
                                                Share business opportunities
                                                across the entire Fiinect
                                                network in one click{" "}
                                            </li>

                                            <li>
                                                Unlimited use of the
                                                communications suite{" "}
                                            </li>

                                            <li>
                                                Unlimited searches for new
                                                connections to help build your
                                                network{" "}
                                            </li>
                                        </ul>
                                        All this for just £24.99 a month...
                                    </td>
                                </tr>
                                <tr>
                                    <td>What geographic region am I in? </td>
                                    <td>
                                        <ul>
                                            <li>
                                                Asia and Pacific:
                                                <ul>
                                                    <li>Afghanistan</li>

                                                    <li>Bangladesh </li>

                                                    <li>Bhutan </li>

                                                    <li>India </li>

                                                    <li>Maldives </li>

                                                    <li>Nepal </li>

                                                    <li>Pakistan </li>

                                                    <li>Sri Lanka </li>

                                                    <li>American Samoa </li>

                                                    <li>Australia </li>

                                                    <li>Brunei Darussalam </li>

                                                    <li>Cambodia </li>

                                                    <li>China </li>

                                                    <li>Fiji </li>

                                                    <li>French Polynesia </li>

                                                    <li>Guam </li>

                                                    <li>
                                                        Hong Kong SAR, China{" "}
                                                    </li>

                                                    <li>Indonesia </li>

                                                    <li>Japan </li>

                                                    <li>Kiribati </li>

                                                    <li>
                                                        Korea, Dem. People's
                                                        Rep.{" "}
                                                    </li>

                                                    <li>Korea, Rep. </li>

                                                    <li>Lao PDR </li>

                                                    <li>Macao SAR, China </li>

                                                    <li>Malaysia </li>

                                                    <li>Marshall Islands </li>

                                                    <li>
                                                        Micronesia, Fed. Sts.{" "}
                                                    </li>

                                                    <li>Mongolia </li>

                                                    <li>Myanmar </li>

                                                    <li>Nauru </li>

                                                    <li>New Caledonia </li>

                                                    <li>New Zealand </li>

                                                    <li>
                                                        Northern Mariana Islands{" "}
                                                    </li>

                                                    <li>Palau </li>

                                                    <li>Philippines </li>

                                                    <li>Samoa </li>

                                                    <li>Singapore </li>

                                                    <li>Solomon Islands</li>

                                                    <li>Taiwan, China </li>

                                                    <li>Thailand </li>

                                                    <li>Timor-Leste </li>

                                                    <li>Papua New Guinea </li>

                                                    <li>Tonga </li>

                                                    <li>Tuvalu </li>

                                                    <li>Vanuatu </li>

                                                    <li>Vietnam </li>

                                                    <li>Armenia </li>

                                                    <li>Azerbaijan </li>

                                                    <li>Georgia </li>

                                                    <li>Kazakhstan </li>

                                                    <li>Kyrgyz Republic </li>

                                                    <li>Russian Federation </li>

                                                    <li>Tajikistan </li>

                                                    <li>Turkey </li>

                                                    <li>Turkmenistan </li>

                                                    <li>Uzbekistan </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Europe:
                                                <ul>
                                                    <li>Albania </li>

                                                    <li>Andorra </li>

                                                    <li>Austria </li>

                                                    <li>Belarus </li>

                                                    <li>Belgium </li>

                                                    <li>
                                                        Bosnia and Herzegovina{" "}
                                                    </li>

                                                    <li>Bulgaria </li>

                                                    <li>Channel Islands </li>

                                                    <li>Croatia </li>

                                                    <li>Cyprus </li>

                                                    <li>Czech Republic </li>

                                                    <li>Denmark </li>

                                                    <li>Estonia </li>

                                                    <li>Faroe Islands</li>

                                                    <li>Finland </li>

                                                    <li>France </li>

                                                    <li>Germany </li>

                                                    <li>Gibraltar </li>

                                                    <li>Greece </li>

                                                    <li>Greenland </li>

                                                    <li>Hungary </li>

                                                    <li>Iceland </li>

                                                    <li>Ireland </li>

                                                    <li>Isle of Man </li>

                                                    <li>Italy </li>

                                                    <li>Kosovo </li>

                                                    <li>Latvia </li>

                                                    <li>Liechtenstein </li>

                                                    <li>Lithuania </li>

                                                    <li>Luxembourg </li>

                                                    <li>Moldova </li>

                                                    <li>Monaco </li>

                                                    <li>Montenegro </li>

                                                    <li>Netherlands </li>

                                                    <li>North Macedonia </li>

                                                    <li>Norway </li>

                                                    <li>Poland </li>

                                                    <li>Portugal </li>

                                                    <li>Romania </li>

                                                    <li>San Marino </li>

                                                    <li>Serbia </li>

                                                    <li>Slovak Republic </li>

                                                    <li>Slovenia </li>

                                                    <li>Spain </li>

                                                    <li>Sweden </li>

                                                    <li>Switzerland </li>

                                                    <li>Ukraine </li>
                                                </ul>
                                            </li>
                                            <li>United Kingdom</li>
                                            <li>
                                                Latin America and the Caribbean:
                                                <ul>
                                                    <li>
                                                        Antigua and Barbuda{" "}
                                                    </li>

                                                    <li>Argentina </li>

                                                    <li>Aruba </li>

                                                    <li>Bahamas </li>

                                                    <li>Barbados </li>

                                                    <li>Belize </li>

                                                    <li>Bolivia </li>

                                                    <li>Brazil </li>

                                                    <li>
                                                        British Virgin Islands{" "}
                                                    </li>

                                                    <li>Cayman Islands </li>

                                                    <li>Chile </li>

                                                    <li>Colombia </li>

                                                    <li>Costa Rica </li>

                                                    <li>Cuba </li>

                                                    <li>Curacao </li>

                                                    <li>Caribbean </li>

                                                    <li>Dominica </li>

                                                    <li>Dominican Republic </li>

                                                    <li>Ecuador </li>

                                                    <li>El Salvador </li>

                                                    <li>Grenada </li>

                                                    <li>Guetemala </li>

                                                    <li>Guyana </li>

                                                    <li>Haiti </li>

                                                    <li>Honduras </li>

                                                    <li>Jamaica </li>

                                                    <li>Mexico </li>

                                                    <li>Nicaragua </li>

                                                    <li>Panama </li>

                                                    <li>Paraguay </li>

                                                    <li>Peru </li>

                                                    <li>Puerto Rico </li>

                                                    <li>
                                                        Sint Maarten (Dutch
                                                        part){" "}
                                                    </li>

                                                    <li>St.Kitts and Nevis </li>

                                                    <li>St.Lucia </li>

                                                    <li>
                                                        St. Martin (French part){" "}
                                                    </li>

                                                    <li>
                                                        St.Vincent and the
                                                        Grenadines{" "}
                                                    </li>

                                                    <li>Suriname </li>

                                                    <li>
                                                        Trinidad and Tobago{" "}
                                                    </li>

                                                    <li>
                                                        Turks and Caicos Islands{" "}
                                                    </li>

                                                    <li>Venezuela </li>

                                                    <li>
                                                        Virgin Islands (U.S.){" "}
                                                    </li>

                                                    <li>Uruguay </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Middle East and North Africa
                                                <ul>
                                                    <li>Algeria </li>

                                                    <li>Bahrain </li>

                                                    <li>Djibouti </li>

                                                    <li>Egypt </li>

                                                    <li>Iran </li>

                                                    <li>Iraq </li>

                                                    <li>Jordan </li>

                                                    <li>Kuwait </li>

                                                    <li>Lebanon </li>

                                                    <li>Libya </li>

                                                    <li>Morocco </li>

                                                    <li>Oman </li>

                                                    <li>Qatar </li>

                                                    <li>Saudi Arabia </li>

                                                    <li>Syria </li>

                                                    <li>Tunisia </li>

                                                    <li>
                                                        United Arab Emirates{" "}
                                                    </li>

                                                    <li>West Bank and Gaza </li>

                                                    <li>Israel </li>

                                                    <li>Yemen </li>
                                                </ul>
                                            </li>
                                            <li>
                                                North America
                                                <ul>
                                                    <li>Bermuda</li>
                                                    <li>Canada</li>
                                                    <li>United States</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Africa
                                                <ul>
                                                    <li>Angola </li>

                                                    <li>Benin </li>

                                                    <li>Botswana </li>

                                                    <li>Burkina Faso </li>

                                                    <li>Burundi </li>

                                                    <li>Cabo Verde </li>

                                                    <li>Cameroon </li>

                                                    <li>
                                                        Central African Republic{" "}
                                                    </li>

                                                    <li>Chad </li>

                                                    <li>Comoros </li>

                                                    <li>Congo, Dem. Rep. </li>

                                                    <li>Congo, Rep </li>

                                                    <li>Côte d'Ivoire </li>

                                                    <li>Equatorial Guinea </li>

                                                    <li>Eritrea </li>

                                                    <li>Eswatini </li>

                                                    <li>Ethiopia </li>

                                                    <li>Gabon </li>

                                                    <li>Gambia, The</li>

                                                    <li>Ghana </li>

                                                    <li>Guinea </li>

                                                    <li>Guinea-Bissau </li>

                                                    <li>Kenya </li>

                                                    <li>Lesotho </li>

                                                    <li>Liberia </li>

                                                    <li>Madagascar </li>

                                                    <li>Malawi </li>

                                                    <li>Mali </li>

                                                    <li>Mauritania </li>

                                                    <li>Mauritius </li>

                                                    <li>Mozambique </li>

                                                    <li>Namibia </li>

                                                    <li>Niger </li>

                                                    <li>Nigeria </li>

                                                    <li>Rwanda </li>

                                                    <li>
                                                        São Tomé and Principe{" "}
                                                    </li>

                                                    <li>Senegal </li>

                                                    <li>Seychelles </li>

                                                    <li>Sierra Leone </li>

                                                    <li>Somalia </li>

                                                    <li>South Africa </li>

                                                    <li>South Sudan </li>

                                                    <li>Sudan </li>

                                                    <li>Tanzania </li>

                                                    <li>Togo </li>

                                                    <li>Uganda </li>

                                                    <li>Zambia </li>

                                                    <li>Zimbabwe </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Can anyone see my Fiinect profile? </td>
                                    <td>
                                        On the site, anyone can search and find
                                        you. If you do not wish to be found,
                                        please go into your Privacy Settings and
                                        change your status. Be advised that this
                                        may have a detrimental effect on the
                                        number of new connections you make{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Can anyone see what opportunities I am
                                        interested in/following?{" "}
                                    </td>
                                    <td>
                                        No. This information is restricted. When
                                        you select a status, the deal owner
                                        (such as the originator or a broker)
                                        will be notified and they may contact
                                        you. Other people interested in the same
                                        opportunity who are in your network will
                                        also see your status. Nobody else will
                                        know.{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Is this a site only for professional
                                        investors?{" "}
                                    </td>
                                    <td>
                                        If you are investing money you must
                                        abide by the site Terms and Conditions,
                                        which include your self-accreditation as
                                        a professional investor. For a full
                                        description see our Terms and
                                        Conditions. Fiinect does not advise upon
                                        or endorse any opportunities featured on
                                        the site. If in doubt you should seek
                                        professional advice.{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        What kind of opportunities are on this
                                        site?{" "}
                                    </td>
                                    <td>
                                        Opportunities are filtered based on your
                                        criteria. Funding calls from around the
                                        globe cover a range of sectors, with
                                        investment rounds ranging from less than
                                        £10,000 to £20M+{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Are your opportunities vetted? </td>
                                    <td>
                                        Fiinect features opportunities from
                                        alternative finance platforms around the
                                        globe. These operate their own vetting
                                        procedures which may vary. Fiinect also
                                        features opportunities uploaded directly
                                        by users. We run a basic check on these
                                        for content monitoring purposes. We do
                                        not undertake Due Diligence on any
                                        opportunity, and you are strongly
                                        advised to do so or seek professional
                                        advice before making an investment
                                        decision. If you have concerns about a
                                        particular opportunity, please contact
                                        us info@fiinect.com{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Can I select more than one currency so I
                                        can see more opportunities?{" "}
                                    </td>
                                    <td>
                                        You don't need to. If you opt to see
                                        opportunities in a given currency you
                                        will be shown opportunities from around
                                        the world in the equivalent of other
                                        currencies.{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        I am raising money. Why should I use
                                        your platform instead of a Crowdfunding
                                        site?{" "}
                                    </td>
                                    <td>
                                        We are not a Crowdfunding platform but
                                        we do work with them. If you already
                                        have enough investors to part finance
                                        your opportunity you may wish to go
                                        directly onto a crowdfunding platform.
                                        However, if you want to build a network
                                        of interested people BEFORE you raise,
                                        then Fiinect is the place for you.
                                        Simply upload your opportunity, select
                                        whether you are raising "now" or "soon"
                                        and find interested people.{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        What will you charge me to raise money?{" "}
                                    </td>
                                    <td>
                                        Nothing. If you want to share your
                                        opportunity across the whole Fiinect
                                        network immediately, you will need to
                                        upgrade to Premium. If not, then you can
                                        share with your network and new
                                        connections that you make.{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        If I list my funding call in one
                                        currency (e.g. USD) will it only show to
                                        people in that country?{" "}
                                    </td>
                                    <td>
                                        No – people who opt to see opportunities
                                        in a given currency will be shown the
                                        equivalent in other currencies.{" "}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </MainContent>
            </>
        );
    }
}

export default FAQs;

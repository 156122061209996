import * as actionTypes from "../actionTypes";
import * as people from "../../data/people";
import axios from "axios";

import { API_BASE } from "../../api";

export const postCommentAction = (comment) => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");
        const body = {
            content: comment
        };

        return axios
            .post(`${API_BASE}/Notification`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(refreshFeedAction());
                dispatch(postCommentSuccessAction());
            })
            .catch((error) => {
                dispatch(postCommentErrorAction(error));
            });
    };
};

export const postCommentSuccessAction = () => ({
    type: actionTypes.POST_COMMENT_SUCCESS
});

export const postCommentErrorAction = (error) => ({
    type: actionTypes.POST_COMMENT_ERROR,
    error
});

export const refreshFeedAction = () => {
    return (dispatch, getState) => {
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Notification/latest`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(refreshFeedSuccessAction(data));
            })
            .catch(({ error }) => {
                dispatch(refreshFeedErrorAction(error));
            });
    };
};

export const refreshFeedSuccessAction = (feed) => ({
    type: actionTypes.REFRESH_FEED_SUCCESS,
    feed
});

export const refreshFeedErrorAction = (error) => ({
    type: actionTypes.REFRESH_FEED_ERROR,
    error
});

export const loadMoreAction = () => ({
    type: actionTypes.LOAD_MORE_FEED
});

export const resetNetworkFiltersAction = () => ({
    type: actionTypes.RESET_NETWORK_FILTERS
});

export const setNetworkFilterAction = (key, value) => ({
    type: actionTypes.SET_NETWORK_FILTER,
    key,
    value
});

export const setNetworkCountryFilterAction = (country) => ({
    type: actionTypes.SET_NETWORK_COUNTRY_FILTER,
    country
});

export const getConnectionSuggestionsAction = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/UserConnection/suggestions/${user.userId}`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const connections = data.map((connection) =>
                    people.mapPerson(connection)
                );
                dispatch(getConnectionSuggestionsSuccessAction(connections));
            })
            .catch((error) => {
                dispatch(getConnectionSuggestionsErrorAction(error));
            });
    };
};

export const getConnectionSuggestionsSuccessAction = (suggestions) => ({
    type: actionTypes.GET_CONNECTION_SUGGESTIONS_SUCCESS,
    suggestions
});

export const getConnectionSuggestionsErrorAction = (error) => ({
    type: actionTypes.GET_CONNECTION_SUGGESTIONS_ERROR,
    error
});

export const getFilteredConnectionsAction = () => {
    return (dispatch, getState) => {
        const connections = getState().network.connections;
        const filters = getState().network.filters;

        const filteredConnections = connections.filter((connection) => {
            let match = false;
            Object.keys(filters).forEach((filter) => {
                if (filter !== "country") {
                    if (
                        filters[filter].id &&
                        connection[filter].find(
                            (f) => f.id === filters[filter].id
                        )
                    )
                        match = true;
                } else {
                    match = connection[filter] === filters[filter].value;
                }
            });
            return match;
        });
        dispatch(setFilteredConnectionsAction(filteredConnections));
    };
};

export const setFilteredConnectionsAction = (connections) => ({
    type: actionTypes.SET_FILTERED_CONNECTIONS,
    connections
});

export const setNetworkViewAction = (view) => ({
    type: actionTypes.SET_NETWORK_VIEW,
    view
});

export const setFocusedUserAction = (focusedUser) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_FOCUSED_USER,
            focusedUser
        });
        return axios
            .get(`${API_BASE}/Users/${focusedUser.userId}/full`)
            .then(({ data }) => {
                dispatch(setFocusedUserSuccessAction(people.mapPerson(data)));
            })
            .catch((error) => {
                dispatch(setFocusedUserErrorAction(error));
            });
    };
};

export const setFocusedUserSuccessAction = (focusedUser) => ({
    type: actionTypes.SET_FOCUSED_USER_SUCCESS,
    focusedUser
});

export const setFocusedUserErrorAction = (error) => ({
    type: actionTypes.SET_FOCUSED_USER_ERROR,
    error
});

export const clearFocusedUserAction = () => ({
    type: actionTypes.CLEAR_FOCUSED_USER
});

export const getMarketplaceResultsAction = (
    occupation,
    expertise,
    location
) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.GET_MARKETPLACE_RESULTS });
        const user = getState().auth.user;
        const connections = getState().network.connections;
        return axios
            .get(`${API_BASE}/Users`)
            .then(({ data }) => {
                const connectionResults = [];
                const communityResults = [];
                data.forEach((dataItem) => {
                    let match = true;
                    if (user.id === dataItem.id) match = false; // user is logged in user

                    if (
                        (occupation &&
                            dataItem.occupationID &&
                            dataItem.occupationID.toString() !==
                                occupation.toString()) ||
                        !dataItem.occupationID
                    )
                        match = false;

                    if (
                        (expertise &&
                            dataItem.userExpertise.length > 0 &&
                            !dataItem.userExpertise.find(
                                (e) =>
                                    e.expertiseId.toString() ===
                                    expertise.toString()
                            )) ||
                        (expertise && dataItem.userExpertise.length === 0)
                    )
                        match = false;

                    if (
                        (location &&
                            dataItem.locationID &&
                            dataItem.locationID.toString() !==
                                location.toString()) ||
                        !dataItem.locationID
                    )
                        match = false;

                    if (match) {
                        const mappedItem = people.mapPerson(dataItem);
                        if (
                            connections.find(
                                (connection) => connection.id === dataItem.id
                            )
                        )
                            connectionResults.push(mappedItem);
                        else communityResults.push(mappedItem);
                    }
                });

                dispatch(
                    getMarketplaceResultsSuccessAction({
                        connectionResults,
                        communityResults
                    })
                );
            })
            .catch((error) => {
                dispatch(getMarketplaceResultsErrorAction(error));
            });
    };
};

export const getMarketplaceResultsSuccessAction = (results) => ({
    type: actionTypes.GET_MARKETPLACE_RESULTS_SUCCESS,
    results
});

export const getMarketplaceResultsErrorAction = (error) => ({
    type: actionTypes.GET_MARKETPLACE_RESULTS_ERROR,
    error
});

export const getUserConnectionsAction = (userId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/UserConnection/${user.userId || userId}`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const connections = data.map((connection) =>
                    people.mapPerson(connection)
                );

                dispatch(
                    getUserConnectionsSuccessAction(
                        connections.filter((c) => c.name !== "Fiinect Update")
                    )
                );
                dispatch(getIgnoredConnectionsAction(user.userId || userId));
            })
            .catch((error) => {
                dispatch(getUserConnectionsErrorAction(error));
            });
    };
};

export const getUserConnectionsSuccessAction = (connections) => ({
    type: actionTypes.GET_USER_CONNECTIONS_SUCCESS,
    connections
});

export const getUserConnectionsErrorAction = (error) => ({
    type: actionTypes.GET_USER_CONNECTIONS_ERROR,
    error
});

export const getIgnoredConnectionsAction = (userId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const connections = getState().network.connections;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(
                `${API_BASE}/UserConnection/${user.userId || userId}/ignored`,
                {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then(({ data }) => {
                const notIgnoredConnections = connections.filter((c) => {
                    return !data.find((ignored) => ignored.destUserId === c.id);
                });

                dispatch(
                    getUserConnectionsSuccessAction(notIgnoredConnections)
                );
                dispatch(getIgnoredConnectionsSuccessAction());
            })
            .catch((error) => {
                dispatch(getIgnoredConnectionsErrorAction(error));
            });
    };
};

export const getIgnoredConnectionsSuccessAction = () => ({
    type: actionTypes.GET_IGNORED_CONNECTIONS_SUCCESS
});

export const getIgnoredConnectionsErrorAction = (error) => ({
    type: actionTypes.GET_IGNORED_CONNECTIONS_ERROR,
    error
});

export const resetMarketplaceAction = () => ({
    type: actionTypes.RESET_MARKETPLACE
});

export const connectToUserAction = (source, destination) => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");
        const body = {
            SourceUserId: source,
            DestUserId: destination,
            Ignore: false
        };

        return axios
            .post(`${API_BASE}/UserConnection`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(connectToUserSuccessAction());
                dispatch(getUserConnectionsAction(source));
                dispatch(getConnectionRequestsAction());
            })
            .catch((error) => {
                dispatch(connectToUserErrorAction(error));
            });
    };
};

export const connectToUserSuccessAction = () => ({
    type: actionTypes.CONNECT_TO_USER_SUCCESS
});

export const connectToUserErrorAction = (error) => ({
    type: actionTypes.CONNECT_TO_USER_ERROR,
    error
});

export const ignoreUserAction = (source, destination) => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");
        const body = {
            SourceUserId: source,
            DestUserId: destination,
            Ignore: true
        };

        return axios
            .post(`${API_BASE}/UserConnection`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(ignoreUserSuccessAction());
                dispatch(getConnectionRequestsAction());
                dispatch(getConnectionSuggestionsAction());
            })
            .catch((error) => {
                dispatch(ignoreUserErrorAction(error));
            });
    };
};

export const ignoreUserSuccessAction = () => ({
    type: actionTypes.IGNORE_USER_SUCCESS
});

export const ignoreUserErrorAction = (error) => ({
    type: actionTypes.IGNORE_USER_ERROR,
    error
});

export const getConnectionRequestsAction = () => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.GET_CONNECTION_REQUESTS
        });
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/UserConnection/${user.userId}/requests`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(getConnectionRequestsSuccessAction(data));
                data.forEach((userId) =>
                    dispatch(getUserDetailsForConnectionRequestAction(userId))
                );
            })
            .catch((error) => {
                dispatch(getConnectionRequestsErrorAction(error));
            });
    };
};

export const getConnectionRequestsSuccessAction = (requests) => ({
    type: actionTypes.GET_CONNECTION_REQUESTS_SUCCESS,
    requests
});

export const getConnectionRequestsErrorAction = (error) => ({
    type: actionTypes.GET_CONNECTION_REQUESTS_ERROR,
    error
});

export const getUserDetailsForConnectionRequestAction = (userId) => {
    return (dispatch) =>
        axios
            .get(`${API_BASE}/Users/${userId}`)
            .then(({ data }) => {
                dispatch(getUserDetailsForConnectionRequestSuccessAction(data));
            })
            .catch((error) => {
                dispatch(getUserDetailsForConnectionRequestErrorAction(error));
            });
};

export const getUserDetailsForConnectionRequestSuccessAction = (user) => ({
    type: actionTypes.GET_CONNECTION_REQUEST_USER_DETAILS_SUCCESS,
    user
});

export const getUserDetailsForConnectionRequestErrorAction = (error) => ({
    type: actionTypes.GET_CONNECTION_REQUEST_USER_DETAILS_ERROR,
    error
});

export const getUnconnectedButMessagedUserDetailsAction = (matrixId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS
        });
        return axios
            .get(`${API_BASE}/Users/matrix/${matrixId}`)
            .then(({ data }) => {
                dispatch(
                    getUnconnectedButMessagedUserDetailsSuccessAction(data)
                );
            })
            .catch((error) => {
                dispatch(
                    getUnconnectedButMessagedUserDetailsErrorAction(
                        matrixId,
                        error
                    )
                );
            });
    };
};

export const getUnconnectedButMessagedUserDetailsSuccessAction = (user) => ({
    type: actionTypes.GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_SUCCESS,
    user
});

export const getUnconnectedButMessagedUserDetailsErrorAction = (
    matrixId,
    error
) => ({
    type: actionTypes.GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_ERROR,
    matrixId,
    error
});

export const searchAction = (query) => {
    return (dispatch) =>
        axios
            .get(`${API_BASE}/Search/${query}`)
            .then(({ data }) => {
                dispatch(
                    searchSuccessAction(
                        data.users,
                        data.opportunities,
                        data.businesses
                    )
                );
                window._paq.push([
                    "trackSiteSearch",
                    query,
                    "Users",
                    data.users.length
                ]);
                window._paq.push([
                    "trackSiteSearch",
                    query,
                    "Opportunities",
                    data.opportunities.length
                ]);
                window._paq.push([
                    "trackSiteSearch",
                    query,
                    "Businesses",
                    data.businesses.length
                ]);
            })
            .catch((error) => {
                dispatch(searchErrorAction(error));
            });
};

export const searchSuccessAction = (users, opportunities, businesses) => ({
    type: actionTypes.SEARCH_SUCCESS,
    users,
    opportunities,
    businesses
});

export const searchErrorAction = (error) => ({
    type: actionTypes.SEARCH_ERROR,
    error
});

export const resetSearchAction = () => ({
    type: actionTypes.RESET_SEARCH
});

export const getLocationSuggestionsAction = (locationId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        dispatch({ type: actionTypes.GET_LOCATION_SUGGESTIONS });
        return axios
            .get(
                `${API_BASE}/UserConnection/suggestions/${user.userId}/locations/${locationId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then(({ data }) => {
                const connections = data.map((connection) =>
                    people.mapPerson(connection)
                );
                dispatch(getLocationSuggestionsSuccessAction(connections));
            })
            .catch((error) => {
                dispatch(getLocationConnectionsErrorAction(error));
            });
    };
};

export const getLocationSuggestionsSuccessAction = (suggestions) => ({
    type: actionTypes.GET_LOCATION_SUGGESTIONS_SUCCESS,
    suggestions
});

export const getLocationConnectionsErrorAction = (error) => ({
    type: actionTypes.GET_LOCATION_SUGGESTIONS_ERROR,
    error
});

export const getFilterSuggestionsAction = (filter, filterId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        dispatch({ type: actionTypes.GET_FILTER_SUGGESTIONS });
        return axios
            .get(
                `${API_BASE}/UserConnection/suggestions/${user.userId}/${filter}/${filterId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then(({ data }) => {
                const connections = data.map((connection) =>
                    people.mapPerson(connection)
                );
                dispatch(getFilterSuggestionsSuccessAction(connections));
            })
            .catch((error) => {
                dispatch(getFilterConnectionsErrorAction(error));
            });
    };
};

export const getFilterSuggestionsSuccessAction = (suggestions) => ({
    type: actionTypes.GET_FILTER_SUGGESTIONS_SUCCESS,
    suggestions
});

export const getFilterConnectionsErrorAction = (error) => ({
    type: actionTypes.GET_FILTER_SUGGESTIONS_ERROR,
    error
});

import React, { Component } from "react";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";

class Cookies extends Component {
    render() {
        return (
            <>
                <HighlightedContent
                    small
                    headline="Fiinect Cookie Policy"
                ></HighlightedContent>
                <MainContent>
                    <div className="container">
                        <p>
                            At Fiinect, we believe in being clear and open about
                            how we collect and use data related to you. This
                            Cookie Policy applies to any Fiinect product or
                            service that links to this policy or incorporates it
                            by reference. We use cookies and similar
                            technologies such as pixels, local storage and
                            mobile ad IDs (collectively referred to in this
                            policy as “cookies”) to collect and use data as part
                            of our Services, as defined in our Privacy
                            Policy (“Services”).{" "}
                        </p>
                        <h2>What technologies are used?</h2>
                        <table cellSpacing={0}>
                            <thead>
                                <th>Type of technology</th>
                                <th>Description</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cookies</td>
                                    <td>
                                        A cookie is a small file placed onto
                                        your device that enables Fiinect
                                        features and functionality. Any browser
                                        visiting our sites may receive cookies
                                        from us or cookies from third parties
                                        such as our customers, partners or
                                        service providers. We or third parties
                                        may also place cookies in your browser
                                        when you visit non-Fiinect sites that
                                        display ads.   We use two types of
                                        cookies: persistent cookies and session
                                        cookies. A persistent cookie lasts
                                        beyond the current session and is used
                                        for many purposes, such as recognizing
                                        you as an existing user, so it's easier
                                        to return to Fiinect and interact with
                                        our Services without signing in again.
                                        Session cookies last only as long as the
                                        session (usually the current visit to a
                                        website or a browser session).
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pixels </td>
                                    <td>
                                        A pixel is a tiny image that may be
                                        embedded within web pages and emails,
                                        requiring a call (which provides device
                                        and visit information) to our servers in
                                        order for the pixel to be rendered in
                                        those web pages and emails. We use
                                        pixels to learn more about your
                                        interactions with email content or web
                                        content, such as whether you interacted
                                        with Notifications, Messages or Ads.
                                        Pixels can also enable us and third
                                        parties to place cookies on your
                                        browser.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Local storage</td>
                                    <td>
                                        Local storage enables a website or
                                        application to store information locally
                                        on your device(s). Local storage may be
                                        used to improve the Fiinect experience,
                                        for example, by enabling features,
                                        remembering your preferences and
                                        speeding up site functionality.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Other similar technologies </td>
                                    <td>
                                        We also use other tracking technologies,
                                        such as mobile advertising IDs and tags
                                        for similar purposes as described in
                                        this Cookie Policy
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>What are these technologies used for? </h2>
                        <p>
                            Below we describe the ways in which we may use
                            cookies.{" "}
                        </p>
                        <table cellSpacing={0}>
                            <thead>
                                <th>Purpose</th>
                                <th>Description</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Authentication</td>
                                    <td>
                                        We use cookies to recognize you when you
                                        visit our Services. If you're signed
                                        into Fiinect, cookies help us show you
                                        the right information and personalize
                                        your experience in line with your
                                        settings.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Security</td>
                                    <td>
                                        We use cookies to make your interactions
                                        with our Services faster, more secure
                                        and to help us detect malicious activity
                                        and violations of our User Agreement.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Preferences, features and services</td>
                                    <td>
                                        We use cookies to enable the
                                        functionality of our Services, and
                                        provide you with features, insights and
                                        customized content. We also use these
                                        technologies to remember information
                                        about your browser and your preferences.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Functional</td>
                                    <td>
                                        We use cookies to improve your
                                        experience on our Services.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Personalised advertising </td>
                                    <td>
                                        Cookies help us show you relevant
                                        advertising, both on and off our
                                        Services and measure the performance of
                                        such ads and report on it. We use
                                        cookies to learn whether content has
                                        been shown to you or whether someone who
                                        was presented with an ad later came back
                                        and took an action (e.g., downloaded a
                                        white paper or made a purchase) on
                                        another site. Similarly, our partners or
                                        service providers may use cookies to
                                        determine whether we've shown an ad or a
                                        post and how it performed or provide us
                                        with information about how you interact
                                        with ads or specific content. We may
                                        also work with our customers and
                                        partners to show you an ad on or off
                                        Fiinect, such as after you've visited a
                                        customer's or partner's site or
                                        application.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Analytics and research</td>
                                    <td>
                                        Cookies help us learn more about how
                                        well our Services perform. We or our
                                        service providers use cookies to
                                        understand, improve, and research
                                        products, features and services,
                                        including as you navigate through our
                                        site or when you access Fiinect from
                                        other sites, applications or devices. We
                                        or our service providers, use cookies to
                                        determine and measure the performance of
                                        ads or posts on and off Fiinect and to
                                        learn whether you have interacted with
                                        our or our customers' websites, content
                                        or emails and provide analytics based on
                                        those interactions. We also use cookies
                                        to provide aggregated information to our
                                        customers and partners as part of our
                                        Services. If you are a Fiinect member
                                        but logged out of your account on a
                                        browser, Fiinect may still continue to
                                        log your interaction with our Services
                                        on that browser for up to 30 days in
                                        order to generate usage analytics for
                                        our Services. We may share these
                                        analytics in aggregate form with our
                                        customers.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>
                            What third parties use these technologies in
                            connection with our Services?{" "}
                        </h2>
                        <p>
                            Third parties such as our customers, partners and
                            service providers may use cookies in connection with
                            our Services.{" "}
                        </p>

                        <p>
                            For example, third parties may use cookies in their
                            Fiinect pages, job posts and their advertisements on
                            and off Fiinect for their own marketing purposes.{" "}
                        </p>

                        <p>
                            Third parties may also use cookies in connection
                            with our off-site Services, such as Fiinect ad
                            services. Third parties may use cookies to help us
                            to provide our Services. We may also work with third
                            parties for our own marketing purposes and to enable
                            us to analyze and research our Services.{" "}
                        </p>

                        <p>Your Choices </p>

                        <p>
                            You have choices on how Fiinect uses cookies. Please
                            note that if you limit the ability of Fiinect to use
                            cookies, you may worsen your overall user
                            experience, since it may no longer be as
                            personalized to you. It may also stop you from
                            saving customized settings like login information.{" "}
                        </p>

                        <p>Targeted Advertising </p>

                        <p>
                            As described here, you have choices regarding the
                            use of your personal data for targeted advertising.
                            Some mobile device operating systems such as Android
                            and iOS provide the ability to control the use of
                            mobile advertising IDs for ads personalization. You
                            can learn how to use these controls by visiting the
                            manufacturer's website for both Android and iOS.{" "}
                        </p>

                        <p>Browser Controls </p>

                        <p>
                            Most browsers allow you to control cookies through
                            their settings, which may be adapted to reflect your
                            consent to the use of cookies. Further, most
                            browsers also enable you to review and erase
                            cookies, including Fiinect cookies. To learn more
                            about browser controls, please consult the
                            documentation that your browser manufacturer
                            provides.{" "}
                        </p>

                        <p>Manage Cookie Preferences </p>
                        <p>
                            Where required, we enable you to reject the use of
                            certain cookies for purposes such as advertising and
                            analytics and research, by visiting your cookie
                            settings, without affecting the lawfulness of
                            processing based on your consent before its
                            withdrawal.{" "}
                        </p>
                    </div>
                </MainContent>
            </>
        );
    }
}

export default Cookies;

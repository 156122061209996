import axios from "axios";

import * as actionTypes from "../actionTypes";
import { API_BASE, NEWSAPI_KEY, NEWSAPI_BASE, NEWSBUCKET } from "../../api";

export const getOccupationsAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Occupation`)
            .then(({ data }) => {
                const occupations = data.map((occupation) => ({
                    id: occupation.id,
                    value: occupation.name,
                    occupationId: occupation.id,
                    occupationName: occupation.name
                }));
                dispatch(getOccupationsSuccessAction(occupations));
            })
            .catch((error) => {
                dispatch(getOccupationsErrorAction(error));
            });
    };
};

export const getOccupationsSuccessAction = (occupations) => ({
    type: actionTypes.GET_OCCUPATIONS_SUCCESS,
    occupations
});

export const getOccupationsErrorAction = (error) => ({
    type: actionTypes.GET_OCCUPATIONS_ERROR,
    error
});

export const getExpertisesAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Expertise`)
            .then(({ data }) => {
                const expertises = data.map((expertise) => ({
                    id: expertise.id,
                    value: expertise.name,
                    occupationId: expertise.occupationId
                }));
                dispatch(getExpertisesSuccessAction(expertises));
            })
            .catch((error) => {
                dispatch(getExpertisesErrorAction(error));
            });
    };
};

export const getExpertisesSuccessAction = (expertises) => ({
    type: actionTypes.GET_EXPERTISES_SUCCESS,
    expertises
});

export const getExpertisesErrorAction = (error) => ({
    type: actionTypes.GET_EXPERTISES_ERROR,
    error
});

export const getLocationsAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Location`)
            .then(({ data }) => {
                const locations = data
                    .map((location) => ({
                        id: location.id,
                        code: location.country,
                        isRegion: location.isRegion,
                        regionId: location.regionId,
                        value: location.name
                    }))
                    .sort((a, b) => {
                        if (a.value === "United Kingdom") return -1;
                        if (b.value === "United Kingdom") return 1;

                        return a.value > b.value ? 1 : -1;
                    });

                dispatch(getLocationsSuccessAction(locations));
            })
            .catch((error) => {
                dispatch(getLocationsErrorAction(error));
            });
    };
};

export const getLocationsSuccessAction = (locations) => ({
    type: actionTypes.GET_LOCATIONS_SUCCESS,
    locations
});

export const getLocationsErrorAction = (error) => ({
    type: actionTypes.GET_LOCATIONS_ERROR,
    error
});

export const getSectorsAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/Sector`)
            .then(({ data }) => {
                const sectors = data.map((sector) => ({
                    id: sector.id,
                    value: sector.name,
                    hexCode: sector.hexCode
                }));
                dispatch(getSectorsSuccessAction(sectors));
            })
            .catch((error) => {
                dispatch(getSectorsErrorAction(error));
            });
    };
};

export const getSectorsSuccessAction = (sectors) => ({
    type: actionTypes.GET_SECTORS_SUCCESS,
    sectors
});

export const getSectorsErrorAction = (error) => ({
    type: actionTypes.GET_SECTORS_ERROR,
    error
});

export const getFinanceStagesAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/FinanceStage`)
            .then(({ data }) => {
                const stages = data.map((fs) => ({
                    id: fs.id,
                    value: fs.name
                }));
                dispatch(getFinanceStagesSuccessAction(stages));
            })
            .catch((error) => {
                dispatch(getFinanceStagesErrorAction(error));
            });
    };
};

export const getFinanceStagesSuccessAction = (stages) => ({
    type: actionTypes.GET_FINANCE_STAGES_SUCCESS,
    stages
});

export const getFinanceStagesErrorAction = (error) => ({
    type: actionTypes.GET_FINANCE_STAGES_ERROR,
    error
});

export const getFinanceTypesAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/FinanceType`)
            .then(({ data }) => {
                const types = data.map((ft) => ({
                    id: ft.id,
                    value: ft.name
                }));
                dispatch(getFinanceTypesSuccessAction(types));
            })
            .catch((error) => {
                dispatch(getFinanceTypesErrorAction(error));
            });
    };
};

export const getFinanceTypesSuccessAction = (types) => ({
    type: actionTypes.GET_FINANCE_TYPES_SUCCESS,
    types
});

export const getFinanceTypesErrorAction = (error) => ({
    type: actionTypes.GET_FINANCE_TYPES_ERROR,
    error
});

export const getLevelOfInvestmentAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/LevelOfInvestment`)
            .then(({ data }) => {
                const levels = {};
                const levelsSorted = {};

                data.forEach((level) => {
                    if (!levels[level.currency]) levels[level.currency] = [];
                    levels[level.currency].push({
                        id: level.id,
                        ticketSize: level.ticketSize,
                        value: level.name
                    });
                });

                Object.keys(levels)
                    .sort()
                    .forEach((key) => (levelsSorted[key] = levels[key]));

                dispatch(getLevelOfInvestmentSuccessAction(levelsSorted));
            })
            .catch((error) => {
                dispatch(getLevelOfInvestmentErrorAction(error));
            });
    };
};

export const getLevelOfInvestmentSuccessAction = (levels) => ({
    type: actionTypes.GET_LEVEL_OF_INVESTMENT_SUCCESS,
    levels
});

export const getLevelOfInvestmentErrorAction = (error) => ({
    type: actionTypes.GET_LEVEL_OF_INVESTMENT_ERROR,
    error
});

export const getBusinessTypesAction = () => {
    return (dispatch) => {
        return axios
            .get(`${API_BASE}/BusinessType`)
            .then(({ data }) => {
                const businessTypes = data.map((businessType) => ({
                    id: businessType.id,
                    value: businessType.name
                }));
                dispatch(getBusinessTypesSuccessAction(businessTypes));
            })
            .catch((error) => {
                dispatch(getBusinessTypesErrorAction(error));
            });
    };
};

export const getBusinessTypesSuccessAction = (businessTypes) => ({
    type: actionTypes.GET_BUSINESS_TYPES_SUCCESS,
    businessTypes
});

export const getBusinessTypesErrorAction = (error) => ({
    type: actionTypes.GET_BUSINESS_TYPES_ERROR,
    error
});

export const getIndustryNewsAction = () => {
    return (dispatch) => {
        return axios
            .get(
                // `${NEWSAPI_BASE}news?keywords=alternative+finance&sort=published_desc&countries=us,au,gb,ca&access_key=${NEWSAPI_KEY}`
                NEWSBUCKET
            )
            .then(({ data }) => {
                dispatch(
                    getIndustryNewsSuccessAction(
                        data.data
                            .sort((a, b) => 0.5 - Math.random())
                            .slice(0, 3)
                            .sort((a, b) =>
                                a.published_at < b.published_at ? 1 : -1
                            )
                    )
                );
            })
            .catch((error) => {
                dispatch(getIndustryNewsErrorAction(error));
            });
    };
};

export const getIndustryNewsSuccessAction = (news) => ({
    type: actionTypes.GET_INDUSTRY_NEWS_SUCCESS,
    news
});

export const getIndustryNewsErrorAction = (error) => ({
    type: actionTypes.GET_INDUSTRY_NEWS_ERROR,
    error
});

// Auth actionTypes
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const GET_LOGGED_IN_USER_DETAILS = "GET_LOGGED_IN_USER_DETAILS";
export const GET_INVITE_DETAILS = "GET_INVITE_DETAILS";
export const GET_INVITE_DETAILS_SUCCESS = "GET_INVITE_DETAILS_SUCCESS";
export const GET_INVITE_DETAILS_ERROR = "GET_INVITE_DETAILS_ERROR";
export const BEGIN_REGISTRATION = "BEGIN_REGISTRATION";
export const END_REGISTRATION = "END_REGISTRATION";
export const REGISTER_MATRIX_USER = "REGISTER_MATRIX_USER";
export const REGISTER_MATRIX_USER_SUCCESS = "REGISTER_MATRIX_USER_SUCCESS";
export const REGISTER_MATRIX_USER_ERROR = "REGISTER_MATRIX_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const CHECK_IF_USER_LOGGED_IN = "CHECK_IF_USER_LOGGED_IN";
export const CHECK_IF_USER_LOGGED_IN_SUCCESS =
    "CHECK_IF_USER_LOGGED_IN_SUCCESS";
export const CHECK_IF_USER_LOGGED_IN_ERROR = "CHECK_IF_USER_LOGGED_IN_ERROR";
export const SET_WELCOME_MODAL = "SET_WELCOME_MODAL";
export const INVITE_TO_FIINECT = "INVITE_TO_FIINECT";
export const INVITE_TO_FIINECT_SUCCESS = "INVITE_TO_FIINECT_SUCCESS";
export const INVITE_TO_FIINECT_ERROR = "INVITE_TO_FIINECT_ERROR";
export const CHECK_PREMIUM_INVITES = "CHECK_PREMIUM_INVITES";
export const CHECK_PREMIUM_INVITES_SUCCESS = "CHECK_PREMIUM_INVITES_SUCCESS";
export const CHECK_PREMIUM_INVITES_ERROR = "CHECK_PREMIUM_INVITES_ERROR";

// Network actionTypes
export const SET_NETWORK_VIEW = "SET_NETWORK_VIEW";
export const POST_COMMENT = "POST_COMMENT";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_ERROR = "POST_COMMENT_ERROR";
export const REFRESH_FEED = "REFRESH_FEED";
export const REFRESH_FEED_SUCCESS = "REFRESH_FEED_SUCCESS";
export const REFRESH_FEED_ERROR = "REFRESH_FEED_ERROR";
export const LOAD_MORE_FEED = "LOAD_MORE_FEED";
export const RESET_NETWORK_FILTERS = "RESET_NETWORK_FILTERS";
export const SET_NETWORK_FILTER = "SET_NETWORK_FILTER";
export const SET_NETWORK_COUNTRY_FILTER = "SET_NETWORK_COUNTRY_FILTER";
export const GET_CONNECTION_SUGGESTIONS = "GET_CONNECTION_SUGGESTIONS";
export const GET_CONNECTION_SUGGESTIONS_SUCCESS =
    "GET_CONNECTION_SUGGESTIONS_SUCCESS";
export const GET_CONNECTION_SUGGESTIONS_ERROR =
    "GET_CONNECTION_SUGGESTIONS_ERROR";
export const SET_FILTERED_CONNECTIONS = "SET_FILTERED_CONNECTIONS";
export const SET_FOCUSED_USER = "SET_FOCUSED_USER";
export const SET_FOCUSED_USER_SUCCESS = "SET_FOCUSED_USER_SUCCESS";
export const SET_FOCUSED_USER_ERROR = "SET_FOCUSED_USER_ERROR";
export const CLEAR_FOCUSED_USER = "CLEAR_FOCUSED_USER";
export const GET_MARKETPLACE_RESULTS = "GET_MARKETPLACE_RESULTS";
export const GET_MARKETPLACE_RESULTS_SUCCESS =
    "GET_MARKETPLACE_RESULTS_SUCCESS";
export const GET_MARKETPLACE_RESULTS_ERROR = "GET_MARKETPLACE_RESULTS_ERROR";
export const RESET_MARKETPLACE = "RESET_MARKETPLACE";
export const GET_USER_CONNECTIONS = "GET_USER_CONNECTIONS";
export const GET_USER_CONNECTIONS_SUCCESS = "GET_USER_CONNECTIONS_SUCCESS";
export const GET_USER_CONNECTIONS_ERROR = "GET_USER_CONNECTIONS_ERROR";
export const GET_IGNORED_CONNECTIONS = "GET_IGNORED_CONNECTIONS";
export const GET_IGNORED_CONNECTIONS_SUCCESS =
    "GET_IGNORED_CONNECTIONS_SUCCESS";
export const GET_IGNORED_CONNECTIONS_ERROR = "GET_IGNORED_CONNECTIONS_ERROR";
export const CONNECT_TO_USER = "CONNECT_TO_USER";
export const CONNECT_TO_USER_SUCCESS = "CONNECT_TO_USER_SUCCESS";
export const CONNECT_TO_USER_ERROR = "CONNECT_TO_USER_ERROR";
export const IGNORE_USER = "IGNORE_USER";
export const IGNORE_USER_SUCCESS = "IGNORE_USER_SUCCESS";
export const IGNORE_USER_ERROR = "IGNORE_USER_ERROR";
export const GET_CONNECTION_REQUESTS = "GET_CONNECTION_REQUESTS";
export const GET_CONNECTION_REQUESTS_SUCCESS =
    "GET_CONNECTION_REQUESTS_SUCCESS";
export const GET_CONNECTION_REQUESTS_ERROR = "GET_CONNECTION_REQUESTS_ERROR";
export const GET_CONNECTION_REQUEST_USER_DETAILS =
    "GET_CONNECTION_REQUEST_USER_DETAILS";
export const GET_CONNECTION_REQUEST_USER_DETAILS_SUCCESS =
    "GET_CONNECTION_REQUEST_USER_DETAILS_SUCCESS";
export const GET_CONNECTION_REQUEST_USER_DETAILS_ERROR =
    "GET_CONNECTION_REQUEST_USER_DETAILS_ERROR";
export const SEARCH = "SEARCH";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const RESET_SEARCH = "RESET_SEARCH";
export const GET_LOCATION_SUGGESTIONS = "GET_LOCATION_SUGGESTIONS";
export const GET_LOCATION_SUGGESTIONS_SUCCESS =
    "GET_LOCATION_SUGGESTIONS_SUCCESS";
export const GET_LOCATION_SUGGESTIONS_ERROR = "GET_LOCATION_SUGGESTIONS_ERROR";
export const GET_FILTER_SUGGESTIONS = "GET_FILTER_SUGGESTIONS";
export const GET_FILTER_SUGGESTIONS_SUCCESS = "GET_FILTER_SUGGESTIONS_SUCCESS";
export const GET_FILTER_SUGGESTIONS_ERROR = "GET_FILTER_SUGGESTIONS_ERROR";
export const GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS =
    "GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS";
export const GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_SUCCESS =
    "GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_SUCCESS";
export const GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_ERROR =
    "GET_UNCONNECTED_BUT_MESSAGED_USER_DETAILS_ERROR";

// Messaging actionTypes
export const LIST_MESSAGES = "LIST_MESSAGES";
export const OPEN_MESSAGE_CENTER = "OPEN_MESSAGE_CENTER";
export const CLOSE_MESSAGE_CENTER = "CLOSE_MESSAGE_CENTER";
export const TOGGLE_MESSAGE_CENTER = "TOGGLE_MESSAGE_CENTER";
export const SET_MESSAGE_CENTER_VIEW = "SET_MESSAGE_CENTER_VIEW";
export const SET_CURRENT_MESSAGE_PARTICIPANTS =
    "SET_CURRENT_MESSAGE_PARTICIPANTS";
export const SET_CURRENT_MESSAGE_OPPORTUNITY =
    "SET_CURRENT_MESSAGE_OPPORTUNITY";
export const GET_CURRENT_CHAT = "GET_CURRENT_CHAT";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const UPDATE_MESSAGE_COUNT = "UPDATE_MESSAGE_COUNT";
export const UPDATE_UNREAD_MESSAGE_COUNT = "UPDATE_UNREAD_MESSAGE_COUNT";
export const CREATE_ROOM = "CREATE_ROOM";
export const CREATE_ROOM_LOADING = "CREATE_ROOM_LOADING";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const SET_MESSAGE_TUTORIAL = "SET_MESSAGE_TUTORIAL";
export const GET_OPPORTUNITY_DETAILS_FOR_CHAT =
    "GET_OPPORTUNITY_DETAILS_FOR_CHAT";
export const GET_OPPORTUNITY_DETAILS_FOR_CHAT_SUCCESS =
    "GET_OPPORTUNITY_DETAILS_FOR_CHAT_SUCCESS";
export const GET_OPPORTUNITY_DETAILS_FOR_CHAT_ERROR =
    "GET_OPPORTUNITY_DETAILS_FOR_CHAT_ERROR";

// Opportunity actionTypes
export const SET_INBOX_VIEW = "SET_INBOX_VIEW";
export const GET_LATEST_OPPORTUNITIES = "GET_LATEST_OPPORTUNITIES";
export const GET_LATEST_OPPORTUNITIES_SUCCESS =
    "GET_LATEST_OPPORTUNITIES_SUCCESS";
export const GET_LATEST_OPPORTUNITIES_ERROR = "GET_LATEST_OPPORTUNITIES_ERROR";
export const GET_ALL_OPPORTUNITIES = "GET_ALL_OPPORTUNITIES";
export const GET_ALL_OPPORTUNITIES_SUCCESS = "GET_ALL_OPPORTUNITIES_SUCCESS";
export const GET_ALL_OPPORTUNITIES_ERROR = "GET_ALL_OPPORTUNITIES_ERROR";
export const GET_PROFILED_OPPORTUNITIES = "GET_PROFILED_OPPORTUNITIES";
export const GET_PROFILED_OPPORTUNITIES_SUCCESS =
    "GET_PROFILED_OPPORTUNITIES_SUCCESS";
export const GET_PROFILED_OPPORTUNITIES_ERROR =
    "GET_PROFILED_OPPORTUNITIES_ERROR";
export const GET_MY_OPPORTUNITIES = "GET_MY_OPPORTUNITIES";
export const GET_MY_OPPORTUNITIES_SUCCESS = "GET_MY_OPPORTUNITIES_SUCCESS";
export const GET_MY_OPPORTUNITIES_ERROR = "GET_MY_OPPORTUNITIES_ERROR";
export const GET_INTERESTED_OPPORTUNITIES = "GET_INTERESTED_OPPORTUNITIES";
export const GET_INTERESTED_OPPORTUNITIES_SUCCESS =
    "GET_INTERESTED_OPPORTUNITIES_SUCCESS";
export const GET_INTERESTED_OPPORTUNITIES_ERROR =
    "GET_INTERESTED_OPPORTUNITIES_ERROR";
export const GET_INTERESTED_OPPORTUNITIES_FOR_LISTING =
    "GET_INTERESTED_OPPORTUNITIES_FOR_LISTING";
export const GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_SUCCESS =
    "GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_SUCCESS";
export const GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_ERROR =
    "GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_ERROR";
export const UPDATE_INBOX = "UPDATE_INBOX";
export const UPDATE_LATEST_OPPORTUNITIES = "UPDATE_LATEST_OPPORTUNITIES";
export const GET_FOLLOWED_OPPORTUNITIES = "GET_FOLLOWED_OPPORTUNITIES";
export const GET_FOLLOWED_OPPORTUNITIES_SUCCESS =
    "GET_FOLLOWED_OPPORTUNITIES_SUCCESS";
export const GET_FOLLOWED_OPPORTUNITIES_ERROR =
    "GET_FOLLOWED_OPPORTUNITIES_ERROR";
export const GET_NOT_INTERESTED_OPPORTUNITIES =
    "GET_NOT_INTERESTED_OPPORTUNITIES";
export const GET_NOT_INTERESTED_OPPORTUNITIES_SUCCESS =
    "GET_NOT_INTERESTED_OPPORTUNITIES_SUCCESS";
export const GET_NOT_INTERESTED_OPPORTUNITIES_ERROR =
    "GET_NOT_INTERESTED_OPPORTUNITIES_ERROR";
export const GET_OPPORTUNITIES_COMING_SOON = "GET_OPPORTUNITIES_COMING_SOON";
export const GET_OPPORTUNITIES_COMING_SOON_SUCCESS =
    "GET_OPPORTUNITIES_COMING_SOON_SUCCESS";
export const GET_OPPORTUNITIES_COMING_SOON_ERROR =
    "GET_OPPORTUNITIES_COMING_SOON_ERROR";
export const GET_OPPORTUNITY_DETAILS = "GET_OPPORTUNITY_DETAILS";
export const GET_OPPORTUNITY_DETAILS_SUCCESS =
    "GET_OPPORTUNITY_DETAILS_SUCCESS";
export const GET_OPPORTUNITY_DETAILS_ERROR = "GET_OPPORTUNITY_DETAILS_ERROR";
export const SET_INTEREST_STATUS = "SET_INTEREST_STATUS";
export const SET_INTEREST_STATUS_SUCCESS = "SET_INTEREST_STATUS_SUCCESS";
export const SET_INTEREST_STATUS_ERROR = "SET_INTEREST_STATUS_ERROR";
export const CREATE_OPPORTUNITY = "CREATE_OPPORTUNITY";
export const CREATE_OPPORTUNITY_SUCCESS = "CREATE_OPPORTUNITY_SUCCESS";
export const CREATE_OPPORTUNITY_ERROR = "CREATE_OPPORTUNITY_ERROR";
export const EDIT_OPPORTUNITY = "EDIT_OPPORTUNITY";
export const EDIT_OPPORTUNITY_SUCCESS = "EDIT_OPPORTUNITY_SUCCESS";
export const EDIT_OPPORTUNITY_ERROR = "EDIT_OPPORTUNITY_ERROR";
export const TOGGLE_OPPORTUNITY_ACTIVE = "TOGGLE_OPPORTUNITY_ACTIVE";
export const TOGGLE_OPPORTUNITY_ACTIVE_SUCCESS =
    "TOGGLE_OPPORTUNITY_ACTIVE_SUCCESS";
export const TOGGLE_OPPORTUNITY_ACTIVE_ERROR =
    "TOGGLE_OPPORTUNITY_ACTIVE_ERROR";
export const RESET_DRAFT_MATCHES = "RESET_DRAFT_MATCHES";
export const GET_MATCHES_FOR_OPPORTUNITY = "GET_MATCHES_FOR_OPPORTUNITY";
export const GET_MATCHES_FOR_OPPORTUNITY_SUCCESS =
    "GET_MATCHES_FOR_OPPORTUNITY_SUCCESS";
export const GET_MATCHES_FOR_OPPORTUNITY_ERROR =
    "GET_MATCHES_FOR_OPPORTUNITY_ERROR";
export const SHARE_OPPORTUNITY = "SHARE_OPPORTUNITY";
export const SHARE_OPPORTUNITY_SUCCESS = "SHARE_OPPORTUNITY_SUCCESS";
export const SHARE_OPPORTUNITY_ERROR = "SHARE_OPPORTUNITY_ERROR";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_ERROR = "UPLOAD_DOCUMENT_ERROR";
export const OPPORTUNITY_DOCUMENT = "OPPORTUNITY_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const ASSIGN_OPPORTUNITY_TO_DOCUMENT = "ASSIGN_OPPORTUNITY_TO_DOCUMENT";
export const ASSIGN_OPPORTUNITY_TO_DOCUMENT_SUCCESS =
    "ASSIGN_OPPORTUNITY_TO_DOCUMENT_SUCCESS";
export const ASSIGN_OPPORTUNITY_TO_DOCUMENT_ERROR =
    "ASSIGN_OPPORTUNITY_TO_DOCUMENT_ERROR";
export const POST_OPPORTUNITY_UPDATE = "POST_OPPORTUNITY_UPDATE";
export const POST_OPPORTUNITY_UPDATE_SUCCESS =
    "POST_OPPORTUNITY_UPDATE_SUCCESS";
export const POST_OPPORTUNITY_UPDATE_ERROR = "POST_OPPORTUNITY_UPDATE_ERROR";
export const SET_OPPORTUNITY_SECTOR = "SET_OPPORTUNITY_SECTOR";
export const SET_OPPORTUNITY_SECTOR_SUCCESS = "SET_OPPORTUNITY_SECTOR_SUCCESS";
export const SET_OPPORTUNITY_SECTOR_ERROR = "SET_OPPORTUNITY_SECTOR_ERROR";

// Auxillary actionTypes
export const GET_OCCUPATIONS = "GET_OCCUPATIONS";
export const GET_OCCUPATIONS_SUCCESS = "GET_OCCUPATIONS_SUCCESS";
export const GET_OCCUPATIONS_ERROR = "GET_OCCUPATIONS_ERROR";
export const GET_EXPERTISES = "GET_EXPERTISES";
export const GET_EXPERTISES_SUCCESS = "GET_EXPERTISES_SUCCESS";
export const GET_EXPERTISES_ERROR = "GET_EXPERTISES_ERROR";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";
export const GET_SECTORS = "GET_SECTORS";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_ERROR = "GET_SECTORS_ERROR";
export const GET_FINANCE_STAGES = "GET_FINANCE_STAGES";
export const GET_FINANCE_STAGES_SUCCESS = "GET_FINANCE_STAGES_SUCCESS";
export const GET_FINANCE_STAGES_ERROR = "GET_FINANCE_STAGES_ERROR";
export const GET_FINANCE_TYPES = "GET_FINANCE_TYPES";
export const GET_FINANCE_TYPES_SUCCESS = "GET_FINANCE_TYPES_SUCCESS";
export const GET_FINANCE_TYPES_ERROR = "GET_FINANCE_TYPES_ERROR";
export const GET_LEVEL_OF_INVESTMENT = "GET_LEVEL_OF_INVESTMENT";
export const GET_LEVEL_OF_INVESTMENT_SUCCESS =
    "GET_LEVEL_OF_INVESTMENT_SUCCESS";
export const GET_LEVEL_OF_INVESTMENT_ERROR = "GET_LEVEL_OF_INVESTMENT_ERROR";
export const GET_BUSINESS_TYPES = "GET_BUSINESS_TYPES";
export const GET_BUSINESS_TYPES_SUCCESS = "GET_BUSINESS_TYPES_SUCCESS";
export const GET_BUSINESS_TYPES_ERROR = "GET_BUSINESS_TYPES_ERROR";
export const GET_INDUSTRY_NEWS = "GET_INDUSTRY_NEWS";
export const GET_INDUSTRY_NEWS_SUCCESS = "GET_INDUSTRY_NEWS_SUCCESS";
export const GET_INDUSTRY_NEWS_ERROR = "GET_INDUSTRY_NEWS_ERROR";

// Preferences actionTypes
export const SET_SECTOR_PREFERENCES = "SET_SECTOR_PREFERENCES";
export const SET_SECTOR_PREFERENCES_SUCCESS = "SET_SECTOR_PREFERENCES_SUCCESS";
export const SET_SECTOR_PREFERENCES_ERROR = "SET_SECTOR_PREFERENCES_ERROR";
export const SET_SECTOR_EXPERTISE = "SET_SECTOR_EXPERTISE";
export const SET_SECTOR_EXPERTISE_SUCCESS = "SET_SECTOR_EXPERTISE_SUCCESS";
export const SET_SECTOR_EXPERTISE_ERROR = "SET_SECTOR_EXPERTISE_ERROR";
export const SET_USER_BUSINESS_TYPE = "SET_USER_BUSINESS_TYPE";
export const SET_USER_BUSINESS_TYPE_SUCCESS = "SET_USER_BUSINESS_TYPE_SUCCESS";
export const SET_USER_BUSINESS_TYPE_ERROR = "SET_USER_BUSINESS_TYPE_ERROR";
export const SET_USER_FINANCE_STAGE = "SET_USER_FINANCE_STAGE";
export const SET_USER_FINANCE_STAGE_SUCCESS = "SET_USER_FINANCE_STAGE_SUCCESS";
export const SET_USER_FINANCE_STAGE_ERROR = "SET_USER_FINANCE_STAGE_ERROR";
export const SET_FINANCE_STAGE_EXPERTISE = "SET_FINANCE_STAGE_EXPERTISE";
export const SET_FINANCE_STAGE_EXPERTISE_SUCCESS =
    "SET_FINANCE_STAGE_EXPERTISE_SUCCESS";
export const SET_FINANCE_STAGE_EXPERTISE_ERROR =
    "SET_FINANCE_STAGE_EXPERTISE_ERROR";
export const SET_USER_LEVEL_OF_INVESTMENT = "SET_USER_LEVEL_OF_INVESTMENT";
export const SET_USER_LEVEL_OF_INVESTMENT_SUCCESS =
    "SET_USER_LEVEL_OF_INVESTMENT_SUCCESS";
export const SET_USER_LEVEL_OF_INVESTMENT_ERROR =
    "SET_USER_LEVEL_OF_INVESTMENT_ERROR";
export const SET_LOCATION_PREFERENCES = "SET_LOCATION_PREFERENCES";
export const SET_LOCATION_PREFERENCES_SUCCESS =
    "SET_LOCATION_PREFERENCES_SUCCESS";
export const SET_LOCATION_PREFERENCES_ERROR = "SET_LOCATION_PREFERENCES_ERROR";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";
export const SET_USER_OCCUPATION = "SET_USER_OCCUPATION";
export const SET_USER_OCCUPATION_SUCCESS = "SET_USER_OCCUPATION_SUCCESS";
export const SET_USER_OCCUPATION_ERROR = "SET_USER_OCCUPATION_ERROR";
export const SET_USER_EXPERTISE = "SET_USER_EXPERTISE";
export const SET_USER_EXPERTISE_SUCCESS = "SET_USER_EXPERTISE_SUCCESS";
export const SET_USER_EXPERTISE_ERROR = "SET_USER_EXPERTISE_ERROR";
export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE";
export const UPLOAD_PROFILE_PICTURE_SUCCESS = "UPLOAD_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_PROFILE_PICTURE_ERROR = "UPLOAD_PROFILE_PICTURE_ERROR";

// Business actionTypes
export const REGISTER_BUSINESS = "REGISTER_BUSINESS";
export const REGISTER_BUSINESS_SUCCESS = "REGISTER_BUSINESS_SUCCESS";
export const REGISTER_BUSINESS_ERROR = "REGISTER_BUSINESS_ERROR";
export const GET_MY_BUSINESS_DETAILS = "GET_MY_BUSINESS_DETAILS";
export const GET_MY_BUSINESS_DETAILS_SUCCESS =
    "GET_MY_BUSINESS_DETAILS_SUCCESS";
export const GET_MY_BUSINESS_DETAILS_ERROR = "GET_MY_BUSINESS_DETAILS_ERROR";
export const UPDATE_BUSINESS = "UPDATE_BUSINESS";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_ERROR = "UPDATE_BUSINESS_ERROR";
export const UPDATE_BUSINESS_LOCATION = "UPDATE_BUSINESS_LOCATION";
export const UPDATE_BUSINESS_LOCATION_SUCCESS =
    "UPDATE_BUSINESS_LOCATION_SUCCESS";
export const UPDATE_BUSINESS_LOCATION_ERROR = "UPDATE_BUSINESS_LOCATION_ERROR";
export const GET_BUSINESS_DETAILS = "GET_BUSINESS_DETAILS";
export const GET_BUSINESS_DETAILS_SUCCESS = "GET_BUSINESS_DETAILS_SUCCESS";
export const GET_BUSINESS_DETAILS_ERROR = "GET_BUSINESS_DETAILS_ERROR";
export const GET_MATCHES_FOR_BUSINESS = "GET_MATCHES_FOR_BUSINESS";
export const GET_MATCHES_FOR_BUSINESS_SUCCESS =
    "GET_MATCHES_FOR_BUSINESS_SUCCESS";
export const GET_MATCHES_FOR_BUSINESS_ERROR = "GET_MATCHES_FOR_BUSINESS_ERROR";
export const SHARE_BUSINESS = "SHARE_BUSINESS";
export const SHARE_BUSINESS_SUCCESS = "SHARE_BUSINESS_SUCCESS";
export const SHARE_BUSINESS_ERROR = "SHARE_BUSINESS_ERROR";
export const GET_ALL_BUSINESSES = "GET_ALL_BUSINESSES";
export const GET_ALL_BUSINESSES_SUCCESS = "GET_ALL_BUSINESSES_SUCCESS";
export const GET_ALL_BUSINESSES_ERROR = "GET_ALL_BUSINESSES_ERROR";

export const VERIFY_RECAPTCHA_TOKEN_SUCCESS = "VERIFY_RECAPTCHA_TOKEN_SUCCESS";
export const VERIFY_RECAPTCHA_TOKEN_ERROR = "VERIFY_RECAPTCHA_TOKEN_ERROR";

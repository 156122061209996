import React, { Component } from "react";

import { arraysEqual, degreeOrdinal } from "../../util";
import { regionIcons } from "../../data/data";
import Button from "../button/Button";
import ProfileIcon from "../profile-icon/ProfileIcon";

import "./UserDetails.scss";

class UserDetails extends Component {
    state = {
        ignoreUser: false
    };

    messageUser() {
        const {
            user,
            messaging,
            toggleMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction,
            clearFocusedUserAction
        } = this.props;

        toggleMessageCenterAction();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction([user]);
        setCurrentMessageOpportunityAction(null);

        const chat = messaging.messages.find((room) => {
            return arraysEqual(room.people, [user]) && !room.opportunity;
        });

        getCurrentChatAction(chat, [user]);
        clearFocusedUserAction();
    }

    close() {
        const { clearFocusedUserAction } = this.props;

        clearFocusedUserAction();
    }

    connectToUser() {
        const {
            currentUser,
            user,
            connectToUserAction,
            clearFocusedUserAction,
            getUserConnectionsAction
        } = this.props;

        connectToUserAction(currentUser.userId, user.id);
        clearFocusedUserAction();
        getUserConnectionsAction();
    }

    ignoreUser() {
        const {
            currentUser,
            user,
            ignoreUserAction,
            clearFocusedUserAction,
            getUserConnectionsAction
        } = this.props;

        ignoreUserAction(currentUser.userId, user.id);

        this.setState({
            ignoreUser: true
        });

        getUserConnectionsAction();

        setTimeout(() => {
            this.setState({
                ignoreUser: false
            });
            clearFocusedUserAction();
        }, 5000);
    }

    render() {
        const { currentUser, user, connections, auxillary } = this.props;
        const { ignoreUser } = this.state;

        const userIsConnection = connections.find(
            (connection) => connection.id === user.id
        );

        const matches = [];
        currentUser.sectorInterests.forEach((si1) => {
            if (user.sectorInterests.find((si2) => si1.id === si2.id)) {
                const sector = auxillary.sectorInterests.find(
                    (sector) => sector.id === si1.id
                );
                if (sector) {
                    matches.push(`Interest in ${sector.value}`);
                }
            }
        });

        if (
            currentUser.locationId &&
            currentUser.locationId === user.locationId
        ) {
            matches.push(`Based in ${currentUser.location}`);
        }

        currentUser.expertise.forEach((e1) => {
            if (user.expertise.find((e2) => e1.id === e2.id)) {
                const expertise = auxillary.expertise.find(
                    (item) => e1.id === item.id
                );
                matches.push(`Expertise in ${expertise.value}`);
            }
        });

        currentUser.levelOfInvestment.forEach((loi1) => {
            if (user.levelOfInvestment.find((loi2) => loi1.id === loi2.id)) {
                const currencyPreference = currentUser.currencyPreference;
                const loiDetails = auxillary.levelOfInvestment[
                    currencyPreference
                ].find((item) => {
                    return loi1.id === item.id;
                });
                if (loiDetails)
                    matches.push(`Investment tickets: ${loiDetails.value}`);
            }
        });

        const location = auxillary.locations.find(
            (l) => l.id === user.locationId
        );
        let region;
        if (location)
            region = location.isRegion
                ? location.value
                : auxillary.locations.find((l) => l.id === location.regionId)
                      .value;

        return (
            <div className="user-details-overlay">
                <div className="user-details">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                        onClick={this.close.bind(this)}
                        className="close"
                        alt="Close"
                    />
                    <div className="basic-details">
                        <ProfileIcon person={user} />
                        <div className="name-actions">
                            <p>
                                <strong>{user.name}</strong>
                                <span className="degree">
                                    {/* {degreeOrdinal(user.degree)} */}
                                </span>
                                {userIsConnection && (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/message-icon.svg`}
                                        onClick={this.messageUser.bind(this)}
                                    />
                                )}
                            </p>
                            {user.jobTitle && <p>{user.jobTitle}</p>}
                            <div className="actions">
                                {user.linkedInProfile && (
                                    <Button
                                        href={user.linkedInProfile}
                                        isExternal
                                    >
                                        View LinkedIn
                                    </Button>
                                )}
                                {!ignoreUser &&
                                    !userIsConnection &&
                                    currentUser.id !== user.id && (
                                        <>
                                            <Button
                                                onClick={this.connectToUser.bind(
                                                    this
                                                )}
                                            >
                                                Connect
                                            </Button>
                                            <Button
                                                onClick={this.ignoreUser.bind(
                                                    this
                                                )}
                                            >
                                                Ignore
                                            </Button>
                                        </>
                                    )}
                                {!ignoreUser && userIsConnection && (
                                    <Button
                                        onClick={this.ignoreUser.bind(this)}
                                    >
                                        Disconnect
                                    </Button>
                                )}
                                {ignoreUser && (
                                    <p>
                                        You have now unconnected. This person
                                        will not be notified.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {currentUser.id !== user.id && matches.length > 0 && (
                        <div className="details match-details">
                            <dl>
                                <dt>Match with you</dt>
                                <dd>
                                    <ul>
                                        {matches.map((match) => (
                                            <li>{match}</li>
                                        ))}
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                    )}

                    <div className="details">
                        {user.country && (
                            <dl>
                                <dt>Location</dt>
                                <dd className="location-details">
                                    {user.location}{" "}
                                    {!location.isRegion && (
                                        <img
                                            src={`https://flagpedia.net/data/flags/h24/${location.code.toLowerCase()}.png`}
                                        />
                                    )}
                                    {location.isRegion && (
                                        <img
                                            src={regionIcons[region]}
                                            className="flag"
                                        />
                                    )}
                                    {region !== user.location && (
                                        <>
                                            {", "}
                                            <span>{region}</span>
                                            <img
                                                src={regionIcons[region]}
                                                className="flag"
                                            />
                                        </>
                                    )}
                                </dd>
                            </dl>
                        )}
                        {user.expertise.length > 0 && (
                            <dl>
                                <dt>Expertise</dt>
                                <dd>
                                    <ul>
                                        {user.expertise.map((e1) => {
                                            const expertise = auxillary.expertise.find(
                                                (item) => e1.id === item.id
                                            );
                                            return <li>{expertise.value}</li>;
                                        })}
                                    </ul>
                                </dd>
                            </dl>
                        )}
                        {user.sectorInterests.length > 0 && (
                            <dl>
                                <dt>Sector interests</dt>
                                <dd>
                                    <ul>
                                        {user.sectorInterests.map((si) => {
                                            const sector = auxillary.sectorInterests.find(
                                                (sector) => sector.id === si.id
                                            );
                                            if (sector) {
                                                return <li>{sector.value}</li>;
                                            }
                                        })}
                                    </ul>
                                </dd>
                            </dl>
                        )}
                        {/*user.connections > 0 && (
                            <dl>
                                <dt>No. of connections</dt>
                                <dd>{user.connections}</dd>
                            </dl>
                        )*/}
                        {user.biography && (
                            <dl>
                                <dt>About</dt>
                                <dd>{user.biography}</dd>
                            </dl>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserDetails;

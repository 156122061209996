import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./auth/authReducer";
import auxillary from "./auxillary/auxillaryReducer";
import messaging from "./messaging/messagingReducer";
import network from "./network/networkReducer";
import opportunities from "./opportunities/opportunityReducer";
import business from "./business/businessReducer";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        auth,
        auxillary,
        messaging,
        network,
        opportunities,
        business
    });

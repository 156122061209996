import React, { Component } from "react";
import { connect } from "react-redux";

import { inviteToFiinectAction } from "../../actions/auth/authActions";
import Button from "../../components/button/Button";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import NetworkFilters from "../../components/network-filters/NetworkFilters";
import TextInput from "../../components/text-input/TextInput";
import { matomoTrack } from "../../util";

import "./SendInvite.scss";

class SendInvite extends Component {
    state = {
        shareConfirm: false,
        externalUserDetails: {
            firstName: "",
            surname: "",
            email: ""
        }
    };

    shareToExternalUser() {
        const { inviteToFiinectAction } = this.props;
        const { externalUserDetails } = this.state;

        inviteToFiinectAction(externalUserDetails);
        this.setState({
            shareConfirm: true
        });

        setTimeout(() => {
            this.setState({
                shareConfirm: false
            });
        }, 5000);
    }

    enterUserDetails(key, value) {
        const { externalUserDetails } = this.state;

        externalUserDetails[key] = value;

        this.setState({
            externalUserDetails
        });
    }

    render() {
        const { premiumInvitesCount } = this.props;

        return (
            <>
                <HighlightedContent headline="My Network">
                    <NetworkFilters />
                </HighlightedContent>
                <MainContent>
                    <div
                        className="send-invite"
                        onClick={() =>
                            matomoTrack(
                                "send-invite-link",
                                document.querySelector(
                                    "div.current-user.logged-in p"
                                ).innerText
                            )
                        }
                    >
                        {premiumInvitesCount < 10 && (
                            <aside>
                                <h4>
                                    Unlock all business opportunities and
                                    connections free of charge. No catches, no
                                    credit card.
                                </h4>
                                <p>
                                    Access to all opportunities &amp; new
                                    connections will immediately unlock once you
                                    have invited 10 people to be part of your
                                    network. You have a free Premium
                                    Membership invitation below for each of
                                    them.
                                </p>
                                <p>
                                    Why? Members bring new opportunities for you
                                    &amp; the wider platform. Full access is
                                    granted to users who add value to the group.
                                </p>
                                <p>
                                    Each membership is worth £600 and lasts 2
                                    years.
                                </p>
                            </aside>
                        )}
                        <section>
                            <img
                                src={
                                    premiumInvitesCount < 10
                                        ? `${process.env.PUBLIC_URL}/assets/premium.png`
                                        : `${process.env.PUBLIC_URL}/assets/standard.png`
                                }
                                alt="Fiinect Premium"
                            />
                            {premiumInvitesCount < 10 && (
                                <>
                                    <h3>
                                        You may gift up to 10 Premium
                                        memberships to join Fiinect. <br />
                                        Each Premium membership is worth £300
                                        per annum (£24.99/month).
                                    </h3>
                                </>
                            )}

                            {premiumInvitesCount >= 10 && (
                                <h3>
                                    Fiinect is invite-only. As a member you may
                                    gift unlimited Standard Memberships to your
                                    business network.
                                </h3>
                            )}
                            <p
                                className={
                                    this.state.shareConfirm
                                        ? "invite-confirm show"
                                        : "invite-confirm"
                                }
                            >
                                Your invite to{" "}
                                {this.state.externalUserDetails.firstName} was
                                sent.
                            </p>
                            <form>
                                <div className="invite-form">
                                    <fieldset>
                                        <label htmlFor="firstName">
                                            First name
                                        </label>
                                        <TextInput
                                            type="text"
                                            onInput={this.enterUserDetails.bind(
                                                this,
                                                "firstName"
                                            )}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label htmlFor="surname">Surname</label>
                                        <TextInput
                                            type="text"
                                            onInput={this.enterUserDetails.bind(
                                                this,
                                                "surname"
                                            )}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label htmlFor="email">
                                            Email address
                                        </label>
                                        <TextInput
                                            type="text"
                                            onInput={this.enterUserDetails.bind(
                                                this,
                                                "email"
                                            )}
                                        />
                                    </fieldset>
                                </div>
                                <div className="actions">
                                    <Button
                                        isPrimary
                                        onClick={this.shareToExternalUser.bind(
                                            this
                                        )}
                                    >
                                        Send invite
                                    </Button>
                                </div>
                            </form>

                            {premiumInvitesCount < 10 && (
                                <div className="premium-benefits">
                                    <h4>Premium membership gives you:</h4>
                                    <ul>
                                        <li>
                                            Access thousands of new business
                                            opportunities from around the world
                                        </li>
                                        <li>
                                            Unlimited sharing with your network
                                        </li>
                                        <li>
                                            Analysis of your network connections
                                        </li>
                                        <li>
                                            Ability to source Professional
                                            Service Providers
                                        </li>
                                        <li>
                                            Use Workflow tools and secure
                                            communications suite to share &amp;
                                            manage opportunities
                                        </li>
                                        <li>
                                            Unlimited communications with future
                                            business opportunities
                                        </li>
                                        <li>
                                            Unlimited use of the communications
                                            suite
                                        </li>
                                        <li>
                                            Unlimited searches for new
                                            connections to help build your
                                            network
                                        </li>
                                        <li>
                                            Share business opportunities across
                                            the entire Fiinect network in one
                                            click (when live)
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </section>
                    </div>
                </MainContent>
            </>
        );
    }
}

export default connect(
    (state) => ({
        premiumInvitesCount: state.auth.premiumInvitesCount
    }),
    {
        inviteToFiinectAction
    }
)(SendInvite);

import React, { Component } from "react";
import sdk from "matrix-js-sdk";

class MatrixTest extends Component {
    componentDidMount() {
        // register a user before this step...

        this.client = sdk.createClient("https://matrix.org");
        this.client
            .login("m.login.password", {
                user: "declantyson",
                password: "******"
            })
            .then((response) => {
                this.client.startClient();
            });
    }

    getRooms() {
        const rooms = this.client.getRooms();
        console.log(rooms);
    }

    render() {
        return (
            <>
                <h1>MatrixTest</h1>
                <button onClick={this.getRooms.bind(this)}>get rooms</button>
            </>
        );
    }
}

export default MatrixTest;

import React, { Fragment } from "react";

export const relevantPeople = (people) => {
    let sentence = [];
    people.forEach((person, index) => {
        if (index > 0) {
            if (index + 1 < people.length)
                sentence.push(<Fragment key={`${person.name},`}>, </Fragment>);
            if (index + 1 === people.length)
                sentence.push(
                    <Fragment key={`and ${person.name}`}> and </Fragment>
                );
        }

        sentence.push(<strong key={person.name}>{person.name}</strong>);
    });

    return sentence;
};

export const mapPerson = (data) => ({
    id: data.id,
    userId: data.id,
    name: data.name,
    firstName: data.firstName,
    surname: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    role: data.roleIds ? data.roleIds[0] : "",
    phoneNumber: data.phoneNumber,
    profilePhoto: data.profilePhoto,
    matrixUserID: data.matrixUserID,
    matrixAccessToken: data.matrixAccessToken,
    degree: 1, // TODO MED
    occupationId: data.occupationID,
    occupation: data.occupationName,
    company: data.company,
    locationId: data.locationID,
    location: data.locationName,
    country: data.country,
    sectorInterests: data.interestedSector
        .filter((sector) => sector.isActive)
        .map((sector) => ({
            id: sector.sectorId
        })),
    expertise:
        data.userExpertise.map((expertise) => ({
            id: expertise.expertiseId
        })) || [],
    sectorExpertise:
        data.userSectorExpertise.map((sector) => ({
            id: sector.sectorId
        })) || [],
    financeStageExpertise:
        data.userFinanceStageExpertise.map((financeStage) => ({
            id: financeStage.financeStageId
        })) || [],
    levelOfInvestment:
        data.userLevelInvestment.map((level) => ({
            id: level.levelOfInvestmentId
        })) || [],
    financeStages:
        data.userFinanceStage.map((fs) => ({
            id: fs.financeStageId
        })) || [],
    businessTypes:
        data.userBusinessType.map((bt) => ({
            id: bt.businessTypeId
        })) || [],
    linkedInProfile: data.linkedInProfile,
    jobTitle: data.jobTitle,
    connections: data.connections,
    isPremium: data.isPremium,
    wantsPreRevenue: data.wantsPreRevenue,
    wantsProfitOnly: data.wantsProfitOnly,
    profileComplete: data.profileComplete,
    biography: data.biography,
    interestedLocations:
        data.interestedLocation.map((location) => ({
            id: location.locationId
        })) || [],
    currencyPreference: data.currencyPreference || "GBP"
});

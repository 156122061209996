import React, { Component } from "react";

import "./TextInput.scss";

class TextInput extends Component {
    state = {
        passwordVisible: false
    };

    onInput(e) {
        const { onInput } = this.props;

        if (onInput) onInput(e.target.value);
    }

    onEnter(e) {
        const { onEnter } = this.props;

        if (!onEnter) return;

        if (e.keyCode === 13) {
            e.target.value = "";
            onEnter();
        }
    }

    onFocus(e) {
        const { placeholder, keepPlaceholder } = this.props;

        if (e.target.value === "" && placeholder && keepPlaceholder) {
            e.target.value = placeholder;
        }
    }

    toggleVisible() {
        const { passwordVisible } = this.state;

        this.setState({
            passwordVisible: !passwordVisible
        });
    }

    render() {
        const { type, placeholder, multiline, maxLength, id } = this.props;
        const { passwordVisible } = this.state;

        if (multiline) {
            return (
                <textarea
                    maxLength={maxLength}
                    onInput={this.onInput.bind(this)}
                    onKeyDown={this.onEnter.bind(this)}
                    onFocus={this.onFocus.bind(this)}
                    placeholder={placeholder}
                >
                    {placeholder}
                </textarea>
            );
        }

        if (type === "password") {
            return (
                <div className="password-input">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        onInput={this.onInput.bind(this)}
                        onKeyDown={this.onEnter.bind(this)}
                        onFocus={this.onFocus.bind(this)}
                        placeholder={placeholder}
                    />
                    <p
                        onClick={this.toggleVisible.bind(this)}
                        className="show-password"
                    >
                        Show
                    </p>
                </div>
            );
        }

        return (
            <input
                id={id}
                type={type}
                onInput={this.onInput.bind(this)}
                onKeyDown={this.onEnter.bind(this)}
                onFocus={this.onFocus.bind(this)}
                placeholder={placeholder}
            />
        );
    }
}

export default TextInput;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
    employeeRanges,
    financeRoundOptions,
    timescaleOptions,
    turnoverOptions
} from "../../data/data";

import "./CreateOpportunity.scss";

import {
    getSectorsAction,
    getLocationsAction,
    getFinanceStagesAction,
    getFinanceTypesAction,
    getLevelOfInvestmentAction
} from "../../actions/auxillary/auxillaryActions";
import {
    createOpportunityAction,
    getMatchesForOpportunityAction,
    resetDraftMatchesAction,
    shareOpportunityAction,
    uploadDocumentAction,
    removeDocumentAction
} from "../../actions/opportunities/opportunityActions";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import PlainCard from "../../components/plain-card/PlainCard";
import StepProgressBar from "../../components/step-progress-bar/StepProgressBar";
import Button from "../../components/button/Button";
import OptionSelector from "../../components/option-selector/OptionSelector";
import RegionSelector from "../../components/region-selector/RegionSelector";
import RangeGroup from "../../components/range-group/RangeGroup";
import TextInput from "../../components/text-input/TextInput";
import MatchBox from "../../components/match-box/MatchBox";
import Loader from "../../components/loader/Loader";
import DocumentUploader from "../../components/document-uploader/DocumentUploader";
import { matomoTrack } from "../../util";

import countries from "../../data/countries";
import OptionButton from "../../components/option-button/OptionButton";

class CreateOpportunity extends Component {
    state = {
        step: 0,
        timings: "",
        sectors: [],
        financeTypes: [],
        financeStages: [],
        locations: [],
        documents: [],
        levelOfInvestment: 1,
        financeDontKnow: [],
        headline: "",
        summary: "",
        description: "",
        youtubeUrl: "",
        websiteUrl: "",
        invalidUrl: false,
        invalidYoutube: false,
        sharedToMatches: false,
        numberOfEmployees: 1,
        IsPreRevenue: null,
        MakingProfit: null,
        timescales: [],
        turnover: [],
        roundOfFinance: [],
        country: [],
        currency: [{ id: "GBP", value: "GBP" }]
    };

    componentDidMount() {
        const { resetDraftMatchesAction } = this.props;

        resetDraftMatchesAction();
    }

    componentDidUpdate() {
        const { currency } = this.state;

        const { currentUser } = this.props;

        if (currentUser && currentUser.currencyPreference !== currency.id) {
            this.setState({
                currency: [
                    {
                        id: currentUser.currencyPreference,
                        value: currentUser.currencyPreference
                    }
                ]
            });
        }
    }

    setTimings(timings) {
        this.setState({
            timings
        });
        this.nextStep();
    }

    nextStep() {
        const { step } = this.state;

        const { createOpportunityAction } = this.props;

        matomoTrack(
            "create-opportunity",
            document.querySelector("div.current-user.logged-in p").innerText
        );

        window.scrollTo(0, 0);

        if (step === 3) {
            createOpportunityAction(this.state);
        }

        this.setState({
            step: step + 1
        });
    }

    previousStep() {
        const { step } = this.state;

        this.setState({
            step: step - 1
        });
    }

    update(key, value) {
        const { getMatchesForOpportunityAction } = this.props;
        if (typeof value === "boolean") {
            this.setState({
                [key]: value
            });
        } else {
            const arr1 = this.state[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));

            this.setState({
                [key]: additions
            });

            getMatchesForOpportunityAction({
                ...this.state,
                [key]: additions
            });
        }
    }

    updateMulti(key, limit, value) {
        const { getMatchesForOpportunityAction } = this.props;

        if (value.length > limit) return;

        this.setState({
            [key]: value
        });

        getMatchesForOpportunityAction({
            ...this.state,
            [key]: value
        });
    }

    updateRange(lookup, key, value) {
        const { getMatchesForOpportunityAction } = this.props;

        this.setState({
            [key]: lookup[value].id
        });

        getMatchesForOpportunityAction({
            ...this.state,
            [key]: lookup[value].id
        });
    }

    updateEmployees(lookup, key, value) {
        this.setState({
            [key]: lookup[value]
        });
    }

    updateText(key, value) {
        this.setState({
            [key]: value
        });
    }

    updateUrl(key, value) {
        const urlValidator = new RegExp(
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
        );
        if (value !== "" && !urlValidator.test(value)) {
            this.setState({
                invalidUrl: true
            });
        } else {
            this.setState({
                invalidUrl: false,
                [key]: value
            });
        }
    }

    updateYoutube(key, value) {
        const urlValidator = new RegExp(
            /^(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+)/i
        );
        const match = value.match(urlValidator);

        if (value !== "" && !match) {
            this.setState({
                invalidYoutube: true
            });
        } else {
            this.setState({
                invalidYoutube: false,
                [key]: match[1]
            });
        }
    }

    nextStepEnabled() {
        const {
            step,
            sectors,
            financeTypes,
            financeStages,
            locations,
            headline,
            summary,
            description,
            invalidUrl,
            invalidYoutube
        } = this.state;

        if (step === 1 && sectors.length === 1) return true;
        if (
            step === 2 &&
            financeTypes.length === 1 &&
            financeStages.length === 1 &&
            locations.length === 1
        )
            return true;
        if (
            step === 3 &&
            headline !== "" &&
            summary !== "" &&
            description !== "" &&
            !invalidUrl &&
            !invalidYoutube
        )
            return true;

        return false;
    }

    countryFlagForList(countryName) {
        var country = countries.ref_country_codes.find(
            (c) => c.country === countryName
        );
        if (!country) {
            return "";
        }

        return `https://flagpedia.net/data/flags/h24/${country.alpha2.toLowerCase()}.png`;
    }

    shareToMatches() {
        const {
            shareOpportunityAction,
            createdOpportunityId,
            draftMatches
        } = this.props;

        shareOpportunityAction(
            { opportunityId: createdOpportunityId },
            draftMatches.map((match) => match.id)
        );
        this.setState({
            sharedToMatches: true
        });
    }

    saveForLater() {
        const { createOpportunityAction } = this.props;
        createOpportunityAction(this.state);
    }

    render() {
        const {
            auxillary,
            draftMatches,
            createdOpportunityId,
            documentUploading,
            documents,
            executiveBrochure,
            investorDeck,
            uploadDocumentAction,
            removeDocumentAction
        } = this.props;
        const {
            step,
            sectors,
            financeTypes,
            timings,
            invalidYoutube,
            invalidUrl,
            sharedToMatches,
            currency,
            financeDontKnow,
            country,
            IsPreRevenue,
            MakingProfit,
            timescales,
            turnover,
            roundOfFinance
        } = this.state;

        if (createdOpportunityId) {
            return (
                <Redirect
                    to={`${process.env.PUBLIC_URL}/opportunities/${createdOpportunityId}`}
                />
            );
        }

        return (
            <div className="home-container">
                <HighlightedContent
                    headline="Create an opportunity"
                    small
                ></HighlightedContent>
                <MainContent>
                    <div className="create-opportunity">
                        {step === 0 && (
                            <div className="timings">
                                <p>I am...</p>
                                <PlainCard
                                    onClick={this.setTimings.bind(this, "Soon")}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/soon.png`}
                                        alt="Looking for finance soon"
                                    />
                                    Looking for finance <strong>soon</strong>
                                    <small>
                                        I'm not quite ready to raise but I want
                                        to find people who might be interested.
                                    </small>
                                </PlainCard>
                                <PlainCard
                                    onClick={this.setTimings.bind(this, "Now")}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/now.png`}
                                        alt="Looking for finance now"
                                    />
                                    Looking for finance <strong>now</strong>
                                </PlainCard>
                            </div>
                        )}

                        {step > 0 && (
                            <>
                                <StepProgressBar step={step} />

                                <div className="create-opportunity-form">
                                    <article>
                                        <p>
                                            Use the form "back" button rather
                                            than your browser button to avoid
                                            losing changes
                                            <br />
                                            <br />
                                        </p>
                                        {step < 4 && (
                                            <small>
                                                <span className="mandatory">
                                                    *
                                                </span>{" "}
                                                mandatory
                                            </small>
                                        )}
                                        {step === 1 && (
                                            <>
                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Select up to 2 sectors
                                                </h4>
                                                <OptionSelector
                                                    selectedValues={sectors}
                                                    options={
                                                        auxillary.sectorInterests
                                                    }
                                                    sectorVariant
                                                    imageFormat="svg"
                                                    action={this.updateMulti.bind(
                                                        this,
                                                        "sectors",
                                                        2
                                                    )}
                                                />
                                            </>
                                        )}
                                        {step === 2 && (
                                            <>
                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Type of finance
                                                </h4>
                                                <OptionSelector
                                                    selectedValues={
                                                        financeTypes
                                                    }
                                                    options={
                                                        auxillary.financeTypes
                                                    }
                                                    action={this.update.bind(
                                                        this,
                                                        "financeTypes"
                                                    )}
                                                />

                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>
                                                    Upload executive
                                                    summary/brochure
                                                </h4>
                                                <DocumentUploader
                                                    uploadDocumentAction={
                                                        uploadDocumentAction
                                                    }
                                                    removeDocumentAction={
                                                        removeDocumentAction
                                                    }
                                                    documentUploading={
                                                        documentUploading
                                                    }
                                                    documents={
                                                        executiveBrochure
                                                    }
                                                    keyName="executiveBrochure"
                                                />
                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>
                                                    Upload investor
                                                    deck/supporting material
                                                </h4>
                                                <DocumentUploader
                                                    uploadDocumentAction={
                                                        uploadDocumentAction
                                                    }
                                                    removeDocumentAction={
                                                        removeDocumentAction
                                                    }
                                                    documentUploading={
                                                        documentUploading
                                                    }
                                                    documents={investorDeck}
                                                    keyName="investorDeck"
                                                />
                                                <h4>Upload other documents</h4>

                                                <DocumentUploader
                                                    uploadDocumentAction={
                                                        uploadDocumentAction
                                                    }
                                                    removeDocumentAction={
                                                        removeDocumentAction
                                                    }
                                                    documentUploading={
                                                        documentUploading
                                                    }
                                                    documents={documents}
                                                    multiUpload
                                                />

                                                {/* <h4>Stage of finance</h4>
                                                <p>
                                                    Select only if you chose
                                                    Equity as "Type of Finance"
                                                </p>
                                                <OptionSelector
                                                    selectedValues={
                                                        financeStages
                                                    }
                                                    options={
                                                        auxillary.financeStages
                                                    }
                                                    action={this.update.bind(
                                                        this,
                                                        "financeStages"
                                                    )}
                                                /> */}

                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Where are you based?
                                                </h4>
                                                <OptionSelector
                                                    vertical
                                                    getImage={this.countryFlagForList.bind(
                                                        this
                                                    )}
                                                    selectedValues={country}
                                                    options={auxillary.locations.filter(
                                                        (location) =>
                                                            !location.isRegion
                                                    )}
                                                    action={this.update.bind(
                                                        this,
                                                        "country"
                                                    )}
                                                />

                                                {timings === "Soon" && (
                                                    <>
                                                        <h4>
                                                            What is your annual
                                                            turnover?
                                                        </h4>
                                                        <OptionSelector
                                                            selectedValues={
                                                                turnover
                                                            }
                                                            options={
                                                                turnoverOptions
                                                            }
                                                            action={this.update.bind(
                                                                this,
                                                                "turnover"
                                                            )}
                                                        />
                                                    </>
                                                )}
                                                <h4>
                                                    Which round of finance are
                                                    you raising?
                                                </h4>
                                                <OptionSelector
                                                    vertical
                                                    selectedValues={
                                                        roundOfFinance
                                                    }
                                                    options={
                                                        financeRoundOptions
                                                    }
                                                    action={this.update.bind(
                                                        this,
                                                        "roundOfFinance"
                                                    )}
                                                />

                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Where do you operate?
                                                </h4>
                                                <RegionSelector
                                                    onRegionToggle={this.update.bind(
                                                        this,
                                                        "locations"
                                                    )}
                                                />

                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Finance required
                                                </h4>

                                                {timings === "Soon" && (
                                                    <OptionSelector
                                                        selectedValues={
                                                            financeDontKnow
                                                        }
                                                        options={[
                                                            {
                                                                id: "",
                                                                value:
                                                                    "I don't know yet"
                                                            }
                                                        ]}
                                                        action={this.update.bind(
                                                            this,
                                                            "financeDontKnow"
                                                        )}
                                                        vertical
                                                        noIcon
                                                    ></OptionSelector>
                                                )}

                                                <br />

                                                {(financeDontKnow.length ===
                                                    0 ||
                                                    timings === "Now") && (
                                                    <>
                                                        <OptionSelector
                                                            selectedValues={
                                                                currency
                                                            }
                                                            options={Object.keys(
                                                                auxillary.levelOfInvestment
                                                            ).map((key) => ({
                                                                id: key,
                                                                value: key
                                                            }))}
                                                            action={this.update.bind(
                                                                this,
                                                                "currency"
                                                            )}
                                                            noIcon
                                                            vertical
                                                            preventDeselect
                                                        />
                                                        <RangeGroup
                                                            smallIcon={`${process.env.PUBLIC_URL}/assets/pennies.png`}
                                                            largeIcon={`${process.env.PUBLIC_URL}/assets/notes.png`}
                                                            initialValue={0}
                                                            idKey="financeRequired"
                                                            onChange={this.updateRange.bind(
                                                                this,
                                                                auxillary
                                                                    .levelOfInvestment[
                                                                    currency[0]
                                                                        .id
                                                                ],
                                                                "levelOfInvestment"
                                                            )}
                                                            lookup={auxillary.levelOfInvestment[
                                                                currency[0].id
                                                            ].map(
                                                                (level) =>
                                                                    level.value
                                                            )}
                                                            lookupId={auxillary.levelOfInvestment[
                                                                currency[0].id
                                                            ].map(
                                                                (level) =>
                                                                    level.id
                                                            )}
                                                            notches
                                                        />
                                                    </>
                                                )}

                                                {timings === "Soon" && (
                                                    <>
                                                        <h4>
                                                            Number of employees
                                                        </h4>
                                                        <RangeGroup
                                                            smallIcon={`${process.env.PUBLIC_URL}/assets/single-employee.svg`}
                                                            largeIcon={`${process.env.PUBLIC_URL}/assets/many-employees.svg`}
                                                            initialValue={0}
                                                            idKey="employees"
                                                            onChange={this.updateEmployees.bind(
                                                                this,
                                                                employeeRanges,
                                                                "numberOfEmployees"
                                                            )}
                                                            lookup={
                                                                employeeRanges
                                                            }
                                                            notches
                                                        />
                                                    </>
                                                )}
                                                {/* {timings === "Now" && ( */}
                                                <>
                                                    <h4>
                                                        Are you pre-revenue?
                                                    </h4>

                                                    <OptionSelector
                                                        isBinary
                                                        isBinarySelected={
                                                            IsPreRevenue
                                                        }
                                                        binaryTrueText=""
                                                        binaryFalseText=""
                                                        action={this.update.bind(
                                                            this,
                                                            "IsPreRevenue"
                                                        )}
                                                    />
                                                </>
                                                {/* )} */}

                                                <h4>
                                                    Are you making a profit?
                                                </h4>
                                                <OptionSelector
                                                    isBinary
                                                    isBinarySelected={
                                                        MakingProfit
                                                    }
                                                    binaryTrueText=""
                                                    binaryFalseText=""
                                                    action={this.update.bind(
                                                        this,
                                                        "MakingProfit"
                                                    )}
                                                />
                                                {timings === "Soon" && (
                                                    <>
                                                        <h4>
                                                            When do you plan to
                                                            raise?
                                                        </h4>
                                                        <OptionSelector
                                                            selectedValues={
                                                                timescales
                                                            }
                                                            options={
                                                                timescaleOptions
                                                            }
                                                            noIcon
                                                            action={this.update.bind(
                                                                this,
                                                                "timescales"
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {step === 3 && (
                                            <>
                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Title of your opportunity
                                                </h4>
                                                <p>
                                                    Top-line description of the
                                                    opportunity in one sentence.
                                                    Try and include all the
                                                    important keywords here.
                                                </p>
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateText.bind(
                                                        this,
                                                        "headline"
                                                    )}
                                                />

                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    Brief summary
                                                </h4>
                                                <p>
                                                    This paragraph will appear
                                                    on the opportunity's info
                                                    card to give prospective
                                                    investors an at-a-glance
                                                    understandng of your offer
                                                </p>
                                                <TextInput
                                                    type="text"
                                                    multiline
                                                    onInput={this.updateText.bind(
                                                        this,
                                                        "summary"
                                                    )}
                                                />

                                                <h4>
                                                    <span className="mandatory">
                                                        *
                                                    </span>{" "}
                                                    A detailed description of
                                                    the opportunity
                                                </h4>
                                                <p>
                                                    This text will appear on an
                                                    opportunity page to give
                                                    prospective investors a more
                                                    in-depth understanding of
                                                    the offer
                                                </p>
                                                <TextInput
                                                    type="text"
                                                    multiline
                                                    onInput={this.updateText.bind(
                                                        this,
                                                        "description"
                                                    )}
                                                />

                                                <h4>
                                                    Add a video link to your
                                                    page
                                                </h4>
                                                <p>
                                                    Upload a video to YouTube
                                                    and copy and paste the link
                                                    here
                                                </p>
                                                {invalidYoutube && (
                                                    <p className="error">
                                                        Please check that your
                                                        link is valid, e.g.
                                                        http://www.youtube.com/watch?v=iwGFalTRHDA.
                                                    </p>
                                                )}
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateYoutube.bind(
                                                        this,
                                                        "youtubeUrl"
                                                    )}
                                                />
                                                <h4>
                                                    Add a website link to your
                                                    page
                                                </h4>
                                                <p>
                                                    You can add a link to the
                                                    opportunity's website. Just
                                                    copy and paste the address
                                                    below
                                                </p>
                                                {invalidUrl && (
                                                    <p className="error">
                                                        Please check that your
                                                        link is valid, e.g.
                                                        http://www.fiinect.com.
                                                    </p>
                                                )}
                                                <TextInput
                                                    type="text"
                                                    onInput={this.updateUrl.bind(
                                                        this,
                                                        "websiteUrl"
                                                    )}
                                                />
                                            </>
                                        )}
                                        {step === 4 && (
                                            <>
                                                {createdOpportunityId ? (
                                                    <Redirect
                                                        to={`${process.env.PUBLIC_URL}/opportunities/${createdOpportunityId}`}
                                                    />
                                                ) : (
                                                    <Loader />
                                                )}
                                            </>
                                        )}
                                    </article>
                                    <aside>
                                        <MatchBox
                                            stickyOffset={255}
                                            matches={draftMatches}
                                            sharedToMatches={sharedToMatches}
                                            // readyToShare={step === 4}
                                            readyToShare={false}
                                            onShare={this.shareToMatches.bind(
                                                this
                                            )}
                                        />
                                    </aside>

                                    {step < 4 && (
                                        <div className="actions">
                                            <Button
                                                onClick={this.previousStep.bind(
                                                    this
                                                )}
                                            >
                                                Back
                                            </Button>
                                            {step > 1 && (
                                                <Button
                                                    onClick={this.saveForLater.bind(
                                                        this
                                                    )}
                                                >
                                                    Save for later
                                                </Button>
                                            )}
                                            {step !== 3 && (
                                                <Button
                                                    onClick={this.nextStep.bind(
                                                        this
                                                    )}
                                                >
                                                    Save and continue
                                                </Button>
                                            )}
                                            {step === 3 && (
                                                <Button
                                                    onClick={this.nextStep.bind(
                                                        this
                                                    )}
                                                >
                                                    Preview
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentUser: state.user,
        auxillary: state.auxillary,
        draftMatches: state.opportunities.draftMatches,
        createdOpportunityId: state.opportunities.createdOpportunityId,
        documentUploading: state.opportunities.documentUploading,
        executiveBrochure: state.opportunities.executiveBrochure,
        investorDeck: state.opportunities.investorDeck,
        documents: state.opportunities.documents
    }),
    {
        getSectorsAction,
        getLocationsAction,
        getFinanceStagesAction,
        getFinanceTypesAction,
        getLevelOfInvestmentAction,
        createOpportunityAction,
        getMatchesForOpportunityAction,
        resetDraftMatchesAction,
        shareOpportunityAction,
        uploadDocumentAction,
        removeDocumentAction
    }
)(CreateOpportunity);

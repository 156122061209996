import axios from "axios";

import { API_BASE } from "../../api";
import * as actionTypes from "../actionTypes";
import { listMessagesAction } from "../messaging/messagingActions";
import {
    mapBusinessOpportunity,
    mapOpportunity
} from "../../data/opportunities";
import { findLevelOfInvestment } from "../../data/levels";

export const getLatestOpportunitiesAction = () => {
    return (dispatch, getState) => {
        const accessToken = localStorage.getItem("accessToken");
        const user = getState().auth.user;
        const levels = getState().auxillary.levelOfInvestment;
        return axios
            .get(`${API_BASE}/Opportunity/matches/${user.userId}`, {
                // .get(`${API_BASE}/Opportunity/latest`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const userLevels = [];
                user.levelOfInvestment.forEach((level) => {
                    const interestedLevel = findLevelOfInvestment(
                        level.id,
                        levels
                    );
                    userLevels.push(interestedLevel.ticketSize);
                });

                const opportunities = data.opportunities
                    .filter((opp) => opp.sectorMatchId !== null)
                    .filter((opp) => {
                        const level = findLevelOfInvestment(
                            opp.levelOfInvestmentId,
                            levels
                        );
                        if (
                            userLevels.indexOf(level.ticketSize) > -1 ||
                            !user.profileComplete
                        ) {
                            return true;
                        }

                        return false;
                    })
                    .sort(() => 0.5 - Math.random())
                    .splice(0, 20)
                    .filter((opportunity) => {
                        if (
                            opportunity.connections.find(
                                (c) => c.userId === user.id
                            ) ||
                            opportunity.brokerId === user.id
                        ) {
                            return false;
                        }
                        return true;
                    })
                    .map((opportunity) => mapOpportunity(opportunity));
                dispatch(getLatestOpportunitiesSuccessAction(opportunities));
            })
            .catch((error) => {
                dispatch(getLatestOpportunitiesErrorAction(error));
            });
    };
};

export const getLatestOpportunitiesSuccessAction = (opportunities) => ({
    type: actionTypes.GET_LATEST_OPPORTUNITIES_SUCCESS,
    opportunities
});

export const getLatestOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_LATEST_OPPORTUNITIES_ERROR,
    error
});

export const getAllOpportunitiesAction = () => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const opportunities = data.map((opportunity) =>
                    mapOpportunity(opportunity)
                );
                dispatch(getAllOpportunitiesSuccessAction(opportunities));
                dispatch(listMessagesAction());
            })
            .catch((error) => {
                dispatch(getAllOpportunitiesErrorAction(error));
            });
    };
};

export const getAllOpportunitiesSuccessAction = (opportunities) => ({
    type: actionTypes.GET_ALL_OPPORTUNITIES_SUCCESS,
    opportunities
});

export const getAllOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_ALL_OPPORTUNITIES_ERROR,
    error
});

export const getProfiledOpportunitiesAction = () => {
    const matches = {
        "Pre-funding": []
    };
    const matchList = [];

    return (dispatch, getState) => {
        const user = getState().auth.user;
        const auxillary = getState().auxillary;
        const levels = auxillary.levelOfInvestment;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity/matches/${user.userId}`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const userLevels = [];
                user.levelOfInvestment.forEach((level) => {
                    const interestedLevel = findLevelOfInvestment(
                        level.id,
                        levels
                    );
                    userLevels.push(interestedLevel.ticketSize);
                });

                data.opportunities
                    .filter((opp) => {
                        const level = findLevelOfInvestment(
                            opp.levelOfInvestmentId,
                            levels
                        );
                        if (
                            userLevels.indexOf(level.ticketSize) > -1 ||
                            !user.profileComplete
                        ) {
                            return true;
                        }
                        return false;
                    })
                    .sort(() => 0.5 - Math.random())
                    .forEach((opportunity) => {
                        if (
                            opportunity.connections.find(
                                (connection) =>
                                    connection.userId === user.userId
                            )
                        )
                            return;

                        const opportunityDetails = mapOpportunity(opportunity);
                        if (opportunityDetails.broker.id === user.userId)
                            return;

                        let matchStrength = 0;

                        const location = auxillary.locations.find(
                            (l) => l.id === opportunity.locationId
                        );
                        if (location) {
                            opportunityDetails.location = {
                                id: location.id,
                                name: location.value
                            };
                        }

                        const sector = auxillary.sectorInterests.find(
                            (s) => s.id === opportunity.sectorId
                        );
                        if (sector) {
                            opportunityDetails.sector = sector.value;
                            opportunityDetails.sectorHex = sector.hexCode;
                        }

                        Object.keys(auxillary.levelOfInvestment).forEach(
                            (currency) => {
                                const levelOfInvestment = auxillary.levelOfInvestment[
                                    currency
                                ].find(
                                    (loi) =>
                                        loi.id ===
                                        opportunity.levelOfInvestmentId
                                );
                                if (levelOfInvestment) {
                                    opportunityDetails.ticketSize =
                                        levelOfInvestment.value;
                                    opportunityDetails.levelOfInvestment = {
                                        id: levelOfInvestment.id,
                                        name: levelOfInvestment.value
                                    };
                                }
                            }
                        );

                        // Pre-funding folder is now being used for 'Pre-funding', but we
                        // will probably need a misc folder later

                        if (location) {
                            //matches["Pre-funding"].push(opportunityDetails);
                        }

                        if (opportunity.sectorMatchId) {
                            if (!matches[opportunityDetails.sector]) {
                                matches[opportunityDetails.sector] = [];
                            }
                            matches[opportunityDetails.sector].push(
                                opportunityDetails
                            );
                            matchStrength++;
                        }
                        if (
                            opportunity.levelInvestmentMatchId &&
                            !matches["Pre-funding"].find(
                                (o) => o.opportunityId === opportunity.id
                            )
                        ) {
                            //matches["Pre-funding"].push(opportunityDetails);
                            matchStrength++;
                        }
                        if (
                            opportunity.financeStageMatchIdd &&
                            !matches["Pre-funding"].find(
                                (o) => o.opportunityId === opportunity.id
                            )
                        ) {
                            //matches["Pre-funding"].push(opportunityDetails);
                            matchStrength++;
                        }
                        opportunityDetails.matchStrength = matchStrength;
                        matchList.push(opportunityDetails);
                    });

                data.businesses.forEach((business) => {
                    const businessDetails = mapBusinessOpportunity(business);
                    let matchStrength = 0;

                    business.businessLocations.forEach((location) => {
                        if (!matches[location.location.name]) {
                            matches[location.location.name] = [];
                        }
                        matches[location.location.name].push(businessDetails);
                    });

                    if (business.sectorMatchId) {
                        if (!matches[business.sector.name]) {
                            matches[business.sector.name] = [];
                        }
                        matches[business.sector.name].push(businessDetails);
                        matchStrength++;
                    }
                    if (business.levelInvestmentMatchId) {
                        if (!matches["Matching investment level"]) {
                            matches["Matching investment level"] = [];
                        }
                        matches["Matching investment level"].push(
                            businessDetails
                        );
                        matchStrength++;
                    }
                    if (business.financeStageMatchId) {
                        if (!matches["Matching finance stage"]) {
                            matches["Matching finance stage"] = [];
                        }
                        matches["Matching finance stage"].push(businessDetails);
                        matchStrength++;
                    }
                    businessDetails.matchStrength = matchStrength;
                    matchList.push(businessDetails);
                });

                matchList.sort((a, b) =>
                    a.datePosted > b.datePosted ? 1 : -1
                );
                matchList.sort((a, b) =>
                    a.matchStrength < b.matchStrength ? 1 : -1
                );

                dispatch(
                    getProfiledOpportunitiesSuccessAction(matches, matchList)
                );
            })
            .catch((error) => {
                dispatch(getProfiledOpportunitiesErrorAction(error));
            });
    };
};

export const getProfiledOpportunitiesSuccessAction = (opportunities, list) => ({
    type: actionTypes.GET_PROFILED_OPPORTUNITIES_SUCCESS,
    opportunities,
    list
});

export const getProfiledOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_PROFILED_OPPORTUNITIES_ERROR,
    error
});

export const getInterestedOpportunitiesAction = (forListing = false) => {
    const matches = {
        "Profiled opportunities you have selected": []
    };

    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity/status/Interested`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                data.forEach((opportunity) => {
                    const opportunityDetails = mapOpportunity(opportunity);
                    if (
                        !opportunity.connections.find((connection) => {
                            return (
                                connection.userId === user.userId &&
                                connection.status === "Interested"
                            );
                        })
                    )
                        return;

                    matches["Profiled opportunities you have selected"].push(
                        opportunityDetails
                    );
                });

                if (forListing)
                    dispatch(
                        getInterestedOpportunitiesForListingSuccessAction(
                            matches
                        )
                    );
                else dispatch(getInterestedOpportunitiesSuccessAction(matches));
            })
            .catch((error) => {
                if (forListing)
                    dispatch(
                        getInterestedOpportunitiesForListingErrorAction(error)
                    );
                else dispatch(getInterestedOpportunitiesErrorAction(error));
            });
    };
};

export const getInterestedOpportunitiesSuccessAction = (opportunities) => ({
    type: actionTypes.GET_INTERESTED_OPPORTUNITIES_SUCCESS,
    opportunities
});

export const getInterestedOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_INTERESTED_OPPORTUNITIES_ERROR,
    error
});

export const getInterestedOpportunitiesForListingSuccessAction = (
    opportunities
) => ({
    type: actionTypes.GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_SUCCESS,
    opportunities
});

export const getInterestedOpportunitiesForListingErrorAction = (error) => ({
    type: actionTypes.GET_INTERESTED_OPPORTUNITIES_FOR_LISTING_ERROR,
    error
});

export const getFollowedOpportunitiesAction = () => {
    const matches = {
        "Just watching": []
    };

    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity/status/Following`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                data.forEach((opportunity) => {
                    const opportunityDetails = mapOpportunity(opportunity);
                    if (opportunityDetails.broker.id === user.id) return;

                    if (
                        !opportunity.connections.find((connection) => {
                            return (
                                connection.userId === user.userId &&
                                connection.status === "Following"
                            );
                        })
                    )
                        return;

                    matches["Just watching"].push(opportunityDetails);
                });
                dispatch(getFollowedOpportunitiesSuccessAction(matches));
            })
            .catch((error) => {
                dispatch(getFollowedOpportunitiesErrorAction(error));
            });
    };
};

export const getFollowedOpportunitiesSuccessAction = (opportunities) => ({
    type: actionTypes.GET_FOLLOWED_OPPORTUNITIES_SUCCESS,
    opportunities
});

export const getFollowedOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_FOLLOWED_OPPORTUNITIES_ERROR,
    error
});

export const getNotInterestedOpportunitiesAction = () => {
    const matches = {
        "Not interested": []
    };

    return (dispatch, getState) => {
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity/status/Not interested`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                data.forEach((opportunity) => {
                    const opportunityDetails = mapOpportunity(opportunity);
                    if (opportunityDetails.broker.id === user.id) return;

                    if (
                        !opportunity.connections.find((connection) => {
                            return (
                                connection.userId === user.userId &&
                                connection.status === "Not interested"
                            );
                        })
                    )
                        return;

                    matches["Not interested"].push(opportunityDetails);
                });
                dispatch(getNotInterestedOpportunitiesSuccessAction(matches));
            })
            .catch((error) => {
                dispatch(getNotInterestedOpportunitiesErrorAction(error));
            });
    };
};

export const getNotInterestedOpportunitiesSuccessAction = (opportunities) => ({
    type: actionTypes.GET_NOT_INTERESTED_OPPORTUNITIES_SUCCESS,
    opportunities
});

export const getNotInterestedOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_NOT_INTERESTED_OPPORTUNITIES_ERROR,
    error
});

export const getOpportunitiesComingSoonAction = () => {
    return (dispatch) => {
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity/comingsoon`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const comingSoon = data.map((opportunity) =>
                    mapOpportunity(opportunity)
                );
                dispatch(getOpportunitiesComingSoonSuccessAction(comingSoon));
            })
            .catch((error) => {
                dispatch(getOpportunitiesComingSoonErrorAction(error));
            });
    };
};

export const getOpportunitiesComingSoonSuccessAction = (opportunities) => ({
    type: actionTypes.GET_OPPORTUNITIES_COMING_SOON_SUCCESS,
    opportunities
});

export const getOpportunitiesComingSoonErrorAction = (error) => ({
    type: actionTypes.GET_OPPORTUNITIES_COMING_SOON_ERROR,
    error
});

export const getMyOpportunitiesAction = () => {
    return (dispatch) => {
        const matches = {
            "Opportunities I have created": []
        };
        const accessToken = localStorage.getItem("accessToken");
        return axios
            .get(`${API_BASE}/Opportunity/my`, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                const myOpportunities = data.map((opportunity) =>
                    mapOpportunity(opportunity)
                );
                matches["Opportunities I have created"] = myOpportunities;
                dispatch(
                    getMyOpportunitiesSuccessAction(myOpportunities, matches)
                );
            })
            .catch((error) => {
                dispatch(getMyOpportunitiesErrorAction(error));
            });
    };
};

export const getMyOpportunitiesSuccessAction = (opportunities, matches) => ({
    type: actionTypes.GET_MY_OPPORTUNITIES_SUCCESS,
    opportunities,
    matches
});

export const getMyOpportunitiesErrorAction = (error) => ({
    type: actionTypes.GET_MY_OPPORTUNITIES_ERROR,
    error
});

export const setInboxViewAction = (view) => ({
    type: actionTypes.SET_INBOX_VIEW,
    view
});

export const getOpportunityDetailsAction = (opportunityId) => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        return axios
            .get(`${API_BASE}/Opportunity/${opportunityId}`)
            .then(({ data }) => {
                const opportunity = mapOpportunity(data, user);
                dispatch(getOpportunityDetailsSuccessAction(opportunity));
            })
            .catch((error) => {
                dispatch(getOpportunityDetailsErrorAction(error));
            });
    };
};

export const getOpportunityDetailsSuccessAction = (opportunity) => ({
    type: actionTypes.GET_OPPORTUNITY_DETAILS_SUCCESS,
    opportunity
});

export const getOpportunityDetailsErrorAction = (error) => ({
    type: actionTypes.GET_OPPORTUNITY_DETAILS_ERROR,
    error
});

export const setInterestStatusAction = (
    opportunityId,
    status,
    existingId = null
) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_INTEREST_STATUS
        });

        const latestOpportunities = getState().opportunities.latest;
        const inboxOpportunities = getState().opportunities.inbox;

        const latestItemIndex = latestOpportunities.findIndex(
            (item) => item.opportunityId === opportunityId
        );
        if (latestItemIndex > -1)
            latestOpportunities.splice(latestItemIndex, 1);

        Object.keys(inboxOpportunities).forEach((key) => {
            const itemIndex = inboxOpportunities[key].findIndex(
                (item) => item.opportunityId === opportunityId
            );
            if (itemIndex > -1) inboxOpportunities[key].splice(itemIndex, 1);
        });

        dispatch(updateLatestOpportunitiesAction(latestOpportunities));
        dispatch(updateInboxAction(inboxOpportunities));

        const user = getState().auth.user;
        const body = {
            UserId: user.userId,
            OpportunityId: opportunityId,
            Status: status
        };
        const opportunityDetails = getState().opportunities.opportunityDetails;
        const accessToken = localStorage.getItem("accessToken");
        let url = `${API_BASE}/OpportunityInterested`;
        if (existingId) {
            url += "/edit/id";
            body.Id = existingId;
        }

        return axios
            .post(url, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(setInterestStatusSuccessAction({ status }));
            })
            .catch((error) => {
                dispatch(setInterestStatusErrorAction(error));
            });
    };
};

export const setInterestStatusSuccessAction = (status) => ({
    type: actionTypes.SET_INTEREST_STATUS_SUCCESS,
    status
});

export const setInterestStatusErrorAction = () => ({
    type: actionTypes.SET_INTEREST_STATUS_ERROR
});

export const updateInboxAction = (opportunities) => ({
    type: actionTypes.UPDATE_INBOX,
    opportunities
});

export const updateLatestOpportunitiesAction = (opportunities) => ({
    type: actionTypes.UPDATE_LATEST_OPPORTUNITIES,
    opportunities
});

export const createOpportunityAction = (opportunity, userId = null) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.CREATE_OPPORTUNITY });
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        const date = new Date();
        // TODO LOW make this a bit cleaner
        if (opportunity.timings !== "Now") date.setFullYear(3000);

        let location = null;
        if (opportunity.locations) {
            location = getState().auxillary.locations.find(
                (location) => location.code === opportunity.locations[0]
            );
        }

        const executiveBrochure = getState().opportunities.executiveBrochure;
        const investorDeck = getState().opportunities.investorDeck;
        const documents = getState().opportunities.documents;

        const body = {
            NumberOfEmployees: opportunity.numberOfEmployees,
            SectorId:
                opportunity.sectors && opportunity.sectors.length > 0
                    ? opportunity.sectors[0].id
                    : null,
            Headline: opportunity.headline,
            LocationId: location ? location.id : null,
            Country:
                opportunity.country && opportunity.country.length
                    ? opportunity.country[0].id
                    : null,
            BrokerId: userId ? userId : user.userId,
            Timings: opportunity.timings || "",
            FinanceTypeId:
                opportunity.financeTypes && opportunity.financeTypes.length
                    ? opportunity.financeTypes[0].id
                    : null,
            FinanceStageId:
                opportunity.financeStages && opportunity.financeStages.length
                    ? opportunity.financeStages[0].id
                    : null,
            LevelOfInvestmentId: Array.isArray(opportunity.levelOfInvestment)
                ? opportunity.levelOfInvestment[0].id
                : opportunity.levelOfInvestment,
            WebsiteURL: opportunity.websiteUrl,
            Summary: opportunity.summary,
            OppertunityDetails: opportunity.description,
            YoutubeURL: opportunity.youtubeUrl,
            StartDate: date.toUTCString(),
            Documents: [],
            ExecBrochureID: executiveBrochure.length
                ? executiveBrochure[0].id
                : null,
            InvestDeckID: investorDeck.length ? investorDeck[0].id : null,
            IsPreRevenue:
                opportunity.turnover &&
                opportunity.turnover.length > 0 &&
                opportunity.turnover[0].value === "Pre Revenue",
            MakingProfit: opportunity.MakingProfit,
            Turnover:
                opportunity.turnover && opportunity.turnover.length > 0
                    ? opportunity.turnover[0].value
                    : null,
            Timescale:
                opportunity.timescales && opportunity.timescales.length > 0
                    ? opportunity.timescales[0].value
                    : null,
            RoundOfFinance:
                opportunity.roundOfFinance && opportunity.roundOfFinance.length
                    ? opportunity.roundOfFinance[0].value
                    : null,
            IsActive: false
        };

        return axios
            .post(`${API_BASE}/Opportunity`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(createOpportunitySuccessAction(data));
                dispatch(getMyOpportunitiesAction());

                documents.forEach((document) => {
                    dispatch(assignOpportunityToDocumentAction(document, data));
                });

                opportunity.sectors.forEach((sector) => {
                    dispatch(setOpportunitySectorAction(data, sector.id));
                });
            })
            .catch((error) => {
                dispatch(createOpportunityErrorAction(error));
            });
    };
};

export const createOpportunitySuccessAction = (opportunityId) => ({
    type: actionTypes.CREATE_OPPORTUNITY_SUCCESS,
    opportunityId
});

export const createOpportunityErrorAction = () => ({
    type: actionTypes.CREATE_OPPORTUNITY_ERROR
});

export const editOpportunityAction = (opportunity) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.EDIT_OPPORTUNITY });
        const user = getState().auth.user;
        const accessToken = localStorage.getItem("accessToken");
        const date = new Date();
        // TODO LOW make this a bit cleaner
        if (opportunity.timings !== "Now") date.setFullYear(3000);

        const location = getState().auxillary.locations.find(
            (location) => location.code === opportunity.locations[0]
        );

        const executiveBrochure = opportunity.executiveBrochure
            ? opportunity.executiveBrochure
            : getState().opportunities.executiveBrochure;
        const investorDeck = opportunity.investorDeck
            ? opportunity.investorDeck
            : getState().opportunities.investorDeck;
        const documents = getState().opportunities.documents;

        const body = {
            id: opportunity.id,
            IsActive: opportunity.active,
            SectorId: opportunity.sectors[0].id,
            Headline: opportunity.headline,
            LocationId: location ? location.id : "",
            BrokerId: user.userId,
            FinanceTypeId: opportunity.financeTypes[0].id,
            FinanceStageId: opportunity.financeStages[0].id,
            LevelOfInvestmentId: opportunity.levelOfInvestment,
            WebsiteURL: opportunity.websiteUrl,
            Summary: opportunity.summary,
            OppertunityDetails: opportunity.description,
            YoutubeURL: opportunity.youtubeUrl,
            StartDate: date.toUTCString(),
            NumberOfEmployees: opportunity.numberOfEmployees,
            Timings: opportunity.timings,
            IsPreRevenue: opportunity.turnover === "Pre Revenue",
            MakingProfit: opportunity.MakingProfit,
            Turnover:
                opportunity.turnover && opportunity.turnover.length > 0
                    ? opportunity.turnover[0].value
                    : null,
            Timescale:
                opportunity.timescales && opportunity.timescales.length > 0
                    ? opportunity.timescales[0].value
                    : null,
            RoundOfFinance:
                opportunity.roundOfFinance && opportunity.roundOfFinance.length
                    ? opportunity.roundOfFinance[0].value
                    : null,
            ExecBrochureID: executiveBrochure.length
                ? executiveBrochure[0].id
                : null,
            InvestDeckID: investorDeck.length ? investorDeck[0].id : null
        };

        return axios
            .post(`${API_BASE}/Opportunity/edit`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                documents.forEach((document) => {
                    dispatch(
                        assignOpportunityToDocumentAction(
                            document,
                            opportunity.id
                        )
                    );
                });

                dispatch(editOpportunitySuccessAction());
                dispatch(getOpportunityDetailsAction(opportunity.id));
                dispatch(getMyOpportunitiesAction());
            })
            .catch((error) => {
                dispatch(editOpportunityErrorAction(error));
            });
    };
};

export const editOpportunitySuccessAction = (opportunity) => ({
    type: actionTypes.EDIT_OPPORTUNITY_SUCCESS
});

export const editOpportunityErrorAction = (error) => ({
    type: actionTypes.EDIT_OPPORTUNITY_ERROR,
    error
});

export const toggleOpportunityActiveAction = (id) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.TOGGLE_OPPORTUNITY_ACTIVE });
        const accessToken = localStorage.getItem("accessToken");
        const opportunity = getState().opportunities.opportunityDetails;

        return axios
            .post(
                `${API_BASE}/Opportunity/toggle/${id}`,
                {},
                {
                    headers: {
                        "content-type": "application/json",
                        accept: "*/*",
                        authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then(({ data }) => {
                dispatch(toggleOpportunityActiveSuccessAction());
                dispatch(getOpportunityDetailsAction(id));
                dispatch(getMyOpportunitiesAction());
                dispatch(
                    getMatchesForOpportunityAction({
                        locations: [],
                        sectors: [{ id: opportunity.sectorId }],
                        financeStages: opportunity.stage
                            ? [{ id: opportunity.stage.id }]
                            : [],
                        levelOfInvestment: opportunity.levelOfInvestmentId
                    })
                );
            })
            .catch((error) => {
                dispatch(toggleOpportunityActiveErrorAction(error));
            });
    };
};

export const toggleOpportunityActiveSuccessAction = () => ({
    type: actionTypes.TOGGLE_OPPORTUNITY_ACTIVE_SUCCESS
});

export const toggleOpportunityActiveErrorAction = (error) => ({
    type: actionTypes.TOGGLE_OPPORTUNITY_ACTIVE_ERROR,
    error
});

export const getMatchesForOpportunityAction = (filters) => {
    return (dispatch, getState) => {
        const accessToken = localStorage.getItem("accessToken");
        const location = getState().auxillary.locations.find(
            (location) => location.code === filters.locations[0]
        );
        const body = {};
        if (location) body.locationId = location.id;
        if (filters.sectors.length > 0) body.sectorId = filters.sectors[0].id;
        if (filters.financeStages && filters.financeStages.length > 0)
            body.financeStageId = filters.financeStages[0].id;
        if (filters.levelOfInvestment)
            body.LevelOfInvestmentId = filters.levelOfInvestment;

        return axios
            .post(`${API_BASE}/Opportunity/matches`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(
                    getMatchesForOpportunitySuccessAction(
                        data.filter((m) => m.name !== "Fiinect Update")
                    )
                );
            })
            .catch((error) => {
                dispatch(getMatchesForOpportunityErrorAction(error));
            });
    };
};

export const getMatchesForOpportunitySuccessAction = (matches) => ({
    type: actionTypes.GET_MATCHES_FOR_OPPORTUNITY_SUCCESS,
    matches
});

export const getMatchesForOpportunityErrorAction = (error) => ({
    type: actionTypes.GET_MATCHES_FOR_OPPORTUNITY_ERROR,
    error
});

export const resetDraftMatchesAction = () => ({
    type: actionTypes.RESET_DRAFT_MATCHES
});

export const shareOpportunityAction = (opportunity, userIds) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SHARE_OPPORTUNITY });
        const accessToken = localStorage.getItem("accessToken");

        return axios
            .post(`${API_BASE}/Share/${opportunity.opportunityId}`, userIds, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(shareOpportunitySuccessAction());
                dispatch(
                    getOpportunityDetailsAction(opportunity.opportunityId)
                );
                dispatch(
                    getMatchesForOpportunityAction({
                        locations: [],
                        sectors: [{ id: opportunity.sectorId }],
                        financeStages: [{ id: opportunity.stage.id }],
                        levelOfInvestment: opportunity.levelOfInvestmentId
                    })
                );
            })
            .catch((error) => {
                dispatch(shareOpportunityErrorAction(error));
            });
    };
};

export const shareOpportunitySuccessAction = () => ({
    type: actionTypes.SHARE_OPPORTUNITY_SUCCESS
});

export const shareOpportunityErrorAction = (error) => ({
    type: actionTypes.SHARE_OPPORTUNITY_ERROR,
    error
});

export const uploadDocumentAction = (file, key = "documents") => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.UPLOAD_DOCUMENT });
        const accessToken = localStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("file", file);
        return axios
            .post(`${API_BASE}/File`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(uploadDocumentSuccessAction(data, key));
            })
            .catch(({ error }) => {
                dispatch(uploadDocumentErrorAction(error));
            });
    };
};

export const uploadDocumentSuccessAction = (file, key) => ({
    type: actionTypes.UPLOAD_DOCUMENT_SUCCESS,
    file,
    key
});

export const opportunityDocumentAction = (file, key = "documents") => ({
    type: actionTypes.OPPORTUNITY_DOCUMENT,
    file,
    key
});

export const uploadDocumentErrorAction = (error) => ({
    type: actionTypes.UPLOAD_DOCUMENT_ERROR,
    error
});

export const removeDocumentAction = (id, key = "documents") => {
    return (dispatch, getState) => {
        // TODO low remove the file from the server itself
        const documents = getState().opportunities[key];

        const indexToDelete = documents.findIndex(
            (document) => document.id === id
        );

        if (indexToDelete > -1) {
            documents.splice(indexToDelete, 1);
            dispatch({
                type: actionTypes.REMOVE_DOCUMENT,
                documents
            });
        }
    };
};

export const assignOpportunityToDocumentAction = (document, opportunityId) => {
    return (dispatch, getState) => {
        const accessToken = localStorage.getItem("accessToken");
        const body = {
            ...document,
            opportunityId
        };
        return axios
            .post(`${API_BASE}/File/edit`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(assignOpportunityToDocumentSuccessAction());
                dispatch(getOpportunityDetailsAction(opportunityId));
            })
            .catch(({ error }) => {
                dispatch(assignOpportunityToDocumentErrorAction(error));
            });
    };
};

export const assignOpportunityToDocumentSuccessAction = () => ({
    type: actionTypes.ASSIGN_OPPORTUNITY_TO_DOCUMENT_SUCCESS
});

export const assignOpportunityToDocumentErrorAction = (error) => ({
    type: actionTypes.ASSIGN_OPPORTUNITY_TO_DOCUMENT_ERROR,
    error
});

export const postOpportunityUpdateAction = (update) => {
    return (dispatch, getState) => {
        const opportunity = getState().opportunities.opportunityDetails;
        const body = {
            OpportunityId: update.opportunityId,
            Text: update.content,
            ParentUpdateId: update.parentId
        };

        const accessToken = localStorage.getItem("accessToken");
        return axios
            .post(`${API_BASE}/OpportunityUpdate`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(postOpportunityUpdateSuccessAction());
                dispatch(getOpportunityDetailsAction(update.opportunityId));
                dispatch(
                    getMatchesForOpportunityAction({
                        locations: [],
                        sectors: [{ id: opportunity.sectorId }],
                        financeStages: [{ id: opportunity.stage.id }],
                        levelOfInvestment: opportunity.levelOfInvestmentId
                    })
                );
            })
            .catch((error) => {
                dispatch(postOpportunityUpdateErrorAction(error));
            });
    };
};

export const postOpportunityUpdateSuccessAction = () => ({
    type: actionTypes.POST_OPPORTUNITY_UPDATE_SUCCESS
});

export const postOpportunityUpdateErrorAction = (error) => ({
    type: actionTypes.POST_OPPORTUNITY_UPDATE_ERROR,
    error
});

export const setOpportunitySectorAction = (opportunityId, sectorId) => {
    return (dispatch) => {
        const body = {
            OpportunityId: opportunityId,
            SectorId: sectorId
        };

        const accessToken = localStorage.getItem("accessToken");
        return axios
            .post(`${API_BASE}/OpportunitySector`, body, {
                headers: {
                    "content-type": "application/json",
                    accept: "*/*",
                    authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {
                dispatch(setOpportunitySectorSuccessAction());
            })
            .catch((error) => {
                dispatch(setOpportunitySectorErrorAction(error));
            });
    };
};

export const setOpportunitySectorSuccessAction = () => ({
    type: actionTypes.SET_OPPORTUNITY_SECTOR_SUCCESS
});

export const setOpportunitySectorErrorAction = (error) => ({
    type: actionTypes.SET_OPPORTUNITY_SECTOR_ERROR,
    error
});

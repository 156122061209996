import React from "react";

import "./Loader.scss";

const Loader = ({ small }) => (
    <div className={small ? "loader-container small" : "loader-container"}>
        <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Loader;

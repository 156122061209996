import React, { Component } from "react";

import "./NewMessageButton.scss";

class NewMessageButton extends Component {
    composeMessage() {
        const {
            openMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageOpportunityAction,
            opportunity
        } = this.props;

        openMessageCenterAction();
        setMessageCenterViewAction("recipient");
        setCurrentMessageOpportunityAction(opportunity);
    }

    render() {
        return (
            <button
                onClick={this.composeMessage.bind(this)}
                className="new-message-button"
            >
                <img src={`${process.env.PUBLIC_URL}/assets/pencil.svg`} />
                <span>New message</span>
            </button>
        );
    }
}

export default NewMessageButton;

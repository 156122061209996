import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import {
    loginAction,
    verifyRecaptchaTokenAction
} from "../../actions/auth/authActions";
import Loader from "../../components/loader/Loader";
import TextInput from "../../components/text-input/TextInput";

import "./Login.scss";
class Login extends Component {
    state = {
        username: "",
        password: "",
        token: null,
        verified: null
    };

    setUsername(value) {
        this.setState({
            username: value
        });
    }

    setPassword(value) {
        this.setState({
            password: value
        });
    }

    login(e) {
        e.preventDefault();

        const { loginAction } = this.props;
        const { username, password } = this.state;
        if (this.props.auth.verified) loginAction(username, password);
    }

    handleVerify(token) {
        const { verifyRecaptchaTokenAction } = this.props;
        verifyRecaptchaTokenAction(token);
    }

    render() {
        const { auth } = this.props;

        if (!auth.authCheck) {
            return <Loader />;
        }

        return (
            <div className="wrapper">
                <h1>Log in</h1>
                <div className="form-container">
                    <form
                        onSubmit={this.login.bind(this)}
                        className="login-form"
                    >
                        {auth.loginError && (
                            <p className="error">
                                Sorry, that doesn't seem to be right. Please try
                                again.
                            </p>
                        )}

                        <label>Email address</label>
                        <TextInput
                            type="text"
                            name="email"
                            onInput={this.setUsername.bind(this)}
                        />

                        <label>Password</label>
                        <TextInput
                            type="password"
                            name="password"
                            onInput={this.setPassword.bind(this)}
                        />

                        <button
                            type="submit"
                            className="button primary g-recaptcha"
                        >
                            Log in
                        </button>
                    </form>
                    {auth.verified === null && (
                        <GoogleReCaptcha
                            onVerify={this.handleVerify.bind(this)}
                        />
                    )}
                    <div className="actions">
                        {/* <Link to={`${process.env.PUBLIC_URL}/register`}>
                            Register with Fiinect
                        </Link> */}

                        <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>
                            Forgotten your password?
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth
    }),
    {
        loginAction,
        verifyRecaptchaTokenAction
    }
)(Login);

import React, { Component } from "react";

import ProfileIcon from "../profile-icon/ProfileIcon";

import "./MultiProfileIcon.scss";

class MultiProfileIcon extends Component {
    render() {
        const { people } = this.props;

        return (
            <div className="multi-icon">
                <ProfileIcon person={people[0]} />
                <ProfileIcon person={people[1]} />
            </div>
        );
    }
}

export default MultiProfileIcon;

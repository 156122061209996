import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
    getSectorsAction,
    getLocationsAction,
    getFinanceStagesAction,
    getLevelOfInvestmentAction
} from "../../actions/auxillary/auxillaryActions";
import {
    getMyBusinessDetailsAction,
    updateBusinessAction
} from "../../actions/business/businessActions";
import PlainCard from "../../components/plain-card/PlainCard";
import Button from "../../components/button/Button";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import Modal from "../../components/modal/Modal";
import OptionSelector from "../../components/option-selector/OptionSelector";
import RangeGroup from "../../components/range-group/RangeGroup";
import RegionSelector from "../../components/region-selector/RegionSelector";
import TextInput from "../../components/text-input/TextInput";
import { employeeRanges, timescaleOptions } from "../../data/data";

import "./CreateBusiness.scss";

class CreateBusiness extends Component {
    state = {
        formData: {
            BusinessID: null,
            BusinessName: "",
            IsPreRevenue: null,
            MakingProfit: null,
            NumberOfEmployees: null,
            Headline: "",
            Summary: "",
            BusinessDetails: "",
            YoutubeURL: "",
            WebsiteURL: "",
            CurrencyPreference: [{ id: "GBP", value: "GBP" }]
        },
        defaultData: {
            BusinessName: "",
            IsPreRevenue: null,
            MakingProfit: null,
            NumberOfEmployees: null,
            Headline: "",
            Summary: "",
            BusinessDetails: "",
            YoutubeURL: "",
            WebsiteURL: ""
        },
        sectors: [],
        levelOfInvestment: [],
        financeStages: [],
        timescales: [],
        locations: [],
        timings: null,
        saved: false,
        activateModal: false
    };

    componentDidMount() {
        const {
            getSectorsAction,
            getLocationsAction,
            getLevelOfInvestmentAction,
            getFinanceStagesAction,
            getMyBusinessDetailsAction
        } = this.props;

        getMyBusinessDetailsAction();
    }

    componentDidUpdate() {
        const { businessDetails } = this.props;

        const { formData, defaultData } = this.state;

        if (businessDetails.name !== defaultData.BusinessName) {
            const preselectedTimescale = timescaleOptions.find(
                (t) => businessDetails.timescale === t.value
            );
            this.setState({
                formData: {
                    ...formData,
                    BusinessID: businessDetails.id,
                    BusinessName: businessDetails.name,
                    Locations: businessDetails.businessLocations,
                    IsPreRevenue: businessDetails.isPreRevenue,
                    MakingProfit: businessDetails.makingProfit,
                    NumberOfEmployees: businessDetails.numberOfEmployees,
                    Headline: businessDetails.headline,
                    Summary: businessDetails.summary,
                    BusinessDetails: businessDetails.businessDetails,
                    YoutubeURL: businessDetails.youtubeURL,
                    WebsiteURL: businessDetails.websiteURL,
                    CurrencyPreference: [
                        {
                            id: "GBP",
                            value: "GBP"
                        }
                    ]
                },
                defaultData: {
                    ...defaultData,
                    BusinessName: businessDetails.name,
                    NumberOfEmployees: businessDetails.numberOfEmployees,
                    Locations: businessDetails.businessLocations,
                    IsPreRevenue: businessDetails.isPreRevenue,
                    MakingProfit: businessDetails.makingProfit,
                    Headline: businessDetails.headline,
                    Summary: businessDetails.summary,
                    BusinessDetails: businessDetails.businessDetails,
                    YoutubeURL: businessDetails.youtubeURL,
                    WebsiteURL: businessDetails.websiteURL
                },
                sectors: [{ id: businessDetails.sectorId }],
                financeStages: [{ id: businessDetails.financeStageId }],
                levelOfInvestment: [
                    { id: businessDetails.levelOfInvestmentId }
                ],
                timescales: [
                    {
                        id: preselectedTimescale
                            ? preselectedTimescale.id
                            : null,
                        value: businessDetails.timescale
                    }
                ],
                timings: businessDetails.timings
            });
        }
    }

    update(key, value) {
        const { formData } = this.state;

        if (typeof value === "boolean") {
            this.setState({
                formData: {
                    ...formData,
                    [key]: value
                }
            });
        } else {
            const arr1 = this.state[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));

            this.setState({
                [key]: additions
            });
        }
    }

    updateForm(key, value) {
        const { defaultData, formData } = this.state;

        let newValue = value;
        if (!newValue) newValue = defaultData[key];

        this.setState({
            formData: {
                ...formData,
                [key]: newValue
            }
        });
    }

    updateCurrency(key, value) {
        const { formData } = this.state;

        const arr1 = formData[key];
        const arr2 = value;

        let additions = arr2.filter((x) => !arr1.includes(x));

        this.setState({
            formData: {
                ...formData,
                [key]: additions
            }
        });
    }

    updateRange(lookup, key, value) {
        const { formData } = this.state;

        this.setState({
            formData: {
                ...formData,
                [key]: lookup[value]
            }
        });
    }

    setTimings(timings) {
        this.setState({
            timings
        });
    }

    save(status) {
        const {
            formData,
            defaultData,
            sectors,
            financeStages,
            levelOfInvestment,
            timescales,
            timings
        } = this.state;

        const { updateBusinessAction } = this.props;

        const locArr1 = defaultData.Locations.map(
            (location) => location.location.country
        );
        const locArr2 = formData.Locations;
        let locationAdditions = locArr2.filter((x) => !locArr1.includes(x));
        let locationSubtractions = locArr1.filter((x) => !locArr2.includes(x));

        // TODO MED Validation
        const businessDetails = {
            BusinessID: formData.BusinessID,
            BusinessName: formData.BusinessName,
            IsPreRevenue: formData.IsPreRevenue,
            MakingProfit: formData.MakingProfit,
            NumberOfEmployees: formData.NumberOfEmployees,
            Headline: formData.Headline,
            Summary: formData.Summary,
            BusinessDetails: formData.BusinessDetails,
            YoutubeURL: formData.YoutubeURL,
            WebsiteURL: formData.WebsiteURL,
            Timescales: timescales,
            status,
            sectors,
            financeStages,
            levelOfInvestment,
            timings,
            locationAdditions,
            locationSubtractions
        };

        updateBusinessAction(businessDetails);
        this.setState({
            saved: true
        });
    }

    toggleActivateModal() {
        this.setState({
            activateModal: !this.state.activateModal
        });
    }

    activate() {
        this.toggleActivateModal();
        this.save("live");
    }

    render() {
        const { currentUser, businessDetails, auxillary } = this.props;

        const {
            sectors,
            levelOfInvestment,
            financeStages,
            timescales,
            timings,
            formData,
            defaultData,
            saved,
            activateModal
        } = this.state;

        if (currentUser.role !== "Business")
            return <Redirect to={`${process.env.PUBLIC_URL}/`} />;

        if (saved)
            return <Redirect to={`${process.env.PUBLIC_URL}/my-business`} />;

        return (
            <div className="details-container">
                <Modal fullScreen visible={activateModal}>
                    <div className="activate-modal">
                        <p>
                            <strong>
                                Are you sure you want to upgrade this business
                                page to an ACTIVE OPPORTUNITY?
                            </strong>
                        </p>
                        <Button onClick={this.toggleActivateModal.bind(this)}>
                            No
                        </Button>
                        <Button onClick={this.activate.bind(this)} isPrimary>
                            Yes
                        </Button>
                    </div>
                </Modal>

                <HighlightedContent
                    headline={
                        currentUser.profileComplete
                            ? "Upgrade to find finance"
                            : "Create a business"
                    }
                    small
                ></HighlightedContent>
                <MainContent>
                    <div className="details">
                        <div className="timings">
                            <p>I am...</p>
                            <PlainCard
                                onClick={this.setTimings.bind(this, "Soon")}
                                className={timings === "Soon" ? "selected" : ""}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/soon.png`}
                                    alt="Looking for finance soon"
                                />
                                Looking for finance <strong>soon</strong>
                            </PlainCard>
                            <PlainCard
                                onClick={this.setTimings.bind(this, "Now")}
                                className={timings === "Now" ? "selected" : ""}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/now.png`}
                                    alt="Looking for finance now"
                                />
                                Looking for finance <strong>now</strong>
                            </PlainCard>
                        </div>
                        <fieldset>
                            <div>
                                <label htmlFor="businessName">
                                    Name of your business
                                </label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "BusinessName"
                                    )}
                                    placeholder={defaultData.BusinessName}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <h4>Currency preference</h4>

                        <OptionSelector
                            selectedValues={formData.CurrencyPreference}
                            options={Object.keys(
                                auxillary.levelOfInvestment
                            ).map((key) => ({ id: key, value: key }))}
                            action={this.updateCurrency.bind(
                                this,
                                "CurrencyPreference"
                            )}
                            noIcon
                            vertical
                            preventDeselect
                        />

                        <h4>How much will you be raising?</h4>

                        <OptionSelector
                            selectedValues={levelOfInvestment}
                            options={
                                auxillary.levelOfInvestment[
                                    formData.CurrencyPreference[0].id
                                ]
                            }
                            action={this.update.bind(this, "levelOfInvestment")}
                        />

                        <h4>What stage of finance?</h4>

                        <OptionSelector
                            selectedValues={financeStages}
                            options={auxillary.financeStages}
                            action={this.update.bind(this, "financeStages")}
                        />

                        <h4>Are you pre-revenue?</h4>

                        <OptionSelector
                            isBinary
                            isBinarySelected={formData.IsPreRevenue}
                            binaryTrueText=""
                            binaryFalseText=""
                            action={this.update.bind(this, "IsPreRevenue")}
                        />

                        <h4>Are you making a profit?</h4>

                        <OptionSelector
                            isBinary
                            isBinarySelected={formData.MakingProfit}
                            binaryTrueText=""
                            binaryFalseText=""
                            action={this.update.bind(this, "MakingProfit")}
                        />

                        <h4>Number of employees</h4>
                        <RangeGroup
                            idKey="employees"
                            smallIcon={`${process.env.PUBLIC_URL}/assets/pennies.png`}
                            largeIcon={`${process.env.PUBLIC_URL}/assets/notes.png`}
                            initialValue={employeeRanges.indexOf(
                                defaultData.NumberOfEmployees
                            )}
                            onChange={this.updateRange.bind(
                                this,
                                employeeRanges,
                                "NumberOfEmployees"
                            )}
                            lookup={employeeRanges}
                            notches
                        />

                        <h4>What sector are you in?</h4>

                        <OptionSelector
                            selectedValues={sectors}
                            options={auxillary.sectorInterests}
                            sectorVariant
                            imageFormat="svg"
                            action={this.update.bind(this, "sectors")}
                        />

                        <h4>In what regions does your business trade?</h4>
                        <RegionSelector
                            allowMultiple
                            initialRegions={defaultData.Locations}
                            onRegionToggle={this.updateForm.bind(
                                this,
                                "Locations"
                            )}
                        />

                        <h4>When do you plan to raise?</h4>

                        <OptionSelector
                            selectedValues={timescales}
                            options={timescaleOptions}
                            noIcon
                            action={this.update.bind(this, "timescales")}
                        />
                        <fieldset>
                            <div>
                                <label htmlFor="headline">
                                    Title of your business
                                </label>
                                <p>
                                    Top-line description of your business in one
                                    sentence. Try to include all the important
                                    keywords here
                                </p>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "Headline"
                                    )}
                                    placeholder={defaultData.Headline}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label htmlFor="summary">
                                    A brief summary of your business
                                </label>
                                <p>
                                    This paragraph will appear on your
                                    business's info card to help prospective
                                    buyers an at-a-glance understanding of your
                                    offer
                                </p>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "Summary"
                                    )}
                                    multiline
                                    placeholder={defaultData.Summary}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label htmlFor="BusinessDetails">
                                    A detailed description of your business
                                </label>
                                <p>
                                    This paragraph will appear on your
                                    business's page to give prospective
                                    investors a more in-depth understanding of
                                    your offer
                                </p>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "BusinessDetails"
                                    )}
                                    multiline
                                    placeholder={defaultData.BusinessDetails}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label htmlFor="youtubeURL">
                                    Add a video link
                                </label>
                                <p>
                                    Upload a video to YouTube and copy and paste
                                    the link here
                                </p>
                                {/* TODO MED Convert to ID */}
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "YoutubeURL"
                                    )}
                                    placeholder={defaultData.YoutubeURL}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label htmlFor="websiteURL">
                                    Add a website link
                                </label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "WebsiteURL"
                                    )}
                                    placeholder={defaultData.WebsiteURL}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        {/* TODO MED Document uploads */}

                        <div className="actions">
                            {!currentUser.profileComplete && (
                                <Button
                                    isPrimary
                                    onClick={this.save.bind(this, "draft")}
                                >
                                    Save and preview page
                                </Button>
                            )}
                            {currentUser.profileComplete &&
                                businessDetails.status !== "live" && (
                                    <Button
                                        isPrimary
                                        onClick={this.toggleActivateModal.bind(
                                            this
                                        )}
                                    >
                                        Upgrade page to find finance
                                    </Button>
                                )}
                            {currentUser.profileComplete &&
                                businessDetails.status === "live" && (
                                    <Button
                                        isPrimary
                                        onClick={this.save.bind(this, "live")}
                                    >
                                        Save and preview page
                                    </Button>
                                )}
                        </div>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        businessDetails: state.business.myBusiness,
        currentUser: state.auth.user,
        auxillary: state.auxillary
    }),
    {
        getSectorsAction,
        getLocationsAction,
        getFinanceStagesAction,
        getLevelOfInvestmentAction,
        getMyBusinessDetailsAction,
        updateBusinessAction
    }
)(CreateBusiness);

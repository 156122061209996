import React, { Component } from "react";
import { Link } from "react-router-dom";

import { relevantPeople } from "../../data/people";
import PlainCard from "../plain-card/PlainCard";
import ProfileIcon from "../profile-icon/ProfileIcon";
import Button from "../button/Button";
import MultiProfileIcon from "../multi-profile-icon/MultiProfileIcon";
import { arraysEqual, timeAgo } from "../../util";

import "./FeedCard.scss";

class FeedCard extends Component {
    openChat(user) {
        const {
            messaging,
            openMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction
        } = this.props;

        openMessageCenterAction();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction([user]);
        setCurrentMessageOpportunityAction(null);

        const chat = messaging.messages.find((room) => {
            const roomPeople = room.people.map((person) => person.id);
            return arraysEqual(roomPeople, [user.id]) && !room.opportunityId;
        });

        getCurrentChatAction(chat, [user], null);
    }

    render() {
        const {
            auth,
            people,
            sharedTo,
            type,
            content,
            opportunity,
            business,
            datePosted
        } = this.props;

        let output;
        switch (type) {
            case "comment":
                output = (
                    <>
                        {sharedTo[0] && (
                            <>
                                <p>
                                    <strong>{sharedTo[0].user.name}</strong>: "
                                    {content}"
                                </p>
                                {sharedTo[0].user.id !== auth.user.id && (
                                    <Button
                                        onClick={this.openChat.bind(
                                            this,
                                            sharedTo[0].user
                                        )}
                                    >
                                        Reply
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                );
                break;
            case "connection":
                output = (
                    <p>
                        <strong>{sharedTo[0].user.name} </strong>
                        {sharedTo.length > 1 ? "have" : "has"} accepted your
                        invitation and {sharedTo.length > 1 ? "are" : "is"} now
                        in your network.
                    </p>
                );
                break;
            case "opportunityUpdate":
                output = (
                    <p>
                        <strong>{sharedTo[0].user.name}</strong> posted an
                        update on{" "}
                        <Link to={`/opportunities/${opportunity.id}`}>
                            {opportunity.headline}
                        </Link>
                        .
                    </p>
                );
                break;
            case "opportunityShare":
                output = (
                    <p>
                        <strong>{sharedTo[0].user.name}</strong> shared an
                        opportunity with you:{" "}
                        <Link to={`/opportunities/${opportunity.id}`}>
                            {opportunity.headline}
                        </Link>
                    </p>
                );
                break;
            case "businessShare":
                output = (
                    <p>
                        <strong>{sharedTo[0].user.name}</strong> shared a
                        business opportunity with you:{" "}
                        <Link to={`/businesses/${business.id}`}>
                            {business.headline}
                        </Link>
                    </p>
                );
                break;
        }

        return (
            <PlainCard className="feed-card">
                <div className="feed-card-content">
                    {sharedTo.length === 1 && (
                        <ProfileIcon person={sharedTo[0].user} />
                    )}
                    {sharedTo.length > 1 && (
                        <MultiProfileIcon people={sharedTo} />
                    )}

                    <section>
                        <time>{timeAgo(datePosted)}</time>
                        {output}
                    </section>
                </div>
            </PlainCard>
        );
    }
}

export default FeedCard;

import React, { Component } from "react";
import { connect } from "react-redux";

import {
    setNetworkCountryFilterAction,
    setNetworkFilterAction,
    resetNetworkFiltersAction,
    getFilterSuggestionsAction,
    getLocationSuggestionsAction,
    getFilteredConnectionsAction,
    setNetworkViewAction,
    setFocusedUserAction,
    getConnectionRequestsAction,
    connectToUserAction,
    ignoreUserAction
} from "../../actions/network/networkActions";
import {
    getLocationsAction,
    getSectorsAction,
    getExpertisesAction,
    getLevelOfInvestmentAction
} from "../../actions/auxillary/auxillaryActions";
import ClusteredBubbleChart from "../../components/clustered-bubble-chart/ClusteredBubbleChart";
import MatchClusterBubbleChart from "../../components/match-cluster-bubble-chart/MatchClusterBubbleChart";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import NetworkFilters from "../../components/network-filters/NetworkFilters";
import NetworkMap from "../../components/network-map/NetworkMap";
import Button from "../../components/button/Button";

import countries from "../../data/countries";
import { regionCodes, regionNames } from "../../data/data";
import { mapPerson } from "../../data/people";

import "./MyNetwork.scss";
import ProfileIcon from "../../components/profile-icon/ProfileIcon";
import InfoBubble from "../../components/info-bubble/InfoBubble";

class MyNetwork extends Component {
    state = {
        influenceLocation: "",
        influenceSector: "",
        influenceTicketSize: "",
        influenceFiltered: false,
        influenceRefreshing: false
    };

    componentDidMount() {
        const {
            resetNetworkFiltersAction,
            setNetworkViewAction,
            getConnectionRequestsAction
        } = this.props;

        const view = window.location.pathname.split("/").pop();
        setNetworkViewAction(view);

        resetNetworkFiltersAction();
        getConnectionRequestsAction();
    }

    setInfluenceLocation(ev) {
        this.setState({
            influenceLocation: ev.target.value
        });
    }

    setInfluenceSector(ev) {
        this.setState({
            influenceSector: ev.target.value
        });
    }

    setInfluenceTicketSize(ev) {
        this.setState({
            influenceTicketSize: ev.target.value
        });
    }

    searchInfluence() {
        this.setState({
            influenceRefreshing: true
        });

        setTimeout(() => {
            this.setState({
                influenceFiltered: true,
                influenceRefreshing: false
            });
        }, 100);
    }

    setFocusedUser(user) {
        const { setFocusedUserAction } = this.props;

        setFocusedUserAction(mapPerson(user));
    }

    acceptConnection(user) {
        const { auth, connectToUserAction } = this.props;

        connectToUserAction(auth.user.id, user.id);
    }

    ignoreConnection(user) {
        const { auth, ignoreUserAction } = this.props;

        ignoreUserAction(auth.user.id, user.id);
    }

    render() {
        const {
            auth,
            network,
            auxillary,
            setNetworkCountryFilterAction,
            setNetworkFilterAction,
            resetNetworkFiltersAction,
            getFilterSuggestionsAction,
            getLocationSuggestionsAction,
            getFilteredConnectionsAction,
            setFocusedUserAction
        } = this.props;

        const {
            influenceLocation,
            influenceSector,
            influenceTicketSize,
            influenceFiltered,
            influenceRefreshing
        } = this.state;

        const connectionLocations = [];
        const connectionSectorInterests = [];
        const connectionTicketSizes = [];

        network.connections.forEach((connection) => {
            if (!connection.country) return;

            if (
                !connectionLocations.find(
                    (location) => location.code === connection.country
                )
            ) {
                const country = countries.ref_country_codes.find((country) => {
                    if (country.alpha2 === connection.country) {
                        return country.alpha2 === connection.country;
                    } else {
                        return (
                            country.alpha2 === regionCodes[connection.country]
                        );
                    }
                });

                if (country) {
                    connectionLocations.push({
                        name: regionNames[connection.country],
                        code: connection.country
                    });
                }
            }
            connection.sectorInterests.forEach((sector) => {
                if (!auxillary.sectorInterests) return;

                if (connectionSectorInterests.indexOf(sector.id) === -1)
                    connectionSectorInterests.push(sector.id);
            });
            connection.levelOfInvestment.forEach((levelOfInvestment) => {
                let ticketSize;
                Object.keys(auxillary.levelOfInvestment).forEach((currency) => {
                    if (ticketSize) return;

                    ticketSize = auxillary.levelOfInvestment[currency].find(
                        (loi) => loi.id === levelOfInvestment.id
                    );
                });
                if (
                    ticketSize &&
                    connectionTicketSizes.indexOf(ticketSize) === -1
                ) {
                    connectionTicketSizes.push(ticketSize);
                }
            });
        });

        connectionLocations.sort((a, b) => (a.name > b.name ? 1 : -1));
        connectionSectorInterests.sort((a, b) => (a > b ? 1 : -1));
        connectionTicketSizes.sort((a, b) => (a > b ? 1 : -1));

        return (
            <div className="network-container">
                <HighlightedContent
                    headline={
                        <>
                            My Network{" "}
                            <InfoBubble
                                content={
                                    <>
                                        <p>
                                            Here your network is analysed by
                                            Location, Sectors, Expertise,
                                            Finance Required, and Your Network's
                                            Influence.
                                        </p>
                                        <p>
                                            This will help you get more from
                                            your network &amp; make the right
                                            connections.
                                        </p>
                                        <p>
                                            The bubbles show you the strength of
                                            your network. Bigger bubbles =
                                            stronger network. This is based on
                                            how many people are in each bubble.
                                        </p>
                                        <p>
                                            Each bubble, shows who is in your
                                            network and new connection
                                            recommendations.
                                        </p>
                                    </>
                                }
                            />
                        </>
                    }
                >
                    <NetworkFilters networkView={network.networkView} />
                </HighlightedContent>
                <MainContent>
                    {network.networkView !== "requests" && (
                        <p className="explanation">
                            {network.networkView === "network" && (
                                <>
                                    Size of <span>orange circles</span> = size
                                    of your network in that region. To see your
                                    connections and make new ones, hover and
                                    click on each circle.
                                </>
                            )}
                            {network.networkView === "sectors" && (
                                <>
                                    Size of <span>orange circles</span> = size
                                    of your network in a given sector. To see
                                    your connections and make new ones, hover
                                    and click on each circle. A grey circle = no
                                    connections.
                                </>
                            )}
                            {network.networkView === "expertise" && (
                                <>
                                    Size of <span>orange circles</span> = size
                                    of your network who have a given expertise.
                                    To see your connections and make new ones,
                                    hover and click on each circle. A grey
                                    circle = no connections.
                                </>
                            )}
                            {network.networkView === "investment" && (
                                <>
                                    This shows who in your network is seeking
                                    companies which are raising a given amount
                                    of money, e.g £100K-£300K. The bigger the{" "}
                                    <span>orange circle</span>, the more people
                                    in that network fit the criteria. A grey
                                    circle = no connections.
                                </>
                            )}
                            {network.networkView === "influence" && (
                                <>
                                    "Does anyone in my network know someone
                                    in…..?" The name in the biggest bubble will
                                    have the most connections in that area.{" "}
                                </>
                            )}
                        </p>
                    )}
                    {network.networkView === "network" && (
                        <NetworkMap
                            premiumInvitesCount={auth.premiumInvitesCount}
                            network={network}
                            locations={auxillary.locations}
                            setNetworkCountryFilterAction={
                                setNetworkCountryFilterAction
                            }
                            resetNetworkFiltersAction={
                                resetNetworkFiltersAction
                            }
                            getLocationSuggestionsAction={
                                getLocationSuggestionsAction
                            }
                            getFilteredConnectionsAction={
                                getFilteredConnectionsAction
                            }
                            setFocusedUserAction={setFocusedUserAction}
                        />
                    )}
                    {network.networkView === "sectors" && (
                        <>
                            <ClusteredBubbleChart
                                premiumInvitesCount={auth.premiumInvitesCount}
                                network={network}
                                auxillary={auxillary}
                                setNetworkFilterAction={setNetworkFilterAction}
                                resetNetworkFiltersAction={
                                    resetNetworkFiltersAction
                                }
                                getFilterSuggestionsAction={
                                    getFilterSuggestionsAction
                                }
                                getFilteredConnectionsAction={
                                    getFilteredConnectionsAction
                                }
                                setFocusedUserAction={setFocusedUserAction}
                                filter="sectorInterests"
                            />
                        </>
                    )}
                    {network.networkView === "expertise" && (
                        <ClusteredBubbleChart
                            premiumInvitesCount={auth.premiumInvitesCount}
                            network={network}
                            auxillary={auxillary}
                            setNetworkFilterAction={setNetworkFilterAction}
                            resetNetworkFiltersAction={
                                resetNetworkFiltersAction
                            }
                            getFilterSuggestionsAction={
                                getFilterSuggestionsAction
                            }
                            getFilteredConnectionsAction={
                                getFilteredConnectionsAction
                            }
                            setFocusedUserAction={setFocusedUserAction}
                            filter="expertise"
                        />
                    )}
                    {network.networkView === "investment" && (
                        <>
                            <ClusteredBubbleChart
                                premiumInvitesCount={auth.premiumInvitesCount}
                                auth={auth}
                                network={network}
                                auxillary={auxillary}
                                setNetworkFilterAction={setNetworkFilterAction}
                                resetNetworkFiltersAction={
                                    resetNetworkFiltersAction
                                }
                                getFilterSuggestionsAction={
                                    getFilterSuggestionsAction
                                }
                                getFilteredConnectionsAction={
                                    getFilteredConnectionsAction
                                }
                                setFocusedUserAction={setFocusedUserAction}
                                filter="levelOfInvestment"
                            />
                        </>
                    )}
                    {network.networkView === "influence" && (
                        <>
                            <form
                                className={
                                    influenceFiltered
                                        ? "collapsed influence-form-container"
                                        : "influence-form-container"
                                }
                            >
                                <h3>Search my network's influence</h3>
                                <div className="influence-form">
                                    <fieldset>
                                        <label>Location</label>
                                        <select
                                            onChange={this.setInfluenceLocation.bind(
                                                this
                                            )}
                                        >
                                            <option>Choose</option>
                                            {connectionLocations.map(
                                                (location) => (
                                                    <option
                                                        value={location.code}
                                                    >
                                                        {location.name}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </fieldset>
                                    <fieldset>
                                        <label>Sector interest</label>
                                        <select
                                            onChange={this.setInfluenceSector.bind(
                                                this
                                            )}
                                        >
                                            <option>Choose</option>
                                            {connectionSectorInterests.map(
                                                (sector) => {
                                                    const sectorObject = auxillary.sectorInterests.find(
                                                        (aSector) =>
                                                            sector ===
                                                            aSector.id
                                                    );
                                                    return (
                                                        <option value={sector}>
                                                            {sectorObject.value}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </fieldset>
                                    <fieldset>
                                        <label>Finance required</label>
                                        <select
                                            onChange={this.setInfluenceTicketSize.bind(
                                                this
                                            )}
                                        >
                                            <option>Choose</option>
                                            {connectionTicketSizes.map(
                                                (ticketSize) => {
                                                    const ticketObject = auxillary.levelOfInvestment[
                                                        auth.user
                                                            .currencyPreference
                                                    ].find(
                                                        (levelOfInvestment) => {
                                                            return (
                                                                parseInt(
                                                                    ticketSize.ticketSize
                                                                ) ===
                                                                parseInt(
                                                                    levelOfInvestment.ticketSize
                                                                )
                                                            );
                                                        }
                                                    );
                                                    return (
                                                        <option
                                                            value={ticketSize}
                                                        >
                                                            {ticketObject.value}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </fieldset>
                                    <fieldset className="submit">
                                        <label>&nbsp;</label>
                                        <Button
                                            isPrimary
                                            onClick={this.searchInfluence.bind(
                                                this
                                            )}
                                        >
                                            Search
                                        </Button>
                                    </fieldset>
                                </div>
                            </form>
                            {influenceFiltered && !influenceRefreshing && (
                                <>
                                    <MatchClusterBubbleChart
                                        network={network}
                                        filters={{
                                            country: influenceLocation,
                                            sectorInterests: influenceSector,
                                            levelOfInvestment: influenceTicketSize
                                        }}
                                        setFocusedUserAction={
                                            setFocusedUserAction
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                    {network.networkView === "requests" && (
                        <div className="connection-requests">
                            <h4>
                                Latest invitations ({network.requests.length})
                            </h4>

                            {network.requests.map((request) => (
                                <div className="request">
                                    <ProfileIcon person={request} large />
                                    <div className="details">
                                        <strong>{request.name}</strong>
                                        <small>{request.occupationName}</small>
                                        <p>{request.biography}</p>
                                        <Button
                                            onClick={this.setFocusedUser.bind(
                                                this,
                                                request
                                            )}
                                        >
                                            View details
                                        </Button>
                                        <div className="actions">
                                            <Button
                                                onClick={this.acceptConnection.bind(
                                                    this,
                                                    request
                                                )}
                                            >
                                                Connect
                                            </Button>
                                            <Button
                                                onClick={this.ignoreConnection.bind(
                                                    this,
                                                    request
                                                )}
                                            >
                                                Ignore
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </MainContent>
                <footer>
                    {/* <Button href={`${process.env.PUBLIC_URL}/send-invite`}>Invite someone to join Fiinect</Button> */}
                    <Button href={`${process.env.PUBLIC_URL}/network/requests`}>
                        Your latest invitations{" "}
                        <strong>({network.requests.length})</strong>
                    </Button>
                </footer>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        network: state.network,
        auxillary: state.auxillary
    }),
    {
        setNetworkCountryFilterAction,
        setNetworkFilterAction,
        resetNetworkFiltersAction,
        getFilterSuggestionsAction,
        getLocationSuggestionsAction,
        getFilteredConnectionsAction,
        setNetworkViewAction,
        setFocusedUserAction,
        getLocationsAction,
        getSectorsAction,
        getExpertisesAction,
        getLevelOfInvestmentAction,
        getConnectionRequestsAction,
        connectToUserAction,
        ignoreUserAction
    }
)(MyNetwork);

import React, { Component } from "react";
import Button from "../button/Button";
import InfoBubble from "../info-bubble/InfoBubble";

import "./NetworkFilters.scss";

class NetworkFilters extends Component {
    render() {
        const { networkView } = this.props;

        return (
            <div className="network-filters">
                <Button
                    href="/network"
                    icon="/assets/dot.svg"
                    cssClass={networkView === "network" ? "focused" : ""}
                >
                    <span>By location</span>
                </Button>
                <Button
                    href="/network/sectors"
                    icon="/assets/dot.svg"
                    cssClass={networkView === "sectors" ? "focused" : ""}
                >
                    <span>By sectors</span>
                    <InfoBubble
                        content={
                            <>
                                <p>This is your Network analysed by Sector.​</p>
                                ​
                                <p>
                                    The size of each 'sector bubble', shows you
                                    the strength of your contacts in that
                                    sector. So if your TECH bubble is the
                                    biggest, then you have more people
                                    interested in the tech sector in your
                                    network than other sectors.​
                                </p>
                                ​
                                <p>
                                    Conversely, if you are into Tech
                                    opportunities & your tech Bubble is small,
                                    then your business will suffer and you need
                                    to build up those numbers by using the new
                                    connection recommendations. ​
                                </p>
                                ​
                                <p>
                                    NB: if a bubble is grey...it means you have
                                    no contacts in that sector!! ​
                                </p>
                            </>
                        }
                    />
                </Button>
                <Button
                    href="/network/expertise"
                    icon="/assets/dot.svg"
                    cssClass={networkView === "expertise" ? "focused" : ""}
                >
                    <span>By expertise</span>
                </Button>
                <Button
                    href="/network/investment"
                    icon="/assets/dot.svg"
                    cssClass={networkView === "investment" ? "focused" : ""}
                >
                    <span>By finance required</span>
                    <InfoBubble
                        content={
                            <>
                                <p>
                                    This is your Network analysed by FINANCE
                                    REQUIRED​​
                                </p>
                                ​
                                <p>
                                    The size of each 'bubble', shows you the
                                    strength of your contacts that are looking
                                    in that financial range. If all the Bubbles
                                    are empty, you will reduce your chances of
                                    receiving syndication or 'next stage'
                                    business opportunities. ​​
                                </p>
                                ​
                                <p>
                                    NB: if a bubble is grey...it means you have
                                    no contacts at that level!! ​​
                                </p>
                            </>
                        }
                    />
                </Button>
                <Button
                    href="/network/influence"
                    icon="/assets/dot.svg"
                    cssClass={networkView === "influence" ? "focused" : ""}
                >
                    <span>My network's influence</span>
                    <InfoBubble
                        content={
                            <>
                                <p>
                                    "Does anyone in my network know someone
                                    in….?" This tab answers the question. Run a
                                    search across sectors. The name with the
                                    biggest bubble will have the most
                                    connections in that area.
                                </p>
                                <p>
                                    You won't see their network, but you can
                                    contact them and ask for an introduction.
                                </p>
                                ​
                                <p>
                                    For example, you can search for which person
                                    in your network has the most contacts in
                                    Europe looking to invest £100 - £300k in the
                                    Energy &amp; Renewables sector. You won't
                                    see their network, but you can then contact
                                    the individual.​
                                </p>
                            </>
                        }
                    />
                </Button>
            </div>
        );
    }
}

export default NetworkFilters;

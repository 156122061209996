import React, { Component } from "react";
import { connect } from "react-redux";

import {
    getProfiledOpportunitiesAction,
    getInterestedOpportunitiesAction,
    getFollowedOpportunitiesAction,
    getNotInterestedOpportunitiesAction,
    getOpportunitiesComingSoonAction,
    setInterestStatusAction,
    setInboxViewAction,
    getMyOpportunitiesAction
} from "../../actions/opportunities/opportunityActions";

import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import OpportunityFilters from "../../components/opportunity-filters/OpportunityFilters";
import OpportunityCard from "../../components/opportunity-card/OpportunityCard";
import Carousel from "../../components/carousel/Carousel";
import Loader from "../../components/loader/Loader";
import Accordion from "../../components/accordion/Accordion";

import "./OpportunitiesInbox.scss";
import InfoBubble from "../../components/info-bubble/InfoBubble";

class OpportunitiesInbox extends Component {
    componentDidMount() {
        const {
            getProfiledOpportunitiesAction,
            getInterestedOpportunitiesAction,
            getMyOpportunitiesAction,
            getFollowedOpportunitiesAction,
            getNotInterestedOpportunitiesAction,
            getOpportunitiesComingSoonAction,
            setInboxViewAction
        } = this.props;

        const view = window.location.pathname.split("/").pop();
        setInboxViewAction(view);

        if (view === "opportunities") {
            getProfiledOpportunitiesAction();
            getOpportunitiesComingSoonAction();
        }
        if (view === "mine") getMyOpportunitiesAction();
        if (view === "interested") getInterestedOpportunitiesAction();
        if (view === "following") getFollowedOpportunitiesAction();
        if (view === "not-interested") getNotInterestedOpportunitiesAction();
    }

    render() {
        const {
            inbox,
            auxillary,
            comingSoon,
            inboxView,
            messaging,
            network,
            currentUser,
            inboxLoading,
            setInterestStatusAction
        } = this.props;

        const view = window.location.pathname.split("/").pop();
        let title = "";
        if (view === "opportunities")
            title = "Opportunities matching your profile";

        return (
            <div className="opportunities-container">
                {inboxLoading && <Loader />}
                <HighlightedContent
                    headline={
                        <>
                            <span>Opportunities</span>
                            <InfoBubble
                                content={
                                    <>
                                        <p>
                                            Your NEW folder shows business
                                            opportunities, specifically matched
                                            to your profile. You need to assign
                                            a 'status' to an opportunity by
                                            selecting one of the buttons on each
                                            opportunity page. This does two
                                            things:​
                                        </p>
                                        ​
                                        <p>
                                            It tells us (only us!) what updates
                                            to show you on which opportunities.
                                            (NB: this is not shared with the
                                            actual business so no-one will
                                            contact you!)​
                                        </p>
                                        ​
                                        <p>
                                            What updates do you receive? They
                                            are:​
                                        </p>
                                        ​
                                        <p>
                                            NEW folder : by leaving an
                                            opportunity in NEW, you will be
                                            shown 'no' updates.​
                                        </p>
                                        <p>
                                            Interested : you will be shown all
                                            updates ​
                                        </p>
                                        <p>
                                            Just Watching : shown just the major
                                            updates​
                                        </p>
                                        <p>
                                            Not Interested. : shown no updates &
                                            the opportunity is removed from your
                                            NEW folder​
                                        </p>
                                        <p>
                                            The 'Status' you assign to an
                                            opportunity also sends a private
                                            signal, to people in your network &
                                            the originator of that opportunity.
                                            This quickens the whole process by
                                            enabling conversations sooner and
                                            prevents wasted communication.​
                                        </p>
                                    </>
                                }
                            />
                        </>
                    }
                >
                    <OpportunityFilters inboxView={inboxView} />
                </HighlightedContent>
                <MainContent>
                    <div className="inbox content">
                        <section className="inbox-filters">
                            <h3>{title}</h3>

                            <div className="sort">
                                {!currentUser.profileComplete && (
                                    <p>
                                        Please complete your profile to see
                                        opportunities which match your criteria
                                    </p>
                                )}
                            </div>
                        </section>

                        {view === "opportunities" && (
                            <>
                                {Object.keys(inbox)
                                    .reverse()
                                    .map((key) => {
                                        if (inbox[key].length === 0)
                                            return null;
                                        return (
                                            <Accordion
                                                header={`${key} (${inbox[key].length})`}
                                                open
                                            >
                                                <Carousel
                                                    idKey={`inbox-opportunities-${Math.floor(
                                                        Math.random() * 100
                                                    )}`}
                                                >
                                                    {inbox[key].map(
                                                        (opportunity) => (
                                                            <OpportunityCard
                                                                key={
                                                                    opportunity.opportunityId
                                                                }
                                                                opportunityId={
                                                                    opportunity.opportunityId
                                                                }
                                                                sector={
                                                                    opportunity.sector
                                                                }
                                                                sectorHex={
                                                                    opportunity.sectorHex
                                                                }
                                                                datePosted={
                                                                    opportunity.datePosted
                                                                }
                                                                headline={
                                                                    opportunity.headline
                                                                }
                                                                ticketSize={
                                                                    opportunity.ticketSize
                                                                }
                                                                description={
                                                                    opportunity.description
                                                                }
                                                                country={
                                                                    opportunity.country
                                                                }
                                                                locationId={
                                                                    opportunity.locationId
                                                                }
                                                                broker={
                                                                    opportunity.broker
                                                                }
                                                                interestedPeople={
                                                                    opportunity.interestedPeople
                                                                }
                                                                otherPeople={
                                                                    opportunity.otherPeople
                                                                }
                                                                currentUser={
                                                                    currentUser
                                                                }
                                                                setInterestStatusAction={
                                                                    setInterestStatusAction
                                                                }
                                                                messaging={
                                                                    messaging
                                                                }
                                                                isBusiness={
                                                                    opportunity.isBusiness
                                                                }
                                                                isActive={
                                                                    opportunity.isActive
                                                                }
                                                                connections={
                                                                    network.connections
                                                                }
                                                                auxillary={
                                                                    auxillary
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Carousel>
                                            </Accordion>
                                        );
                                    })}
                                {/*comingSoon && comingSoon.length > 0 && (
                                    <Accordion
                                        header={`Pre-Funding (${comingSoon.length})`}
                                        open
                                        cssClass="coming-soon-accordion"
                                    >
                                        <Carousel idKey="comingSoon-to-follow">
                                            {comingSoon.map((opportunity) => (
                                                <OpportunityCard
                                                    key={
                                                        opportunity.opportunityId
                                                    }
                                                    opportunityId={
                                                        opportunity.opportunityId
                                                    }
                                                    sector={opportunity.sector}
                                                    sectorHex={
                                                        opportunity.sectorHex
                                                    }
                                                    datePosted={
                                                        opportunity.datePosted
                                                    }
                                                    headline={
                                                        opportunity.headline
                                                    }
                                                    ticketSize={
                                                        opportunity.ticketSize
                                                    }
                                                    description={
                                                        opportunity.description
                                                    }
                                                    country={
                                                        opportunity.country
                                                    }
                                                    locationId={
                                                        opportunity.locationId
                                                    }
                                                    broker={opportunity.broker}
                                                    interestedPeople={
                                                        opportunity.interestedPeople
                                                    }
                                                    otherPeople={
                                                        opportunity.otherPeople
                                                    }
                                                    currentUser={currentUser}
                                                    messaging={messaging}
                                                    setInterestStatusAction={
                                                        setInterestStatusAction
                                                    }
                                                    comingSoon
                                                    isBusiness={
                                                        opportunity.isBusiness
                                                    }
                                                    isActive={
                                                        opportunity.isActive
                                                    }
                                                    connections={
                                                        network.connections
                                                    }
                                                    auxillary={auxillary}
                                                />
                                            ))}
                                        </Carousel>
                                    </Accordion>
                                                )*/}
                            </>
                        )}

                        {view !== "opportunities" &&
                            Object.keys(inbox)
                                .reverse()
                                .map((key) => (
                                    <Accordion
                                        header={`${key} (${inbox[key].length})`}
                                        open
                                    >
                                        <div className="card-stack">
                                            {inbox[key].map((opportunity) => (
                                                <OpportunityCard
                                                    key={
                                                        opportunity.opportunityId
                                                    }
                                                    opportunityId={
                                                        opportunity.opportunityId
                                                    }
                                                    sector={opportunity.sector}
                                                    sectorHex={
                                                        opportunity.sectorHex
                                                    }
                                                    datePosted={
                                                        opportunity.datePosted
                                                    }
                                                    headline={
                                                        opportunity.headline
                                                    }
                                                    ticketSize={
                                                        opportunity.ticketSize
                                                    }
                                                    description={
                                                        opportunity.description
                                                    }
                                                    country={
                                                        opportunity.country
                                                    }
                                                    locationId={
                                                        opportunity.locationId
                                                    }
                                                    broker={opportunity.broker}
                                                    interestedPeople={
                                                        opportunity.interestedPeople
                                                    }
                                                    otherPeople={
                                                        opportunity.otherPeople
                                                    }
                                                    messaging={messaging}
                                                    currentUser={currentUser}
                                                    setInterestStatusAction={
                                                        setInterestStatusAction
                                                    }
                                                    showIndications
                                                    isBusiness={
                                                        opportunity.isBusiness
                                                    }
                                                    isActive={
                                                        opportunity.isActive
                                                    }
                                                    updates={
                                                        opportunity.updateNotifications.filter(
                                                            (update) =>
                                                                update.userId !==
                                                                currentUser.userId
                                                        ).length +
                                                        opportunity.qaNotifications.filter(
                                                            (update) =>
                                                                update.userId !==
                                                                currentUser.userId
                                                        ).length
                                                    }
                                                    statusChanges={
                                                        opportunity.statusChanges
                                                    }
                                                    connections={
                                                        network.connections
                                                    }
                                                    auxillary={auxillary}
                                                />
                                            ))}
                                        </div>
                                    </Accordion>
                                ))}
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentUser: state.auth.user,
        inbox: state.opportunities.inbox,
        inboxLoading: state.opportunities.inboxLoading,
        comingSoon: state.opportunities.comingSoon,
        inboxView: state.opportunities.inboxView,
        messaging: state.messaging,
        network: state.network,
        auxillary: state.auxillary
    }),
    {
        getProfiledOpportunitiesAction,
        getInterestedOpportunitiesAction,
        getFollowedOpportunitiesAction,
        getNotInterestedOpportunitiesAction,
        getOpportunitiesComingSoonAction,
        setInterestStatusAction,
        getMyOpportunitiesAction,
        setInboxViewAction
    }
)(OpportunitiesInbox);

import React, { Component } from "react";
import { connect } from "react-redux";

import {
    getOccupationsAction,
    getExpertisesAction,
    getLocationsAction
} from "../../actions/auxillary/auxillaryActions";
import {
    updateUserDetailsAction,
    uploadProfilePictureAction,
    setUserExpertiseAction,
    setSectorExpertiseAction,
    setFinanceStageExpertiseAction
} from "../../actions/preferences/preferencesActions";
import Button from "../../components/button/Button";
import HighlightedContent from "../../components/highlighted-content/HighlightedContent";
import MainContent from "../../components/main-content/MainContent";
import OptionSelector from "../../components/option-selector/OptionSelector";
import ProfilePhotoUploader from "../../components/profile-photo-uploader/ProfilePhotoUploader";
import TextInput from "../../components/text-input/TextInput";

import countries from "../../data/countries";

import "./MyDetails.scss";

class MyDetails extends Component {
    state = {
        formData: {
            FirstName: "",
            LastName: "",
            Email: "",
            PhoneNumber: "",
            Biography: "",
            newPassword: "",
            confirmPassword: "",
            LocationID: "",
            OccupationID: "",
            LinkedInProfile: "",
            JobTitle: ""
        },
        defaultData: {
            FirstName: "",
            LastName: "",
            Email: "",
            PhoneNumber: "",
            Biography: "",
            LocationID: "",
            OccupationID: "",
            LinkedInProfile: "",
            JobTitle: ""
        },
        expertises: [],
        errors: [],
        sectorsExpertise: [],
        financeExpertise: []
    };

    componentDidMount() {
        const {
            currentUser,
            getOccupationsAction,
            getExpertisesAction,
            getLocationsAction
        } = this.props;

        getOccupationsAction();
        getExpertisesAction();
        getLocationsAction();

        this.setState({
            formData: {
                FirstName: currentUser.firstName,
                LastName: currentUser.surname,
                Email: currentUser.email,
                PhoneNumber: currentUser.phoneNumber,
                Biography: currentUser.biography,
                LocationID: currentUser.locationId,
                OccupationID: currentUser.occupationId,
                LinkedInProfile: currentUser.linkedInProfile,
                JobTitle: currentUser.jobTitle
            },
            defaultData: {
                FirstName: currentUser.firstName,
                LastName: currentUser.surname,
                Email: currentUser.email,
                PhoneNumber: currentUser.phoneNumber,
                Biography: currentUser.biography,
                LocationID: currentUser.locationId,
                OccupationID: currentUser.occupationId,
                LinkedInProfile: currentUser.linkedInProfile,
                JobTitle: currentUser.jobTitle
            },
            expertises: currentUser.expertise,
            sectorsExpertise: currentUser.sectorExpertise,
            financeExpertise: currentUser.financeStageExpertise
        });
    }

    update(key, action, value) {
        this.setState({
            [key]: value
        });

        if (typeof value === "boolean") {
            action({ [key]: value });
        } else {
            const arr1 = this.state[key];
            const arr2 = value;

            let additions = arr2.filter((x) => !arr1.includes(x));
            let subtractions = arr1.filter((x) => !arr2.includes(x));

            if (additions.length > 0) action(additions[0].id, "post");
            if (subtractions.length > 0) action(subtractions[0].id, "delete");
        }
    }

    updateForm(key, value) {
        const { defaultData, formData } = this.state;

        let newValue = value;
        if (!newValue) newValue = defaultData[key];

        this.setState({
            formData: {
                ...formData,
                [key]: newValue
            }
        });
    }

    updateSelector(key, value) {
        const { formData } = this.state;

        this.setState({
            formData: {
                ...formData,
                [key]: value[1].id
            }
        });
    }

    countryFlagForList(countryName) {
        var country = countries.ref_country_codes.find(
            (c) => c.country === countryName
        );
        if (!country) {
            return "";
        }

        return `https://flagpedia.net/data/flags/h24/${country.alpha2.toLowerCase()}.png`;
    }

    save() {
        const { updateUserDetailsAction } = this.props;
        const { formData } = this.state;

        if (formData.newPassword) {
            const errors = [];
            const regex = new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/
            );
            if (!regex.test(formData.newPassword))
                errors.push("passwordTooWeak");
            if (formData.newPassword !== formData.confirmPassword)
                errors.push("passwordMismatch");

            this.setState({
                errors
            });

            if (errors.length > 0) return;

            formData.Password = formData.newPassword;
        }

        updateUserDetailsAction(formData);
    }

    preview() {
        const { updateUserDetailsAction } = this.props;
        const { formData } = this.state;

        updateUserDetailsAction(formData, true);
    }

    render() {
        const {
            currentUser,
            auxillary,
            uploadProfilePictureAction,
            setUserExpertiseAction,
            setSectorExpertiseAction,
            setFinanceStageExpertiseAction
        } = this.props;

        const {
            sectorsExpertise,
            financeExpertise,
            expertises,
            errors,
            formData
        } = this.state;

        let aboutYouPlaceholder = null;

        if (currentUser.role === "Investor") {
            aboutYouPlaceholder = (
                <>
                    This section is for you to say what opportunities you like
                    (types of businesses, sectors etc) as well as outlining your
                    experience. This will give users an idea of what you do &
                    what you are looking for, ensuring you make the right
                    connections quickly.
                    <br />
                    <br />
                    For example: "I have 10 years' experience investing in
                    scale-ups and tech, including Widget Co Ltd and Example
                    Funds. I am looking to back scale-up businesses in the
                    Medical and Health sectors which have traction. My sweet
                    spot is pre-Series A, usually companies raising between
                    £0.5M and £1M." `
                </>
            );
        } else if (currentUser.role === "Business") {
            aboutYouPlaceholder = (
                <>
                    This section is for you to say a little about your business
                    & your experience so you can match with people that can help
                    you achieve your objectives.
                </>
            );
        } else if (currentUser.role === "Professional") {
            aboutYouPlaceholder = (
                <>
                    This section is for you to say what opportunities you like
                    (types of businesses, sectors etc) as well as outlining your
                    experience. This will give users an idea of what you do &
                    what you are looking for, ensuring you make the right
                    connections quickly.
                    <br />
                    <br />
                    {currentUser.occupationId === 1 && (
                        <>
                            For example: "I am a junior parner at Sample Law
                            Firm LLP. I specialise in contracts arounds Impact
                            and Green Energy investments, particularly solar. We
                            also work on non-contentious matters for clients
                            entering new markets."
                        </>
                    )}
                    {currentUser.occupationId === 2 && (
                        <>
                            For example: "I am a forensic accountant at Sample
                            Accountancy Firm. We focus on financial planning and
                            audit preparation for companies, with a particular
                            focus on shipping and transport. We are happy to
                            introduce our clients to vetted opportunities on a
                            discretionary basis."
                        </>
                    )}
                    {currentUser.occupationId === 3 && (
                        <>
                            For example: "I have 10 years' experience working
                            for Sample Brokerage in London, specialising in
                            mezzanine finance. My clients have a preference for
                            long term investment in portfolio businesses with a
                            steady income strip. My sweet spot is in the
                            £10M-£20M range."
                        </>
                    )}
                </>
            );
        }

        return (
            <div className="details-container">
                <HighlightedContent
                    headline="About Me"
                    small
                ></HighlightedContent>
                <MainContent>
                    <div className="details">
                        <fieldset>
                            <div>
                                <label for="firstName">Your first name</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "FirstName"
                                    )}
                                    placeholder={currentUser.firstName}
                                    keepPlaceholder
                                />
                            </div>

                            <div>
                                <label for="firstName">Your surname</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "LastName"
                                    )}
                                    placeholder={currentUser.surname}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <div>
                                <label for="email">Email address</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "Email"
                                    )}
                                    placeholder={currentUser.email}
                                    keepPlaceholder
                                />
                            </div>
                            <div>
                                <label for="mobile">Mobile number</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "PhoneNumber"
                                    )}
                                    placeholder={currentUser.phoneNumber}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div>
                                <label for="firstName">
                                    Your LinkedIn profile
                                </label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "LinkedInProfile"
                                    )}
                                    placeholder={currentUser.linkedInProfile}
                                    keepPlaceholder
                                />
                            </div>
                            <div>
                                <label for="firstName">Job title</label>
                                <TextInput
                                    type="text"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "JobTitle"
                                    )}
                                    placeholder={currentUser.jobTitle}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        <h4>Profile photo</h4>
                        <ProfilePhotoUploader
                            user={currentUser}
                            uploadProfilePictureAction={
                                uploadProfilePictureAction
                            }
                        />

                        <p className="password-info">
                            If you wish to change your password at any stage,
                            use the box below.
                        </p>
                        <fieldset>
                            <div>
                                <label for="password">New password</label>
                                {errors.find(
                                    (error) => error === "passwordTooWeak"
                                ) && (
                                    <p className="error">
                                        Your password is too weak, please try
                                        another
                                    </p>
                                )}
                                <TextInput
                                    type="password"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "newPassword"
                                    )}
                                />
                                <p>
                                    <small>
                                        Passwords should be at least 10
                                        characters long, have at least one
                                        lowercase, uppercase, numeric and
                                        punctuation character
                                    </small>
                                </p>
                            </div>

                            <div>
                                <label for="confirmPassword">
                                    Confirm new password
                                </label>
                                {errors.find(
                                    (error) => error === "passwordMismatch"
                                ) && (
                                    <p className="error">
                                        Passwords do not match
                                    </p>
                                )}
                                <TextInput
                                    type="password"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "confirmPassword"
                                    )}
                                />
                            </div>
                        </fieldset>

                        {currentUser.role === "Professional" && (
                            <>
                                <h4>I am a...</h4>
                                <OptionSelector
                                    selectedValues={[
                                        { id: formData.OccupationID }
                                    ]}
                                    noIcon
                                    options={auxillary.occupations}
                                    action={this.updateSelector.bind(
                                        this,
                                        "OccupationID"
                                    )}
                                />
                            </>
                        )}

                        {formData.OccupationID !== "" &&
                            currentUser.role === "Professional" && (
                                <>
                                    <h4>
                                        I can provide services in the following
                                        areas{" "}
                                    </h4>
                                    <OptionSelector
                                        selectedValues={expertises}
                                        noIcon
                                        options={auxillary.expertise.filter(
                                            (e) =>
                                                e.occupationId ===
                                                formData.OccupationID
                                        )}
                                        action={this.update.bind(
                                            this,
                                            "expertises",
                                            setUserExpertiseAction
                                        )}
                                    />
                                </>
                            )}

                        <fieldset>
                            <div>
                                <label for="email">About you</label>
                                <p>
                                    This brief introduction will appear in your
                                    profile card.
                                </p>

                                <p>
                                    <small>{aboutYouPlaceholder}</small>
                                </p>

                                <TextInput
                                    multiline
                                    maxLength="500"
                                    onInput={this.updateForm.bind(
                                        this,
                                        "Biography"
                                    )}
                                    placeholder={currentUser.biography}
                                    keepPlaceholder
                                />
                            </div>
                        </fieldset>

                        {currentUser.role !== "Business" && (
                            <>
                                <h4>Where are you based?</h4>
                                <OptionSelector
                                    vertical
                                    getImage={this.countryFlagForList.bind(
                                        this
                                    )}
                                    selectedValues={[
                                        { id: formData.LocationID }
                                    ]}
                                    options={auxillary.locations.filter(
                                        (location) => !location.isRegion
                                    )}
                                    action={this.updateSelector.bind(
                                        this,
                                        "LocationID"
                                    )}
                                />

                                <h4>Skills and expertise</h4>

                                <p>
                                    Coming soon - we will have an area called
                                    'Fiinect Marketplace' where members can
                                    offer their expertise to others. If you'd
                                    like to participate, please select your
                                    areas of expertise below to provide advisory
                                    services. Your details will appear as an
                                    advert on Fiinect Marketplace and members
                                    who need help will contact you via private
                                    messaging on the site.
                                </p>

                                <h4>I work/have worked in these sectors</h4>

                                <OptionSelector
                                    selectedValues={sectorsExpertise}
                                    options={auxillary.sectorInterests}
                                    sectorVariant
                                    imageFormat="svg"
                                    action={this.update.bind(
                                        this,
                                        "sectorsExpertise",
                                        setSectorExpertiseAction
                                    )}
                                />

                                <h4>
                                    I work/have worked in these areas of finance
                                </h4>

                                <OptionSelector
                                    selectedValues={financeExpertise}
                                    options={auxillary.financeStages}
                                    action={this.update.bind(
                                        this,
                                        "financeExpertise",
                                        setFinanceStageExpertiseAction
                                    )}
                                />
                            </>
                        )}

                        <div className="actions">
                            <Button isPrimary onClick={this.save.bind(this)}>
                                Save details
                            </Button>
                            <Button onClick={this.preview.bind(this)}>
                                Preview my profile
                            </Button>
                        </div>
                    </div>
                </MainContent>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentUser: state.auth.user,
        auxillary: state.auxillary
    }),
    {
        updateUserDetailsAction,
        getOccupationsAction,
        getExpertisesAction,
        setUserExpertiseAction,
        setSectorExpertiseAction,
        setFinanceStageExpertiseAction,
        getLocationsAction,
        uploadProfilePictureAction
    }
)(MyDetails);

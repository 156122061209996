import React, { Component } from "react";

import PlainCard from "../plain-card/PlainCard";
import MessageList from "../message-list/MessageList";
import Message from "../message/Message";
import NewMessageButton from "../new-message-button/NewMessageButton";
import TextInput from "../text-input/TextInput";
import ProfileIcon from "../profile-icon/ProfileIcon";
import MultiProfileIcon from "../multi-profile-icon/MultiProfileIcon";
import Button from "../button/Button";
import { relevantPeople } from "../../data/people";
import { arraysEqual } from "../../util";

import "./MessageCenter.scss";
import { Link } from "react-router-dom";

class MessageCenter extends Component {
    state = {
        filter: "",
        message: "",
        recipients: []
    };

    toggleMessageCenter() {
        const { toggleMessageCenterAction } = this.props;
        clearInterval(window.refreshChat);
        this.resetFilter();

        this.setState({
            recipients: []
        });
        toggleMessageCenterAction();
    }

    filterView(filter) {
        this.setState({
            filter
        });
    }

    resetFilter() {
        this.setState({
            filter: ""
        });
    }

    writeMessage(message) {
        this.setState({
            message
        });
    }

    sendMessage() {
        const {
            user,
            messaging,
            sendMessageAction,
            setMessageTutorialAction,
            setWelcomeModalAction
        } = this.props;

        sendMessageAction(
            messaging.currentChat.chatId,
            this.state.message,
            user
        );
        setTimeout(() => {
            const chatView = document.querySelector(
                ".message-center .message-center-container .chat-view .view-body"
            );
            if (chatView) chatView.scrollTop = chatView.scrollHeight;
        }, 10);

        document.getElementById("chat-message-input").value = "";

        if (messaging.messageTutorial) {
            setMessageTutorialAction(false);
            setWelcomeModalAction(true);
        }
    }

    viewAllMessages() {
        const {
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction
        } = this.props;

        this.resetFilter();
        setMessageCenterViewAction("list");
        setCurrentMessageParticipantsAction([]);
        setCurrentMessageOpportunityAction(null);
    }

    viewChat(connections, opportunity) {
        const {
            messaging,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getCurrentChatAction
        } = this.props;

        this.resetFilter();
        setMessageCenterViewAction("chat");
        setCurrentMessageParticipantsAction(connections);
        setCurrentMessageOpportunityAction(opportunity);

        const chat = messaging.messages.find(
            (room) =>
                arraysEqual(room.people, connections) &&
                ((!opportunity && !room.opportunity) ||
                    (opportunity &&
                        room.opportunity &&
                        room.opportunity.opportunityId === opportunity))
        );

        getCurrentChatAction(chat, connections, opportunity);
    }

    openRecipientView() {
        const { setMessageCenterViewAction } = this.props;

        setMessageCenterViewAction("recipient");
    }

    openOpportunityView() {
        const {
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            messaging
        } = this.props;

        const { recipients } = this.state;

        if (messaging.currentMessageOpportunity) {
            this.viewChat(recipients, messaging.currentMessageOpportunity);
        } else {
            setCurrentMessageParticipantsAction(recipients);
            setMessageCenterViewAction("opportunity");
        }
    }

    addRecipient(connection) {
        const { recipients } = this.state;

        const existingIndex = recipients.findIndex(
            (r) => r.id === connection.id
        );
        if (existingIndex > -1) {
            recipients.splice(existingIndex, 1);
        } else {
            recipients.push(connection);
        }

        this.setState({
            recipients
        });
    }

    calculateHeightOfBody() {
        const baseOffset = 85 /*height*/ + 22; /*margin*/
        const participantsBanner = document.querySelector(
            ".message-participants"
        );
        const newOffset = participantsBanner
            ? baseOffset + participantsBanner.clientHeight
            : baseOffset;
        return `calc(100% - ${newOffset}px)`;
    }

    render() {
        const {
            user,
            messaging,
            network,
            opportunities,
            openMessageCenterAction,
            setMessageCenterViewAction,
            setCurrentMessageParticipantsAction,
            setCurrentMessageOpportunityAction,
            getOpportunityDetailsForChatAction,
            getCurrentChatAction,
            leaveRoomAction
        } = this.props;

        const { filter, recipients } = this.state;

        const opportunityOptions = [
            ...opportunities.mine,
            ...opportunities.interested[
                "Profiled opportunities you have selected"
            ]
        ];

        return (
            <div className="message-center">
                <img
                    onClick={this.toggleMessageCenter.bind(this)}
                    src={`${process.env.PUBLIC_URL}/assets/message-center.svg`}
                />
                <span className="unread-count">{messaging.unread}</span>
                <PlainCard
                    className={
                        messaging.messageCenterOpen
                            ? "message-center-container open"
                            : "message-center-container"
                    }
                >
                    {messaging.messageCenterView === "list" && (
                        <div className="list-view">
                            <header>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    onClick={this.toggleMessageCenter.bind(
                                        this
                                    )}
                                    className="close"
                                    alt="Close"
                                />
                                <h4>
                                    Direct messages{" "}
                                    <strong>({messaging.total})</strong>
                                </h4>
                                <p>
                                    <strong>{messaging.unread}</strong> unread
                                </p>
                            </header>
                            <NewMessageButton
                                openMessageCenterAction={
                                    openMessageCenterAction
                                }
                                setMessageCenterViewAction={
                                    setMessageCenterViewAction
                                }
                                setCurrentMessageOpportunityAction={
                                    setCurrentMessageOpportunityAction
                                }
                                opportunity={null}
                            />
                            <div className="view-body">
                                <MessageList
                                    chats={messaging.messages}
                                    setMessageCenterViewAction={
                                        setMessageCenterViewAction
                                    }
                                    openMessageCenterAction={
                                        openMessageCenterAction
                                    }
                                    setCurrentMessageParticipantsAction={
                                        setCurrentMessageParticipantsAction
                                    }
                                    setCurrentMessageOpportunityAction={
                                        setCurrentMessageOpportunityAction
                                    }
                                    getCurrentChatAction={getCurrentChatAction}
                                    getOpportunityDetailsForChatAction={
                                        getOpportunityDetailsForChatAction
                                    }
                                    leaveRoomAction={leaveRoomAction}
                                />
                            </div>
                        </div>
                    )}
                    {messaging.messageCenterView === "recipient" && (
                        <div className="recipient-view">
                            <header>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    onClick={this.toggleMessageCenter.bind(
                                        this
                                    )}
                                    className="close"
                                    alt="Close"
                                />
                                <h4>New message</h4>
                            </header>

                            <div className="message-participants">
                                <p>Send message to:</p>
                                {recipients.map((r) => (
                                    <span
                                        onClick={this.addRecipient.bind(
                                            this,
                                            r
                                        )}
                                    >
                                        {r.name}
                                    </span>
                                ))}
                            </div>
                            <div
                                className="view-body"
                                style={{ height: this.calculateHeightOfBody() }}
                            >
                                {network.connections
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .map((connection) => {
                                        const isRecipient = recipients.find(
                                            (r) => r.id === connection.id
                                        );

                                        if (
                                            filter &&
                                            connection.name
                                                .toLowerCase()
                                                .indexOf(
                                                    filter.toLowerCase()
                                                ) === -1
                                        )
                                            return;
                                        return (
                                            <div
                                                key={
                                                    connection.id +
                                                    Math.random()
                                                }
                                                className={
                                                    isRecipient
                                                        ? "recipient connection"
                                                        : "connection"
                                                }
                                            >
                                                <ProfileIcon
                                                    person={connection}
                                                />
                                                <p>{connection.name}</p>
                                                {!isRecipient && (
                                                    <Button
                                                        onClick={this.addRecipient.bind(
                                                            this,
                                                            connection
                                                        )}
                                                    >
                                                        Add
                                                    </Button>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className="view-actions">
                                <Button
                                    disabled={recipients.length === 0}
                                    onClick={this.openOpportunityView.bind(
                                        this
                                    )}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    )}
                    {messaging.messageCenterView === "opportunity" && (
                        <div className="chat-type-view">
                            <header>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    onClick={this.toggleMessageCenter.bind(
                                        this
                                    )}
                                    className="close"
                                    alt="Close"
                                />
                                <h4>New message</h4>
                            </header>
                            <div className="message-participants">
                                <p>Tag this to an opportunity</p>
                                <span>(Skip if this is a general message)</span>
                            </div>
                            <div
                                className="view-body"
                                style={{ height: this.calculateHeightOfBody() }}
                            >
                                <section className="chat-type chat-filter">
                                    <TextInput
                                        type="text"
                                        placeholder="Search opportunities"
                                        onInput={this.filterView.bind(this)}
                                    />
                                </section>
                                <section className="chat-type">
                                    {opportunityOptions
                                        .filter(
                                            (opportunity) =>
                                                opportunity.headline
                                                    .toLowerCase()
                                                    .indexOf(
                                                        filter.toLowerCase()
                                                    ) > -1
                                        )
                                        .map((opportunity) => (
                                            <div
                                                className="room opportunity-related"
                                                onClick={this.viewChat.bind(
                                                    this,
                                                    messaging.currentMessageParticipants,
                                                    opportunity.opportunityId
                                                )}
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/opportunity_chat.svg`}
                                                    alt="Opportunity chat"
                                                />
                                                {opportunity.headline}
                                            </div>
                                        ))}
                                </section>
                            </div>
                            <div className="view-actions">
                                <Button
                                    onClick={this.openRecipientView.bind(this)}
                                >
                                    Back
                                </Button>
                                <Button
                                    onClick={this.viewChat.bind(
                                        this,
                                        messaging.currentMessageParticipants,
                                        null
                                    )}
                                >
                                    Skip
                                </Button>
                            </div>
                        </div>
                    )}
                    {messaging.messageCenterView === "chat" && (
                        <div
                            className={
                                messaging.roomLoading
                                    ? "chat-view loading"
                                    : "chat-view"
                            }
                        >
                            <header>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/close.svg`}
                                    onClick={this.toggleMessageCenter.bind(
                                        this
                                    )}
                                    className="close"
                                    alt="Close"
                                />
                                <h4 onClick={this.viewAllMessages.bind(this)}>
                                    <span>&#9666;</span> All messages
                                </h4>
                            </header>
                            <div className="message-participants">
                                {messaging.currentMessageParticipants.length ===
                                    1 && (
                                    <>
                                        <ProfileIcon
                                            person={
                                                messaging
                                                    .currentMessageParticipants[0]
                                            }
                                        />
                                        <p>
                                            <strong>
                                                {
                                                    messaging
                                                        .currentMessageParticipants[0]
                                                        .name
                                                }
                                            </strong>
                                        </p>
                                    </>
                                )}
                                {messaging.currentMessageParticipants.length >
                                    1 && (
                                    <>
                                        <MultiProfileIcon
                                            people={
                                                messaging.currentMessageParticipants
                                            }
                                        />
                                        <p>
                                            {relevantPeople(
                                                messaging.currentMessageParticipants
                                            )}
                                        </p>
                                    </>
                                )}
                            </div>
                            {messaging.currentChat &&
                                messaging.currentChat.opportunity && (
                                    <div className="related-opportunity-details">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/opportunity_chat_blue.svg`}
                                            alt="Opportunity chat"
                                        />
                                        <Link
                                            to={
                                                messaging.currentChat
                                                    .opportunity.isBusiness
                                                    ? `/businesses/${messaging.currentChat.opportunity.opportunityId}`
                                                    : `/opportunities/${messaging.currentChat.opportunity.opportunityId}`
                                            }
                                        >
                                            <div>
                                                <p>
                                                    {
                                                        messaging.currentChat
                                                            .opportunity
                                                            .headline
                                                    }
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            <div
                                className={
                                    messaging.currentChat &&
                                    messaging.currentChat.opportunity
                                        ? "view-body with-opportunity"
                                        : "view-body"
                                }
                            >
                                {(!messaging.currentChat ||
                                    messaging.currentChat.chat.length ===
                                        0) && (
                                    <p className="first-messages">
                                        This is the beginning of your chat
                                        history with{" "}
                                        {relevantPeople(
                                            messaging.currentMessageParticipants
                                        )}
                                        {messaging.currentChat &&
                                            messaging.currentChat.opportunity &&
                                            " about this opportunity"}
                                        .
                                    </p>
                                )}
                                {messaging.currentChat &&
                                    messaging.currentChat.chat.map(
                                        (message) => (
                                            <Message
                                                user={user}
                                                message={message}
                                            />
                                        )
                                    )}
                            </div>
                            <div className="send-message">
                                {messaging.messageTutorial && (
                                    <div className="message-tutorial">
                                        Please feedback to{" "}
                                        {
                                            messaging
                                                .currentMessageParticipants[0]
                                                .name
                                        }{" "}
                                        here
                                    </div>
                                )}

                                <TextInput
                                    id="chat-message-input"
                                    type="text"
                                    onInput={this.writeMessage.bind(this)}
                                    onEnter={this.sendMessage.bind(this)}
                                    placeholder="A a"
                                />
                                <button onClick={this.sendMessage.bind(this)}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/send.svg`}
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                </PlainCard>
            </div>
        );
    }
}

export default MessageCenter;
